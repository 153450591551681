import {
  ETaskAdditions,
  EUserStatus,
  IColumn,
  InputCheckbox,
  IPListType,
} from "@/shared";
import React from "react";
import { SortableHeaderCell } from "react-data-grid";
import { IRenderColumnsProps } from "../interfaces";
import ClipSVGIcon from "../../../assets/img/clip.svg";
import { SELECT_COLUMN_KEY } from "../configs";
import { CommentIcon, StarIcon } from "@/shared/components/icons";
import { Input as InputAntd, Select, DatePicker } from "antd";
import moment from "moment";

export const headerRender = (
  item: { column: IColumn },
  props: IRenderColumnsProps
) => {
  const onChangeDate = (selectedDate: moment.Moment, fieldKey: string) => {
    const date = selectedDate ? selectedDate.format("MM DD YYYY") : null;
    props.setTableFilter({
      ...props.tableFilter,
      [fieldKey]: date,
    });
    props.paginationList.setLoadParams({
      [fieldKey]: date,
    });
  };

  const getHeaderCell = (column: IColumn) => {
    if (column.sortable) {
      return (
        <SortableHeaderCell
          priority={null}
          sortDirection={props.sortBy ? props.sortBy[column?.key] : undefined}
          onSort={() => props.onSort(column)}
        >
          {column.name}
        </SortableHeaderCell>
      );
    }
    if (column.key === SELECT_COLUMN_KEY)
      return (
        <InputCheckbox
          style={{
            height: "50%",
            alignItems: "center",
            display: "flex",
          }}
          size={20}
          value={props.allSelected}
          onChange={props.onSelectAll}
        />
      );
    if (column.key === "additions")
      return (
        <div>
          <img src={ClipSVGIcon} style={{ width: 20, height: 20 }} />
          <span>{"/"}</span>
          <CommentIcon width={22} height={22} color="#3b3b3b" />
        </div>
      );
    if (column.key === "isFavorite")
      return (
        <div style={{ textAlign: "center" }}>
          <StarIcon />
        </div>
      );

    return <div>{column.name}</div>;
  };

  if (item.column.filter && props.showFilter) {
    if (item.column.filterType === "search")
      return (
        <>
          {getHeaderCell(item.column)}
          <InputAntd
            name={item.column.key}
            autoFocus={props.focusedFilterField === item.column.key}
            onFocus={() => props.onFocusFilterField(item.column.key)}
            onChange={(e) => {
              props.setTableFilter({
                ...props.tableFilter,
                [e.target.name]: e.target.value,
              });
              props.paginationList.setLoadParams({
                [e.target.name]: e.target.value,
              });
            }}
            value={props.tableFilter[item.column.key]}
          />
        </>
      );
    if (item.column.filterType === "datePicker")
      return (
        <>
          {getHeaderCell(item.column)}
          <DatePicker
            autoFocus={props.focusedFilterField === item.column.key}
            name={item.column.key}
            onFocus={() => props.onFocusFilterField(item.column.key)}
            className={`date-picker-table`}
            dropdownClassName={`date-picker-dropdown-${item.column.key}`}
            placeholder="Виберіть дату"
            allowClear={true}
            format={
              item.column.key === "dateOfBirth" ? "DD MMMM" : "DD-MM-YYYY"
            }
            onChange={(date) => onChangeDate(date, item.column.key)}
            showToday={false}
            value={
              props.tableFilter[item.column.key]
                ? moment(props.tableFilter[item.column.key])
                : null
            }
          />
        </>
      );
    if (item.column.filterType === "userStatus") {
      const statusValue = {
        [EUserStatus.Active]: "Активний",
        [EUserStatus.Blocked]: "Заблоковано",
      };
      return (
        <>
          {getHeaderCell(item.column)}
          <Select
            allowClear
            value={statusValue[props.tableFilter[item.column.key]]}
            style={{ width: "100%" }}
            onFocus={() => props.onFocusFilterField(item.column.key)}
            onChange={(val) => {
              props.setTableFilter({
                ...props.tableFilter,
                [item.column.key]: val,
              });
              props.paginationList.setLoadParams({
                [item.column.key]: val,
              });
            }}
          >
            <Select.Option value={EUserStatus.Active}>Активний</Select.Option>
            <Select.Option value={EUserStatus.Blocked}>
              Заблоковано
            </Select.Option>
          </Select>
        </>
      );
    }
    if (item.column.filterType === "ipStatus") {
      const statusValue = {
        [IPListType.Black]: "Заблоковано",
        [IPListType.White]: "Активний",
      };
      return (
        <>
          {getHeaderCell(item.column)}

          <Select
            allowClear
            value={statusValue[props.tableFilter[item.column.key]]}
            style={{ width: "100%" }}
            onFocus={() => props.onFocusFilterField(item.column.key)}
            onChange={(val) => {
              props.setTableFilter({
                ...props.tableFilter,
                [item.column.key]: val,
              });
              props.paginationList.setLoadParams({
                [item.column.key]: val,
              });
            }}
          >
            <Select.Option value={IPListType.White}>Активний</Select.Option>
            <Select.Option value={IPListType.Black}>Заблоковано</Select.Option>
          </Select>
        </>
      );
    }
    if (item.column.filterType === "files_comments") {
      return (
        <>
          {getHeaderCell(item.column)}
          <Select
            allowClear
            value={props.tableFilter[item.column.key]}
            style={{ width: "100%", color: "rgba(162,162,162,.65)" }}
            onFocus={() => props.onFocusFilterField(item.column.key)}
            onChange={(val) => {
              props.setTableFilter({
                ...props.tableFilter,
                [item.column.key]: val,
              });
              props.paginationList.setLoadParams({
                [item.column.key]: val,
              });
            }}
          >
            <Select.Option value={ETaskAdditions.Documents}>
              <img src={ClipSVGIcon} style={{ width: 20, height: 20 }} />
            </Select.Option>
            <Select.Option value={ETaskAdditions.Comments}>
              <CommentIcon width={22} height={22} color="#3b3b3b" />
            </Select.Option>
            <Select.Option
              value={ETaskAdditions.All}
              style={{ color: "rgba(162,162,162,.65)" }}
            >
              <img src={ClipSVGIcon} style={{ width: 20, height: 20 }} /> +{" "}
              <CommentIcon width={22} height={22} color="#3b3b3b" />
            </Select.Option>
          </Select>
        </>
      );
    }
  }

  return getHeaderCell(item.column);
};
