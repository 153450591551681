import React from "react";
import { ButtonToolbar, Button } from "reactstrap";
import ModalComponent from "@/components/Modal";

import { UserSearchWithBtn } from "@/containers/User/components";
import { UsersSelectList } from "@/containers/User/components";
import { useSelector } from "react-redux";
import {
  SelectAll,
  selectSelectedUsers,
  SelectUser,
  UnselectUser,
} from "@/store/users";
import { useFetchChatUsers } from "@/containers/User/hooks";
import { simpleDispatch } from "@/store/store-helpers";

interface IProps {
  isShow: boolean;
  setOpen: (val: boolean) => void;
  addUsers: () => void;
  excludeIds?: number[];
}

export const AddUsersModal = ({
  isShow,
  setOpen,
  excludeIds,
  addUsers,
}: IProps) => {
  const onAddUsers = async () => {
    addUsers();
    setOpen(false);
  };

  const onToggle = () => {
    setOpen(false);
    simpleDispatch(new SelectAll({ items: [] }));
  };

  const selectedUsers = useSelector(selectSelectedUsers);

  const {
    items,
    searchString,
    setSearchVal,
    loadMore,
    loadParams,
  } = useFetchChatUsers();

  return (
    <ModalComponent title="Додати учасників" show={isShow} toggle={onToggle}>
      <div className="chat-modal-container">
        <div className="add-users-header-info">
          <UserSearchWithBtn
            items={items}
            searchStr={searchString}
            onChangeContact={setSearchVal}
            selectAll={(items) => simpleDispatch(new SelectAll({ items }))}
          />
        </div>

        <UsersSelectList
          items={items}
          selectedUsers={selectedUsers}
          selectOne={(user) => simpleDispatch(new SelectUser({ user }))}
          unSelectOne={(userId) => simpleDispatch(new UnselectUser({ userId }))}
          excludeIds={excludeIds}
          loadMore={loadMore}
          count={loadParams.count}
        />

        <ButtonToolbar className="users__add-chat">
          <Button
            style={{ width: "110px", height: "40px" }}
            disabled={""}
            name={"allSelect"}
            color="primary"
            onClick={onAddUsers}
            title={"Додати"}
          >
            Додати
          </Button>
        </ButtonToolbar>
      </div>
    </ModalComponent>
  );
};
