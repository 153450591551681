import React, { FC, useEffect, useRef, useState } from "react";
import "./style.scss";
import moment from "moment";
import { Button } from "reactstrap";
import { useReactToPrint } from "react-to-print";
import { Controller, useForm } from "react-hook-form";
import { DateField } from "@/components/Fields";
import { PrintComponent } from "./components";
import { ITasksCountsByAuthor, IUser } from "@/shared";
import { tasksService } from "@/services/domain";
import _ from "lodash";

interface IProps {
  profile: IUser;
  authProfile: any;
  onSubmit: () => void;
  countTaskStats?: ITasksCountsByAuthor;
}

interface ICountTasksForm {
  from: Date;
  to: Date;
}

export const CountTask: FC<IProps> = ({
  profile,
  authProfile,
  countTaskStats,
}) => {
  const [dateValues, setDateValues] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [countTasksStatsValue, setCountTasksStats] = useState<
    ITasksCountsByAuthor
  >(countTaskStats);

  useEffect(() => {
    setCountTasksStats(countTaskStats);
  }, [countTaskStats]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { control, getValues, handleSubmit } = useForm<ICountTasksForm>({
    defaultValues: { from: null, to: null },
  });

  const submitSearchTasks = async (data: ICountTasksForm) => {
    try {
      const preparedValues = {
        from: moment(data.from).format("YYYY-MM-DD"),
        to: moment(data.to).format("YYYY-MM-DD"),
      };
      const preparedForPrintValues = {
        from: data.from,
        to: data.to,
      };

      const resultSearch = await tasksService.fetchTasksCountsByAuthor(
        profile.id,
        preparedValues
      );
      await setDateValues(preparedForPrintValues);
      await setCountTasksStats(resultSearch);
    } catch (e) {
      return console.log("error fetching tasks count", e);
    }
  };

  const handleDateChange = (dateName, dateValue) => {
    setDateValues({
      ...dateValues,
      [dateName]: dateValue,
    });
  };

  const { from } = getValues();

  return (
    <div className="count-task-user">
      <div className="title">
        Підрахунок поставлених{" "}
        {profile.id === authProfile.id ? "вами" : "користувачем"} задач
      </div>

      <div className="form-content">
        <div className="start-date">
          <Controller
            name={"from"}
            control={control}
            rules={{ required: "Заповнити обов'язково" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DateField
                label={"Початок"}
                value={_.isEmpty(value) ? "" : value}
                onChange={(date) => {
                  onChange(date);
                  handleDateChange("from", date ? date : new Date());
                }}
                meta={{
                  error: error?.message,
                }}
                disabledDate={(current) => current.isAfter(moment())}
              />
            )}
          />
        </div>

        <div className="end-date">
          <Controller
            name={"to"}
            control={control}
            rules={{ required: "Заповнити обов'язково" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DateField
                label={"Кінець"}
                value={_.isEmpty(value) ? "" : value}
                onChange={(date) => {
                  onChange(date);
                  handleDateChange("to", date ? date : new Date());
                }}
                meta={{
                  error: error?.message,
                }}
                disabledDate={(current) => current.isBefore(from)}
              />
            )}
          />
        </div>
      </div>
      <div className="search-btn">
        <Button
          onClick={handleSubmit(submitSearchTasks)}
          className="btn btn-primary"
          type="button"
        >
          Пошук
        </Button>
      </div>

      <div className="content-block">
        <div className="content-row">
          <p className="title-row">Унікальних: </p>
          <p className="value-row">{countTasksStatsValue.unique}</p>
        </div>
        <div className="content-row">
          <p className="title-row">Дублікатів: </p>
          <p className="value-row">{countTasksStatsValue.duplicate}</p>
        </div>
        <div className="content-row">
          <p className="title-row">Власних: </p>
          <p className="value-row">{countTasksStatsValue.personal}</p>
        </div>
        <div className="content-row">
          <p className="title-row">Загальна кількість: </p>
          <p className="value-row">{countTasksStatsValue.all}</p>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <PrintComponent
          ref={componentRef}
          taskCounts={{
            unique: countTasksStatsValue.unique,
            copying: countTasksStatsValue.duplicate,
            self: countTasksStatsValue.personal,
            total: countTasksStatsValue.all,
          }}
          // taskCountsStats={countTasksStatsValue}
          profile={profile}
          startDate={dateValues.from}
          endDate={dateValues.to}
        />
      </div>

      <div className="print-btn">
        <Button color="primary" type="button" onClick={handlePrint}>
          Друкувати
        </Button>
      </div>
    </div>
  );
};
