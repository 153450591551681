import React from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { getProfile } from "../../store/account/selectors";
import { EUserRole } from "@/shared";
import { useHistory } from "react-router-dom";
import { IpsTable } from "./components/Data";

export const Ips = () => {
  const profile = useSelector(getProfile);
  const history = useHistory();

  if (profile.role !== EUserRole.Admin) {
    history.push("/");
    return null;
  }

  return (
    <Container className="task">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <IpsTable />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
