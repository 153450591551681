import { chatMembersService, chatsService } from "@/services/domain";
import {
  ChatMemberRole,
  ChatType,
  IChatDetails,
  IChatMember,
  useEventsListener,
  useSocketListener,
} from "@/shared";
import { getProfile } from "@/store/account";
import { selectSelectedChatId } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";
import { selectSelectedUsers, UnselectAllUsers } from "@/store/users";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { transformShrotUserToChatMember } from "../transforms";
import { appEvents } from "@/shared/events";

export const useEditGroupChat = (id?: number) => {
  const [chatDetails, setChatDetails] = useState<IChatDetails>(null);
  const [chatMembers, setMembers] = useState<IChatMember[]>([]);
  const [membersIdsToAdd, setMembersIdsToAdd] = useState<number[]>([]);
  const [role, setRole] = useState<ChatMemberRole>(ChatMemberRole.Admin);
  const [isChatMuted, setMuted] = useState<boolean>(null);
  const [previewImg, setPreviewImg] = useState<unknown>(null);

  const selectedChatId = useSelector(selectSelectedChatId);
  const selectedUsers = useSelector(selectSelectedUsers);

  const chatId = id || selectedChatId;
  const account = useSelector(getProfile);

  const fetchDetails = async () => {
    const chatDetails = await chatsService.fetchChatDetails({ id: chatId });
    setChatDetails(chatDetails);
  };

  useEffect(() => {
    fetchDetails();
  }, [chatId]);

  const setChatMembers = useCallback(
    (chat: IChatDetails) => {
      const members = chat?.chatMembers;

      if (chat.type === ChatType.Personal) {
        setMembers(members);
      } else {
        const admins = members?.filter(
          (it) => it.role === ChatMemberRole.Admin && !it.isDeleted
        );
        const adminsIds = admins?.map((it) => it.userId);
        const membersWithoutAdmins = members?.filter(
          (it) => !adminsIds?.includes(it.userId) && !it.isDeleted
        );

        const membersWithAdminsAtBeginning = admins?.concat(
          ...membersWithoutAdmins
        );

        // const preparedMembersToAdd = selectedUsers.map((it) =>
        //   transformShrotUserToChatMember(chatId, it)
        // );

        const preparedChatMembers = _.unionBy(
          membersWithAdminsAtBeginning,
          // preparedMembersToAdd,
          "userId"
        );

        setMembers(preparedChatMembers);
      }
    },
    [chatDetails]
  );

  const onAddUsersToChat = async () => {
    try {
      if (!_.isEmpty(membersIdsToAdd)) {
        await chatMembersService.addMember({
          chatId,
          usersIds: membersIdsToAdd,
        });
        appEvents.emit("onAddMember", {});
        fetchDetails();
      }

      simpleDispatch(new UnselectAllUsers());
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeMemberRole = (data: {
    memberId: number;
    role: ChatMemberRole;
  }) => {
    const member = _.find(chatMembers, (member) => member.id === data.memberId);
    if (member) {
      const changeMember = (member: IChatMember) => {
        if (member.id === data.memberId)
          return {
            ...member,
            role: data.role,
          };

        return member;
      };

      const changedMembers = chatMembers.map(changeMember);
      setMembers(
        _.sortBy(
          changedMembers,
          (member) => member.role === ChatMemberRole.Member
        )
      );

      const allMembers = chatDetails.chatMembers.map(changeMember);
      setChatDetails({ ...chatDetails, chatMembers: allMembers });
    }
  };

  const onDeleteMember = (data: { memberId: number }) => {
    const member = _.find(chatMembers, (member) => member.id === data.memberId);
    if (member) {
      const changedMembers = chatMembers.filter(
        (member) => member.id !== data.memberId
      );
      setMembers(changedMembers);
    }

    const allMembers = chatDetails.chatMembers.filter(
      (member) => member.id !== data.memberId
    );
    setChatDetails({ ...chatDetails, chatMembers: allMembers });
  };

  useEffect(() => {
    const activeMembers = chatDetails?.chatMembers?.filter(
      (it) => !it.isDeleted
    );
    const activeMembersIds = activeMembers?.map((it) => it.userId);

    const selectedMembersToAdd = selectedUsers.map((it) =>
      transformShrotUserToChatMember(chatId, it)
    );
    const chatMembersToAddIds = selectedMembersToAdd?.map((it) => it.userId);

    const membersIdsToAdd = chatMembersToAddIds?.filter(
      (id) => !activeMembersIds?.includes(id)
    );

    setMembersIdsToAdd(membersIdsToAdd);
  }, [chatDetails, chatMembers]);

  const setMemberRole = (members: IChatMember[]) => {
    const member = _.find(members, (member) => member?.userId === account.id);
    setRole(member?.role ? member?.role : ChatMemberRole.Member);
  };

  const updateChatData = (id: number) => {
    if (id !== chatId) return;
    fetchDetails();
  };

  const onEditChat = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  useEffect(() => {
    if (chatDetails) {
      setChatMembers(chatDetails);
      setMemberRole(chatDetails?.chatMembers);
    }
  }, [chatDetails, selectedUsers]);

  useEffect(() => {
    if (chatDetails) {
      setMuted(chatDetails?.isChatMuted);
    }
  }, [chatDetails]);

  // APP EVENTS AND SOCKET LISTENERS //

  useEventsListener("onChangeMemberRole", onChangeMemberRole, [chatMembers]);
  useEventsListener("onDeleteMember", onDeleteMember, [
    chatMembers,
    selectedUsers,
  ]);

  useSocketListener("chat/edit-chat", onEditChat, [chatDetails]);

  return {
    chatInfo: chatDetails,
    chatMembers,
    isChatMuted,
    setMuted,
    role,
    previewImg,
    setPreviewImg,
    onAddUsersToChat,
  };
};
