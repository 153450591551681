import React, { FC } from "react";
import { Card, CardBody, Col } from "reactstrap";
import _ from "lodash";
import {
  createFullName,
  ITasksCountsByAuthor,
  IUser,
  IUserStats,
} from "@/shared";
import { CountUser } from "./count";
import { Avatar } from "./avatar";
import { Actions } from "./Actions";
import { CountTask } from "./CountTask/count-task.component";

interface IProps {
  isUpdate: boolean;
  profile: IUser;
  authProfile?: IUser;
  countStats?: IUserStats;
  countTasksStats?: ITasksCountsByAuthor;
  onPressUserAction?: {
    ban: () => void;
    logout: () => void;
  };
  onChangeAvatar?: () => void;
  onDeleteAvatar?: () => void;
}

export const ProfileMain: FC<IProps> = ({
  isUpdate,
  profile,
  authProfile,
  countStats,
  countTasksStats,
  onPressUserAction,
  onChangeAvatar,
  onDeleteAvatar,
}) => {
  return (
    <>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card">
            <div
              className="profile__information"
              style={{ border: "none", padding: 0 }}
            >
              <div>
                <Avatar
                  profile={profile}
                  authProfile={authProfile}
                  isUpdate={isUpdate || profile.id === authProfile.id}
                  onChangeAvatar={onChangeAvatar}
                  onDeleteAvatar={onDeleteAvatar}
                />
              </div>

              <div className="profile__data">
                <p className="profile__name">
                  {createFullName(
                    profile.info?.firstName,
                    profile.info?.middleName,
                    profile.info?.lastName
                  )}
                </p>
                <p className="profile__work">
                  {(profile.factoryName ? `${profile.factoryName}, ` : "") +
                    `${profile.info.position}`}
                </p>
                {/* <div className="profile__data_card">
                  <p className="profile__data-tooltip">Email: </p>
                  <p className="profile__contact">{profile.email}</p>
                </div>
                <div className="profile__data_card">
                  <p className="profile__data-tooltip">Логін: </p>
                  <p className="profile__contact">
                    {profile?.login || `login name`}
                  </p>
                </div> */}
              </div>
            </div>

            {!_.isEmpty(countStats) ? (
              <CountUser
                userId={profile.id}
                groupCount={countStats.groupsCount}
                taskCount={countStats.completedTasksCount}
                commentCount={countStats.commentsCount}
              />
            ) : (
              <CountUser
                userId={0}
                groupCount={0}
                taskCount={0}
                commentCount={0}
              />
            )}
          </CardBody>
        </Card>
      </Col>

      <Actions
        profile={profile}
        authProfile={authProfile}
        onPressBlock={onPressUserAction?.ban}
        onPressLogout={onPressUserAction?.logout}
      />

      <Col md={12} lg={12} xl={12} style={{ marginBottom: "40px" }}>
        <CountTask
          profile={profile}
          authProfile={authProfile}
          onSubmit={() => {}}
          countTaskStats={{ unique: 0, duplicate: 0, personal: 0, all: 0 }}
        />
      </Col>
    </>
  );
};
