import React from "react";
// import { EUserStatus } from "@/shared";
import moment from "moment";
import "../styles.scss";
import {
  voidColumn,
  // VOID_COLUMN_KEY,
} from "@/components/TableGrid/configs/void-row.config";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { createFullName } from "@/shared";

export const columnsConfig = () => {
  const history = useHistory();
  const onPressName = (e, row) => {
    if (e.detail === 2) {
      history.push("/contact", { contactinfo: row });
    }
  };
  const result: any = [
    voidColumn,
    {
      name: "Аватар",
      key: "avatarUrl",
      filter: false,
      width: 65,
      maxWidth: 75,
      resizable: false,
      formatter: ({ row }) => {
        if (_.isEmpty(row)) return null;
        return row.avatarUrl ? (
          <img
            src={row.avatarUrl}
            style={{
              height: 40,
              width: 40,
              borderRadius: 100,
              objectFit: "fill",
            }}
            alt="avatarka"
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/img/default-avatar.jpg`}
            style={{
              height: 40,
              width: 40,
              borderRadius: 100,
              objectFit: "fill",
            }}
            alt="avatarka"
          />
        );
      },
    },
    {
      name: "П.І.Б.",
      key: "name",
      sortKey: "lastName", // допоміжне поле, використовується, якщо ключ для сортування по цьому полю
      // (назва поля по якому треба сортувати) відрізняється від поля key
      // width: 260,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      sortDirection: "ASC",
      formatter: ({ row }) => (
        <div className="column-name" onClick={(e) => onPressName(e, row)}>
          <div className="info">
            <div className="ellipsis" style={{ cursor: "pointer" }}>
              <span className="full-name">
                {createFullName(row.firstName, row.middleName, row.lastName)}
              </span>
            </div>
            <span className="position ellipsis">{row.position}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Підрозділ",
      key: "factory",
      // width: 135,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div onClick={(e) => onPressName(e, row)}>
            <span className="column-with-secondary-txt">
              {row?.factoryName || ""}
            </span>
          </div>
        );
      },
    },
    {
      name: "Посада",
      key: "position",
      // width: 165,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(e, row)}>
            <span>{row?.position}</span>
          </div>
        );
      },
    },
    {
      name: "Тел. робочий",
      key: "phoneNumber",
      // width: 160,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div onClick={(e) => onPressName(e, row)}>
            <span
              onClick={(e) => onPressName(e, row)}
              className="column-with-secondary-txt"
            >
              {row?.phoneNumber || ""}
            </span>
          </div>
        );
      },
    },
    {
      name: "Тел. особистий",
      key: "personalPhoneNumber",
      // width: 160,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div onClick={(e) => onPressName(e, row)}>
            <span
              onClick={(e) => onPressName(e, row)}
              className="column-with-secondary-txt"
            >
              {row?.personalPhoneNumber || ""}
            </span>
          </div>
        );
      },
    },
    {
      name: "Тел. внутрішній",
      key: "innerPhoneNumber",
      // width: 160,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div onClick={(e) => onPressName(e, row)}>
            <span
              onClick={(e) => onPressName(e, row)}
              className="column-with-secondary-txt"
            >
              {row?.innerPhoneNumber || ""}
            </span>
          </div>
        );
      },
    },
    {
      name: "Email",
      dataIndex: "email",
      key: "email",
      // width: 165,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(e, row)}>
            <span onClick={(e) => onPressName(e, row)}>{row?.email}</span>
          </div>
        );
      },
    },
    {
      name: "Дата народження",
      dataIndex: "dateOfBirth",
      key: "dateOfBirthStr",
      // width: 185,
      minWidth: 150,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(e, row)}>
            <span onClick={(e) => onPressName(e, row)}>
              {row?.dateOfBirth
                ? moment(row?.dateOfBirth).format("DD MMMM")
                : ""}
            </span>
          </div>
        );
      },
    },
  ];
  return result;
};

export const defaultActiveColumnsConfig = [
  "select-row",
  "avatarUrl",
  "subdivision",
  "position",
  "phoneNumber",
  "innerPhoneNumber",
  "name",
  "login",
  "email",
  "dateOfBirth",
  "loginOnDesktopDate",
  "loginOnMobileDate",
  "factories",
  "status",
  "factory",
];
