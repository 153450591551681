import { ITaxonomy, ICreateOrUpdateTaxonomy } from '@/shared'
import { createReducer } from '@bitalikrty/redux-create-reducer'
import { TTaxonomiesActions } from './types'
import _ from 'lodash'
import { taxonomiesService } from "@/services/domain/taxonomies.service"

export interface ITaxonomiesState {
	isLoading: boolean
	taxonomiesList: ITaxonomy[]
	targetTaxonomy: ICreateOrUpdateTaxonomy
	count: number
	filter?: any
}

const initialState: ITaxonomiesState = {
	isLoading: false,
	taxonomiesList: [],
	targetTaxonomy: null,
	count: null
}

export const TaxonomiesReducer = createReducer<ITaxonomiesState, TTaxonomiesActions>(
	initialState,
	{
		SET_TAXONOMIES: (state, action) => {
			return {
				...state,
				taxonomiesList: action.payload.taxonomies,
			}
		},

		CREATE_TAXONOMY: (state, action) => {
			return {
				...state,
				...state.taxonomiesList,
				taxonomiesList: state.taxonomiesList
			}
		},

		UPDATE_TAXONOMY: (state, action) => {
			return {
				...state,
				...state.taxonomiesList,
				taxonomiesList: state.taxonomiesList
			}
		},

		DELETE_TAXONOMY: (state, action) => {
			return {
				...state,
				...state.taxonomiesList,
				taxonomiesList: state.taxonomiesList
			}
		},

		IS_LOADING_TAXONOMIES: (state, action) => {
			return {
				...state,
				isLoading: action.payload.isLoading,
			}
		},

		SET_TARGET_TAXONOMY: (state, action) => {
			return {
				...state,
				targetTaxonomy: action.payload.taxonomy
			}
		},

		DELETE_ICON: (state) => {
			return {
				...state,
				...state.targetTaxonomy,
				targetTaxonomy: { ...state.targetTaxonomy, iconUrl: null }
			}
		}
	},
)
