import s1 from "@/assets/stickers/s1.png";
import s2 from "@/assets/stickers/s2.png";
import s3 from "@/assets/stickers/s3.png";
import s4 from "@/assets/stickers/s4.png";
import s5 from "@/assets/stickers/s5.png";
import s6 from "@/assets/stickers/s6.png";
import s7 from "@/assets/stickers/s7.png";
import s8 from "@/assets/stickers/s8.png";

import briefcase from "@/assets/stickers/s-briefcase.png";
import calculator from "@/assets/stickers/s-calculator.png";
import lightBulb from "@/assets/stickers/s-light-bulb.png";
import pieChart from "@/assets/stickers/s-pie-chart.png";
import pos from "@/assets/stickers/s-pos.png";
import shoppingCart from "@/assets/stickers/s-shopping-cart.png";
import statistics from "@/assets/stickers/s-statistics.png";
import target from "@/assets/stickers/s-target.png";

export const stickersList = {
  "new-briefcase": briefcase,
  "new-calculator": calculator,
  "new-light-bulb": lightBulb,
  "new-pie-chart": pieChart,
  "new-pos": pos,
  "new-shopping-cart": shoppingCart,
  "new-statistics": statistics,
  "new-target": target,

  "1s": s1,
  "2s": s2,
  "3s": s3,
  "4s": s4,
  "5s": s5,
  "6s": s6,
  "7s": s7,
  "8s": s8,
};
