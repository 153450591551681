import { IMessage, MessageType } from "@/shared";
import default_avatar from "@/assets/img/default-avatar.jpg";

export const messagesMockConfig: IMessage[] = [
  {
    id: 0,
    content: {
      message:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    },
    authorId: 47,
    author: {
      name: "Artem Осядлий",
      id: 47,
      avatar: default_avatar,
    },
    createdAt: "06-17-2015 14:24:36",
    type: MessageType.Text,
    isMy: false,
    isPined: false,
    read: true,
  },
  {
    id: 1,
    content: {
      message: "Amet minim mollit.",
    },
    authorId: 40,
    author: {
      name: "Оксана",
      id: 47,
      avatar: default_avatar,
    },
    createdAt: "06-17-2015 14:34:36",
    type: MessageType.Text,
    isMy: true,
    isPined: false,
    read: false,
  },
  {
    id: 2,
    content: {
      fileUrl: "https://i.imgur.com/c1foeTp.jpeg",
    },
    authorId: 40,
    author: {
      name: "Оксана",
      id: 47,
      avatar: default_avatar,
    },
    createdAt: "06-17-2015 14:36:36",
    type: MessageType.Image,
    isMy: true,
    isPined: false,
    read: true,
  },
];
