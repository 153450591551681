import ModalComponent from "@/components/Modal";
import { UserSelectWithSearch } from "@/components/SmartComponents";
import { EUsersListType } from "@/shared";
import React, { useState } from "react";
import { FC } from "react";
import { Button } from "reactstrap";

interface IProps {
  title: string;
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  onSubmit: (userIds: string[]) => void;
}

export const ReassignTasksModal: FC<IProps> = ({
  title,
  isOpen,
  setOpen,
  onSubmit,
}) => {
  const [userIds, setUserIds] = useState<string[]>([]);
  const submit = () => {
    onSubmit(userIds);
    setUserIds([]);
    setOpen(false);
  };

  return (
    <ModalComponent title={title} show={isOpen} toggle={() => setOpen(false)}>
      <div className="reassign-task-modal_content">
        <UserSelectWithSearch
          label="Виконавець:"
          type={EUsersListType.Executors}
          value={userIds}
          onChange={(val: string[]) => setUserIds(val)}
          mode="multiple"
        />

        <Button onClick={submit} color="primary" className="submit-btn">
          Зберегти
        </Button>
      </div>
    </ModalComponent>
  );
};
