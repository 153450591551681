import {
  ChatMemberRole,
  createFullName,
  createFullRolePreviewText,
  hasImageUrl,
  IChatMember,
} from "@/shared";
import React, { FC } from "react";
import {
  ChatAvatarWithOnlineIndicator,
  DeleteUserButton,
  SetUserAdminButton,
} from "../atoms";
import { useChatMembers } from "../hooks";

interface IProps {
  userRole: ChatMemberRole;
  members: IChatMember[];
  ignoreRole?: boolean;
}

export const ChatMembersListSmart: FC<IProps> = ({
  userRole,
  members,
  ignoreRole,
}) => {
  const { changeMemberRole, deleteMember } = useChatMembers();

  return (
    <div
      className="setting-user-list-container"
      style={{ paddingRight: members.length > 4 ? 40 : 0 }}
    >
      {members.map((item, index) => (
        <div className="settings-user-row-container" key={index}>
          <div className="settings-user-info">
            <ChatAvatarWithOnlineIndicator
              isOnline={item.isOnline}
              imageUrl={hasImageUrl(item.user.avatarUrl, item.user.firstName)}
              size={55}
            />
            <div className="user-names-role">
              <span>
                {createFullName(item.user?.firstName, item.user?.lastName)}
              </span>
              <p>{`(${createFullRolePreviewText(
                ignoreRole ? ChatMemberRole.Member : item.role
              )})`}</p>
            </div>
          </div>
          {userRole === ChatMemberRole.Admin ? (
            <div className="settings-user-btns">
              <SetUserAdminButton
                role={item.role}
                setUserAsAdmin={() => {
                  if (item.role === ChatMemberRole.Member)
                    changeMemberRole({
                      member: item,
                      role: ChatMemberRole.Admin,
                    });
                }}
                setUserAsMember={() => {
                  if (item.role === ChatMemberRole.Admin)
                    changeMemberRole({
                      member: item,
                      role: ChatMemberRole.Member,
                    });
                }}
                possibleToRemoveAdmin={
                  members?.filter((item) => item?.role === ChatMemberRole.Admin)
                    .length > 1
                }
              />
              <DeleteUserButton
                deleteUser={() => {
                  deleteMember(item);
                }}
                role={item.role}
                possibleToRemoveAdmin={
                  members?.filter((item) => item?.role === ChatMemberRole.Admin)
                    .length > 1
                }
              />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};
