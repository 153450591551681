import React from "react";
import { Badge } from "reactstrap";
import { NavLink } from "react-router-dom";
import { StorageKey } from "@/shared";

interface IProps {
  title: string;
  icon?: string;
  newLink?: boolean;
  route?: string;
  iconFontawesome?: string;
  iconCustom?: string;
  onClick?: () => void;
}

export const SidebarLink = (props: IProps) => {
  const onPress = () => {
    props.onClick();
    localStorage.setItem(StorageKey.Path, props.route);
  };

  return (
    <NavLink
      to={props.route}
      onClick={onPress}
      activeClassName="sidebar__link-active"
    >
      <li className="sidebar__link">
        {props.iconCustom ? (
          <div className="icon colored">
            <img style={{ width: 26 }} src={props.iconCustom} />
          </div>
        ) : (
          ""
        )}
        {props.icon ? (
          <span className={`sidebar__link-icon lnr lnr-${props.icon}`} />
        ) : (
          ""
        )}
        {props.iconFontawesome ? (
          <i className={`sidebar__link-icon ${props.iconFontawesome}`} />
        ) : (
          ""
        )}
        <p className="sidebar__link-title">
          {props.title}
          {props.newLink ? (
            <Badge className="sidebar__link-badge">
              <span>New</span>
            </Badge>
          ) : (
            ""
          )}
        </p>
      </li>
    </NavLink>
  );
};
