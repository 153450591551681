import React from "react";
import { ButtonToolbar, Button } from "reactstrap";
import { InputField, isValidIP } from "@/shared";
import { Controller, useForm } from "react-hook-form";
interface IForm {
  ip: string;
}
interface IProps {
  listType: "w" | "b";
  onAddIp: (value: IForm) => void;
}

export const AddIpForm = (props: IProps) => {
  const { control, handleSubmit } = useForm<IForm>();

  return (
    <form className="form" onSubmit={handleSubmit(props.onAddIp)}>
      <Controller
        name="ip"
        control={control}
        rules={{
          required: "Заповнити обовязково",
          validate: (val: string) => isValidIP(val) || "Не валідна ІР адреса",
        }}
        render={({ field: { value, onChange }, fieldState }) => (
          <InputField
            error={fieldState?.error?.message}
            required={value?.length > 0 ? true : false}
            label="IP"
            value={value}
            placeholder={"xxx.xxx.xxx.xxx"}
            onChange={onChange}
          />
        )}
      />
      <ButtonToolbar className="form__add-IP">
        <Button style={{ width: 152 }} color="primary" type="submit">
          Зберегти
        </Button>
      </ButtonToolbar>
    </form>
  );
};
