import { IconComponent } from "@/shared";
import React from "react";
import downIcon from "@/assets/img/caretbottom-1.svg";

interface IProps {
  onPress: () => void;
}

export const ChatDownButton = ({ onPress }: IProps) => {
  return (
    <div className="chat-down-container">
      <button type="button" className="chat-down-button" onClick={onPress}>
        <IconComponent name={downIcon} />
      </button>
    </div>
  );
};
