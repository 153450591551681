import { MessageType } from "@/shared";
import _ from "lodash";
import moment from "moment";
import { IChatMessage } from "../plugins/interfaces";

export const getCopiedContent = (items: Partial<IChatMessage>[]) => {
  if (items.length === 1 && items[0].type === MessageType.Image) {
    return items[0].content.fileUrl;
  } else if (_.every(items, (it) => it.type === MessageType.Text)) {
    return createCopiedMessagesContent(items);
  }
};

function createCopiedMessagesContent(items: Partial<IChatMessage>[]) {
  const text = [];

  const sortedItems = _.orderBy(items, ["createdAt"], ["asc"]);

  for (const item of sortedItems) {
    const formattedContent = getFormattedMessageContent(item);
    text.push(formattedContent);
  }

  return text.join("\n");
}

function getFormattedMessageContent(item: Partial<IChatMessage>) {
  const dateAndTime = getDateAndTime(item);
  const authorName = _.defaultTo(item.author?.name, "");
  const text = _.defaultTo(item.content?.message, "");

  return `[${dateAndTime}] ${authorName}: ${text}`;
}

function getDateAndTime(item: Partial<IChatMessage>) {
  if (!item.createdAt) return "";
  return moment(item.createdAt).format("DD-MM-YYYY, HH:mm");
}
