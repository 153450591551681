import React, { FC, CSSProperties } from "react";
import copySvg from "@/assets/img/copy-icon-1.svg";

import "./styles.scss";
import { message } from "antd";
import PhoneInput from "react-phone-input-2";
import { copyText } from "@/shared/helpers/copy.helpers";

export interface ISelection {
  start: number;
  end: number;
}
export interface IInputState {
  value: string;
  selection: ISelection;
}

interface IProps {
  label?: string;
  value: string;
  placeholder: string;
  style?: CSSProperties;
  required?: boolean;
  error?: string;
  mask?: string;
  onChange: (val: string) => void;
  inputMaskProps?: any;
  enableCopy?: boolean;
}

export const InputMaskField: FC<IProps> = ({
  label,
  value,
  style,
  onChange,
  mask,
  error,
  inputMaskProps,
  enableCopy = false,
}) => {
  const onCopy = (value) => {
    copyText(value);
  };
  return (
    <div className="input-mask-field" style={style}>
      {label && <p className="input-label">{label}</p>}

      <div className="input-container" style={{ padding: "5px 0" }}>
        <PhoneInput
          defaultMask={mask}
          containerStyle={{
            flexDirection: "row-reverse",
            display: "flex",
            width: "auto",
          }}
          country={"ua"}
          enableSearch={false}
          value={value}
          buttonStyle={{
            position: "relative",
            border: "none",
            background: "transparent",
          }}
          inputStyle={{
            background: "transparent",
            paddingLeft: 0,
          }}
          onChange={(value: string) => {
            onChange(value);
          }}
          {...inputMaskProps}
        />
      </div>
      {error && <span className="error">{error}</span>}
      {enableCopy && (
        <img
          className="contact-copy"
          src={copySvg}
          alt="copy"
          onClick={() => onCopy(value)}
        />
      )}
    </div>
  );
};
