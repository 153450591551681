import { useSelector } from "react-redux";
import { IUpdateFactory } from "./../../../api/factories/requests.interface";
import { useEffect, useState } from "react";
import { factoriesService } from "@/services/domain";
import { IFactory } from "@/shared";
import { ICreateFactory } from "@/api/factories/requests.interface";
import { isLoadingFactories } from "@/store/factories";
import { factoriesApi } from "@/api";

export const useFactory = () => {
  const [factories, setFactories] = useState<IFactory[]>([]);
  const [needRefetch, setRefetch] = useState<boolean>(false);

  const isLoading = useSelector(isLoadingFactories);

  const fetchFactories = async () => {
    try {
      const { data: factories } = await factoriesApi.fetchFactoriesFullList();
      setFactories(factories);
    } catch (e) {
      console.log("FETCH FACTORIES ERROR", e);
    }
  };

  useEffect(() => {
    fetchFactories();
  }, []);

  useEffect(() => {
    if (needRefetch) {
      fetchFactories();
      setRefetch(false);
    }
  }, [needRefetch]);

  const createFactory = async (factory: ICreateFactory) => {
    await factoriesService.createFactory(factory);

    setRefetch(true);
  };

  const updateFactory = async (factory: IUpdateFactory) => {
    const updatedFactory = await factoriesService.updateFactory(factory);

    factories.forEach((it) => {
      if (it.id !== factory.id) return it;
      else return updatedFactory;
    });

    setFactories(factories);
    setRefetch(true);
  };

  const deleteFactory = async (id: number) => {
    await factoriesService.deleteFactory(id);

    factories.filter((it) => it.id !== id);

    setFactories(factories);
    setRefetch(true);
  };

  return {
    factories,
    isLoading,
    createFactory,
    updateFactory,
    deleteFactory,
  };
};
