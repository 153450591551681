import { genTree } from "@/lib/helper";
import { IFactory } from "../interfaces";

export const filterParentsTree = (
  factories: IFactory[],
  selectedFactory: IFactory
) => {
  const filteredFactories = factories.filter(it => {
    return it.id !== selectedFactory.id && it.id !== selectedFactory.parentId;
  });

  for (let i; i < filteredFactories.length; i++) {
    if (filteredFactories[i].children.length) {
      filterParentsTree(filteredFactories[i].children, selectedFactory);
    }
  }

  return filteredFactories;
};

export const getParentsTree = (
  factories: IFactory[],
  selectedFactory: IFactory
) => {
  let parentsTree: any;

  if (selectedFactory) {
    const filteredFactories = filterParentsTree(factories, selectedFactory);

    parentsTree = genTree(filteredFactories, undefined, undefined, "parentId");
  } else {
    parentsTree = genTree(factories, undefined, undefined, "parentId");
  }

  return parentsTree;
};
