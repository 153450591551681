import React, { FC, useEffect, useState } from "react";
import ModalComponent from "@/components/Modal";
import {
  ChatMemberRole,
  ChatType,
  ConfirmModal,
  hasImageUrl,
  SelectAvatar,
} from "@/shared";
import {
  Avatar,
  DeleteChatButton,
  ErrorLabel,
  NextIndicator,
  PinnedIndicator,
  PinnedLimitChatModal,
  SearchInput,
} from "../atoms";
import { Switch, Collapse } from "antd";
import { chatMessagesService, chatsService } from "@/services/domain";
import _, { trim } from "lodash";
import { chatMembersService } from "@/services/domain/chat-members.service";
import { BackgroundSettingsChatModal } from "./background-settings-chat-modal.component";
import { useEditGroupChat } from "../hooks";
import { ChatMembersListSmart } from "../smart-components";
import { AddUsersModal } from "./add-users-chat-modal.component";

const { Panel } = Collapse;
interface IProps {
  isShow: boolean;
  isPinned: boolean;
  setOpen: (val: boolean) => void;
  onPressPinnedChat: () => void;
  chatId: number;
}

export const SettingsChatModal: FC<IProps> = ({
  isShow,
  isPinned,
  setOpen,
  onPressPinnedChat,
  chatId,
}) => {
  const [searchStr, setSearchStr] = useState("");
  const [isShowUsersModal, setShowUsersModal] = useState<boolean>(false);
  const [isShowDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [isShowClearModal, setConfirmClearModal] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = useState<string>("");
  const [isBackgroundSettingsOpen, setBackgroundSettingsOpen] = useState<
    boolean
  >(false);

  const [inputValue, setInputValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [isError, setError] = useState<boolean>(false);

  const {
    role,
    chatMembers,
    chatInfo,
    isChatMuted,
    setMuted,
    previewImg,
    setPreviewImg,
    onAddUsersToChat,
  } = useEditGroupChat(chatId);

  const deleteOptions = [
    {
      title: "Ви справді хочете видалити цей чат для себе?",
      onPress: () => setTimeout(() => onDeleteChatForMe(chatId), 300),
    },
    {
      title: "Ви справді хочете видалити цей чат для всіх?",
      onPress: () => setTimeout(() => onDeleteChatForAll(chatId), 300),
    },
  ];

  const onDeleteChat = (title: string) => {
    const selectDeleteOption = _.find(
      deleteOptions,
      (item) => item.title === title
    );

    selectDeleteOption.onPress();
  };

  const clearChatOptions = [
    {
      title: "Ви справді хочете очистити цей чат для себе?",
      onPress: () =>
        setTimeout(
          () => handleClearForMe(chatId, chatInfo?.unreadMessagesCount),
          300
        ),
    },
    {
      title: "Ви справді хочете очистити цей чат для всіх?",
      onPress: () => setTimeout(() => handleClearForAll(chatId), 300),
    },
  ];

  const onClearChat = (title: string) => {
    const selectClearOption = _.find(
      clearChatOptions,
      (item) => item.title === title
    );

    selectClearOption.onPress();
  };

  const onChangeMuted = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setMuted(() => checked);
  };

  const setChatMuted = async () => {
    try {
      if (isChatMuted !== chatInfo?.isChatMuted)
        await chatsService.setChatMuted({ chatId, isChatMuted });
    } catch (err) {
      console.log("something going wrong", err);
    }
  };

  const validateTitle = (e) => {
    if (_.isEmpty(e.target.value)) {
      setError(true);
      return;
    }
    setError(false);
    return true;
  };

  const changeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const inputChanged = (e) => {
    changeHandler(e);

    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      changeHandler(e);
      if (!validateTitle(e)) return;

      chatsService.editGroupChatName(chatId, trim(e.target.value));

      setError(false);
    }, 1000);

    setTimer(newTimer);
  };

  useEffect(() => {
    if (previewImg) chatsService.editGroupChatImg(chatId, previewImg);
  }, [previewImg]);

  const closeModal = () => {
    setChatMuted();
    setConfirmDeleteModal(false);
    setConfirmClearModal(false);
    setOpen(false);
  };

  const onDeleteChatForMe = async (id: number) => {
    await chatMembersService.deleteMember(
      { chatId: id },
      chatInfo?.unreadMessagesCount
    );
    setConfirmDeleteModal(false);
    closeModal();
  };

  const onDeleteChatForAll = async (id: number) => {
    await chatsService.deleteChatId(id);
    setConfirmDeleteModal(false);
    closeModal();
  };

  const handleClearForMe = async (id: number, unreadCount: number) => {
    await chatMessagesService.clearChatMessages({ chatId: id }, unreadCount);
    setConfirmClearModal(false);
    closeModal();
  };

  const handleClearForAll = async (id: number) => {
    await chatMessagesService.clearChatMessages({
      chatId: id,
      deleteForAll: true,
    });
    setConfirmClearModal(false);
    closeModal();
  };

  const openDeleteModal = (message: string) => {
    setShowMessageModal(message);
    setConfirmDeleteModal(true);
  };

  const openClearModal = (message: string) => {
    setShowMessageModal(message);
    setConfirmClearModal(true);
  };

  const filteredData = chatMembers.filter((el) => {
    if (searchStr === "") {
      return el;
    } else {
      return (
        el.user?.lastName?.toLowerCase().includes(searchStr?.toLowerCase()) ||
        el.user?.firstName?.toLowerCase().includes(searchStr?.toLowerCase())
      );
    }
  });

  const excludedIds = chatMembers.map((item) => item.userId);

  useEffect(() => {
    if (chatInfo) {
      setInputValue(chatInfo?.name);
    }
  }, [chatInfo]);

  return (
    <ModalComponent title="Налаштування чату" show={isShow} toggle={closeModal}>
      <div
        className={`chat-settings-modal-container ${filteredData.length > 4 &&
          "chat-modal-container"} `}
      >
        <div className="chat-settings-header">
          {chatInfo?.type === ChatType.Group && (
            <div className="chat-settings-basic">
              <div>
                {role === ChatMemberRole.Admin ? (
                  <SelectAvatar
                    image={chatInfo?.previewUrl}
                    previewMessage={hasImageUrl(
                      chatInfo?.previewUrl,
                      chatInfo?.name
                    )}
                    title="Зміна аватару чату"
                    defaultImg={null}
                    onChange={setPreviewImg}
                    className="chat__avatar"
                  />
                ) : (
                  <div className="current-image-chat">
                    <Avatar
                      imageUrl={hasImageUrl(
                        chatInfo?.previewUrl,
                        chatInfo?.name
                      )}
                      maxHeight={100}
                      maxWidth={100}
                    />
                  </div>
                )}
              </div>
              <div className="input-field">
                <input
                  value={inputValue}
                  type="text"
                  placeholder={"Назва бесіди"}
                  onChange={inputChanged}
                  disabled={role === ChatMemberRole.Member}
                />
                {isError && (
                  <ErrorLabel title="Назва групового чату не може бути порожньою" />
                )}
              </div>
            </div>
          )}

          <div className="chat-settings-pin" onClick={onPressPinnedChat}>
            <PinnedLimitChatModal />
            {isPinned ? <p>Відкріпити чат</p> : <p>Закріпити чат</p>}
            <div className={isPinned ? "unpin-container" : ""}>
              <PinnedIndicator />
            </div>
          </div>
          <div
            className="chat-settings-background"
            onClick={() => setBackgroundSettingsOpen(true)}
          >
            <BackgroundSettingsChatModal
              isShow={isBackgroundSettingsOpen}
              setOpen={setBackgroundSettingsOpen}
            />
            <p>Змінити фон чату</p>
            <div className="settings-background-button">
              <NextIndicator />
            </div>
          </div>

          <Collapse bordered={false} className="site-collapse-custom-collapse">
            <Panel
              header="Очистити повідомлення бесіди"
              key="1"
              className="site-collapse-custom-panel"
              showArrow={false}
              extra={<NextIndicator rotated={"90deg"} />}
            >
              <ConfirmModal
                isShow={isShowClearModal}
                message={showMessageModal}
                onConfirm={() => onClearChat(showMessageModal)}
                setShow={setConfirmClearModal}
              />
              <div
                className="collapsed-row"
                onClick={() =>
                  openClearModal("Ви справді хочете очистити цей чат для себе?")
                }
              >
                <p>Очистити у себе</p>
                <div className="settings-background-button"></div>
              </div>
              {role === ChatMemberRole.Admin &&
              chatInfo?.type === ChatType.Group ? (
                <div
                  className="collapsed-row"
                  onClick={() =>
                    openClearModal(
                      "Ви справді хочете очистити цей чат для всіх?"
                    )
                  }
                >
                  <p>Очистити у всіх</p>
                  <div className="settings-background-button"></div>
                </div>
              ) : null}
            </Panel>
          </Collapse>

          <div className="chat-settings-row">
            <div className="search-users-row">
              <p className="input-label">Пошук</p>
              <SearchInput
                value={searchStr}
                onChange={setSearchStr}
                placeholder="Пошук користувачів"
              />
            </div>
            <div>
              <p className="input-label"> Вимкнути cповіщення</p>
              <div className="notification-toggle-switch">
                <Switch
                  style={{
                    backgroundColor: isChatMuted
                      ? "rgba(158, 39, 67, 1)"
                      : "#7F7F7F",
                  }}
                  checked={isChatMuted}
                  onChange={onChangeMuted}
                />
              </div>
            </div>
          </div>
        </div>
        <ChatMembersListSmart
          members={filteredData}
          userRole={
            chatInfo?.type === ChatType.Group ? role : ChatMemberRole.Member
          }
          ignoreRole={chatInfo?.type === ChatType.Personal}
        />
        {role === ChatMemberRole.Admin && chatInfo?.type === ChatType.Group ? (
          <div className="add-member-settings-default">
            <button onClick={() => setShowUsersModal(true)}>
              <AddUsersModal
                isShow={isShowUsersModal}
                setOpen={setShowUsersModal}
                excludeIds={excludedIds}
                addUsers={onAddUsersToChat}
              />
              <p>{`Додати учасника`}</p>
            </button>
          </div>
        ) : null}
      </div>
      <div className="settings-delete-chat">
        <ConfirmModal
          isShow={isShowDeleteModal}
          message={showMessageModal}
          onConfirm={() => onDeleteChat(showMessageModal)}
          setShow={setConfirmDeleteModal}
        />

        {role === ChatMemberRole.Admin && chatInfo?.type === ChatType.Group ? (
          <>
            <DeleteChatButton
              btnTitle="Видалити для себе"
              deleteChat={() =>
                openDeleteModal("Ви справді хочете видалити цей чат для себе?")
              }
            />
            <DeleteChatButton
              btnTitle="Видалити для всіх"
              deleteChat={() =>
                openDeleteModal("Ви справді хочете видалити цей чат для всіх?")
              }
            />
          </>
        ) : (
          <DeleteChatButton
            btnTitle="Видалити для себе"
            deleteChat={() =>
              openDeleteModal("Ви справді хочете видалити цей чат для себе?")
            }
          />
        )}
      </div>
    </ModalComponent>
  );
};
