import { IContactsFilter, IShortUser, IUsersFilter } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TUsersActions } from "./types";

export const usersFilterInitialState = {
  isBlocked: false,
  factoryId: null,
};

export const contactsFilterInitialState = {
  factoryId: null,
  fromDateOfBirth: null,
  toDateOfBirth: null,
};
export interface IUsersState {
  selectedUsers: IShortUser[];
  selectedExecutor: IShortUser;
  birthdayCountToday: number;
  usersFilter: IUsersFilter;
  contactsFilter: IContactsFilter;
}

const initialState: IUsersState = {
  selectedUsers: [],
  selectedExecutor: null,
  birthdayCountToday: 0,
  usersFilter: usersFilterInitialState,
  contactsFilter: contactsFilterInitialState,
};

export const usersReducer = createReducer<IUsersState, TUsersActions>(
  initialState,
  {
    SELECT_ALL: (state, action) => {
      return {
        ...state,
        selectedUsers: [...action.payload.items],
      };
    },

    SELECT_USER: (state, action) => {
      return {
        ...state,
        selectedUsers: [...state.selectedUsers, action.payload.user],
      };
    },
    UNSELECT_USER: (state, action) => {
      return {
        ...state,
        selectedUsers: state.selectedUsers.filter(
          (user) => user.id !== action.payload.userId
        ),
      };
    },
    UNSELECT_ALL_USERS: (state) => {
      return {
        ...state,
        selectedUsers: [],
      };
    },
    SET_USERS_FILTER: (state, action) => {
      return {
        ...state,
        usersFilter: action.payload,
      };
    },
    SET_CONTACTS_FILTER: (state, action) => {
      return {
        ...state,
        contactsFilter: action.payload,
      };
    },
    CLEAR_USERS_FILTER: (state) => {
      return {
        ...state,
        usersFilter: usersFilterInitialState,
      };
    },
    CLEAR_CONTACTS_FILTER: (state) => {
      return {
        ...state,
        contactsFilter: contactsFilterInitialState,
      };
    },
  }
);
