import {
  ChatMemberRole,
  ChatMessageEventType,
  IChatMessage,
  MessageType,
} from "@/shared";
import {
  useEventsListener,
  useIdsList,
  useSocket,
  useSocketListener,
} from "@/shared/hooks/";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { chatMessagesService } from "@/services/domain";
import { IFetchChatMessages } from "@/api/chats-messages/request.interfaces";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";
import { appEvents } from "@/shared/events";
import { ChatMessageActionEnum, ChatViewModeEnum } from "../enums";
import { getChatMessageMenuOptions } from "../configs";
import { chatMessagesApi } from "@/api";
import { useChatViewModeState } from "./use-chat-view-mode-state.hook";
import { copyText } from "@/shared/helpers/copy.helpers";

export const useChatMessages = (
  chatId: number,
  firstMessageId: number,
  lastMessageId: number
) => {
  const account = useSelector(getProfile);
  const socket = useSocket();

  const [replyTo, setReplyTo] = useState<IChatMessage>(null);
  const [scrollToId, setScrollToId] = useState(null);

  const { setMode: setChatViewMode } = useChatViewModeState();

  const {
    items: messages,
    loadNew,
    loadOld,
    loadMessage,
    isLoadingNew,
    isLoadingOld,
    lastMessageLoaded,
    firstMessageLoaded,
    resetList,
    _setItems,
    loadParams,
    setLoadParams,
  } = useIdsList<IChatMessage, IFetchChatMessages>({
    limit: 20,
    req: async (params) => await chatMessagesService.fetchMessages(params),
    needInit: false,
    clearWhenReload: false,
    lastMessageId,
    firstMessageId,
  });

  const onCopy = useCallback((message: IChatMessage) => {
    if (message.type === MessageType.Image) {
      copyText(message.content.fileUrl);
    } else if (message.type === MessageType.Text) {
      copyText(message.content.message);
    }
  }, []);

  const onReply = (message: IChatMessage) => {
    setReplyTo(message);
  };

  const onDelete = (message: IChatMessage, deleteForAll?: boolean) => {
    setTimeout(
      () =>
        appEvents.emit("openConfirmDeleteMessageModal", {
          message,
          deleteForAll,
          isShow: true,
        }),
      300
    );
  };

  const onDeleteForAll = useCallback((message: IChatMessage) => {
    onDelete(message, true);
  }, []);

  const checkCanDeleteForAll = useCallback(
    (message: IChatMessage, role: ChatMemberRole) => {
      if (role === ChatMemberRole.Admin) return true;

      const isAuthor = message.userId === account.id;
      if (_.isEmpty(message.events) && isAuthor) return true;

      const viewed = _.some(message.events, (event) => {
        const keys = Object.keys(event);
        return _.some(
          keys,
          (key) => Number(key) !== account.id && event[key] === "view"
        );
      });

      return isAuthor && !viewed;
    },
    []
  );

  const onPin = useCallback(
    async (message: IChatMessage) =>
      await chatMessagesApi.pinMessageReq(message.id),
    []
  );

  const onUnpin = useCallback(
    async (message: IChatMessage) =>
      await chatMessagesApi.unpinMessageReq(message.id),
    []
  );

  const onForward = (message: IChatMessage) => {
    appEvents.emit("openForwardMessageModal", { message, isShow: true });
  };

  const onEdit = (message: IChatMessage) => {
    appEvents.emit("onPressEditmessage", { message });
  };

  const onSelect = () => setChatViewMode(ChatViewModeEnum.SELECT);

  const actions = {
    [ChatMessageActionEnum.FORWARD]: onForward,
    [ChatMessageActionEnum.DELETE]: onDelete,
    [ChatMessageActionEnum.DELETE_FOR_ALL]: onDeleteForAll,
    [ChatMessageActionEnum.REPLY]: onReply,
    [ChatMessageActionEnum.COPY]: onCopy,
    [ChatMessageActionEnum.PIN]: onPin,
    [ChatMessageActionEnum.UNPIN]: onUnpin,
    [ChatMessageActionEnum.EDIT]: onEdit,
    [ChatMessageActionEnum.SELECT]: onSelect,
  };

  const onMessageActions = (message: IChatMessage, role: ChatMemberRole) => {
    const copyEnabledTypes = [MessageType.Text, MessageType.Image];
    const canDeleteForAll = checkCanDeleteForAll(message, role);
    const canCopy = copyEnabledTypes.includes(message.type);
    const canPin = !message.isPined;
    const canUnpin = message.isPined;
    const canEdit =
      message.type === MessageType.Text && message.userId === account.id;

    const options = getChatMessageMenuOptions({
      canDeleteForAll,
      canPin,
      canUnpin,
      canCopy,
      onClick: (actionType: ChatMessageActionEnum) =>
        actions[actionType](message),
      canEdit,
    });

    appEvents.emit("openMessageMenuOptions", { items: options });
  };

  const onPressMessagePreview = async (id: number) => {
    if (!messageContainsItem(id)) {
      await loadMessage(id);
      setScrollToId(id);
    } else {
      setScrollToId(id);
    }
  };

  const onLoadNew = async (limit?: number) => {
    await loadNew(limit);
  };

  useEffect(() => {
    if (chatId && chatId !== loadParams.chatId) {
      setLoadParams({ chatId });
    }
  }, [chatId]);

  const messageContainsItem = (itemId: number) =>
    _.find(messages, (message) => message.id === itemId);

  const updateEvents = (
    messages: IChatMessage[],
    userId: number,
    event: ChatMessageEventType
  ) => {
    const updatedItems = messages.map((item) => {
      const userEvent = _.find(
        item.events,
        (it) =>
          _.includes(Object.keys(it), userId.toString()) && it[userId] === event
      );
      if (userEvent) return item;

      return {
        ...item,
        events: [...item.events, { userId: event }],
      };
    });

    return updatedItems;
  };

  const onNewOneMessage = (message: IChatMessage) => {
    if (
      Number(message?.chatId) === chatId &&
      !messageContainsItem(message?.id)
    ) {
      _setItems([message, ...messages]);

      socket.emit("chat/read-message", {
        userId: account.id,
        messagesIds: [message.id],
        chatId,
      });

      if (message.userId === account.id) setScrollToId(message.id);
    }
  };

  const onNewMessages = (message: IChatMessage[]) => {
    if (_.isEmpty(message) || Number(message[0].chatId) !== chatId) return;

    {
      const messagesToAdd = _.filter(
        message,
        (it) => !messageContainsItem(it.id)
      );

      if (!_.isEmpty(messagesToAdd)) {
        _setItems([...messagesToAdd, ...messages]);

        const messagesIds = messagesToAdd.map((it) => it.id);

        socket.emit("chat/read-message", {
          userId: account.id,
          messagesIds,
          chatId,
        });
      }
    }
  };

  const onChatIsRead = (data: { chatId: number; userId: number }) => {
    if (Number(data?.chatId) !== chatId || data?.userId === account.id) return;

    const newItems = updateEvents(
      messages,
      data.userId,
      ChatMessageEventType.View
    );
    _setItems(newItems);
  };

  const onNewMessage = (data: { message: IChatMessage | IChatMessage[] }) => {
    if (_.isArray(data.message)) {
      onNewMessages(data.message);
    } else onNewOneMessage(data.message);
  };

  const onPinedMessage = (data: { chatId: number; messageId: number }) => {
    if (
      data?.chatId !== chatId ||
      !_.find(messages, (message) => message.id === data.messageId)
    )
      return;

    const newItems = messages.map((item) => {
      if (item.id === data.messageId)
        return {
          ...item,
          isPined: !item.isPined,
        };
      return item;
    });

    _setItems(newItems);
  };

  const onMessageDeleted = (data: { messageId: number; chatId: number }) => {
    if (data?.chatId !== chatId) return;

    const updatedMessages = messages.map((item) => {
      if (
        item.content?.replyToMessage &&
        item.content?.replyToMessage?.id === data.messageId
      )
        return {
          ...item,
          content: {
            ...item.content,
            replyToMessage: {
              ...item.content.replyToMessage,
              isDeleted: true,
            },
          },
        };
      return item;
    });

    const filteredItems = _.filter(
      updatedMessages,
      (item) => item.id !== data?.messageId
    );

    _setItems(filteredItems);
  };

  const onClearChat = (data: { chatId: number }) => {
    if (data?.chatId !== chatId) return;
    _setItems([]);
  };

  const onUpdateMessage = ({ message }) => {
    console.log("on update message");
    const newItems = messages.map((it) => {
      if (it.id === message.id) {
        return message;
      }
      return it;
    });

    _setItems(newItems);
  };

  useSocketListener("chat/update-message", onUpdateMessage, [messages]);

  // APP EVENTS AND SOCKET LISTENERS //

  useEventsListener("onDeleteMessage", onMessageDeleted, [chatId, messages]);

  useEventsListener(
    "onClearAllChats",
    () => {
      _setItems([]);
    },
    [_setItems]
  );

  useEventsListener(
    "onClearChatForMe",
    () => {
      _setItems([]);
    },
    [_setItems]
  );

  useEventsListener(
    "onDeleteAllChats",
    () => {
      _setItems([]);
    },
    [_setItems]
  );

  useSocketListener(
    "chat/new-message",
    (data) => {
      onNewMessage(data);
    },
    [chatId, messages]
  );

  useSocketListener("chat/delete-message", onMessageDeleted, [
    chatId,
    messages,
  ]);

  useSocketListener("chat/pined-message", onPinedMessage, [chatId, messages]);

  useSocketListener("chat/is-read", onChatIsRead, [chatId, messages]);

  useSocketListener("chat/clear-chat", onClearChat, [chatId, messages]);

  return {
    messages,
    loadMoreMessages: onLoadNew,
    loadEarlier: loadOld,
    loadMessage,
    setMessages: _setItems,
    state: {
      isLoadingNew,
      isLoadingOld,
      lastMessageLoaded,
      firstMessageLoaded,
    },

    onMessageActions,
    resetList,
    scrollToId,
    setScrollToId,
    onForward,
    onPressMessagePreview,
    replyTo,
    setReplyToMessage: setReplyTo,
  };
};
