import { Tree } from "antd";
import React, { FC, useState, useCallback, useEffect } from "react";
import { genTree } from "@/lib/helper";
import _ from "lodash";
import {
  checkPermission,
  ConfirmModal,
  IFactory,
  IUser,
  Permissions,
} from "@/shared";
import "./style.scss";
import TrashSvgIcon from "../../../../assets/img/trash-icon.svg";
import { useSelector } from "react-redux";
import { getMyPermissions } from "@/store/permissions";

const ROW_HEIGHT = 55;

interface IProps {
  factories: IFactory[];
  profile: IUser;
  searchVal: string;
  openModal: () => void;
  setSelectedFactory: (factory: IFactory) => void;
  deleteFactory: (id: number) => void;
}

export const TreeFactory: FC<IProps> = ({
  factories,
  profile,
  searchVal,
  openModal,
  deleteFactory,
  setSelectedFactory,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);
  const [isOpenConfirmModal, setConfirmModal] = useState<boolean>(false);
  const [factoryIdToDelete, setFactoryIdToDelete] = useState<number>();
  const permissions = useSelector(getMyPermissions);

  const preparedTree = genTree(_.cloneDeep(factories));

  const getParentKey = (id, tree) => {
    let parentKey;

    tree.forEach((el) => {
      if (el.children) {
        if (el.children.some((it) => it.id === id)) {
          parentKey = el.id;
        } else if (getParentKey(id, el.children)) {
          parentKey = getParentKey(id, el.children);
        }
      }
    });

    return parentKey;
  };

  const findParentKeys = (arr: IFactory[], str: string): string[] =>
    arr
      .map((item) => {
        if (item.name.toLowerCase().indexOf(str.toLowerCase()) > -1) {
          const parentKey = getParentKey(item.id, preparedTree);

          return parentKey;
        }
        if (item.children.length) return findParentKeys(item.children, str);
      })
      .filter((item, i, self) => {
        return item && self.indexOf(item) === i;
      });

  const onExpend = (expandedKeys: string[]) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  useEffect(() => {
    if (!searchVal) {
      setExpandedKeys([]);
      setAutoExpandParent(true);
      return;
    }

    const expandedKeys = findParentKeys(factories, searchVal);

    setAutoExpandParent(true);
    setExpandedKeys(expandedKeys.map((key) => String(key)));
  }, [searchVal]);

  const renderTitle = (item: any, isChildren?: boolean) => {
    const index = item.name.indexOf(searchVal);
    const beforeStr = item.name.substr(0, index);
    const afterStr = item.name.substr(index + searchVal.length);

    const title =
      index > -1 ? (
        <span className={isChildren ? "title-txt_children" : "title-txt"}>
          {beforeStr}
          <span className="search-title">{searchVal}</span>
          {afterStr}
        </span>
      ) : (
        <span className={isChildren ? "title-txt_children" : "title-txt"}>
          {item.name}
        </span>
      );

    return (
      <div className="tree-title">
        <div>
          {item?.children?.length ? (
            <div className="left-container">
              <i
                onClick={() =>
                  setExpandedKeys((prev) => {
                    if (prev.includes(String(item.id)))
                      return prev.filter((key) => key !== String(item.id));

                    return [...prev, String(item.id)];
                  })
                }
                style={{
                  transform: expandedKeys.includes(String(item.id))
                    ? "rotate(0deg)"
                    : "rotate(270deg)",
                }}
                className="fas fa-chevron-down"
              />
            </div>
          ) : (
            <div className="left-container"></div>
          )}

          <div>
            <div
              className={
                isChildren ? "tree-title-icon_children" : "tree-title-icon"
              }
            >
              {item.children.length ? (
                <i className="far factory-folder" />
              ) : (
                <i className="far factory-icon" />
              )}
            </div>

            <div
              className="text"
              onClick={() => {
                setSelectedFactory(item);
                openModal();
              }}
            >
              {title}
            </div>
          </div>

          {checkPermission(
            "tabs",
            "factory",
            profile,
            permissions,
            Permissions.DESTROY
          ) && (
            <img
              src={TrashSvgIcon}
              className="icon-trash"
              onClick={() => {
                setFactoryIdToDelete(item.id);
                setConfirmModal(true);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderTree = useCallback(
    (preparedTree, isChildren?: boolean) => {
      if (Array.isArray(preparedTree)) {
        return _.sortBy(preparedTree, (factory) =>
          factory.name.toLowerCase()
        ).map((item) => (
          <Tree.TreeNode
            className={isChildren ? "tree-node_children" : "tree-node"}
            style={{
              height: expandedKeys.includes(String(item.id))
                ? ROW_HEIGHT
                : ROW_HEIGHT,
            }}
            title={renderTitle(item, isChildren)}
            key={item.id}
          >
            {item.children && renderTree(item.children, true)}
          </Tree.TreeNode>
        ));
      }
    },
    [factories, searchVal, expandedKeys, permissions]
  );

  return (
    <div className="tree-factory">
      <ConfirmModal
        isShow={isOpenConfirmModal}
        message={"Ви справді хочете видалити?"}
        onConfirm={() => deleteFactory(factoryIdToDelete)}
        setShow={setConfirmModal}
      />

      <Tree
        style={{ background: "none" }}
        blockNode
        onExpand={onExpend}
        autoExpandParent={autoExpandParent}
        expandedKeys={expandedKeys}
      >
        {renderTree(preparedTree)}
      </Tree>
    </div>
  );
};
