export const getTitleByCount = (
	count: number,
	[form1, form2, form3]: string[],
) => {
	const tens = Math.abs(count) % 100
	const units = tens % 10
	if (tens > 10 && tens < 20) {
		return `${count} ${form3}`
	}
	if (units > 1 && units < 5) {
		return `${count} ${form2}`
	}
	if (units == 1) {
		return `${count} ${form1}`
	}
	return `${count} ${form3}`
}
