import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';

interface IProps {
  sidebar: {
    show: boolean
    collapse: boolean
  }
  changeToDark: () => void
  changeToLight: () => void
  changeMobileSidebarVisibility: () => void
}

export const Sidebar = (props: IProps) => {

  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': props.sidebar.show,
    'sidebar--collapse': props.sidebar.collapse,
  });


  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={props.changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            hideSidebar={() => { }}
            changeToDark={props.changeToDark}
            changeToLight={props.changeToLight}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            hideSidebar={props.changeMobileSidebarVisibility}
            changeToDark={props.changeToDark}
            changeToLight={props.changeToLight}
          />
        </div>
      </Scrollbar>
    </div>
  )
}
