import React, { CSSProperties, FC, useMemo } from "react";
import { DatePicker, message } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/uk_UA";
import CalendarSVG from "@/assets/img/calendarIcon.svg";
import "moment/locale/uk";
import "./DateField.scss";
import _ from "lodash";
import copySvg from "@/assets/img/copy-icon-1.svg";
import { copyText } from "@/shared/helpers/copy.helpers";

moment.updateLocale("uk", {
  monthsShort: moment
    .months()
    .map((month) => month.charAt(0).toUpperCase() + month.slice(1)),
});

interface IProps {
  label: string;
  value: string | Date;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  meta?: { touched?: boolean; error?: string };
  onChange: (date: string) => void;
  disabledDate?: any;
  onOpenChange?: (status: boolean) => void;
  input?: any;
  allowClear?: boolean;
  format?: string;
  enableCopy?: boolean;
}

export const DateField: FC<IProps> = ({
  label,
  value,
  meta,
  placeholder,
  style,
  disabled,
  disabledDate,
  onChange,
  onOpenChange,
  input,
  allowClear = true,
  format = "DD-MM-YYYY",
  enableCopy = false,
}) => {
  const onCopy = (value) => {
    copyText(value);
  };
  const currValue = useMemo(() => (input?.value ? input.value : value), [
    value,
    input,
  ]);

  return (
    <>
      <div className="profile__data_card" style={style}>
        <p className="profile__data-tooltip">{label}</p>
        <DatePicker
          allowClear={input ? input?.allowClear : allowClear}
          value={
            currValue && moment(currValue).isValid() ? moment(currValue) : null
          }
          disabled={disabled}
          disabledDate={disabledDate}
          onOpenChange={onOpenChange ? onOpenChange : _.noop}
          locale={locale}
          dropdownClassName={
            input?.dropdownClassName ? input?.dropdownClassName : null
          }
          placeholder={placeholder}
          suffixIcon={<img src={CalendarSVG} />}
          style={{ width: "100%" }}
          onChange={(date) => {
            if (input?.onChange) input?.onChange(date);
            else onChange(date ? date.format() : null);
          }}
          format={input?.format ? input?.format : format}
          showToday={false}
        />

        {enableCopy && (
          <img
            className="contact-copy"
            src={copySvg}
            alt="copy"
            onClick={() => onCopy(value)}
          />
        )}

        {meta?.error && <span className="error">{meta?.error}</span>}
      </div>
    </>
  );
};
