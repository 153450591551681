import React, { FC, useRef } from "react";
import ModalComponent from "@/components/Modal";
import { SettingsChatButton } from "../atoms";
import { BgList } from "./background-list.component";
import { useChatBgFromLibrary } from "../hooks";

interface IProps {
  isShow: boolean;
  setOpen: (val: boolean) => void;
}

export const BackgroundSettingsChatModal: FC<IProps> = ({
  isShow,
  setOpen,
}) => {
  const closeModal = () => {
    setOpen(false);
  };

  const {
    chatBackgrounds,
    onSave,
    setDefaultBg,
    selectImgFromLibrary,
  } = useChatBgFromLibrary();

  const fileRef = useRef(null);

  const onBgSave = () => {
    onSave();
    closeModal();
  };

  return (
    <ModalComponent title="Змінити фон чату" show={isShow} toggle={closeModal}>
      <div className="background-settings-chat-modal">
        <div className="background-image-pick">
          <p>Вибрати з галереї</p>
          <div className="settings-background-button">
            <input
              type="file"
              ref={fileRef}
              accept="image/*"
              onChange={selectImgFromLibrary}
              className="attachments-input"
              style={{
                visibility: "hidden",
                position: "absolute",
              }}
            />
            <button
              onClick={() => {
                fileRef.current.click();
              }}
            >
              <p>{`>`}</p>
            </button>
          </div>
        </div>

        <BgList chatBackground={chatBackgrounds} />

        <div className="background-settings-default">
          <button onClick={setDefaultBg}>
            <p>{`Обрати стандартний фон`}</p>
          </button>
        </div>
        <div className="background-settings-save">
          <SettingsChatButton
            btnTitle={"Зберегти"}
            onPressSetting={onBgSave}
            isSave={true}
          />
          <SettingsChatButton
            btnTitle={"Скасувати"}
            onPressSetting={closeModal}
            isSave={false}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
