import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";
import * as Res from "./responses.interfaces";

const createPermissionsGroup = (
  data: Req.ICreatePermissionsGroupPayload
): ApiResponse<Res.ICreatePermissionsGroup> => {
  return http.post<Res.ICreatePermissionsGroup>(
    "admin/permissions-groups",
    data
  );
};

const updatePermissionsGroup = (
  permissionGroupId: number,
  data: Req.IUpdatePermissionsGroupPayload
): ApiResponse<Res.IUpdatePermissionsGroup> => {
  return http.put<Res.IUpdatePermissionsGroup>(
    `admin/permissions-groups/${permissionGroupId}`,
    data
  );
};

const deletePermissionsGroup = (
  permissionGroupId: number
): ApiResponse<void> => {
  return http.delete<void>(`admin/permissions-groups/${permissionGroupId}`);
};

const fetchPermissionsGroupsList = (
  params: Req.IGetPermissionsGroupsListParams
): ApiResponse<Res.IFetchPermissionsGroupList> => {
  return http.get<Res.IFetchPermissionsGroupList>("admin/permissions-groups", {
    params
  });
};

const fetchPermissionsGroupById = (
  permissionGroupId: number
): ApiResponse<Res.IFetchPermissionsGroupById> => {
  return http.get<Res.IFetchPermissionsGroupById>(
    `admin/permissions-groups/${permissionGroupId}`
  );
};

export const permissionsGroupApi = {
  createPermissionsGroup,
  updatePermissionsGroup,
  deletePermissionsGroup,
  fetchPermissionsGroupsList,
  fetchPermissionsGroupById
};
