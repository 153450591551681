import { IChatInList } from "@/shared";
import React, { FC } from "react";
import { ChatListItem } from "./chat-list-item.component";

interface IProps {
  items: IChatInList[];
  selectOne: (id: number) => void;
  unSelectOne: (id: number) => void;
}

export const ChatsSelectList: FC<IProps> = ({
  items,
  selectOne,
  unSelectOne,
}) => {
  return (
    <div className="chats-list-container">
      {items.map((item, index) => {
        return (
          <ChatListItem
            key={index}
            chatId={item.id}
            imageUrl={item.previewUrl}
            chatName={item.name}
            isChecked={item.isSelected}
            onSelectItem={() => selectOne(item.id)}
            onDeselectItem={() => unSelectOne(item.id)}
          />
        );
      })}
    </div>
  );
};
