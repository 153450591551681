import React from "react";
import burger_icon from "@/assets/img/burger_new.svg";

interface IProps {
  changeSidebarVisibility: () => void;
  changeMobileSidebarVisibility: () => void;
}

export const TopBarSidebarButton = (props: IProps) => {
  return (
    <div>
      <button
        type="button"
        className="topbar__button topbar__button--desktop"
        onClick={props.changeSidebarVisibility}
      >
        <img src={burger_icon} alt="" className="topbar__button-icon" />
      </button>
      <button
        type="button"
        className="topbar__button topbar__button--mobile"
        onClick={props.changeMobileSidebarVisibility}
      >
        <img src={burger_icon} alt="" className="topbar__button-icon" />
      </button>
    </div>
  );
};
