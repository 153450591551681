import React, { useRef, useState } from "react";
import { SettingsChatButton } from "../atoms";
import { useEventsListener } from "@/shared";

import ModalComponent from "@/components/Modal";

export const ChatSendImgModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newImage, setImage] = useState("");

  const modalRef = useRef(null);

  useEventsListener(
    "chatSendImgModal",
    (payload) => {
      modalRef.current.onSend = payload.onSend;
      setImage(payload.url);
      setIsOpen(payload.isShow);
    },
    [setImage, setIsOpen]
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  const onImgSend = () => {
    modalRef.current.onSend();
    closeModal();
  };

  return (
    <ModalComponent
      title="Відправка картинки"
      show={isOpen}
      ref={(ref) => (modalRef.current = ref)}
      toggle={() => setIsOpen(false)}
    >
      <div className="send-img-chat-modal">
        <div className="img-container">
          <img src={newImage} />
        </div>
        <div className="send-buttons">
          <SettingsChatButton
            btnTitle={"Надіслати зображення"}
            onPressSetting={onImgSend}
            isSave={true}
          />
          <SettingsChatButton
            btnTitle={"Скасувати"}
            onPressSetting={closeModal}
            isSave={false}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
