import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  createFullName,
  EUsersListType,
  Option,
  usePaginationList,
} from "@/shared";
import React from "react";
import { Select, SelectProps, Spin } from "antd";
import { tasksApi, usersApi } from "@/api";
import _ from "lodash";
import { IExecutorsInListRes } from "@/api/tasks/responses.interfaces";
import { IShortInfoUserRes } from "@/api/users/responses.interfaces";
import "./style.scss";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";

export interface IUserSelectWithSearchProps {
  onChange: (val: string | string[]) => void;
  value: string | string[];
  defaultOptions?: Option[];
  defaultCount?: number;
  label?: string;
  mode?: "multiple" | "tags";
  type?: EUsersListType;
  input?: any;
  error?: string;
  selectProps?: SelectProps;
  excludeIds?: number[];
  showCurrentUserOnTop?: boolean;
}

const loadMoreOption: Option = {
  title: "Загрузити ще",
  value: "load-more-option",
};

const limit = 20;

export const UserSelectWithSearch: FC<IUserSelectWithSearchProps> = ({
  onChange,
  value,
  defaultOptions,
  defaultCount,
  label,
  mode,
  type = EUsersListType.All,
  input,
  error,
  selectProps,
  showCurrentUserOnTop,
  excludeIds,
}) => {
  const account = useSelector(getProfile);
  const [searchString, setSearchString] = useState("");
  const timerRef = useRef(null);
  const [additionalOptions, setAdditionalOptions] = useState([]);

  const fetchItemsByType = {
    [EUsersListType.All]: usersApi.fetchShortInfoUsersList,
    [EUsersListType.Executors]: tasksApi.getTaskExecutors,
    [EUsersListType.ExecutorsAll]: tasksApi.getAllExecutors,
  };

  const userPaginationList = usePaginationList<Option>({
    fetchItems: fetchItemsByType[type],
    serrializatorItems: (items) =>
      items.map((it) => ({
        title: createFullName(it.firstName, it.middleName, it.lastName),
        value:
          type === EUsersListType.All ? it.id.toString() : it.userId.toString(),
      })),
    loadParams: {
      limit: !_.isEmpty(defaultOptions) ? defaultOptions.length : limit,
      count: defaultCount,
      sortField: "lastName",
      sort: "ASC",
      name: searchString,
    },
    needInit: false,
    clearBeforeFetch: false,
  });

  const loadAdditionalUser = async (ids: number[]) => {
    const { data } = await fetchItemsByType[type]({
      limit: 1,
      includeIds: ids,
    });

    if (_.isEmpty(data.items)) return;

    const options = data.items.map((option) => ({
      title: createFullName(
        option.firstName,
        option.middleName,
        option.lastName
      ),
      value:
        type === EUsersListType.All
          ? (option as IShortInfoUserRes).id.toString()
          : (option as IExecutorsInListRes).userId.toString(),
    }));

    setAdditionalOptions(options);
  };

  useEffect(() => {
    if (searchString === userPaginationList.loadParams["name"]) return;

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      userPaginationList.setLoadParams({
        name: searchString,
        limit: !_.isEmpty(defaultOptions) ? defaultOptions.length : limit,
      });

      clearTimeout(timerRef.current);
    }, 500);

    return () => clearTimeout(timerRef.current);
  }, [searchString]);

  useEffect(() => {
    if (defaultOptions) userPaginationList._setItems(defaultOptions);
    else userPaginationList.resetFlatList();
  }, [defaultOptions]);

  useEffect(() => {
    if (excludeIds)
      userPaginationList.setLoadParams({ excludeIds: excludeIds.map(Number) });
  }, [excludeIds]);

  useEffect(() => {
    const val = value || input?.value;

    const idsToLoad = [];

    if (_.isArray(val)) {
      if (
        _.isEmpty(val) ||
        (_.isEmpty(userPaginationList.items) && _.isEmpty(defaultOptions))
      )
        return;

      const idsToLoadString = val.filter(
        (it) =>
          !_.find(defaultOptions, (opt) => opt.value === it.toString()) &&
          !_.find(
            userPaginationList?.items,
            (item) => item.value === it.toString()
          )
      );
      idsToLoadString.map((it) => idsToLoad.push(Number(it)));
    } else {
      if (
        !val ||
        _.isEmpty(userPaginationList.items) ||
        (!_.isEmpty(defaultOptions) &&
          _.find(defaultOptions, (it) => it.value === val.toString())) ||
        _.find(userPaginationList.items, (it) => it.value === val.toString())
      )
        return;

      idsToLoad.push(Number(val));
    }

    if (_.isEmpty(idsToLoad)) return;
    loadAdditionalUser(idsToLoad);
  }, [value, input, defaultOptions, userPaginationList.items]);

  const additionalUserOptionItems = useMemo(() => {
    const optionsToRender = [];
    if (_.isEmpty(searchString) || !_.isEmpty(additionalOptions))
      additionalOptions.map((option) => {
        if (
          !_.find(userPaginationList.items, (it) => it.value === option.value)
        )
          optionsToRender.push(option);
      });

    return optionsToRender;
  }, [searchString, additionalOptions, userPaginationList.items]);

  const loadMoreOptionItem = useMemo(() => {
    const additionalCount = _.isEmpty(additionalUserOptionItems)
      ? additionalUserOptionItems.length
      : 0;

    if (
      userPaginationList.loadParams.count <=
      userPaginationList.items.length + additionalCount
    )
      return null;

    return (
      <Select.Option
        key={loadMoreOption.value}
        value={loadMoreOption.value}
        disabled={true}
      >
        <div
          onClick={userPaginationList.loadMore}
          style={{ cursor: "default", color: "#000", fontWeight: "bold" }}
        >
          {loadMoreOption.title}
        </div>
      </Select.Option>
    );
  }, [
    userPaginationList.loadParams.count,
    userPaginationList.items,
    additionalUserOptionItems,
  ]);

  const renderOptions = () => {
    return (
      <>
        {showCurrentUserOnTop && _.isEmpty(searchString) && (
          <Select.Option
            key={account.id}
            value={account.id.toString()}
            disabled={false}
          >
            {createFullName(
              account.info.firstName,
              account.info.middleName,
              account.info.lastName
            )}
          </Select.Option>
        )}

        {userPaginationList.items.map((item, index) => {
          if (
            showCurrentUserOnTop &&
            item.value === account.id.toString() &&
            _.isEmpty(searchString)
          )
            return;
          return (
            <Select.Option
              key={item.value + "-" + index}
              value={item.value}
              disabled={false}
            >
              {item.title}
            </Select.Option>
          );
        })}

        {additionalUserOptionItems.map((additionalOption) => {
          if (
            showCurrentUserOnTop &&
            additionalOption.value === account.id.toString()
          )
            return;

          return (
            <Select.Option
              key={additionalOption.value}
              value={additionalOption.value}
              disabled={false}
            >
              {additionalOption.title}
            </Select.Option>
          );
        })}

        {loadMoreOptionItem}
      </>
    );
  };

  const getValue = () => {
    if (!value) return input?.value;
    if (Number(value) === -1) return "Видалений користувач";
    return value;
  };

  return (
    <div className="form__form-group select-field select-field_custom">
      {label ? <div className="label">{label}</div> : null}

      <Select
        {...selectProps}
        value={getValue()}
        showSearch
        showArrow
        loading={userPaginationList.isLoading}
        className="select"
        optionFilterProp="children"
        allowClear
        mode={mode}
        onSearch={(val: string) => setSearchString(val)}
        onChange={(val: string) => {
          setSearchString("");

          if (input?.onChange) input.onChange(val);
          else onChange(val);
        }}
        notFoundContent={
          userPaginationList.isLoading ? <Spin size="small" /> : "не знайдено"
        }
      >
        {renderOptions()}
      </Select>

      {error && <span className="error">{error}</span>}
    </div>
  );
};
