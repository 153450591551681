import { SearchInput } from "@/containers/Chats/atoms";
import { IShortUser } from "@/shared";
import _ from "lodash";
import React, { FC } from "react";
import { Button } from "reactstrap";

interface IProps {
  items: IShortUser[];
  disableIds?: number[];
  searchStr: string;
  onChangeContact: (text) => void;
  selectAll: (users: IShortUser[]) => void;
  allBtnLabel?: string;
}

export const UserSearchWithBtn: FC<IProps> = ({
  allBtnLabel = "Додати всіх",
  items,
  disableIds,
  searchStr,
  onChangeContact,
  selectAll,
}) => {
  const handleSelectAll = async () => {
    selectAll(_.filter(items, (item) => !_.includes(disableIds, item.id)));
  };

  return (
    <div className="chat-modal-search-row">
      <SearchInput
        value={searchStr}
        onChange={onChangeContact}
        placeholder="Знайдіть контакт"
      />

      <Button
        style={{ width: "110px", height: "40px" }}
        name={"allSelect"}
        color="primary"
        onClick={handleSelectAll}
      >
        {allBtnLabel}
      </Button>
    </div>
  );
};
