import React from "react";

import pinIconSvg from "@/assets/img/pushPin-icon.svg";

interface IProps {
  width?: number;
  height?: number;
}

export const PinnedIndicator = ({ width, height }: IProps) => {
  return (
    <div
      style={{
        width: width || 24,
        height: height || 24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={pinIconSvg} />
    </div>
  );
};
