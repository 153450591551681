import React, { FC } from "react";
import "./styles.scss";
interface SearchInputProps {
  value: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange: (val: string) => void;
  isFocused?: boolean;
  onFocus?: () => void;
}

export const SearchInput: FC<SearchInputProps> = ({
  value,
  placeholder,
  style,
  onChange,
  isFocused,
  onFocus,
}) => {
  return (
    <div className="search-input-field" style={style}>
      <i className="fa fa-search" aria-hidden="true" />

      <input
        autoFocus={isFocused}
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
      />

      {value && (
        <button onClick={() => onChange("")}>
          <i className="fa fa-times-circle clear" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};
