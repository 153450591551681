import {
  checkPermission,
  IMyPermissions,
  ISelectedRowsMenuItemConfig,
  IUser,
  Permissions,
} from "@/shared";
import iconUserPen from "@/assets/img/userPencil.svg";
import iconUserLock from "@/assets/img/userLock.svg";
import iconUserUnLock from "@/assets/img/unlock-user.svg";
import iconUseExit from "@/assets/img/userExit.svg";
import iconBasketUser from "@/assets/img/basketUser.svg";
import _ from "lodash";

interface IProps {
  onClick: (key: "edit" | "block" | "logout" | "delete") => void;
  selectedItems: Partial<IUser>[];
  profile: IUser;
  permissions: IMyPermissions;
}

export const getSelectedUsersMenuConfig = (
  props: IProps
): ISelectedRowsMenuItemConfig[] => {
  if (
    _.isEmpty(props.selectedItems) ||
    (props.selectedItems.length > 1 &&
      !checkPermission(
        "tabs",
        "user",
        props.profile,
        props.permissions,
        Permissions.DESTROY
      ))
  )
    return [];

  if (props.selectedItems.length > 1)
    return [
      {
        onClick: () => props.onClick("delete"),
        key: "delete",
        label: "Видалити",
        icon: {
          scr: iconBasketUser,
          name: "user delete",
          className: "basket-icon",
        },
      },
    ];

  const menuItems = [];

  if (
    checkPermission(
      "tabs",
      "user",
      props.profile,
      props.permissions,
      Permissions.UPDATE
    )
  )
    menuItems.push({
      onClick: () => props.onClick("edit"),
      key: "edit",
      label: "Редагувати",
      icon: {
        scr: iconUserPen,
        name: "user pen",
        className: "icon-user-pen",
      },
    });

  if (
    checkPermission(
      "tabs",
      "user",
      props.profile,
      props.permissions,
      Permissions.BAN
    )
  )
    menuItems.push({
      onClick: () => props.onClick("block"),
      key: "lock",
      label:
        props.selectedItems[0]?.status === "b" ? "Розблокувати" : "Заблокувати",
      icon: {
        scr:
          props.selectedItems[0]?.status === "b"
            ? iconUserUnLock
            : iconUserLock,
        name: "user lock",
        className: props.selectedItems[0]?.status === "b" && "user-unlock-icon",
      },
    });

  if (
    checkPermission(
      "tabs",
      "user",
      props.profile,
      props.permissions,
      Permissions.LOGOUT
    )
  )
    menuItems.push({
      onClick: () => props.onClick("logout"),
      key: "logout",
      label: "Розлогінити",
      icon: {
        scr: iconUseExit,
        name: "user logout",
      },
    });

  if (
    checkPermission(
      "tabs",
      "user",
      props.profile,
      props.permissions,
      Permissions.DESTROY
    )
  )
    menuItems.push({
      onClick: () => props.onClick("delete"),
      key: "delete",
      label: "Видалити",
      icon: {
        scr: iconBasketUser,
        name: "user delete",
        className: "basket-icon",
      },
    });

  return menuItems;
};
