export const getStatsConfig = (groupCount: number, taskCount: number, commentCount: number) => {
    return [
        {
            label: "Груп",
            value: groupCount,
        },
        {
            label: "Задач",
            value: taskCount,
        },
        {
            label: "Коментарів",
            value: commentCount,
        },
    ];
}