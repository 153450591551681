import { DateField } from "@/components/Fields";
import ModalComponent from "@/components/Modal";
import { chatsService, contactsService } from "@/services/domain";
import {
  createFullName,
  EUserStatus,
  FunctionalEmailField,
  InputField,
  IUser,
} from "@/shared";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Avatar } from "../atoms";
import "./user-info.styles.scss";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { simpleDispatch } from "@/store/store-helpers";
import { SelectChatId } from "@/store/chats";
import { appEvents } from "@/shared/events";
import default_avatar from "@/assets/img/default-avatar.jpg";
interface UserInfoModalProps {
  isOpen?: boolean;
  toggle: () => void;
  userId: number;
}

export const UserInfoModal: FC<UserInfoModalProps> = (props) => {
  const history = useHistory();
  const [user, setUser] = useState<IUser>(null);

  const getUserInfo = async () => {
    if (!props.userId) return;
    const info = await contactsService.fetchContact(props.userId);
    setUser(info);
  };

  useEffect(() => {
    getUserInfo();

    return () => setUser(null);
  }, [props.userId]);

  const onPressMessage = async () => {
    try {
      const chatId =
        user?.chatId ||
        (await chatsService.createPersonalChat({
          userId: user?.id,
        }));

      simpleDispatch(new SelectChatId({ id: chatId }));

      appEvents.emit("onReadChat", {
        chatId: chatId,
        unreadCount: 0,
      });

      history.push(`/chats?id=${chatId}`);

      if (props.toggle) props.toggle();
    } catch (e) {
      console.log("Error", e.message);
    }
  };

  return (
    <ModalComponent
      title={"Користувач"}
      show={props.isOpen}
      toggle={props.toggle}
      modalHeaderClass="user-info"
    >
      <form className="form container user-modal-container">
        <div className="top-block">
          <div className="avatar-wrap">
            <p className="avatar-label">Аватар</p>

            <Avatar
              imageUrl={user?.info?.avatarUrl || default_avatar}
              maxWidth={100}
              maxHeight={100}
            />
          </div>

          <div className="fields">
            <InputField
              disabled
              label="ПІБ"
              value={createFullName(
                user?.info?.firstName,
                user?.info?.middleName,
                user?.info?.lastName
              )}
              onChange={_.noop}
              enableCopy={true}
            />

            <DateField
              disabled
              style={{ marginBottom: 20, marginTop: 10 }}
              label="Дата народження"
              value={user?.info?.dateOfBirth}
              onChange={_.noop}
              enableCopy={true}
            />
          </div>
        </div>

        <InputField
          disabled
          style={{ marginBottom: 9 }}
          label="Посада"
          value={user?.info?.position}
          onChange={_.noop}
          enableCopy={true}
        />

        <div className="bottom-block">
          <div>
            <InputField
              disabled
              label="Телефон внутрішній"
              value={user?.info?.innerPhoneNumber}
              onChange={_.noop}
              enableCopy={true}
            />

            <InputField
              disabled
              label="Телефон особистий"
              value={user?.info?.personalPhoneNumber}
              onChange={_.noop}
              enableCopy={true}
            />

            <Button
              color={"danger"}
              onClick={() => {}}
              className="user-info-btn info-left"
              title={"Виклик користувача"}
              disabled
            >
              {"Виклик"}
            </Button>
          </div>

          <div>
            <InputField
              disabled
              label="Телефон  робочий"
              value={user?.phoneNumber}
              onChange={_.noop}
              enableCopy={true}
            />

            <FunctionalEmailField
              label={"Email"}
              value={user?.email}
              onClick={() => {
                if (user?.status !== EUserStatus.Deleted)
                  window.open(`mailto:${user?.email}`);
              }}
              enableCopy
            />

            <Button
              color={"danger"}
              onClick={onPressMessage}
              disabled={user?.status === EUserStatus.Deleted}
              className="user-info-btn info-right"
              title="Надіслати повідомлення"
            >
              {"Повідомлення"}
            </Button>
          </div>
        </div>
      </form>
    </ModalComponent>
  );
};
