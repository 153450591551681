import { InputField } from "@/shared";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React from "react";
import "./style.scss";
import { Button } from "antd";

interface IProps {
  label: string;
  value: string;
  onChange: (val: string) => void;
  onPressOk: () => void;
  onPressClose: () => void;
  placeholder?: string;
}

export const AddTaxonomyField = ({
  onPressOk,
  onPressClose,
  ...props
}: IProps) => {
  return (
    <div className="add-taxonomy-field">
      <InputField maxLength={80} {...props} />
      <div className="add-taxonomy-icons">
        <Button
          className="add-taxonomy-field_icon"
          type="link"
          onClick={onPressOk}
          icon={
            <CheckCircleOutlined style={{ color: "#9e2743", fontSize: 19 }} />
          }
        />
        <Button
          className="add-taxonomy-field_icon"
          type="link"
          onClick={onPressClose}
          icon={
            <CloseCircleOutlined style={{ color: "#9e2743", fontSize: 19 }} />
          }
        />
      </div>
    </div>
  );
};
