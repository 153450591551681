import { IPListType, ISelectedRowsMenuItemConfig } from "@/shared";
import {
  FileAddOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import iconBasket from "@/assets/img/basketUser.svg";
import _ from "lodash";
import React from "react";

interface IProps {
  onClick: (key: "block" | "unblock" | "add_to_white_list" | "delete") => void;
  selected: Record<string, any>[];
}

export const getSelectedIPsMenuConfig = (
  props: IProps
): Record<IPListType, ISelectedRowsMenuItemConfig[]> => {
  if (_.isEmpty(props.selected))
    return {
      [IPListType.Black]: [],
      [IPListType.White]: [],
    };

  const menuItems = {
    block: {
      onClick: () => props.onClick("block"),
      key: "lock_ip",
      label: "Заблокувати",
      iconNode: <LockOutlined className="lock-icon" />,
    },
    unblock: {
      onClick: () => props.onClick("unblock"),
      key: "unlock_ip",
      label: "Розблокувати",
      iconNode: <UnlockOutlined className="unlock-icon" />,
    },
    add_to_white_list: {
      onClick: () => props.onClick("add_to_white_list"),
      key: "add_to_white_list",
      label: "Додати в білий список",
      iconNode: <FileAddOutlined className="white-list-icon" />,
    },
    delete: {
      onClick: () => props.onClick("delete"),
      key: "delete",
      label: "Видалити",
      icon: {
        scr: iconBasket,
        name: "basket",
        className: "basket-icon",
      },
    },
  };

  return {
    [IPListType.White]: [menuItems.block, menuItems.delete],
    [IPListType.Black]: [
      menuItems.unblock,
      menuItems.add_to_white_list,
      menuItems.delete,
    ],
  };
};
