import { ISelectedRowsMenuItemConfig } from "@/shared";
import iconBasket from "@/assets/img/basketUser.svg";
import iconUserLock from "@/assets/img/userLock.svg";
import iconUserUnlock from "@/assets/img/unlock-user.svg";
import _ from "lodash";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import React from "react";

interface IProps {
  onClick: (
    key: "delete" | "block_users" | "unblock_users" | "block_ip" | "unblock_ip"
  ) => void;
  selectedIds: number[];
}

export const getSelectedLogsMenuConfig = (
  props: IProps
): ISelectedRowsMenuItemConfig[] => {
  if (_.isEmpty(props.selectedIds)) return [];

  return [
    {
      onClick: () => props.onClick("delete"),
      key: "delete",
      label: "Видалити",
      icon: {
        scr: iconBasket,
        name: "basket",
        className: "basket-icon",
      },
    },
    {
      onClick: () => props.onClick("block_users"),
      key: "lock_user",
      label: "Заблокувати користувачів",
      icon: {
        scr: iconUserLock,
        name: "user lock",
      },
    },
    {
      onClick: () => props.onClick("unblock_users"),
      key: "unlock_user",
      label: "Розблокувати користувачів",
      icon: {
        scr: iconUserUnlock,
        name: "user lock",
        className: "user-unlock-icon",
      },
    },
    {
      onClick: () => props.onClick("block_ip"),
      key: "lock_ip",
      label: "Заблокувати ІР",
      iconNode: <LockOutlined className="lock-icon" />,
    },
    {
      onClick: () => props.onClick("unblock_ip"),
      key: "unlock_ip",
      label: "Розблокувати ІР",
      iconNode: <UnlockOutlined className="unlock-icon" />,
    },
  ];
};
