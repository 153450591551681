import { defaultChatBgConfig } from "@/config/default-chat-bg.config";
import {
  ChatBgKeys,
  ChatMemberRole,
  ChatType,
  createFullName,
  MessageType,
  StorageKey,
} from "@/shared";
import { getProfile } from "@/store/account";
import { selectCurrentChatBgId } from "@/store/chat-bg";
import { selectSelectedChatId } from "@/store/chats";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, CardBody } from "reactstrap";
import { SelectChatButton } from "./atoms";

import {
  ChatsList,
  CreateChatModal,
  ForwardMessageModal,
  SearchChatsWithSettings,
} from "./components";

import {
  useChatDetails,
  useChatList,
  useChatMessages,
  useChatSelectedMessages,
  useChatSelectedMessagesState,
  useChatViewModeState,
  useCreateTextMessage,
  usePinedMessages,
  useSendFiles,
} from "./hooks";

import { UserInfoModal } from "./modals";
import { ChatBar, ChatHeader, ChatMessages, PinnedMessage } from "./plugins";
import { transformMessages } from "./transforms";
import "./style.scss";
import _ from "lodash";
import { ChatConfirmDeleteModal } from "./modals/chat-confirm-delete-modal";
import { SelectStickersModalSmart } from "@/smart-components";
import { ChatSendImgModal } from "./components/chat-send-img-modal.component";
import { ChatViewModeEnum } from "./enums";
import { message } from "antd";

const ChatsPage: FC = () => {
  const accountId = useSelector(getProfile);
  const currentBgImgId = useSelector(selectCurrentChatBgId);
  const selectedChatId = useSelector(selectSelectedChatId);

  const [messageApi, contextHolder] = message.useMessage();

  const [isCreateChatModal, setCreateChatModal] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isOpenUserInfoModal, setUserInfoModal] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [chatBg, setBg] = useState<any>();

  const { setMode } = useChatViewModeState();
  const { unselectAll: unselectAllMessages } = useChatSelectedMessagesState();

  const { openSelectedMessagesMenu } = useChatSelectedMessages({
    infoMessageApi: messageApi,
  });

  useEffect(() => {
    setMode(ChatViewModeEnum.DEFAULT);
    unselectAllMessages();
  }, [selectedChatId]);

  const preparedBg = async () => {
    if (currentBgImgId === ChatBgKeys.DEFAULT) return setBg(null);

    if (currentBgImgId === ChatBgKeys.CUSTOM_BG) {
      const customBg = await localStorage.getItem(StorageKey.CustomChatBg);
      setBg(customBg);
    }

    const defBg = defaultChatBgConfig.find(({ id }) => id === currentBgImgId)
      .img;
    setBg(`${defBg.default}`);
  };

  useEffect(() => {
    preparedBg();
  }, [currentBgImgId]);

  const {
    chats,
    searchString,
    loadParams,
    loadMore,
    loadPage,
    setSearchVal,
    isLoading,
    onPin,
    onSetUnread,
    isPinned,
    isUnread,
  } = useChatList();

  const { headerChatInfo, chatDetails } = useChatDetails(selectedChatId);

  const {
    messages,
    resetList,
    loadMoreMessages,
    loadEarlier,
    state,
    replyTo,
    setReplyToMessage,
    scrollToId,
    setScrollToId,
    onForward,
    onPressMessagePreview,
    onMessageActions,
  } = useChatMessages(
    selectedChatId,
    chatDetails?.firstMessageId,
    chatDetails?.lastMessageId
  );

  const onSendMessage = () => {
    if (replyTo) setReplyToMessage(null);
  };

  const clearReplyTo = () => setReplyToMessage(null);

  const replyToMessage =
    replyTo?.type === MessageType.Forwarded
      ? replyTo?.content?.originalMessage
      : replyTo;

  const replyToUserName = useMemo(() => {
    if (replyTo?.chatId !== selectedChatId) {
      clearReplyTo();
    }

    if (!replyToMessage) return null;

    const author = _.find(
      chatDetails?.chatMembers,
      (member) => member.userId === replyToMessage?.userId
    );

    return createFullName(author?.user?.firstName, author?.user?.lastName);
  }, [replyToMessage, chatDetails]);

  const role = useMemo(() => {
    const member = _.find(
      chatDetails?.chatMembers,
      (member) => member.userId === accountId.id
    );

    const role =
      member && chatDetails?.type === ChatType.Group
        ? member.role
        : ChatMemberRole.Member;

    return role;
  }, [chatDetails]);

  const { currentPinedIndex, onPressPined, pinOptions } = usePinedMessages({
    messages: chatDetails?.pinedMessages,
    role,
    onPress: onPressMessagePreview,
  });

  const {
    suggestionItems,
    message: newMessage,
    setMessage: setNewMessage,
    isSending,
    sendMessage,
    editedMessage,
    clearEditedMessage,
  } = useCreateTextMessage({
    chatMembers: chatDetails?.chatMembers,
    chatId: selectedChatId,
    onSend: onSendMessage,
    replyToMessage,
  });

  useEffect(() => {
    if (editedMessage && replyToMessage) clearReplyTo();
  }, [editedMessage]);

  useEffect(() => {
    if (replyToMessage && editedMessage) clearEditedMessage();
  }, [replyToMessage]);

  useSendFiles({
    // replyToMessage,
    chatId: selectedChatId,
    onSend: onSendMessage,
  });

  const onPressMenuItem = async () => {
    setMenuOpen(false);
  };

  const onProfilePress = useCallback((authorId: number) => {
    setSelectedUserId(authorId);
    setUserInfoModal(true);
  }, []);

  const onMessageOptionsPress = useCallback(
    (id) => {
      const item = _.find(messages, (message) => message?.id === id);
      onMessageActions(item, role);
    },
    [chatDetails, messages]
  );

  const getPinnedMessage = () => {
    if (_.isEmpty(chatDetails?.pinedMessages)) return null;

    return chatDetails?.pinedMessages[currentPinedIndex]?.type ===
      MessageType.Forwarded
      ? chatDetails?.pinedMessages[currentPinedIndex]?.content?.originalMessage
      : chatDetails?.pinedMessages[currentPinedIndex];
  };

  return (
    <Container>
      {contextHolder}
      <div className="chats">
        <Row>
          <CreateChatModal
            isShow={isCreateChatModal}
            setOpen={setCreateChatModal}
            excludeIds={[accountId.id]}
          />

          <UserInfoModal
            isOpen={isOpenUserInfoModal}
            userId={selectedUserId}
            toggle={() => setUserInfoModal(false)}
          />
          <Col md={12} lg={12} xl={4}>
            <div className="left-side-chats">
              <SearchChatsWithSettings
                searchStr={searchString}
                setSearchStr={setSearchVal}
                onClickCreate={() => setCreateChatModal(true)}
              />

              <div className="chat-cards">
                <CardBody>
                  <ChatsList
                    isLoading={isLoading}
                    loadParams={loadParams}
                    loadMore={loadMore}
                    loadPage={loadPage}
                    chats={chats} // paginationList={paginationList}
                  />
                </CardBody>
              </div>
            </div>
          </Col>
          <Col md={12} lg={12} xl={8}>
            <div className="chat-field-card">
              {selectedChatId ? (
                <>
                  <ChatHeader
                    label={headerChatInfo.name}
                    userCount={headerChatInfo.label}
                    previewUrl={headerChatInfo.previewUrl}
                    chatDetails={chatDetails}
                    isPinned={isPinned(selectedChatId)}
                    isUnread={isUnread(selectedChatId)}
                    setPinned={() => onPin(selectedChatId)}
                    setUnread={() =>
                      onSetUnread(selectedChatId, headerChatInfo)
                    }
                    onPressActionsBtn={() => openSelectedMessagesMenu(role)}
                  />

                  <div
                    className="chat-field-area"
                    style={{
                      backgroundImage: chatBg ? `url(${chatBg})` : `none`,
                    }}
                  >
                    <ForwardMessageModal />
                    <ChatConfirmDeleteModal />
                    <ChatSendImgModal />
                    <PinnedMessage
                      message={getPinnedMessage()}
                      onPress={onPressPined}
                      options={pinOptions}
                    />

                    <ChatMessages
                      onMessageMenuPress={onMessageOptionsPress}
                      userId={accountId.id}
                      selectedChatId={selectedChatId}
                      chatDetails={chatDetails}
                      items={transformMessages(
                        messages,
                        chatDetails?.chatMembers,
                        accountId.id
                      )}
                      onRefresh={resetList}
                      loadPrev={loadEarlier}
                      loadNext={loadMoreMessages}
                      onProfilePress={(id) => {
                        setSelectedUserId(id);
                        setUserInfoModal(true);
                      }}
                      onForwardedAuthorPress={onProfilePress}
                      onMentionPress={onProfilePress}
                      isLoadingNext={state.isLoadingNew}
                      lastMessageLoaded={state.lastMessageLoaded}
                      firstMessageLoaded={state.firstMessageLoaded}
                      scrollToId={scrollToId}
                      afterScroll={() => setScrollToId(null)}
                      onForwardPressMessage={onForward}
                    />
                  </div>

                  <ChatBar
                    withAttachmentsBtn={true}
                    isMenuOpen={isMenuOpen}
                    onPressAttachments={() => setMenuOpen(!isMenuOpen)}
                    onPressSend={sendMessage}
                    message={newMessage}
                    chatId={selectedChatId}
                    onChangeMessage={setNewMessage}
                    onPressMenuItem={onPressMenuItem}
                    suggestions={suggestionItems}
                    replyToMessage={replyToMessage}
                    clearReplyTo={clearReplyTo}
                    replyToName={replyToUserName}
                    editedMessage={editedMessage}
                    onPressClearEditedMessage={clearEditedMessage}
                    isSending={isSending}
                    onSend={onSendMessage}
                  />
                </>
              ) : (
                <div className="chat-initial-button">
                  <SelectChatButton selectChat={_.noop} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <SelectStickersModalSmart />
    </Container>
  );
};

export default ChatsPage;
