import http from "../http.service";
import { ApiResponse } from "../http.types";
import { IUser as IUserInterface } from "@/shared";
import * as req from "./requests.interfaces";
import * as res from "./responses.interfaces";

export const fetchContactsList = (
  params: req.IGetContactsParams
): ApiResponse<res.IFetchContactsList> => {
  const reqParams = { ...params };
  if (!params.sortField) {
    reqParams.sort = "ASC";
    reqParams.sortField = "lastName";
  }
  return http.get<res.IFetchContactsList>(`admin/contacts`, {
    params: reqParams,
  });
};

export const fetchContactInfo = (id: number): ApiResponse<IUserInterface> => {
  return http.get<IUserInterface>(`admin/contacts/${id}`);
};

export const contactsApi = {
  fetchContactsList,
  fetchContactInfo,
};
