import { contactsApi } from "@/api";
import { IUser } from "@/shared";

const fetchContact = async (payload: number): Promise<IUser> => {
  try {
    const { data: dataContact } = await contactsApi.fetchContactInfo(payload);
    return dataContact;
  } catch (error) {
    console.log("contact fetching error", error);
  } finally {
  }
};

export const contactsService = {
  fetchContact
};
