import { chatMessagesApi } from "@/api";
import {
  IClearChatPayload,
  IDeleteMessageParams,
  IEditTextMessage,
  IFetchChatMessages,
  ISendFileMessage,
  ISendFilesMessages,
  ISendStickerMessage,
  ISendTextMessage,
} from "@/api/chats-messages/request.interfaces";
import { IFetchChatMessagesList } from "@/api/chats-messages/responses.interfaces";
import { ApiResponse } from "@/api/http.types";
import { IChatMessage, runActionByType } from "@/shared";
import { appEvents } from "@/shared/events";
import { SetUnreadMessagesCount } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";
import { IChatMessage as IChatMessageItem } from "@/containers/Chats/plugins/interfaces";
import _ from "lodash";

const fetchMessages = async (params: {
  params: IFetchChatMessages;
}): ApiResponse<IFetchChatMessagesList> => {
  try {
    const resp = await chatMessagesApi.getChatMessagesListReq(params);

    return resp;
  } catch (error) {
    console.log("ERROR | FETCH CHAT MESSAGES ERROR ", error);
  }
};

const sendTextMessage = async (data: ISendTextMessage) => {
  try {
    await chatMessagesApi.sendTextMessageReq(data);
  } catch (error) {
    console.log("ERROR | SEND CHAT MESSAGES ERROR ", error);
  }
};

const editTextMessage = async (data: IEditTextMessage) => {
  try {
    await chatMessagesApi.editTextMessageReq(data);
  } catch (error) {
    console.log("ERROR | SEND CHAT MESSAGES ERROR ", error);
  }
};

const sendStickerMessage = async (data: ISendStickerMessage) => {
  try {
    await chatMessagesApi.sendStickerMessageReq(data);
  } catch (error) {
    console.log("ERROR | SEND CHAT MESSAGES ERROR ", error);
  }
};

const sendMediaMessage = async (data: ISendFileMessage[]) => {
  for await (const item of data) {
    await runActionByType(item.file, {
      image: async () => {
        await chatMessagesApi.sendImageMessageReq(item);
        console.log("image sended by service");
      },
      video: async () => {
        await chatMessagesApi.sendVideoMessageReq(item);
        console.log("video sended by service");
      },
    });
  }
};

const sendFileMessage = async (data: ISendFilesMessages) => {
  try {
    await chatMessagesApi.sendFileMessageReq(data);
    console.log("file sended by service");
  } catch (err) {
    console.log("SEND FILE MESSAGE ERROR", err.response.data);
  }
};

const deleteChatMessage = async (
  message: IChatMessage | Partial<IChatMessageItem>[],
  deleteForAll?: boolean
) => {
  const params: IDeleteMessageParams = {};
  if (deleteForAll) params.deleteForAll = deleteForAll;

  const messages = _.isArray(message)
    ? (message as IChatMessageItem[])
    : [message as IChatMessage];

  try {
    for await (const item of messages) {
      await chatMessagesApi.deleteMessageByIdReq(item.id, params);

      appEvents.emit("onDeleteMessage", {
        chatId: item.chatId,
        messageId: item.id,
      });
    }
  } catch (err) {
    console.log("ERROR ON DELETE MESSAGE", err);
  }
};

const clearChatMessages = async (
  data: IClearChatPayload,
  unreadMessagesCount?: number
) => {
  try {
    await chatMessagesApi.clearChatReq(data);

    if (!data.deleteForAll)
      appEvents.emit("onClearChatForMe", { chatId: data.chatId });

    if (!data.deleteForAll && unreadMessagesCount > 0)
      appEvents.emit("decreaseCounter", {
        count: unreadMessagesCount,
      });
  } catch (err) {
    // showUknowError()
    console.log("ERROR ON CLEAR CHAT", err);
  }
};

const clearAllChatMessages = async () => {
  try {
    await chatMessagesApi.clearChatReq();

    simpleDispatch(new SetUnreadMessagesCount(0));
    appEvents.emit("onClearAllChats", {});
  } catch (error) {
    console.log("ERROR ON CLEAR ALL CHATS", error);
  }
};

export const chatMessagesService = {
  fetchMessages,
  sendTextMessage,
  sendFileMessage,
  sendMediaMessage,
  deleteChatMessage,
  clearChatMessages,
  clearAllChatMessages,
  sendStickerMessage,
  editTextMessage,
};
