import { tasksDocumentsApi } from "@/api";
import { IFile, notification } from "@/shared";
import { appEvents } from "@/shared/events";
import _ from "lodash";
import { useEffect, useState } from "react";
import { ITaskFileItem } from "../interfaces";
import { transformTaskDocsToFileItems } from "../transforms";

interface IProps {
  taskId: number;
  needInit: boolean;
}

export const useTaskDocs = ({ taskId, needInit }: IProps) => {
  const [taskDocs, setTaskDocs] = useState<ITaskFileItem[]>(null);
  const [deleteDocId, setDeleteDocId] = useState(null);
  const [isInit, setInit] = useState<boolean>(false);

  const openErrorNotification = (description: string) =>
    notification.showError("Помилка", description);

  const openSuccessNotification = (description: string) =>
    notification.showSuccess("Повідомлення", description);

  const fetchTaskDocs = async taskId => {
    const { data } = await tasksDocumentsApi.getTaskDocuments(taskId);

    if (data) {
      const docs = transformTaskDocsToFileItems(data);
      setTaskDocs(docs);

      if (!_.isEmpty(docs)) appEvents.emit("onReadDocuments", { taskId });
    }
  };

  const addDocs = async (files: IFile[]) => {
    try {
      await tasksDocumentsApi.addTaskDocuments({
        taskId,
        files
      });

      if (_.isEmpty(taskDocs))
        appEvents.emit("onFirstTaskDocument", { taskId });

      await fetchTaskDocs(taskId);
      openSuccessNotification(
        `${files.length > 1 ? "Файли" : "Файл"} успішно завантажено`
      );
    } catch (e) {
      openErrorNotification(
        `Не вдалось додати ${files.length > 1 ? "файли" : "файл"}`
      );
    }
  };

  const deleteDoc = async () => {
    if (!deleteDocId) return;
    try {
      await tasksDocumentsApi.removeTaskDocument(deleteDocId);
      const docs = taskDocs.filter(it => it.id !== deleteDocId);
      setTaskDocs(docs);

      if (_.isEmpty(docs)) appEvents.emit("onDeleteAllTaskDocs", { taskId });
    } catch (e) {
      openErrorNotification("Не вдалось видалити файл");
    } finally {
      setDeleteDocId(null);
    }
  };

  const softDelete = (id: number) => {
    setTaskDocs(prev => _.filter(prev, it => it.id !== id));
  };

  useEffect(() => {
    if (taskId && needInit && !isInit) {
      fetchTaskDocs(taskId);
      setInit(true);
    }
  }, [taskId, isInit, needInit]);

  useEffect(() => {
    if (isInit) {
      setInit(false);
      setTaskDocs(null);
    }
  }, [taskId]);

  return {
    taskDocs,
    addDocs,
    deleteDoc,
    setDeleteDocId,
    softDelete
  };
};
