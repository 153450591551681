import React, { FC } from "react";
import Modal from "../../../components/Modal";
import "./styles.scss";

interface ConfirmModalProps {
  isShow: boolean;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  setShow: (val: boolean) => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  isShow,
  message,
  onConfirm,
  onCancel,
  setShow,
}) => {
  return (
    <Modal full show={isShow} toggle={setShow}>
      <div className="confirm-modal">
        <span className="message">{message}</span>

        <div className="button-bar">
          <button
            className="confirm-btn"
            onClick={() => {
              onCancel && onCancel();
              setShow(false);
            }}
          >
            Нi
          </button>

          <button className="cancel-btn" onClick={onConfirm}>
            Так
          </button>
        </div>
      </div>
    </Modal>
  );
};
