import React, { FC, useMemo } from "react";
import ModalComponent from "@/components/Modal";
import FileViewer from "react-file-viewer";
import config from "@/config";
import Scrollbar from "react-smooth-scrollbar";

interface IProps {
  isShow: boolean;
  setOpen: (val: boolean) => void;
  docName: string;
  docType: string;
  docSrc: string;
}

export const ShowDocModal: FC<IProps> = ({
  isShow,
  setOpen,
  docName,
  docType,
  docSrc,
}) => {
  const donwloadWithoutOpenUrl = (url: string): void => {
    const newWindow = window.open(url, "_self", "noopener, noreferrer");

    if (newWindow) newWindow.opener = null;

    // setTimeout(function() {
    //   newWindow.document.title = docName;
    // }, 100);
  };

  const onClickUrl = (url: string): (() => void) => () =>
    donwloadWithoutOpenUrl(url);

  const isSupported = () => {
    return [
      "pdf",
      "csv",
      "xslx",
      "docx",
      "png",
      "jpg",
      "jpeg",
      "bmp",
      "mp4",
      "webm",
      "mp3",
    ].includes(docType);
  };

  const Content = useMemo(() => {
    if (!docSrc || !docType) return null;

    if (!isSupported()) {
      return (
        <p
          style={{
            paddingTop: 40,
            paddingBottom: 100,
            textAlign: "center",
            fontSize: 18,
          }}
        >
          Предперегляд {'"'}
          {docType}
          {'"'} типу документа не передбачено в системі
        </p>
      );
    }

    if (docType.includes("pdf")) {
      return (
        <object
          type="text/html"
          id="webUrl"
          data={`${config.pdfViewer}?url=${docSrc}`}
          style={{
            width: "100%",
            height: "70vh",
            position: "relative",
            zIndex: 99999,
          }}
        ></object>
      );
    }

    return (
      <Scrollbar
        className="sidebar__scroll scroll"
        style={{
          width: "100%",
          height: "70vh",
          position: "relative",
          zIndex: 99999,
        }}
      >
        <FileViewer fileType={docType} filePath={docSrc} />
      </Scrollbar>
    );
  }, [docSrc, docType]);

  return (
    <ModalComponent
      isDoc
      document="document"
      title={docName}
      show={isShow}
      toggle={() => setOpen(false)}
      onDownload={onClickUrl(docSrc)}
    >
      {Content}
    </ModalComponent>
  );
};

// return (
//   <>
//     <iframe
//       ref={iframeRef}
//       src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
//         docSrc
//       )}`}
//       onLoad={(event) => {
//         console.log(event.nativeEvent);
//       }}
//       style={{ width: "100%", height: "auto", border: "none" }}
//     />
//   </>
// );
