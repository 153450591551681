import { CheckIndicator } from "@/shared";
import React, { FC } from "react";

interface IProps {
  source: any;
  isChecked: boolean;
  onPress: () => void;
}

export const CheckableBgItem: FC<IProps> = ({ source, isChecked, onPress }) => {
  //   const [isLoading, setLoad] = useState(false);
  console.log(source);
  return (
    <div onClick={onPress}>
      <img src={source}></img>
      {/* {isLoading ? <Loader /> : null} */}
      {isChecked ? <CheckIndicator /> : null}
    </div>
  );
};
