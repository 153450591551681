/* eslint-disable react/display-name */
import React from "react";
import moment from "moment";
import { IUser, createFullName } from "@/shared";

interface IProps {
  profile: IUser;
  startDate: Date;
  endDate: Date;
  taskCounts: {
    unique: number;
    copying: number;
    self: number;
    total: number;
  };
}

export const PrintComponent = React.forwardRef(
  (props: IProps, ref: React.RefObject<HTMLDivElement>) => {
    const { profile, startDate, endDate, taskCounts } = props;

    const fullName = createFullName(
      profile.info.firstName,
      profile.info.middleName,
      profile.info.lastName
    );

    return (
      <div ref={ref} className="print-content-block" style={{ padding: 50 }}>
        <p style={{ fontSize: "18px" }}>
          <strong>{fullName} </strong>
          <i>{`(${profile?.info?.position ? profile?.info?.position : ""}${
            profile?.info?.position && profile?.factoryName ? `, ` : ""
          }${profile?.factoryName ? `${profile?.factoryName}` : ""})`}</i>
        </p>
        <p style={{ fontSize: "18px", marginTop: "0px" }}>
          <strong>
            ({moment(startDate).format("DD-MM-YYYY")} -{" "}
            {moment(endDate).format("DD-MM-YYYY")})
          </strong>
        </p>
        <p>Унікальних - {taskCounts.unique}</p>
        <p>Дублікатів - {taskCounts.copying}</p>
        <p>Власних - {taskCounts.self}</p>
        <p style={{ borderTop: "1px solid black" }} />
        <p>
          <strong>Загальна кількість: {taskCounts.total}</strong>
        </p>
      </div>
    );
  }
);
