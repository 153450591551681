import { IChatMember } from "@/shared";
import _ from "lodash";

export const getHeaderGroupChatInfo = (
  membersCount: number,
  onlineCount: number
) => {
  return `${membersCount} учасників${
    onlineCount ? `, ${onlineCount} онлайн` : ""
  }`;
};

export const getHeaderPersonalChatInfo = (
  members: IChatMember[],
  accountId: number
) => {
  const member = _.find(members, (member) => member.userId !== accountId);
  if (member?.isOnline) return "Зараз в мережі";

  return "Не в мережі";
};
