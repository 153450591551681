import React, { FC } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { checkPermission, EUserStatus, IUser, Permissions } from "@/shared";
import { useSelector } from "react-redux";
import { getMyPermissions } from "@/store/permissions";
import { Tooltip } from "antd";

interface IProps {
  profile: IUser;
  authProfile: IUser;
  onPressBlock?: () => void;
  onPressLogout?: () => void;
}

export const Actions: FC<IProps> = ({
  profile,
  authProfile,
  onPressBlock,
  onPressLogout,
}) => {
  const permissions = useSelector(getMyPermissions);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__action-card">
            <div className="profile__action-status">
              Статус:{" "}
              <strong>
                {profile.status === EUserStatus.Blocked
                  ? "Заблокований"
                  : "Активний"}
              </strong>
            </div>
            <div className="profile__action-btns">
              {checkPermission(
                "tabs",
                "user",
                authProfile,
                permissions,
                Permissions.BAN
              ) && (
                <Tooltip
                  title={
                    profile.status === EUserStatus.Active
                      ? "Заблокувати користувача"
                      : "Розблокувати користувача"
                  }
                  key={"block/unblock-user"}
                  mouseEnterDelay={0.5}
                >
                  <Button
                    color="primary"
                    onClick={onPressBlock}
                    className="profile__btn"
                  >
                    {profile.status === EUserStatus.Active
                      ? "Заблокувати"
                      : "Розблокувати"}
                  </Button>
                </Tooltip>
              )}
              {checkPermission(
                "tabs",
                "user",
                authProfile,
                permissions,
                Permissions.LOGOUT
              ) && (
                <Tooltip
                  title="Розлогінити на всіх пристроях"
                  key={"logout-user"}
                  mouseEnterDelay={0.5}
                >
                  <Button
                    color="primary"
                    onClick={onPressLogout}
                    className="profile__btn"
                  >
                    Розлогінити
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
