import React, { FC, useState } from "react";
import { Tooltip } from "antd";
import { hasImageUrl, IChatDetails, IconComponent } from "@/shared";
import {
  ChatAvatarWithOnlineIndicator,
  ChatHeaderInfo,
  SelectedMessagesInfo,
} from "../atoms";
import "./style.scss";
import gearIcon from "@/assets/img/gear-icon.svg";
import chatUnreadIcon from "@/assets/img/chat-unread-icon.svg";
import { SettingsChatModal } from "../components";
interface ChatHeaderProps {
  label: string;
  userCount: string;
  previewUrl: string;
  chatDetails: IChatDetails;
  isPinned: boolean;
  isUnread: boolean;
  setPinned: () => void | Promise<void>;
  setUnread: () => void | Promise<void>;
  onPressActionsBtn: () => void;
}

export const ChatHeader: FC<ChatHeaderProps> = ({
  previewUrl,
  label,
  userCount,
  chatDetails,
  isPinned,
  isUnread,
  setPinned,
  setUnread,
  onPressActionsBtn,
}) => {
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  return (
    <div className="chat-header-container">
      {chatDetails && (
        <SettingsChatModal
          isShow={isSettingsOpen}
          isPinned={isPinned}
          setOpen={setSettingsOpen}
          chatId={chatDetails?.id}
          onPressPinnedChat={setPinned}
        />
      )}
      <div className="chat-header-info">
        <ChatAvatarWithOnlineIndicator
          isOnline={false}
          imageUrl={hasImageUrl(previewUrl, label)}
          size={36}
        />
        <ChatHeaderInfo label={label} userCount={userCount} />
      </div>

      <SelectedMessagesInfo onPressActionsBtn={onPressActionsBtn} />

      <div className="chat-header-settings">
        {!isUnread && (
          <Tooltip
            placement="left"
            arrowPointAtCenter
            mouseEnterDelay={0.5}
            title="Позначити бесіду як непрочитану "
          >
            <div className="chat-unread-indicator" onClick={setUnread}>
              <IconComponent name={chatUnreadIcon} />
            </div>
          </Tooltip>
        )}

        <Tooltip mouseEnterDelay={0.5} title="Налаштування бесіди">
          <div className="chat-settings" onClick={() => setSettingsOpen(true)}>
            <IconComponent name={gearIcon} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
