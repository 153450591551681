export const createUserName = (
  firstName?: string,
  middleName?: string,
  lastName?: string
) => {
  const nameArr = [];
  if (lastName) nameArr.push(lastName);
  if (firstName) nameArr.push(firstName);
  if (middleName) nameArr.push(middleName);

  return nameArr.join(" ");
};
