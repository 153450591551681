import React, { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { EyeIcon, PasswordIcon } from "../atoms";

interface IProps {
  onChange?: any;
  onBlur?: any;
  value?: string;
  error?: string;
  register: UseFormRegisterReturn;
}

export const NewPasswordField = (props: IProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className="form__form-group-auth">
      <span className="form__form-group-label">Новий пароль</span>
      <div className="form__form-group-field-signIn">
        <div className="form__form-group-icon">
          <PasswordIcon />
        </div>
        <input
          name="password"
          placeholder="Пароль"
          type={showPassword ? "text" : "password"}
          onChange={props.onChange}
          onBlur={props.onBlur}
          {...props.register}
        />
        <button
          className={`form__form-group-button${showPassword ? " active" : ""}`}
          onClick={() => setShowPassword((prev) => !prev)}
          type="button"
        >
          <EyeIcon />
        </button>
      </div>
      {props.error && (
        <span className="form__form-group-error">{props.error}</span>
      )}
    </div>
  );
};
