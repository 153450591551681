import { ICreateOrUpdateTaxonomy, ITaxonomy } from '@/shared'
import { Action } from 'redux'

export class SetTaxonomies implements Action {
	readonly type = 'SET_TAXONOMIES'

	constructor(
		public readonly payload: {
			taxonomies: ITaxonomy[]
		}
	) { }
}

export class CreateTaxonomy implements Action {
	readonly type = 'CREATE_TAXONOMY'

	constructor(
		public readonly payload: {
			taxonomy: ITaxonomy
		}
	) { }
}

export class UpdateTaxonomy implements Action {
	readonly type = 'UPDATE_TAXONOMY'

	constructor(
		public readonly payload: {
			taxonomyId: number,
			taxonomy: ITaxonomy
		}
	) { }
}

export class DeleteTaxonomy implements Action {
	readonly type = 'DELETE_TAXONOMY'

	constructor(
		public readonly payload: {
			taxonomyId: number
		}
	) { }
}

export class SetTargetTaxonomy implements Action {
	readonly type = 'SET_TARGET_TAXONOMY'

	constructor(
		public readonly payload: {
			taxonomy: ICreateOrUpdateTaxonomy
		}
	) { }
}

export class DeleteIcon implements Action {
	readonly type = 'DELETE_ICON'
}


export class isLoading {
	readonly type = 'IS_LOADING_TAXONOMIES'
	constructor(
		public readonly payload: {
			isLoading: boolean
		}
	) { }
}
export class ResetAccount {
	readonly type = 'RESET_ACCOUNT'
}

export type TTaxonomiesActions = SetTaxonomies | isLoading | SetTargetTaxonomy | DeleteTaxonomy | CreateTaxonomy | UpdateTaxonomy | DeleteIcon
