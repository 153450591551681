import {
  ICreateFactory,
  IUpdateFactory,
} from "@/api/factories/requests.interface";
import { IFactory } from "@/shared";
import React, { FC } from "react";
import { FormFactory } from "../FormFactory";

interface IProps {
  isOpen: boolean;
  selectedFactory: IFactory;
  factories: IFactory[];
  closeModal: () => void;
  createFactory: (factory: ICreateFactory) => void;
  updateFactory: (factory: IUpdateFactory) => void;
  editable: boolean;
}

export const ModalForm: FC<IProps> = ({
  selectedFactory,
  factories,
  closeModal,
  createFactory,
  updateFactory,
  editable,
}) => {
  return (
    <FormFactory
      selectedFactory={selectedFactory}
      factories={factories}
      closeModal={closeModal}
      updateFactory={updateFactory}
      createFactory={createFactory}
      editable={editable}
    />
  );
};
