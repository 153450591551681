import React, { useMemo, useState } from "react";
import { ChatViewModeEnum } from "../enums";
import { useChatViewModeState } from "../hooks";
import DotsIcon from "@/assets/img/3-dots.svg";
import { Dropdown, Menu } from "antd";
import { IconComponent, getTitleByCount, useEventsListener } from "@/shared";
import { useChatSelectedMessagesState } from "../hooks";
import _ from "lodash";
import { ItemType } from "antd/lib/menu/hooks/useItems";

interface IProps {
  onPressActionsBtn: () => void;
}

export const SelectedMessagesInfo = ({ onPressActionsBtn }: IProps) => {
  const mode = useChatViewModeState((s) => s.mode);
  const { setMode } = useChatViewModeState();
  const selectedMessages = useChatSelectedMessagesState((s) => s.messages);
  const { unselectAll: unselectAllMessages } = useChatSelectedMessagesState();

  const [menuItems, setMenuItems] = useState([]);

  const menu = useMemo(() => {
    const preparedItems = menuItems.map((item) => ({
      ..._.pick(item, ["key", "label", "onClick"]),
      icon: item.iconNode ? item.iconNode : null,
    }));
    return (
      <Menu
        className="context-menu-chat-item"
        items={preparedItems as ItemType[]}
      />
    );
  }, [menuItems]);

  useEventsListener(
    "openSelectedMessagesMenuOptions",
    (payload) => {
      setMenuItems(payload.items);
    },
    [setMenuItems]
  );

  const infoText = useMemo(() => {
    if (selectedMessages.length >= 1)
      return `Вибрано ${getTitleByCount(selectedMessages.length, [
        "повідомлення",
        "повідомлення",
        "повідомлень",
      ])}`;
    return "Виберіть повідомлення";
  }, [selectedMessages]);

  if (mode === ChatViewModeEnum.DEFAULT) return <></>;

  const cancelSelectMode = () => {
    unselectAllMessages();
    setMode(ChatViewModeEnum.DEFAULT);
  };

  return (
    <div className="chat-selected-messages-info">
      <div className="selected-messages-cancel-btn" onClick={cancelSelectMode}>
        Скасувати
      </div>

      <div className="selected-messages-count-text">{infoText}</div>

      {selectedMessages?.length > 0 && (
        <Dropdown
          onVisibleChange={onPressActionsBtn}
          overlay={menu}
          trigger={["click"]}
        >
          <div className="selected-messages-action-btn">
            Дії
            <IconComponent name={DotsIcon} />
          </div>
        </Dropdown>
      )}
    </div>
  );
};
