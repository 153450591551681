import React, { FC, useMemo, useState } from "react";
import Avatar from "react-avatar-edit";
import { useSelector } from "react-redux";
import { getFilesConfig } from "@/store/shared";

interface Iprops {
  src: string;
  setImage: (preview: any) => void;
}

export const CropperComponent: FC<Iprops> = ({ src, setImage }) => {
  const [preview, setPreview] = useState(null);
  const { avatarWidth, avatarHeight, avatarSize, avatarTypes } = useSelector(
    getFilesConfig
  );

  const prepareMimeTypes = () => {
    const types = avatarTypes.split(",");
    const preparedTypes = types.map((it) => `image/${it}`);
    return preparedTypes.join(",");
  };

  const mimeTypes = useMemo(() => prepareMimeTypes(), [avatarTypes]);

  const typesMessage = useMemo(
    () =>
      avatarTypes
        .toUpperCase()
        .split(",")
        .join(", ")
        .replace("WEBP", "WebP"),
    [avatarTypes]
  );

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
    setImage(preview);
  };
  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > avatarSize * 1000) {
      alert(`Зображення не повинно перевищувати ${avatarSize}kb!`);
      elem.target.value = "";
    } else {
      return;
    }
  };

  return (
    <div className="container">
      <div className="container-cropper">
        <Avatar
          width={avatarWidth}
          height={avatarHeight}
          onCrop={onCrop}
          onClose={onClose}
          src={src}
          onBeforeFileLoad={onBeforeFileLoad}
          mimeTypes={mimeTypes}
          // mimeTypes={} The mime types used to filter loaded files (default: image/jpeg,image/png)
          label="Оберіть файл"
        />
      </div>
      <p className="size-description">
        {`Розмір зображення не більший ${avatarWidth}px./${avatarHeight}px., ${avatarSize}kb.`}
      </p>
      <p className="type-description">{`Дозволені типи: ${typesMessage}`}</p>
    </div>
  );
};
