import { encode, decode } from "js-base64";

export class TranspositionCipher {
  static encrypt(message: string): string {
    return encode(message);
  }

  static decrypt(encryptedMessage: string): string {
    return decode(encryptedMessage);
  }
}
