import _ from "lodash";
import { create } from "zustand";

interface State {
  isOpen: boolean;
  usersIdsSelected: string[];

  open: () => void;
  close: () => void;

  select: (userIds: string[]) => void;
}

export const useAddUsersModalState = create<State>()((set) => ({
  isOpen: false,
  usersIdsSelected: [],

  open() {
    set({ isOpen: true, usersIdsSelected: [] });
  },

  close() {
    set({ isOpen: false, usersIdsSelected: [] });
  },

  select(userIds) {
    set(() => ({
      usersIdsSelected: _.uniq(userIds),
    }));
  },
}));
