import { chatMembersService, chatMessagesService } from "@/services/domain";
import { notification } from "@/shared";

import _ from "lodash";
import { useState } from "react";

interface IModalState {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
}

const defaultModalState = {
  isOpen: false,
  message: "",
  onConfirm: _.noop,
};

export const useChatsSettings = () => {
  const [confirmModal, setConfirmModal] = useState<IModalState>(
    defaultModalState
  );

  const openConfirmModal = (message: string, onConfirm: () => void) => {
    setConfirmModal({ isOpen: true, message, onConfirm });
  };

  const afterAction = () => {
    setConfirmModal(defaultModalState);
  };

  const deleteAllChats = async () => {
    try {
      console.log("detete all chats");

      await chatMembersService.deleteFromAllChats();
      notification.showSuccess("Успіх!", "Всі бесіди видалено");
    } catch (e) {
      notification.showError(
        "Помилка",
        "Виникла помилка! Не вдалось видалити всі бесіди"
      );
    } finally {
      afterAction();
    }
  };

  const onDeleteChats = () => {
    openConfirmModal("Ви впевнені, що хочете видалити всі бесіди?", () =>
      deleteAllChats()
    );
  };

  const clearAllChats = async () => {
    try {
      console.log("clear all chats");

      await chatMessagesService.clearAllChatMessages();
      notification.showSuccess("Успіх!", "Всі повідомлення в чатах видалено");
    } catch (e) {
      notification.showError(
        "Помилка",
        "Виникла помилка! Не вдалось видалити повідомлення в бесідах"
      );
    } finally {
      afterAction();
    }
  };

  const onClearChats = () => {
    openConfirmModal(
      "Ви впевнені, що хочете очистити повідомлення всіх чатів?",
      () => clearAllChats()
    );
  };

  return {
    confirmModal: {
      state: confirmModal,
      set: setConfirmModal,
      open: openConfirmModal,
    },
    actions: {
      deletechats: onDeleteChats,
      clearchats: onClearChats,
    },
  };
};
