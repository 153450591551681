import { ITaskFilter } from "@/shared";
export class SetTaskFilter {
  readonly type = "SET_TASK_FILTER";
  constructor(
    public readonly payload: {
      filter: ITaskFilter;
    }
  ) {}
}
export class ClearFilter {
  readonly type = "CLEAR_FILTER";
}

export type TTaskActions = SetTaskFilter | ClearFilter;
