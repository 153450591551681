import React from "react";
import { useAddUsersModalState, useSearchState } from "../states";
import { SearchInput } from "@/shared";
import { Button } from "reactstrap";

export const UsersTableHeader = () => {
  const addUsers = useAddUsersModalState((s) => s.open);
  const { value, onChange } = useSearchState();

  return (
    <div
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        marginBottom: 30,
      }}
    >
      <div>
        <h4>Користувачі</h4>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <SearchInput
          value={value}
          onChange={onChange}
          placeholder="Пошук"
          style={{ height: 46, marginRight: "20px", width: 235 }}
        />
        <Button color="primary" size="sm" onClick={addUsers}>
          Додати
        </Button>
      </div>
    </div>
  );
};
