import React, { FC } from "react";
import { NavItem, NavLink } from "reactstrap";
import { ETasksModalTabs } from "../enums";
import classnames from "classnames";

interface IProps {
  title: string;
  isActive: boolean;
  onToggleTab: (tab: ETasksModalTabs) => void;
}

export const ModalTab: FC<IProps> = ({ title, isActive, onToggleTab }) => {
  return (
    <NavItem>
      <NavLink
        className={`modal-tab ${isActive ? "modal-tab_active" : ""}`}
        onClick={onToggleTab}
      >
        {title}
      </NavLink>
    </NavItem>
  );
};
