import { IMyPermissions } from "@/shared";
import { Action } from "redux";

export class SetAvailablePermissions implements Action {
  readonly type = "SET_AVAILABLE_PERMISSIONS";

  constructor(
    public readonly payload: {
      data: {
        [x: string]: string[] | { [x: string]: string[] };
      };
    }
  ) {}
}

export class SetMyPermissions implements Action {
  readonly type = "SET_MY_PERMISSIONS";

  constructor(
    public readonly payload: {
      data: IMyPermissions;
    }
  ) {}
}

export class isLoading {
  readonly type = "IS_LOADING_PERMISSIONS";
  constructor(
    public readonly payload: {
      isLoading: boolean;
    }
  ) {}
}

export type TPermissionsActions =
  | SetAvailablePermissions
  | SetMyPermissions
  | isLoading;
