import { permissionsGroupApi, usersApi } from "@/api";
import {
  SelectField,
  usePaginationList,
  Option,
  IPermissionsGroup,
  transformPermissionsToFormValues,
} from "@/shared";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import "../../style.scss";
import { PermissionsForm } from "../FormPermissions";

interface IProps {
  form: any;
  factoriesTree?: any;
}

export const PermissionsPage = ({ form, factoriesTree }: IProps) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const permissionsGroups = usePaginationList<IPermissionsGroup>({
    fetchItems: permissionsGroupApi.fetchPermissionsGroupsList,
    loadParams: {
      sort: "ASC",
      sortField: "name",
    },
  });

  const userPaginationList = usePaginationList<Option>({
    fetchItems: usersApi.fetchShortInfoUsersList,
    serrializatorItems: (items) =>
      items.map((it) => ({
        title: `${it.firstName || ""} ${it.lastName || ""}`,
        value: it.id.toString(),
      })),
    loadParams: {
      limit: 20,
      sortField: "id",
      sort: "ASC",
    },
  });

  const groupsOptions = useMemo(
    () =>
      permissionsGroups.items?.map((it) => ({
        title: it.name,
        value: it.id,
      })),
    [permissionsGroups.items]
  );

  const onSelectGroup = (id: string) => {
    const group = _.find(permissionsGroups.items, (it) => it.id === Number(id));
    if (!group) return;

    const permissions = transformPermissionsToFormValues(group.permissions);

    setSelectedGroup(group);
    form.setValue("permissions", permissions);
  };

  return (
    <div className="tab-permissions-container">
      <div className="permission-row-field" style={{ marginTop: 20 }}>
        <SelectField
          value={selectedGroup?.name}
          options={groupsOptions}
          label={"Група прав доступу"}
          placeholder={""}
          onChange={onSelectGroup}
          error={`error?`}
        />
      </div>

      <PermissionsForm
        form={form}
        userPaginationList={userPaginationList}
        factoriesTree={factoriesTree}
      />
    </div>
  );
};
