import { DateField, TreeSelectField } from "@/components/Fields";
import {
  InputField,
  TextField,
  EUsersListType,
  ETaxonomyType,
  notification,
} from "@/shared";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { TabPane, ButtonToolbar, Button } from "reactstrap";
import { ETaskModalMode, ETasksModalTabs } from "../enums";
import { AddTaxonomyField, CreateTaskTooltip } from "../atoms";
import GroupPlusSVG from "@/assets/img/group-plus.svg";
import moment from "moment";
import { UserSelectWithSearch } from "@/components/SmartComponents";
import { useSelector } from "react-redux";
import { getTaxonomiesList } from "@/store/taxonomies";
import _ from "lodash";
import { taxonomiesService } from "@/services/domain";
import { ITaskForm } from "../interfaces";
import { taxonomiesApi } from "@/api";

const TOOLTIP_SHOW_TIMEOUT = 1500;

type TCalendar = "start" | "end";

interface IProps {
  control: any;
  form: UseFormReturn<ITaskForm, any>;
  onSubmit: (e) => void;
  mode: ETaskModalMode;
  errors?: any;
  disableBtn?: boolean;
}

const rowStyle: CSSProperties = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  margin: 0,
  marginBottom: 10,
};

export const ModalTabMainInfo: FC<IProps> = ({
  form,
  control,
  onSubmit,
  mode,
  disableBtn,
}) => {
  const [showAddGroup, setShowAddGroup] = useState<boolean>(false);
  const [showAddReason, setShowAddReason] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<string>(null);
  const [newReason, setNewReason] = useState<string>(null);
  const [{ isShowPop, calendarType }, setWarningPop] = useState<{
    isShowPop: boolean;
    calendarType?: TCalendar;
  }>({ isShowPop: false });

  const taxonomiesList = useSelector(getTaxonomiesList);
  const watchValues = form.watch();

  const categoriesTree = useMemo(
    () =>
      taxonomiesList.list
        .filter((item) => item.type === ETaxonomyType.taskCategory)
        .sort((a, b) => {
          if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
            return -1;
          }
          if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
            return 1;
          }
          return 0;
        }),
    [taxonomiesList]
  );

  const reasonsTree = useMemo(
    () =>
      taxonomiesList.list
        .filter((item) => item.type === ETaxonomyType.taskReason)
        .sort((a, b) => {
          if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
            return -1;
          }
          if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
            return 1;
          }
          return 0;
        }),
    [taxonomiesList]
  );

  const openErrorNotification = (description: string) =>
    notification.showError("Помилка", description);

  useEffect(() => {
    if (
      mode === ETaskModalMode.Create &&
      !watchValues?.groupId &&
      !_.isNull(watchValues?.groupId)
    ) {
      const defaultGroup = taxonomiesList?.list?.find(
        (it) => it.isDefault && it.type === ETaxonomyType.taskCategory
      );

      if (defaultGroup) form.setValue("groupId", defaultGroup?.id?.toString());
    }
  }, [form, watchValues, taxonomiesList, mode]);

  const addGroup = async () => {
    if (_.isEmpty(newGroup)) return;

    try {
      const { data: group } = await taxonomiesApi.createTaxonomy({
        name: newGroup,
        type: ETaxonomyType.taskCategory,
        icon: null,
      });

      await taxonomiesService.fetchTaxonomies();

      form.setValue("groupId", group.id.toString());
      setNewGroup(null);
      setShowAddGroup(false);
    } catch (e) {
      openErrorNotification("Не вдалось зберегти групу");
    }
  };

  const addReason = async () => {
    if (_.isEmpty(newReason)) return;

    try {
      const { data: reason } = await taxonomiesApi.createTaxonomy({
        name: newReason,
        type: ETaxonomyType.taskReason,
        icon: null,
      });

      await taxonomiesService.fetchTaxonomies();

      form.setValue("reasonId", reason.id.toString());
      setNewReason(null);
      setShowAddReason(false);
    } catch (e) {
      openErrorNotification("Не вдалось зберегти підставу");
    }
  };

  useEffect(() => {
    if (_.isEmpty(taxonomiesList.list)) taxonomiesService.fetchTaxonomies();
  }, []);

  return (
    <TabPane tabId={ETasksModalTabs.Tasks}>
      <form
        onSubmit={(e) => {
          // e.preventDefault();
          onSubmit(e);
        }}
        className="form"
        style={{ flexDirection: "column", maxWidth: 480 }}
      >
        <div className="tab-task-container">
          <div className="task-row-field" style={{ marginBottom: 20 }}>
            <div className="col-sm-12 col-md-6">
              <CreateTaskTooltip
                isVisible={isShowPop && calendarType === "start"}
                message={"Дата початку не може бути більша, ніж дата Кінця"}
              />

              <Controller
                name={"startDate"}
                control={control}
                rules={{
                  validate: (val) => {
                    const values = form.getValues();
                    if (
                      moment(values.endDate).startOf("day") <=
                      moment(val).startOf("day")
                    )
                      return false;

                    return true;
                  },
                  required: true,
                }}
                render={({ field: { value, onChange }, fieldState }) => {
                  return (
                    <DateField
                      label={"Початок"}
                      value={value}
                      onChange={(date: string) => {
                        onChange(date);
                      }}
                      disabledDate={(current) =>
                        form.getValues()?.createDate
                          ? current.isBefore(
                              moment(form.getValues()?.createDate).startOf(
                                "day"
                              )
                            )
                          : current.isBefore(moment().startOf("day"))
                      }
                      meta={{
                        error: fieldState?.error
                          ? "Дата початку має бути меншою за дату кінця"
                          : "",
                      }}
                      onOpenChange={(status) => {
                        if (status) {
                          setWarningPop({
                            isShowPop: true,
                            calendarType: "start",
                          });
                          setTimeout(() => {
                            setWarningPop({
                              isShowPop: false,
                              calendarType: "start",
                            });
                          }, TOOLTIP_SHOW_TIMEOUT);
                        }
                      }}
                    />
                  );
                }}
              />
            </div>

            <div
              className="col-sm-12 col-md-6"
              style={{ alignItems: "center" }}
            >
              <CreateTaskTooltip
                isVisible={isShowPop && calendarType === "end"}
                message={"Дата початку не може бути більша, ніж дата Кінця"}
              />

              <Controller
                name={"endDate"}
                control={control}
                rules={{
                  validate: (val) => {
                    const values = form.getValues();
                    if (
                      moment(values.startDate).startOf("day") >=
                      moment(val).startOf("day")
                    )
                      return false;

                    return true;
                  },
                  required: true,
                }}
                render={({ field: { value, onChange }, fieldState }) => (
                  <DateField
                    label={"Кінець"}
                    value={value}
                    onChange={onChange}
                    disabledDate={(current) =>
                      form.getValues()?.createDate
                        ? current.isBefore(moment(form.getValues()?.createDate))
                        : current.isBefore(moment())
                    }
                    meta={{
                      error: fieldState?.error
                        ? "Дата кінця має бути більшою за дату початку"
                        : "",
                    }}
                    onOpenChange={(status) => {
                      if (status) {
                        setWarningPop({
                          isShowPop: true,
                          calendarType: "end",
                        });
                        setTimeout(() => {
                          setWarningPop({
                            isShowPop: false,
                            calendarType: "end",
                          });
                        }, TOOLTIP_SHOW_TIMEOUT);
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="task-row-field" style={{ marginBottom: 20 }}>
            <div className="col-sm-12 col-md-12">
              <Controller
                name="name"
                control={control}
                rules={{ required: "Заповнити обов'язково" }}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    label="Назва"
                    placeholder=""
                    value={value}
                    onChange={onChange}
                    textAreaProps={{
                      maxLength: 200,
                      autoSize: {
                        minRows: 2,
                        maxRows: 6,
                      },
                    }}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="task-row-field" style={{ marginBottom: 20 }}>
            <div className="col-sm-12 col-md-12">
              <Controller
                name="description"
                control={control}
                rules={{ required: "Заповнити обов'язково" }}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    label="Опис"
                    placeholder=""
                    value={value}
                    onChange={onChange}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="task-row-field" style={{ marginBottom: 20 }}>
            <div className="col-sm-12 col-md-12">
              <Controller
                name="initiatorId"
                control={control}
                rules={{ required: "Заповнити обов'язково" }}
                render={({ field: { value, onChange }, fieldState }) => (
                  <UserSelectWithSearch
                    label="Ініціатор"
                    type={EUsersListType.All}
                    value={value}
                    onChange={onChange}
                    showCurrentUserOnTop={true}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="task-row-field" style={{ marginBottom: 20 }}>
            <div className="col-sm-12 col-md-12">
              <Controller
                name="executorsId"
                control={control}
                rules={{ required: "Заповнити обов'язково" }}
                render={({ field: { value, onChange }, fieldState }) => (
                  <UserSelectWithSearch
                    label="Виконавець"
                    type={EUsersListType.Executors}
                    value={value}
                    onChange={onChange}
                    mode={
                      mode === ETaskModalMode.Create ? "multiple" : undefined
                    }
                    showCurrentUserOnTop={true}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="task-row-field" style={{ marginBottom: 30 }}>
            <div className={"col-sm-12 col-md-6"}>
              {showAddGroup ? (
                <AddTaxonomyField
                  label="Група"
                  value={newGroup}
                  placeholder="Введіть значення"
                  onChange={setNewGroup}
                  onPressOk={addGroup}
                  onPressClose={() => setShowAddGroup(false)}
                />
              ) : (
                <Controller
                  name="groupId"
                  control={control}
                  rules={{ required: "Заповнити обов'язково" }}
                  render={({
                    field: { value, onChange, ref, onBlur },
                    fieldState,
                  }) => (
                    <div className="taxonomy-select-container">
                      <TreeSelectField
                        register={{
                          ref,
                          value: value ? Number(value) : value,
                          onBlur,
                          onChange: (val: number) => {
                            console.log("val", val);
                            if (!val) onChange(null);
                            else onChange(val.toString());
                          },
                        }}
                        error={fieldState?.error?.message}
                        touched={true}
                        label="Група"
                        tree={categoriesTree}
                      />

                      <div
                        className="plus-icon"
                        onClick={() => setShowAddGroup(true)}
                      >
                        <img className="group-plus-icon" src={GroupPlusSVG} />
                      </div>
                    </div>
                  )}
                />
              )}
            </div>

            <div className={"col-sm-12 col-md-6"}>
              {showAddReason ? (
                <AddTaxonomyField
                  label="Підстава"
                  value={newReason}
                  placeholder="Введіть значення"
                  onChange={setNewReason}
                  onPressOk={addReason}
                  onPressClose={() => setShowAddReason(false)}
                />
              ) : (
                <Controller
                  name="reasonId"
                  control={control}
                  render={({
                    field: { value, onChange, ref, onBlur },
                    fieldState,
                  }) => (
                    <div className="taxonomy-select-container">
                      <TreeSelectField
                        register={{
                          ref,
                          value: value ? Number(value) : value,
                          onBlur,
                          onChange: (val: number) => {
                            if (!val) onChange(null);
                            else onChange(val.toString());
                          },
                        }}
                        touched={fieldState.isTouched}
                        label="Підстава"
                        tree={reasonsTree}
                      />

                      <div
                        className="plus-icon"
                        onClick={() => setShowAddReason(true)}
                      >
                        <img className="group-plus-icon" src={GroupPlusSVG} />
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
          </div>

          <div style={rowStyle}>
            <div className="col-sm-12 col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                <Button
                  disabled={disableBtn}
                  color="primary"
                  type="submit"
                  className="submit-btn"
                >
                  Зберегти
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </form>
    </TabPane>
  );
};
