import { IChat } from "@/shared";
import { RootState } from "@/store";

export const selectChats = ({ chats }: RootState) => chats.chats;

export const selectSelectedChats = (state: RootState): IChat[] =>
  state.chats.selectedChats;

export const selectSelectedChatId = ({ chats }: RootState) =>
  chats.selectedChatId;

export const selectSelectedChat = ({ chats }: RootState) => {
  const selectedChat = chats.chats.find((it) => it.id === chats.selectedChatId);

  return selectedChat;
};

export const selectUnreadMessagesCount = (state: RootState): number =>
  state.chats.unreadMessagesCount;
