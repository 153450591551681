import {
  ChatMessageEventType,
  createFullName,
  hasImageUrl,
  IChatMember,
  IChatMessage as IMessageInChat,
  MessageType,
} from "@/shared";

import { IChatMessage } from "../plugins/interfaces";
import _ from "lodash";
import { isSystem } from "../helpers";
import moment from "moment";

const checkIsRead = (item: IMessageInChat, accountId: number) => {
  if (item.userId !== accountId) return true;
  if (_.isEmpty(item.events)) return false;

  const usersViewedIds = item.events
    .filter((it) => _.includes(Object.values(it), ChatMessageEventType.View))
    .map((it) => Object.keys(it)[0]);

  return _.some(usersViewedIds, (id) => Number(id) !== item.userId);
};

const findAuthor = (members: IChatMember[], authorId: number) => {
  return _.find(members, (member) => member?.userId === authorId);
};

const getUserName = (userId: number, members: IChatMember[]) => {
  const member = _.find(members, (member) => member.userId === userId);

  return createFullName(member?.user?.firstName, member?.user?.lastName);
};

const getDeleteMemberMessage = (
  item: IMessageInChat,
  members: IChatMember[]
) => {
  const name = getUserName(item.userId, members);

  if (item.userId !== item?.content?.deletedUserId) {
    const deletedUserName = getUserName(
      Number(item?.content?.deletedUserId),
      members
    );
    return `${name} видалив ${deletedUserName} з чату`;
  }

  return `${name} покидає чат`;
};

const getNewChatMessage = (item: IMessageInChat) => {
  return `Створено нова група ${moment(item?.content?.createDate).format(
    "DD-MM-YYYY"
  )}`;
};

const getMissedCallMessage = (item: IMessageInChat, members: IChatMember[]) => {
  const name = getUserName(item.userId, members);

  return `Пропущено виклик від ${name}`;
};

const getSuccessCallMessage = (
  item: IMessageInChat,
  members: IChatMember[]
) => {
  const name = getUserName(item.userId, members);

  return `Успішний виклик від ${name}`;
};

const getNewMembersMessage = (item: IMessageInChat, members: IChatMember[]) => {
  const name = getUserName(item.userId, members);
  const newMembersNames = item.content?.newUsersIds?.map((it) =>
    getUserName(it, members)
  );

  return `Користувач ${name} додав ${newMembersNames.join(", ")} у чат`;
};

const getTextMessageContent = (
  item: IMessageInChat,
  members: IChatMember[]
) => {
  const replyToMessage = getReplyToMessageInfo(
    item.content.replyToMessage,
    members
  );
  const message = item.content?.mentionsMessage
    ? item.content?.mentionsMessage
    : item.content?.message;

  return {
    message,
    replyToMessage,
  };
};

const getReplyToMessageInfo = (
  replyToMessage: Partial<IMessageInChat>,
  members: IChatMember[]
) => {
  if (!replyToMessage) return null;

  return {
    ...replyToMessage,
    userName: getUserName(replyToMessage.userId, members),
  };
};

const getContentByMessageType = {
  [MessageType.MissedCall]: getMissedCallMessage,
  [MessageType.SuccessCall]: getSuccessCallMessage,
  [MessageType.NewChatMembers]: getNewMembersMessage,
  [MessageType.DeleteChatMember]: getDeleteMemberMessage,
  [MessageType.NewChat]: getNewChatMessage,
};

const createContent = (item: IMessageInChat, members: IChatMember[]) => {
  if (!item) return null

  if (isSystem(item.type))
    return getContentByMessageType[item.type](item, members);

  if (item.type === MessageType.Text)
    return getTextMessageContent(item, members);

  if (item.type === MessageType.Forwarded)
    return createContent(item.content.originalMessage, members);

  const replyToMessage = getReplyToMessageInfo(
    item.content.replyToMessage,
    members
  );

  return {
    message: item.content?.message,
    fileUrl: item.content?.fileUrl,
    name: item.content?.name,
    size: item.content?.size,
    width: item.content?.width,
    height: item.content?.height,
    sticker: item.content?.sticker,
    replyToMessage,
  };
};

const getForwardedFrom = (item: IMessageInChat) => {
  if (!item.content || !item.content?.originalMessage) return null
  if (item.type !== MessageType.Forwarded) return null;

  const { user } = item.content?.originalMessage;
  return {
    id: user.id,
    name: createFullName(user?.firstName, user?.lastName),
  };
};

export const transformMessage = (
  item: IMessageInChat,
  members: IChatMember[],
  accountId: number
) => {
  const author = findAuthor(members, item.userId);
  const fullName = createFullName(
    author?.user?.firstName,
    author?.user?.lastName
  );

  const content = createContent(item, members);
  const type =
    item.type === MessageType.Forwarded
      ? item.content?.originalMessage?.type
      : item.type;
  const forwardedFrom = getForwardedFrom(item);

  return {
    id: item.id,
    text: item.content?.message,
    chatId: item.chatId,
    createdAt: item.createdAt,
    authorId: item.userId,
    author: {
      id: author?.userId,
      name: fullName,
      avatar:
        item.userId !== accountId &&
        hasImageUrl(author?.user?.avatarUrl, fullName),
    },
    type,
    sent: true,
    read: checkIsRead(item, accountId),
    isPined: item.isPined,
    forwardedFrom,
    content,
  };
};

export const transformMessages = (
  messages: IMessageInChat[],
  members: IChatMember[],
  accountId: number
): IChatMessage[] => {
  if (_.isEmpty(messages) || _.isEmpty(members)) return [];

  return messages?.map((it) => transformMessage(it, members, accountId));
};
