import { IShortUser } from "@/shared";
import { RootState } from "@/store";

export const selectSelectedUsers = (state: RootState): IShortUser[] =>
  state.users.selectedUsers;

export const getUsersFilter = (state: RootState) => state.users.usersFilter;

export const getContactsFilter = (state: RootState) =>
  state.users.contactsFilter;
