import { createReducer } from '@bitalikrty/redux-create-reducer'
import _ from 'lodash'
import { TErrorActions } from "./types"

export interface IErrorState {
	isError: boolean
	messages: any[]
}

const initialState: IErrorState = {
	isError: false,
	messages: [],
}

export const ErrorReducer = createReducer<IErrorState, TErrorActions>(
	initialState,
	{
		ERROR: (state, action) => {
			return {
				...state,
				isError: true,
				messages: [...state.messages, action.payload.message]
			}
		},
		CLEAR_ERROR: (state, action) => {
			return initialState
		}
	},
)
