import { InputCheckbox } from "@/shared";
import _ from "lodash";
import React from "react";
export const SELECT_COLUMN_KEY = "select-row";

interface SelectColumnProps {
  selected: Record<string, any>[];
  onSelect: (value: Record<string, any>[]) => void;
}

export const getSelectColumn = ({ selected, onSelect }: SelectColumnProps) => ({
  key: SELECT_COLUMN_KEY,
  name: "",
  width: 50,
  maxWidth: 50,
  resizable: false,
  sortable: false,
  frozen: true,
  formatter(props) {
    return (
      <InputCheckbox
        style={{
          height: "100%",
          alignItems: "center",
          display: "flex",
        }}
        size={20}
        value={_.some(selected, (it) => it.id === props.row.id)}
        onChange={(isChecked: boolean) => {
          if (isChecked) onSelect([...selected, { ...props.row }]);
          else {
            onSelect(selected.filter((it) => it.id !== props.row.id));
          }
        }}
      />
    );
  },
});
