import _ from "lodash";
import { IChatMessage } from "../plugins/interfaces";
import { create } from "zustand";
import { ChatMemberRole, MessageType, useEventsListener } from "@/shared";
import { ChatMessageActionEnum, ChatViewModeEnum } from "../enums";
import { useChatViewModeState } from "./use-chat-view-mode-state.hook";
import { appEvents } from "@/shared/events";
import { getCopiedContent } from "../helpers";
import { COPY_ENABLED_MESSAGES_TYPES } from "../consts";
import { getSelectedMessagesMenuOptions } from "../configs";
import React from "react";
import { MessageInstance } from "antd/lib/message";

interface IChatSelectedMessagesState {
  messages: Partial<IChatMessage>[];
  selectMessage: (message: Partial<IChatMessage>) => void;
  unselectAll: () => void;
}

export const useChatSelectedMessagesState = create<
  IChatSelectedMessagesState
>()((set) => ({
  messages: [],
  selectMessage(message: Partial<IChatMessage>) {
    set((state) => {
      if (_.find(state.messages, (it) => it.id === message.id))
        return {
          messages: state.messages.filter((it) => it.id !== message.id),
        };
      else
        return {
          messages: [
            ...state.messages,
            _.pick(message, [
              "id",
              "type",
              "content",
              "authorId",
              "chatId",
              "read",
              "isMy",
              "author",
              "isPined",
              "createdAt",
            ]),
          ],
        };
    });
  },
  unselectAll() {
    set({ messages: [] });
  },
}));

interface IProps {
  infoMessageApi: MessageInstance;
}

export const useChatSelectedMessages = ({ infoMessageApi }: IProps) => {
  const messages = useChatSelectedMessagesState((s) => s.messages);
  const { unselectAll } = useChatSelectedMessagesState();
  const { setMode } = useChatViewModeState();

  const actions = {
    [ChatMessageActionEnum.FORWARD]: forwardMany,
    [ChatMessageActionEnum.COPY]: copyMany,
    [ChatMessageActionEnum.DELETE]: deleteMany,
    [ChatMessageActionEnum.DELETE_FOR_ALL]: deleteForAllMany,
  };

  const afterAction = () => {
    unselectAll();
    setMode(ChatViewModeEnum.DEFAULT);
  };

  function forwardMany() {
    appEvents.emit("openForwardMessageModal", {
      message: messages as IChatMessage[],
      isShow: true,
    });

    afterAction();
  }

  function copyMany() {
    const copiedContent = getCopiedContent(messages);
    navigator.clipboard.writeText(copiedContent);

    infoMessageApi.open({
      type: "success",
      icon: <></>,
      className: "custom-info-message",
      content: "Повідомлення скопійовано",
      style: {
        marginTop: 60,
      },
    });
    afterAction();
  }

  function deleteMany() {
    deleteMessages();
  }

  function deleteForAllMany() {
    deleteMessages(true);
  }

  function deleteMessages(deleteForAll?: boolean) {
    setTimeout(
      () =>
        appEvents.emit("openConfirmDeleteMessageModal", {
          message: messages,
          deleteForAll,
          isShow: true,
          onSuccess: afterAction,
        }),
      300
    );
  }

  const openSelectedMessagesMenu = (userRoleInChat: ChatMemberRole) => {
    const canCopy = checkCanCopy();
    const canDeleteForAll = checkCanDeleteForAll();

    function checkCanCopy() {
      if (
        messages.length === 1 &&
        COPY_ENABLED_MESSAGES_TYPES.includes(messages[0].type)
      )
        return true;

      if (
        messages.length > 1 &&
        _.every(messages, (it) => it.type === MessageType.Text)
      )
        return true;

      return false;
    }

    function checkCanDeleteForAll() {
      if (userRoleInChat === ChatMemberRole.Admin) return true;

      return _.every(messages, (it) => {
        if (!it.read && it.isMy) return true;

        return false;
      });
    }

    const options = getSelectedMessagesMenuOptions({
      canDeleteForAll,
      canCopy,
      onClick: (actionType: ChatMessageActionEnum) =>
        actions[actionType](messages),
    });

    appEvents.emit("openSelectedMessagesMenuOptions", { items: options });
  };

  useEventsListener("onDeleteMember", afterAction, []);
  useEventsListener("onAddMember", afterAction, []);

  return {
    openSelectedMessagesMenu,
  };
};
