import "core-js/stable/atob";
import jwtDecode from "jwt-decode";

export function isTokenValid(token) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000) + 500;
    return decoded["exp"] > currentTime;
  } catch (error) {
    console.error("Invalid JWT token", error, token);
    return false;
  }
}
