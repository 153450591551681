import { ChatMemberRole, IChatMessage, MessageType } from "@/shared";
import { appEvents } from "@/shared/events";
import { chatMessagesApi } from "@/api";
import _ from "lodash";
import { useEffect, useState } from "react";

import copyIconSvg from "@/assets/img/copy-icon-1.svg";
import forwardIconSvg from "@/assets/img/forward-icon-1.svg";
import unPinIconSvg from "@/assets/img/unpin-icon.svg";

interface IProps {
  messages: IChatMessage[];
  role: ChatMemberRole;
  onPress: (id: number) => Promise<void>;
}

export const usePinedMessages = ({ messages, onPress }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if (_.isEmpty(messages)) setActiveIndex(0);
  }, [messages]);

  const onPressPined = async () => {
    await onPress(messages[activeIndex].id);

    if (messages.length === 1) return;
    let newIndex = 0;

    if (activeIndex !== messages.length - 1) newIndex = activeIndex + 1;
    console.log("newIndex: ", newIndex);
    setActiveIndex(newIndex);
  };

  const pinOptions = [
    {
      name: "copy",
      onPress: (message: IChatMessage) => {
        if (message.type === MessageType.Image) {
          navigator.clipboard.writeText(message.content.fileUrl);
        } else if (message.type === MessageType.Text) {
          navigator.clipboard.writeText(message.content.message);
        }
      },
      iconName: copyIconSvg
    },
    {
      name: "forward",
      onPress: (message: IChatMessage) =>
        appEvents.emit("openForwardMessageModal", { message, isShow: true }),
      iconName: forwardIconSvg
    },
    {
      name: "unPin",
      onPress: async () =>
        await chatMessagesApi.unpinMessageReq(messages[activeIndex].id),
      iconName: unPinIconSvg
    }
  ];

  return {
    currentPinedIndex: activeIndex,
    onPressPined,
    pinOptions
  };
};
