import { IChatMessage } from "@/shared";
import React, { FC } from "react";

import { PinnedMessageButton } from "../atoms";
import { IActionOptions } from "../plugins";

interface IProps {
  message: IChatMessage;
  options: IActionOptions[];
}

export const PinnedMessageActions: FC<IProps> = ({ message, options }) => {
  return (
    <>
      {options?.map((option) => (
        <PinnedMessageButton
          key={option.name}
          onPress={() => option.onPress(message)}
          iconName={option.iconName}
        />
      ))}
    </>
  );
};
