
import React, { Component, CSSProperties } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { LoginIcon } from '../atoms';



interface IProps {
  onChange?: any
  onBlur?: any
  value?: string
  error?: string
  register: UseFormRegisterReturn
}



export const LoginField = (props: IProps) => {
  return (
    <div className="form__form-group-auth">
      <span className="form__form-group-label">Логін</span>
      <div className="form__form-group-field-signIn">
        <div className="form__form-group-icon">
          <LoginIcon />
        </div>
        <input
          name="login"
          placeholder="Логін"
          type="text"
          onChange={props.onChange}
          onBlur={props.onBlur}
          {...props.register}
        />
      </div>
      {props?.error && <span className="form__form-group-error">{props.error}</span>}
    </div>
  )
}

