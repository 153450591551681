import React from "react";
import { useSelector } from "react-redux";
import { ButtonToolbar, Button } from "reactstrap";
import Modal from "../../../components/Modal";
import { isShowModal, isVisibleModal } from "@/store/account";
import { simpleDispatch } from "@/store/store-helpers";

interface IProps {
  submitChageAccount: any;
}
export const ModalValidationUserName = (props: IProps) => {
  const showModal = useSelector(isShowModal);

  return (
    <Modal
      toggle={() =>
        simpleDispatch(new isVisibleModal({ isVisibleModal: false }))
      }
      show={showModal}
      title={"Користувач з таким іменем існує"}
    >
      <h4 style={{ textAlign: "center", marginBottom: 30 }}>
        Бажаєте зберегти?
      </h4>
      <div style={{ padding: "0 10px" }}>
        <ButtonToolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px",
          }}
        >
          <Button
            onClick={props.submitChageAccount}
            style={{ width: 152 }}
            color="primary"
            type="submit"
          >
            Так
          </Button>
          <Button
            onClick={() => {
              simpleDispatch(new isVisibleModal({ isVisibleModal: false }));
            }}
            style={{ width: 152 }}
            color="primary"
            type="submit"
          >
            Ні
          </Button>
        </ButtonToolbar>
      </div>
    </Modal>
  );
};
