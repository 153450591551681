export enum ChatType {
  Group = "g",
  Personal = "p",
}

export enum ChatMemberRole {
  Admin = "a",
  Member = "m",
}

export enum MessageType {
  Text = "t",
  File = "f",
  Audio = "a",
  Video = "v",
  Image = "i",
  Forwarded = "fwd",
  MissedCall = "mc",
  SuccessCall = "sc",
  NewChatMembers = "ncm",
  DeleteChatMember = "dcm",
  NewChat = "nch",
  Date = "date",
  Sticker = "stk",
}

export enum ChatMessageEventType {
  View = "view",
}
