import { FC, useEffect, useMemo, useRef, useState } from "react";
import { ETaxonomyType, Option, usePaginationList } from "@/shared";
import React from "react";
import { Select, Spin } from "antd";
import { taxonomiesApi } from "@/api";
import _ from "lodash";
import "./style.scss";

export interface ITaxonomySelectWithSearchProps {
  onChange: (val: string) => void;
  value: string | string[];
  type: ETaxonomyType;
  defaultOptions?: Option[];
  defaultCount?: number;
  label?: string;
  mode?: "multiple" | "tags";
  input?: any;
}

const loadMoreOption: Option = {
  title: "Загрузити ще",
  value: "load-more-option",
};

const limit = 20;

export const TaxonomySelectWithSearch: FC<ITaxonomySelectWithSearchProps> = ({
  onChange,
  value,
  defaultOptions,
  defaultCount,
  label,
  mode,
  type,
  input,
}) => {
  const [searchString, setSearchString] = useState("");
  const timerRef = useRef(null);
  const [additionalOptions, setAdditionalOptions] = useState([]);

  const taxonomyPaginationList = usePaginationList<Option>({
    fetchItems: taxonomiesApi.fetchTaxonomiesByType,
    serrializatorItems: (items) =>
      items.map((it) => ({
        title: it.name,
        value: it.id.toString(),
      })),
    loadParams: {
      limit: !_.isEmpty(defaultOptions) ? defaultOptions.length : limit,
      count: defaultCount,
      sortField: "name",
      sort: "ASC",
      searchString,
      type,
    },
    needInit: false,
    clearBeforeFetch: false,
  });

  const loadAdditionalTaxonomy = async (ids: number[]) => {
    const { data } = await taxonomiesApi.fetchTaxonomiesByType({
      limit: ids.length,
      type,
      includeIds: ids,
    });

    if (_.isEmpty(data.items)) return;

    const options = data.items.map((option) => ({
      title: option.name,
      value: option.id.toString(),
    }));

    setAdditionalOptions(options);
  };

  useEffect(() => {
    if (searchString === taxonomyPaginationList.loadParams["searchString"])
      return;

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      taxonomyPaginationList.setLoadParams({
        searchString,
        limit: !_.isEmpty(defaultOptions) ? defaultOptions.length : limit,
      });

      clearTimeout(timerRef.current);
    }, 500);

    return () => clearTimeout(timerRef.current);
  }, [searchString]);

  useEffect(() => {
    if (defaultOptions) taxonomyPaginationList._setItems(defaultOptions);
    else taxonomyPaginationList.resetFlatList();
  }, [defaultOptions]);

  useEffect(() => {
    const val = value || input?.value;

    const idsToLoad = [];

    if (_.isArray(val)) {
      if (
        _.isEmpty(val) ||
        (_.isEmpty(taxonomyPaginationList.items) && _.isEmpty(defaultOptions))
      )
        return;

      const idsToLoadString = val.filter(
        (it) =>
          !_.find(defaultOptions, (opt) => opt.value === it.toString()) &&
          !_.find(
            taxonomyPaginationList?.items,
            (item) => item.value === it.toString()
          )
      );
      idsToLoadString.map((it) => idsToLoad.push(Number(it)));
    } else {
      if (
        !val ||
        _.isEmpty(taxonomyPaginationList.items) ||
        (!_.isEmpty(defaultOptions) &&
          _.find(defaultOptions, (it) => it.value === val.toString())) ||
        _.find(
          taxonomyPaginationList.items,
          (it) => it.value === val.toString()
        )
      )
        return;

      idsToLoad.push(Number(val));
    }

    if (_.isEmpty(idsToLoad)) return;
    loadAdditionalTaxonomy(idsToLoad);
  }, [value, input, defaultOptions, taxonomyPaginationList.items]);

  const additionalTaxonomyOptionItems = useMemo(() => {
    const optionsToRender = [];
    if (_.isEmpty(searchString) || !_.isEmpty(additionalOptions))
      additionalOptions.map((option) => {
        if (
          !_.find(
            taxonomyPaginationList.items,
            (it) => it.value === option.value
          )
        )
          optionsToRender.push(option);
      });

    return optionsToRender;
  }, [searchString, additionalOptions, taxonomyPaginationList.items]);

  const loadMoreOptionItem = useMemo(() => {
    const additionalCount = _.isEmpty(additionalTaxonomyOptionItems)
      ? additionalTaxonomyOptionItems.length
      : 0;

    if (
      taxonomyPaginationList.loadParams.count <=
      taxonomyPaginationList.items.length + additionalCount
    )
      return null;

    return (
      <Select.Option
        key={loadMoreOption.value}
        value={loadMoreOption.value}
        disabled={true}
      >
        <div
          onClick={taxonomyPaginationList.loadMore}
          style={{ cursor: "default", color: "#000", fontWeight: "bold" }}
        >
          {loadMoreOption.title}
        </div>
      </Select.Option>
    );
  }, [
    taxonomyPaginationList.loadParams.count,
    taxonomyPaginationList.items,
    additionalTaxonomyOptionItems,
  ]);

  return (
    <div className="form__form-group select-field select-field_custom">
      {label ? <span className="label">{label}</span> : null}

      <Select
        value={value || input?.value}
        showSearch
        className="select"
        optionFilterProp="children"
        allowClear
        mode={mode}
        showArrow={true}
        onSearch={(val: string) => setSearchString(val)}
        onChange={(val: string) => {
          setSearchString("");
          if (input?.onChange) input.onChange(val);
          else onChange(val);
        }}
        notFoundContent={
          taxonomyPaginationList.isLoading ? (
            <Spin size="small" />
          ) : (
            "не знайдено"
          )
        }
      >
        {taxonomyPaginationList.items.map((item, index) => (
          <Select.Option
            key={item.value + "-" + index}
            value={item.value}
            disabled={false}
          >
            {item.title}
          </Select.Option>
        ))}

        {additionalTaxonomyOptionItems.map((additionalOption) => {
          return (
            <Select.Option
              key={additionalOption.value}
              value={additionalOption.value}
              disabled={false}
            >
              {additionalOption.title}
            </Select.Option>
          );
        })}

        {loadMoreOptionItem}
      </Select>

      {/* {touched && error && <span className="error">{error}</span>} */}
    </div>
  );
};
