import { getMessagePreviewText, IconComponent } from "@/shared";
import React, { FC, useMemo } from "react";
import { IChatMessage } from "./interfaces";
import { MessageMediaPreview } from "./message-media-preview.component";
import x1Icon from "@/assets/img/x-1-icon.svg";

interface IProps {
  message: IChatMessage;
  name: string;
  onPressClose: () => void;
}
export const ReplyBarSection: FC<IProps> = ({
  message,
  onPressClose,
  name,
}) => {
  if (!message || !name) return null;

  const text = useMemo(() => getMessagePreviewText(message), [message]);

  return (
    <div className="reply-bar-container">
      <div className="reply-main-section">
        <div className="reply-media-preview">
          <MessageMediaPreview
            type={message?.type}
            uri={message?.content?.fileUrl}
          />
        </div>
        <div className="reply-text-section">
          <span>{`Відповісти ${name}`}</span>
          <span className="collapsed">{text}</span>
        </div>
      </div>

      <div className="reply-close-section" onClick={onPressClose}>
        <IconComponent name={x1Icon} />
      </div>
    </div>
  );
};
