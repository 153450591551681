import { configsApi } from "@/api";
import { transformFilesLimitsConfig } from "@/shared";
import { SetFilesConfig } from "@/store/shared";
import { simpleDispatch } from "@/store/store-helpers";

const loadFilesLimitsConfig = async () => {
  try {
    const { data } = await configsApi.fetchFilesLimitsConfig();
    if (data) {
      const config = transformFilesLimitsConfig(data.config);
      simpleDispatch(new SetFilesConfig({ config }));
    }
  } catch (e) {
    console.log("Error on load files limits config", e);
  }
};

export const configsService = {
  loadFilesLimitsConfig
};
