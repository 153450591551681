import React from "react";
import { CardBody } from "reactstrap";
import {
  AddUsersModal,
  UsersTableHeader,
  SecretUsersTable,
} from "../components";

export const SecretUsersWidget = () => {
  return (
    <div>
      <CardBody>
        <UsersTableHeader />
        <SecretUsersTable />
      </CardBody>
      <AddUsersModal />
    </div>
  );
};
