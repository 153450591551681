import React, { FC } from "react";
import CheckMarkSvgIcon from "../../../assets/img/check-mark.svg";
import "./styles.scss";

interface InputCheckboxProps {
  value: boolean;
  onChange: (val: boolean) => void;
  tabIndex?: number;
  size?: number;
  style?: React.CSSProperties;
  children?: string | JSX.Element;
}

export const InputCheckbox: FC<InputCheckboxProps> = ({
  value,
  onChange,
  style,
  size = 15,
  tabIndex,
  children,
}) => {
  return (
    <div
      tabIndex={tabIndex}
      className="input-checkbox"
      style={style}
      onClick={() => onChange(!value)}
    >
      <>
        <input
          type="checkbox"
          checked={value}
          style={{ width: size, height: size }}
          onChange={() => onChange(!value)}
          value={undefined}
        />

        {value && (
          <img
            src={CheckMarkSvgIcon}
            className="check-mark"
            style={{ width: size - size * (10 / 100) }}
          />
        )}
      </>

      <div>{children}</div>
    </div>
  );
};
