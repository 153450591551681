import React from "react";
import Modal from "../../../components/Modal";
import {
  secretUsersListSelectIds,
  useAddUsersModalState,
  useSecretUsersList,
} from "../states";
import { UserSelectWithSearch } from "@/components/SmartComponents";
import { EUsersListType, Loader } from "@/shared";
import { Button } from "reactstrap";
import { useSecretUsersEdit } from "../hooks";

export const AddUsersModal = () => {
  const { close, usersIdsSelected, select, isOpen } = useAddUsersModalState();
  const { isLoading, add } = useSecretUsersEdit();

  const hiddenUsersIds = useSecretUsersList((s) =>
    secretUsersListSelectIds(s.users)
  );

  const save = () => {
    add(usersIdsSelected.map(Number));
    close();
  };

  return (
    <Modal show={isOpen} toggle={close} title="Додати користувачів">
      <div>
        <UserSelectWithSearch
          label="Оберіть користувачів"
          type={EUsersListType.All}
          value={usersIdsSelected}
          onChange={select}
          mode="multiple"
          showCurrentUserOnTop={false}
          excludeIds={hiddenUsersIds}
        />

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Button color="primary" size="sm" onClick={save}>
              Зберегти
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
