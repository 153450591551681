import _ from "lodash";

export const config = {
  // apiUrl: "http://localhost:3000/",
  // apiUrl: "http://185.69.154.136:5000/admin/",
  // apiUrl: "http://185.69.154.136:5000/",
  // socketUrl: "http://localhost:3000/",

  apiUrl: "https://taskme-api.work-jetup.site/",
  socketUrl: "https://taskme-api.work-jetup.site",
  pdfViewer: "https://taskme-pdf-viewer.work-jetup.site",
  oneSignalId: "8b9066f5-8c3f-49f7-bef4-c5ab621f9d27",
  oneSignalSafaryId: "",
  appVersion: process.env.REACT_APP_VERSION || "old",
};

const mod = _.defaultTo(process.env.REACT_APP_MOD, "local");

if (mod === "production") {
  config.apiUrl = "https://tasks-api.rwsbank.com.ua/";
  config.socketUrl = "https://tasks-api.rwsbank.com.ua";
  config.oneSignalId = "5e1a5e18-33e5-4ed3-8423-45b1abc354c6";
  config.oneSignalSafaryId =
    "web.onesignal.auto.66c89079-ab76-4c24-84be-2fca07f56f6c";
}

export default config;
