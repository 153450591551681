import { IUser } from "@/shared";
import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";

const hideUser = (data: Req.IHideUsersPayload): ApiResponse<void> => {
  return http.post("admin/secret-mod-users/hide", data);
};

const revealUser = (data: Req.IRevealUsersPayload): ApiResponse<void> => {
  return http.post("admin/secret-mod-users/reveal", data);
};

const getUsers = (params: Req.IGetUsersListParams) => {
  return http.get<{
    items: IUser[];
    count: number;
  }>("admin/secret-mod-users", { params });
};

const getStatus = () => {
  return http.get<boolean>("admin/secret-mod/is-active");
};

const changeStatus = (value: boolean) => {
  return http.post("admin/secret-mod/turn", { value });
};

export const secretModApi = {
  hideUser,
  revealUser,
  getUsers,
  getStatus,
  changeStatus,
};
