import React, { useCallback, useMemo, useState } from "react";
import { Radio } from "antd";
import { Button as Btn } from "reactstrap";
import "./style.scss";
import Modal from "../../../../components/Modal";
import { IPListType, ConfirmModal, dropdownMenuConfig } from "@/shared";
import * as _ from "lodash";
import { Table } from "@/components/TableGrid/Table";
import { AddIpForm } from "../Form";
import { ipsService } from "@/services/domain/ips.service";
import {
  columnsConfig,
  defaultActiveColumns,
  getSelectedIPsMenuConfig,
} from "../../configs";
import { getSelectColumn } from "@/components/TableGrid/configs";
import { useIPs } from "../../hooks";
import iconNotePencil from "@/assets/img/notepencil-light-icon.svg";
import { CustomTableRow } from "@/components/TableGrid/components";
import { IpsSelectedTableHeader } from "./ips-selected-table-header.component";

export const IpsTable = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [active, setActive] = useState(false);
  const [focusFilterKey, setFocusFilterKey] = useState<string>("");

  const {
    paginationList,
    selectedIPs,
    setSelectedIPs,
    confirmModal,
    listType,
    setListType,
    actions,
    allIds,
  } = useIPs();

  const styles = {
    radioBtn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
      height: 46,
      fontSize: 16,
      color: "#7F7F7F",
      borderColor: "#7F7F7F",
      width: 156,
    },
    checkedRadio: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
      height: 46,
      fontSize: 16,
      color: "#9E2743",
      borderColor: "#9E2743",
      width: 156,
    },
  };

  const onAddIp = async (value: any) => {
    await ipsService.addIp({
      ip: value.ip,
      listType,
    });
    paginationList.resetFlatList();
    setShowModal(false);
  };

  const onIPsAction = (
    key: "block" | "unblock" | "add_to_white_list" | "delete"
  ) => {
    const actionByKey = {
      block: () => actions.block(),
      unblock: () => actions.unblock(),
      add_to_white_list: () => actions.addToWhiteList(),
      delete: () => actions.delete(),
    };

    actionByKey[key]();
  };

  const selectColumn = getSelectColumn({
    selected: selectedIPs,
    onSelect: setSelectedIPs,
  });

  const selectedItemsMenuConfig = useMemo(
    () =>
      getSelectedIPsMenuConfig({
        onClick: onIPsAction,
        selected: selectedIPs,
      }),
    [selectedIPs]
  );

  const contextMenuConfig = useMemo(
    () => [
      {
        onClick: () => setShowModal(true),
        key: "create",
        label: "Створити",
        icon: {
          scr: iconNotePencil,
          name: "note pencil",
          className: "note-pencil-icon",
        },
      },
      ...selectedItemsMenuConfig[listType],
    ],
    [selectedItemsMenuConfig]
  );

  const onOpenContextMenu = (isOpen: boolean, IPId: number) => {
    if (isOpen && !_.some(selectedIPs, (it) => it.id === IPId))
      setSelectedIPs([paginationList.items.find((it) => it.id === IPId)]);
  };

  const selectedIPDelete = useCallback(() => {
    const temp = selectedIPs.map((el: any) => el?.ip);
    return temp.length > 30
      ? temp.slice(0, 30).join(", ") + " ..."
      : temp.join(", ");
  }, [selectedIPs, paginationList.items]);

  const selectAllIps = () => {
    setSelectedIPs(paginationList.items);
  };

  const unSelectAllIps = () => {
    setSelectedIPs([]);
  };

  const configDropdownSelected = useMemo(() => {
    return dropdownMenuConfig({
      onSelectAll: selectAllIps,
      onUnSelectAll: unSelectAllIps,
      all: allIds?.length === selectedIPs.length,
    });
  }, [paginationList, selectedIPs]);

  const handleSelectAllColumnPress = () => {
    if (selectedIPs.length === allIds?.length) unSelectAllIps();
    else selectAllIps();
  };

  return (
    <React.Fragment>
      <Modal show={showModal} toggle={setShowModal} title={"Добавити IP"}>
        <AddIpForm onAddIp={onAddIp} listType={listType} />
      </Modal>

      <ConfirmModal
        isShow={confirmModal.state.isOpen}
        message={`${confirmModal.state.message} - ${selectedIPDelete()}?`}
        onConfirm={confirmModal.state.onConfirm}
        setShow={(state: boolean) =>
          confirmModal.set({ ...confirmModal.state, isOpen: state })
        }
      />

      <div
        className="col-md-12 flex"
        style={{ justifyContent: "space-between" }}
      >
        <IpsSelectedTableHeader
          selectedRows={selectedIPs.map((it) => it.id)}
          selectedItemsMenuConfig={selectedItemsMenuConfig[listType]}
          configDropdownSelected={configDropdownSelected}
        />

        <div
          style={{
            marginBottom: 50,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Radio.Group
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              textAlign: "center",
            }}
            value={listType}
            onChange={(e) => {
              setListType(e.target.value);
              setSelectedIPs([]);
              setActive(!active);
            }}
          >
            <div>
              <Radio.Button
                style={!active ? styles.radioBtn : styles.checkedRadio}
                value={IPListType.White}
              >
                Білий список
              </Radio.Button>
            </div>
            <div>
              <Radio.Button
                style={active ? styles.radioBtn : styles.checkedRadio}
                value={IPListType.Black}
              >
                Чорний список
              </Radio.Button>
            </div>
          </Radio.Group>
          <Btn
            style={{ marginLeft: 20, height: 46 }}
            color="primary"
            size="sm"
            onClick={() => setShowModal(true)}
          >
            Створити
          </Btn>
        </div>
      </div>

      <div>
        <div>
          <Table
            columns={[selectColumn, ...columnsConfig]}
            tableName={"ips"}
            paginationList={paginationList}
            activeColumns={defaultActiveColumns}
            selectedRows={selectedIPs.map((it) => it.id)}
            // setSelectedRows={setSelectedIPsIds}
            focusedFilterField={focusFilterKey}
            onFocusFilterField={(fieldKey: string) =>
              setFocusFilterKey(fieldKey)
            }
            tableProps={{
              rowRenderer: (data: any) => (
                <CustomTableRow
                  rowData={data}
                  menuConfig={contextMenuConfig}
                  onOpenMenu={onOpenContextMenu}
                />
              ),
            }}
            allSelected={
              !_.isEmpty(selectedIPs) && selectedIPs.length === allIds?.length
            }
            selectAll={handleSelectAllColumnPress}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
