import { StorageKey } from "@/shared";
import { SetChatBg } from "@/store/chat-bg";
import { simpleDispatch } from "@/store/store-helpers";

const selectChatBg = async (bgId: any) => {
  try {
    simpleDispatch(new SetChatBg({ bgId }));
    await localStorage.setItem(StorageKey.ChatBgId, bgId);
  } catch (error) {
    console.log("SELECT CHAT BG ERROR: ", error);
  }
};

export const chatBgService = {
  selectChatBg,
};
