import React from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.css";
import "./scss/app.scss";
import { BrowserRouter } from "react-router-dom";
import store from "@/store";
import { authService } from "@/services/domain";
import { AppRouter } from "@/containers/App/router";

import { ConfigProvider } from "antd";
import "moment/locale/uk";
import locale from "antd/lib/locale/uk_UA";
import { appService } from "./services/system/app.service";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn:
    "https://9567500c736db7c1e6f5cc0735b76a5a@o402114.ingest.us.sentry.io/4507232702627840",
  environment: process.env.SENTRY_ENVIROMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  React.useEffect(() => {
    appService.initApp();
    authService.autoAuth();
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default hot(module)(App);
