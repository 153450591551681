import React, { FC } from "react";

import ModalComponent from "@/components/Modal";

import { CreateChatForm } from "./create-chat-form.component";
import { useCreateChat } from "../hooks";
import { simpleDispatch } from "@/store/store-helpers";
import { SelectAll } from "@/store/users";

interface IProps {
  isShow: boolean;
  setOpen: (val: boolean) => void;
  excludeIds: number[];
}

export const CreateChatModal: FC<IProps> = ({
  isShow,
  setOpen,
  excludeIds,
}) => {
  const onChatCreated = () => setOpen(false);

  const {
    name,
    setName,
    setImg,
    onSubmit,
    nameError,
    setError,
    membersError,
    setMembersError,
  } = useCreateChat({ onChatCreated });

  const onToggle = () => {
    setError(null);
    setMembersError(null);
    setOpen(false);
    simpleDispatch(new SelectAll({ items: [] }));
  };

  return (
    <ModalComponent title="Створити бесіду" show={isShow} toggle={onToggle}>
      <CreateChatForm
        name={name}
        setName={setName}
        setImg={setImg}
        onSubmit={onSubmit}
        nameError={nameError}
        membersError={membersError}
        excludeIds={excludeIds}
      />
    </ModalComponent>
  );
};
