import { PermissionsPage } from "@/containers/Permissions";
import { getParentsTree } from "@/shared";
import React, { FC } from "react";
import { ButtonToolbar, Button } from "reactstrap";
import { useEditUserPermissions } from "../../hooks";

interface IPermissionsFormProps {
  userId: number;
  onSuccessUpdate: () => void;
  onError: () => void;
}

export const ProfilePermissionsForm: FC<IPermissionsFormProps> = ({
  userId,
  onSuccessUpdate,
  onError,
}) => {
  const { form, factories, submit } = useEditUserPermissions({
    userId: userId,
    onSuccess: onSuccessUpdate,
    onError: onError,
  });
  const parentCategoryTree = getParentsTree(factories, null);

  return (
    <form
      className="form"
      style={{ flexDirection: "column" }}
      onSubmit={submit}
    >
      <PermissionsPage form={form} factoriesTree={parentCategoryTree} />
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit" className="contact__btn">
          Зберегти
        </Button>
      </ButtonToolbar>
    </form>
  );
};
