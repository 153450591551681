import { secretModApi } from "@/api/secret-mod/requests";
import { useEffect, useState } from "react";

export const useSecretMod = () => {
  const [isLoading, setLoading] = useState(false);
  const [isActive, setActive] = useState(false);

  const load = async () => {
    try {
      setLoading(true);

      const { data } = await secretModApi.getStatus();
      setActive(data);
    } finally {
      setLoading(false);
    }
  };

  const turn = async () => {
    try {
      setLoading(true);

      await secretModApi.changeStatus(!isActive);
    } catch (e) {
    } finally {
      load();
    }
  };

  useEffect(() => {
    load();
  }, []);

  return {
    isActive,
    isLoading,
    turn,
  };
};
