import React from "react";
// import { SelectColumn } from "@/components/TableGrid/configs";
import ClipSVGIcon from "../../../assets/img/clip.svg";
import StarFilled from "../../../assets/img/starFilled.svg";
import moment from "moment";
import { voidColumn } from "@/components/TableGrid/configs/void-row.config";
import { createFullName, ITask, notification } from "@/shared";
import { CommentIcon, StarIcon } from "@/shared/components/icons";
import { tasksService } from "@/services/domain";
import _ from "lodash";
import { Popover } from "antd";
import "../components/style.scss";

interface IProps {
  onPressName: (task: ITask, e: any) => void;
}

export const getTasksColumnsConfig: any = ({ onPressName }: IProps) => {
  const columnConfig = [
    voidColumn,
    {
      name: "Обрані",
      key: "isFavorite",
      width: 55,
      maxWidth: 55,
      filter: true,
      sortable: false,
      resizable: false,
      formatter: ({ row }: { row: ITask }) => {
        if (_.isEmpty(row)) return null;
        if (row.isFavorite)
          return (
            <Popover
              content={row?.description}
              mouseEnterDelay={1.5}
              placement="bottom"
            >
              <img
                alt="Gold star"
                onClick={async () => {
                  try {
                    await tasksService.removeTasksFromFavorite({
                      taskIds: [row.id],
                    });
                  } catch (e) {
                    notification.showError(
                      "Помилка",
                      "Не вдалось видалити з обраних"
                    );
                  }
                }}
                src={StarFilled}
                style={{ width: 30, height: 30 }}
              />
            </Popover>
          );
        return (
          <Popover
            content={row?.description}
            mouseEnterDelay={1.5}
            placement="bottom"
          >
            <div>
              <StarIcon
                onClick={async () => {
                  try {
                    await tasksService.addTasksToFavorite({
                      taskIds: [row.id],
                    });
                  } catch (e) {
                    notification.showError(
                      "Помилка",
                      "Не вдалось додати до обраних"
                    );
                  }
                }}
              />
            </div>
          </Popover>
        );
      },
    },
    {
      name: "№",
      key: "id",
      minWidth: 40,
      // maxWidth: 65,
      filter: true,
      filterType: "search",
      sortable: true,
      resizable: true,
      formatter: ({ row }: { row: ITask }) => {
        return (
          <Popover
            content={row?.description}
            mouseEnterDelay={1.5}
            placement="bottom"
          >
            <div
              onClick={(e) => onPressName(row, e)}
              style={{ width: "100%", height: "100%" }}
            >
              <div className="ellipsis">{row.id}</div>
            </div>
          </Popover>
        );
      },
    },
    {
      name: "Назва",
      key: "name",
      // width: 100,
      // minWidth: 100,
      filter: true,
      filterType: "search",
      sortable: true,
      resizable: true,
      formatter: ({ row }: { row: ITask }) => {
        return (
          <Popover
            content={row?.description}
            mouseEnterDelay={1.5}
            placement="bottom"
          >
            <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
              {row.name}
            </div>
          </Popover>
        );
      },
    },
    {
      name: "Початок",
      key: "startDate",
      // width: 150,
      minWidth: 141,
      filter: true,
      filterType: "datePicker",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
        >
          <div
            className="ellipsis"
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => onPressName(row, e)}
          >
            {row?.startDate ? moment(row.startDate).format("DD-MM-YY") : ""}
          </div>
        </Popover>
      ),
    },
    {
      name: "Кiнець",
      key: "endDate",
      minWidth: 141,
      // width: 150,
      filter: true,
      filterType: "datePicker",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
        >
          <div
            className="ellipsis"
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => onPressName(row, e)}
          >
            {row?.endDate ? moment(row.endDate).format("DD-MM-YY") : ""}
          </div>
        </Popover>
      ),
    },
    {
      name: "Дата виконання",
      key: "doneDate",
      minWidth: 141,
      // width: 150,
      filter: true,
      filterType: "datePicker",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
        >
          <div
            className="ellipsis"
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => onPressName(row, e)}
          >
            {row?.doneDate ? moment(row.doneDate).format("DD-MM-YY") : ""}
          </div>
        </Popover>
      ),
    },
    {
      name: "Хто створив",
      key: "author",
      minWidth: 140,
      // width: 100,
      filter: true,
      filterType: "search",
      sortable: true,
      sortKey: "author",
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
        >
          <div
            className="ellipsis"
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => onPressName(row, e)}
          >
            {createFullName(
              row.author?.firstName,
              row.author?.middleName,
              row.author?.lastName
            )}
          </div>
        </Popover>
      ),
    },
    {
      name: "Виконавець",
      key: "executor",
      minWidth: 80,
      // width: 100,
      filter: true,
      filterType: "search",
      sortable: true,
      sortKey: "executor",
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
        >
          <div
            className="ellipsis"
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => onPressName(row, e)}
          >
            {createFullName(
              row.executor?.firstName,
              row.executor?.middleName,
              row.executor?.lastName
            )}
          </div>
        </Popover>
      ),
    },
    {
      name: "Ініціатор",
      key: "initiator",
      minWidth: 80,
      // width: 100,
      // maxWidth: "100%",
      filter: true,
      filterType: "search",
      sortable: true,
      sortKey: "initiator",
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
        >
          <div
            className="ellipsis"
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => onPressName(row, e)}
          >
            {createFullName(
              row.initiator?.firstName,
              row.initiator?.middleName,
              row.initiator?.lastName
            )}
          </div>
        </Popover>
      ),
    },
    {
      name: "Підстава",
      key: "reason",
      minWidth: 80,
      // width: 100,
      // width: 150,
      filter: true,
      filterType: "search",
      sortable: true,
      sortKey: "reason",
      resizable: true,
      formatter: ({ row }) => (
        <Popover
          content={row?.description}
          mouseEnterDelay={1.5}
          placement="bottom"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="ellipsis" style={{ width: "100%", height: "100%" }}>
            {row.reason ? `${row.reason?.name}` : ""}
          </div>
        </Popover>
      ),
    },
    {
      name: "Файли",
      key: "additions",
      filter: true,
      filterType: "files_comments",
      sortable: false,
      // width: 80,
      minWidth: 120,
      // maxWidth: 130,
      resizable: true,
      formatter: ({ row }: { row: ITask }) => {
        return (
          <Popover
            content={row?.description}
            mouseEnterDelay={1.5}
            placement="bottom"
          >
            <div className="ellipsis">
              {row.hasDocuments && (
                <span style={{ position: "relative" }}>
                  <img src={ClipSVGIcon} style={{ width: 20, height: 20 }} />
                  {row.hasUnreadDocuments && (
                    <span className="unread-indicator"></span>
                  )}
                </span>
              )}

              {row.hasComments && row.hasDocuments && <span>&nbsp;</span>}
              {row.hasComments && (
                <span style={{ position: "relative" }}>
                  <CommentIcon width={22} height={22} color="#3b3b3b" />
                  {row.hasUnreadComments && (
                    <span className="unread-indicator comment"></span>
                  )}
                </span>
              )}
            </div>
          </Popover>
        );
      },
    },
  ];
  return columnConfig;
};

export const initialActiveColumns = [
  "select-row",
  "id",
  "name",
  "startDate",
  "endDate",
  "initiator",
];
