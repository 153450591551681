import { TFactoriesActions } from "./types";
import { createReducer } from "@bitalikrty/redux-create-reducer";

export interface IFactoriesState {
  isLoading: boolean;
}

const initialState: IFactoriesState = {
  isLoading: false,
};

export const factoriesReducer = createReducer<
  IFactoriesState,
  TFactoriesActions
>(initialState, {
  IS_LOADING_FACTORIES: (state, action) => {
    return { ...state, isLoading: action.payload.isLoading };
  },
});
