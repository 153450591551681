export const errorsKey = {
  email: {
    key: "email_exist",
    exist: null,
    description: "Така пошта вже існує",
  },
  phone_number: {
    key: "user_already_exists",
    exist: null,
    description: "Такий телефон вже існує",
  },
  personalPhone_number: {
    key: "personal_phone_number_exist",
    exist: null,
    description: "Такий телефон вже існує",
  },

  login_exist: {
    key: "login_exist",
    exist: null,
    description: "Такий логін вже існує",
  },

  default_err: false,
};
