import { create } from "zustand";

interface State {
  value: string;
  onChange: (value: string) => void;
}

export const useSearchState = create<State>()((set) => ({
  value: null,
  onChange: (value) => set({ value }),
}));
