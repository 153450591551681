export enum ETaskStatus {
  Active = "active",
  Finished = "finished",
  Deleted = "deleted"
}

export enum ETaskAdditions {
  Comments = "comments",
  Documents = "docs",
  All = "all"
}

export enum TaskEvent {
  Read = "read"
}
