import { config } from "@/config";
import { socketEvents } from "@/shared/events";

import io from "socket.io-client";
import { authService } from "../domain";
import { GlobalContainerService } from "./global-container.service";
import { isTokenValid } from "./jwt.service";
import { UserFingerprintGenerator } from "@/shared";

const store = () => GlobalContainerService.get("store");

export class SocketIo {
  private socket: any;

  init() {
    try {
      const getTokenFn = this.getToken.bind(this);

      this.socket = io(config.socketUrl, {
        transports: ["websocket", "polling"],
        reconnection: true,
        secure: true,
        async auth(cb) {
          const accessToken = await getTokenFn();
          const deviceUuid = await UserFingerprintGenerator.generateUserFingerprint();
          cb({ accessToken, deviceUuid });
        },
      });
      this._on("connect", () => {
        // this.emit("join-user", store().getState().account.account);
        this.initSockets();
      });
    } catch (e) {
      console.log("ERROR");
    }
  }

  async getToken() {
    const accessToken = store()?.getState()?.auth?.accessToken;
    if (isTokenValid(accessToken)) return accessToken;

    await authService.refreshSession();

    return store()?.getState()?.auth?.accessToken;
  }

  get header() {
    return {
      authorization: "Bearer " + store()?.getState()?.auth?.accessToken,
    };
  }

  _on(key, action) {
    this.socket.on(key, (data) => action(data));
  }

  _onSocketSendEvent(key) {
    this.socket.on(key, (data) => {
      socketEvents.emit(key, data);
    });
  }

  _off(key) {
    this.socket.off(key);
  }

  _disconnect() {
    this.socket.disconnect();
  }

  emit(key, data: any = {}) {
    data.headers = this?.header;
    this.socket.emit(key, data);
    console.log("EMIT EVENT", key);
  }

  initSockets() {
    this._onSocketSendEvent("notification");
    this._onSocketSendEvent("chat/new-chat");
    this._onSocketSendEvent("chat/new-message");
    this._onSocketSendEvent("chat/delete-message");
    this._onSocketSendEvent("chat/clear-chat");
    this._onSocketSendEvent("chat/delete-chat");
    this._onSocketSendEvent("chat/new-members");
    this._onSocketSendEvent("chat/delete-member");
    this._onSocketSendEvent("chat/change-role");
    this._onSocketSendEvent("chat/edit-chat");
    this._onSocketSendEvent("chat/pined-message");
    this._onSocketSendEvent("chat/is-read");
    this._onSocketSendEvent("chat/pin");
    this._onSocketSendEvent("chat/unpin");
    this._onSocketSendEvent("chat/unread");

    this._onSocketSendEvent("task/new-comment");
    this._onSocketSendEvent("task/new-task");
    this._onSocketSendEvent("task/update-task");
    this._onSocketSendEvent("task/finish-task");
    this._onSocketSendEvent("task/delete-task");
    this._onSocketSendEvent("task/hard-delete-task");
    this._onSocketSendEvent("task/new-docs");
    this._onSocketSendEvent("task/read");
    this._onSocketSendEvent("task/read-docs");
    this._onSocketSendEvent("task/read-comments");
    this._onSocketSendEvent("task/update-comment");
    this._onSocketSendEvent("task/delete-comment");

    this._onSocketSendEvent("push-notification");

    this._onSocketSendEvent("user/connected");
    this._onSocketSendEvent("user/disconnected");
    this._onSocketSendEvent("user/deleted");
    this._onSocketSendEvent("user/change-permissions");
    this._onSocketSendEvent("chat/update-message");

    this._onSocketSendEvent("version");
    this._onSocketSendEvent("stopSessions");

    this._on("secret-mod/turn", () => {
      window.location.reload();
    });

    this._on("error/join-user", async () => {
      await authService.refreshSession();
      this.emit("join-user");
    });

    // this._on('reconnect_attempt', error => {
    // 	console.log('SOCKET reconnect_attempt', error)
    // })
  }

  static instance: SocketIo = null;

  static get(): SocketIo {
    if (this.instance) return this.instance;

    this.instance = new SocketIo();

    return this.instance;
  }
}
