import { chatsApi } from "@/api";
import { transformToShortUsers } from "@/api/chats/transform";
import { IShortUser, usePaginationList } from "@/shared";
import _ from "lodash";
import { useEffect, useState } from "react";

export const useFetchChatUsers = () => {
  const [searchString, setSearchVal] = useState<string>("");
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

  const {
    items,
    isLoading,
    setLoadParams,
    loadMore,
    loadParams,
  } = usePaginationList<IShortUser>({
    fetchItems: chatsApi.getUsersForChatReq,
    serrializatorItems: (_items) => transformToShortUsers(_items),
    needInit: false,
    loadParams: {
      sort: "ASC",
      sortField: "firstName",
      limit: 20,
    },
    clearBeforeFetch: false,
  });

  useEffect(() => {
    if (searchString !== null) setLoadParams({ searchString });
  }, [searchString]);

  useEffect(() => {
    if (!_.isEmpty(items) && loadParams?.count && !loadParams?.searchString)
      setTotalUsersCount(loadParams?.count);
  }, [items, loadParams?.searchString, loadParams?.count]);

  return {
    items: items as IShortUser[],
    isLoading,
    searchString,
    loadMore,
    setSearchVal,
    loadParams,
    totalUsersCount,
  };
};
