import { createFullName, IChatDetails } from "@/shared";
import _ from "lodash";
import { IHeaderChatInfo } from "../interfaces";

export const getGroupChatInfo = (
  chatDetail: IChatDetails
): Omit<IHeaderChatInfo, "label"> => ({
  name: chatDetail?.name,
  previewUrl: chatDetail?.previewUrl,
  type: chatDetail?.type
});

export const getPersonalChatInfo = (
  chatDetail: IChatDetails,
  accountId: number
): Omit<IHeaderChatInfo, "label"> => {
  const member = _.find(
    chatDetail?.chatMembers,
    member => member?.userId !== accountId
  );

  return {
    name: createFullName(member?.user?.firstName, member?.user?.lastName),
    previewUrl: member?.user?.avatarUrl,
    type: chatDetail?.type
  };
};
