import React, { FC } from "react";
import { Col, Container, Row } from "reactstrap";
import "./style.scss";
import { ProfileMain, ProfileTabs } from "./components";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account/selectors";
import { useContact } from "../Contact/hooks/use-contact.hook";
import { ConfirmModal } from "@/shared";
import { useUserActions } from "./hooks";
import { getRefreshToken } from "@/store/auth";
import { accountService, authService } from "@/services/domain";
import { useHistory } from "react-router-dom";

export const ProfileDetail: FC = () => {
  const history = useHistory();
  const profile = useSelector(getProfile);
  const refreshToken = useSelector(getRefreshToken);

  const { contactStats, contactTasksCount } = useContact(profile.info.userId);

  const { confirmModal, onPressAction } = useUserActions({
    user: profile,
    onAction: async (type?: "block" | "logout") => {
      if (type === "block") await accountService.getAccount();
      else {
        await authService.logout(refreshToken);
        history.push("/");
      }
    },
  });

  const onChangeAvatar = async () => await accountService.getAccount();

  return (
    <Container>
      <ConfirmModal
        isShow={confirmModal.state.isOpen}
        message={confirmModal.state.message}
        onConfirm={confirmModal.state.onConfirm}
        setShow={(state: boolean) =>
          confirmModal.set({ ...confirmModal.state, isOpen: state })
        }
      />
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain
                isUpdate={true}
                profile={profile}
                authProfile={profile}
                countStats={contactStats}
                countTasksStats={contactTasksCount}
                onPressUserAction={onPressAction}
                onChangeAvatar={onChangeAvatar}
                onDeleteAvatar={onChangeAvatar}
              />
            </Row>
          </Col>

          <ProfileTabs
            isUpdate={true}
            profile={profile}
            authProfile={profile}
          />
        </Row>
      </div>
    </Container>
  );
};
