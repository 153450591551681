import _ from "lodash";

export const checkIpValue = (value: string) => {
  const subips = value.split(".");

  if (subips.length > 4) {
    return false;
  }

  if (subips.length > 0 && _.isNaN(parseInt(subips[0]))) return false;

  const invalidSubips = subips.filter(ip => {
    const _ip = parseInt(ip);
    if (_ip < 0 || _ip > 255) return true;

    if (_.startsWith(ip, "0") && ip.length > 1) return true;

    return false;
  });

  if (invalidSubips.length !== 0) {
    return false;
  }

  let emptyIpCount = 0;
  subips.forEach(ip => {
    if (ip === "") {
      emptyIpCount++;
    }
  });

  if (emptyIpCount > 1) {
    return false;
  }

  const twoDots = _.includes(value, "..");
  if (twoDots) return false;

  return true;
};

export const isValidIP = (ip: string) => {
  const subips = ip.split(".");
  if (subips.length !== 4 || _.some(subips, it => parseInt(it) > 255))
    return false;

  if (subips.length === 4 && _.endsWith(ip, ".")) return false;

  return true;
};
