import { getTimeFromMessageSend, hasImageUrl } from "@/shared";
import React, { FC, useMemo } from "react";
import {
  NewMessagesCountIndicator,
  ChatAvatarWithOnlineIndicator,
  ChatCardInfo,
  PinnedIndicator,
} from "../atoms";
import "./style.scss";

interface IProps {
  id: number;
  lastClicked: number;
  label: string;
  lastMessage: string;
  previewUrl: string;
  isOnline: boolean;
  isPinned: boolean;
  isUnread: boolean;
  unreadMessagesCount: number;
  sendDateTime: string;
  onPress: () => void;
}

export const ChatRowCard: FC<IProps> = ({
  id,
  lastClicked,
  label,
  lastMessage,
  previewUrl,
  isOnline,
  isPinned,
  isUnread,
  unreadMessagesCount,
  sendDateTime,
  onPress,
}) => {
  const renderIndicator = useMemo(() => {
    if (unreadMessagesCount || isUnread) {
      return <NewMessagesCountIndicator count={unreadMessagesCount} />;
    }
    if (isPinned) {
      return <PinnedIndicator />;
    }
    return null;
  }, [unreadMessagesCount, isPinned, isUnread]);

  const dateFromSendToRender = useMemo(
    () => getTimeFromMessageSend(sendDateTime),
    [sendDateTime]
  );

  return (
    <div
      className={"chat-card-container " + (lastClicked === id ? "active" : "")}
      onClick={onPress}
    >
      <div className="chat-card-mainContent">
        <ChatAvatarWithOnlineIndicator
          isOnline={isOnline}
          imageUrl={hasImageUrl(previewUrl, label)}
          size={55}
        />

        <ChatCardInfo label={label} message={lastMessage} />
      </div>
      <div className="chat-card-rightContent">
        <p className="chat-card-dateFromSend">{dateFromSendToRender}</p>
        <div className="chat-card-indicator">{renderIndicator}</div>
      </div>
    </div>
  );
};
