import { chatBgService } from "@/services/domain";
import { ChatBgKeys } from "@/shared";
import { selectCurrentChatBgId } from "@/store/chat-bg";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { CheckableBgItem } from "../atoms";
import { IChatBackgroundData } from "../interfaces";

interface IProps {
  chatBackground: IChatBackgroundData[];
}

export const BgList: FC<IProps> = ({ chatBackground }) => {
  const currentChatBgId = useSelector(selectCurrentChatBgId);

  const renderItem = ({ item: { id, img } }) => {
    const isActive = id === currentChatBgId;
    const isCustom = id === ChatBgKeys.CUSTOM_BG;

    return (
      <CheckableBgItem
        isChecked={isActive}
        source={isCustom ? `${img}` : img.default}
        key={`${id}---BG-CHAT`}
        onPress={() => chatBgService.selectChatBg(id)}
      />
    );
  };

  return (
    <div className="background-settings-images">
      {chatBackground.map((item) => renderItem({ item }))}
    </div>
  );
};
