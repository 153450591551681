import { tasksApi } from "@/api";
import {
  IAddTasksToFavorite,
  IFetchTasksCountParams,
  IFetchTasksParams,
  IRemoveTasksFromFavorite,
} from "@/api/tasks/requests.interfaces";
import { IFetchTasksRes } from "@/api/tasks/responses.interfaces";
import { appEvents } from "@/shared/events";

interface IFetchTasksPayload extends IFetchTasksParams {}
interface IFetchTasksCountPayload extends IFetchTasksCountParams {}

const fetchTasks = async (
  payload: IFetchTasksPayload
): Promise<IFetchTasksRes | void> => {
  try {
    const { data } = await tasksApi.fetchTasks(payload);

    return data;
  } catch (err) {
    console.log("ERROR | FETCH TASKS: ", err);
  }
};

const fetchTasksCountsByAuthor = async (
  userId: number,
  datePayload?: IFetchTasksCountPayload
) => {
  try {
    const { data } = await tasksApi.fetchTasksCountByAuthor(
      userId,
      datePayload
    );

    return data;
  } catch (error) {
    console.log("FETCH TASKS COUNTS STATS ERROR", error);
  } finally {
  }
};

const addTasksToFavorite = async (data: IAddTasksToFavorite) => {
  await tasksApi.addTasksToFavorite(data);
  appEvents.emit("onTaskAddToFavorite", { ids: data.taskIds });
};

const removeTasksFromFavorite = async (params: IRemoveTasksFromFavorite) => {
  await tasksApi.removeTasksFromFavorite(params);
  appEvents.emit("onTaskRemoveFromFavorite", { ids: params.taskIds });
};

export const tasksService = {
  fetchTasks,
  fetchTasksCountsByAuthor,
  addTasksToFavorite,
  removeTasksFromFavorite,
};
