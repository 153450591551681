import { CheckField, TreeSelectField } from "@/components/Fields";
import { IUsersFilter } from "@/shared";
import { simpleDispatch } from "@/store/store-helpers";
import { getUsersFilter, SetUsersFilter } from "@/store/users";
import React from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import "./style.scss";

interface IProps {
  factoriesTree: any;
  filterFactory?: boolean;
}

const FilterUsers: FC<IProps> = ({ factoriesTree, filterFactory = true }) => {
  const filter = useSelector(getUsersFilter);

  const onChange = (key: keyof IUsersFilter, value: any) =>
    simpleDispatch(new SetUsersFilter({ ...filter, [key]: value }));

  return (
    <div className="filter-user">
      <div className="filter">
        <Field
          name="isBlock"
          className="filter"
          label="Заблоковані"
          component={CheckField}
          color={filter.isBlocked && "#9e2743"}
          input={{
            value: filter.isBlocked,
            onChange: (val: boolean) => onChange("isBlocked", val),
          }}
        />
        {filterFactory && (
          <Field
            name="factoryId"
            component={TreeSelectField}
            placeholder="Підприємство"
            label="Підприємство"
            hideIcon
            value_field="id"
            tree={factoriesTree}
            register={{
              value: filter.factoryId,
              onChange: (val: string) => onChange("factoryId", val),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default reduxForm({
  form: "filter_users",
})(FilterUsers);
