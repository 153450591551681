import { Tooltip } from "antd";
import classNames from "classnames";
import React from "react";

export interface ISelectedRowsMenuItemConfig {
  name?: string;
  className?: string;
  key?: string;
  label?: string;
  onClick: () => void;
  icon?: {
    scr: string;
    name: string;
    className?: string;
  };
  iconNode?: any;
}

interface IProps {
  itemsConfig: ISelectedRowsMenuItemConfig[];
  blockClassName?: string;
}

export const SelectedRowsMenu = ({ itemsConfig, blockClassName }: IProps) => {
  return (
    <div style={{ marginBottom: 10, minHeight: 20 }} className={blockClassName}>
      {itemsConfig.map((it) => {
        return (
          <Tooltip title={it.label} key={it.key}>
            <button
              key={it.icon?.name}
              onClick={it.onClick}
              className={classNames(it.className, "selected-rows-menu-btn")}
            >
              {it.iconNode ? (
                it.iconNode
              ) : (
                <img
                  className={it.icon.className}
                  src={it.icon.scr}
                  alt={it.icon.name}
                />
              )}
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};
