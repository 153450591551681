import {
  IChatMessage,
  IComment,
  IMessage,
  IPushNotification,
} from "../interfaces";
import { Events } from "jet-tools";
import { ChatMemberRole, EntityType } from "../enums";
import { IChatMessage as IMessageInChat } from "@/containers/Chats/plugins/interfaces";

export type AppEvents = {
  onDeleteChatForMe: { chatId: number };
  decreaseCounter: { count: number };
  onReadChat: { chatId: number; unreadCount?: number };
  onTaskAddToFavorite: { ids: number[] };
  onTaskRemoveFromFavorite: { ids: number[] };
  onClearAllChats: any;
  onDeleteAllChats: any;
  onDeleteMessage: { chatId: number; messageId: number };
  onClearChatForMe: { chatId: number };

  onChangeMemberRole: { memberId: number; role: ChatMemberRole };
  onDeleteMember: { memberId: number };
  onAddMember: {};
  onFirstTaskComment: { taskId: number };
  onFirstTaskDocument: { taskId: number };
  onDeleteAllTaskDocs: { taskId: number };
  onReadComments: { taskId: number };
  onReadDocuments: { taskId: number };

  openForwardMessageModal: {
    message: IMessage | IMessageInChat[];
    isShow: boolean;
    onCancel?: () => void;
  };

  closeForwardMessageModal: {
    isShow: boolean;
  };

  openLimitPinnedChatsModal: {
    isShow: boolean;
  };

  openConfirmDeleteMessageModal: {
    message: IChatMessage | Partial<IMessageInChat>[];
    deleteForAll: boolean;
    isShow: boolean;
    onSuccess?: () => void;
  };

  closeConfirmDeleteMessageModal: {
    isShow: boolean;
  };

  onDeleteUserAvatar: { userId: number };

  openMessageMenuOptions: {
    items: Array<{
      name: string;
      onClick: () => void | Promise<void>;
    }>;
    onCancel?: () => void;
  };

  openSelectedMessagesMenuOptions: {
    items: Array<{
      name: string;
      onClick: () => void | Promise<void>;
    }>;
    onCancel?: () => void;
  };

  selectSticker: {
    onSelect: (sticker: string) => void;
  };

  onPressEditmessage: {
    message: IChatMessage;
  };

  chatSendImgModal: {
    isShow: boolean;
    url: string;
    onSend(): void;
  };
  contentModal: {
    content: React.ReactElement | string;
    title: string;
  };
};

export type SocketEvents = {
  "chat/new-message": { message: IChatMessage | IChatMessage[] };
  "chat/new-chat": any;
  "chat/delete-message": { messageId: number; chatId: number };
  "chat/clear-chat": { chatId: number };
  "chat/delete-chat": { chatId: number };
  "chat/new-members": { chatId: number };
  "chat/delete-member": { chatId: number };
  "chat/edit-chat": { chatId: number };
  "chat/pined-message": { messageId: number; chatId: number };
  "chat/is-read": { chatId: number; userId: number };
  "chat/change-role": { chatId: number };
  "chat/pin": { chatId: number };
  "chat/unpin": { chatId: number };
  "chat/unread": { chatId: number };

  "task/new-comment": { taskId: number; commentId: number; userId: number };
  "task/new-task": { taskId: number; executorId: number };
  "task/update-task": { taskId: number; executorId: number };
  "task/delete-task": { taskId: number; executorId: number };
  "task/hard-delete-task": { taskId: number; executorId: number };
  "task/finish-task": { taskId: number; executorId: number };
  "task/new-docs": { taskId: number };
  "task/read": { taskId: number };
  "task/read-docs": { taskId: number };
  "task/read-comments": { taskId: number; userId?: number };
  "task/update-comment": { comment: IComment };
  "task/delete-comment": {
    commentId: number;
    taskId: number;
    deletedAll?: boolean;
  };

  "user/connected": { userId: number; sessionType: string };
  "user/disconnected": { userId: number; sessionType: string };
  "user/deleted": { userId: number };
  "user/change-permissions": any;

  stopSessions: { sessionsIds: number[] };

  notification: { notification: IPushNotification };

  "chat/update-message": { message: IChatMessage };

  version: { type: EntityType; entityId: number };
};

export const appEvents = new Events<AppEvents>();

export const socketEvents = new Events<SocketEvents>();
