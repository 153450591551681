import { secretModApi } from "@/api/secret-mod/requests";
import { useState } from "react";
import { secretEvents } from "../events";
import { notification } from "@/shared";

export const useSecretUsersEdit = () => {
  const [isLoading, setLoading] = useState(false);

  const add = async (usersIdsSelected: number[]) => {
    try {
      setLoading(true);
      if (usersIdsSelected.length) {
        await secretModApi.hideUser({ userIds: usersIdsSelected.map(Number) });
      }
      secretEvents.emit("addUsers", {});
    } catch (e) {
      notification.showError(
        "Помилка",
        "Виникла помилка, спробуйте, будь ласка, пізніше."
      );
    } finally {
      setLoading(false);
    }
  };

  const remove = async (ids: number[]) => {
    try {
      setLoading(true);
      if (ids.length) {
        await secretModApi.revealUser({ userIds: ids.map(Number) });
      }
      secretEvents.emit("addUsers", {});
    } catch (e) {
      notification.showError(
        "Помилка",
        "Виникла помилка, спробуйте, будь ласка, пізніше."
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    setLoading,
    add,
    remove,
  };
};
