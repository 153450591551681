import { getMessagePreviewText } from "@/shared";
import _ from "lodash";
import React, { useMemo } from "react";
import { IChatMessage } from "./interfaces";
import { MessageMediaPreview } from "./message-media-preview.component";

interface IProps {
  repliedMessage: Partial<IChatMessage> & {
    userName: string;
    isDeleted: boolean;
  };
  onPress: () => void;
}

export const RepliedMessageInfo = ({ repliedMessage }: IProps) => {
  if (!repliedMessage) return null;

  const text = useMemo(() => {
    if (repliedMessage?.isDeleted) return "Видалене повідомлення";

    return getMessagePreviewText(repliedMessage);
  }, [repliedMessage]);

  return (
    <div className="replied-message-container" onClick={_.noop}>
      <MessageMediaPreview
        type={repliedMessage?.type}
        uri={repliedMessage?.content?.fileUrl}
      />
      <div className="replied-content">
        <span className="user-name">{repliedMessage?.userName}</span>
        <span className="replied-message-textblock">{text}</span>
      </div>
    </div>
  );
};
