import { create } from 'zustand'
import { ChatViewModeEnum } from '../enums'

interface IChatViewModeState {
	mode: ChatViewModeEnum
	setMode: (mode: ChatViewModeEnum) => void
}

export const useChatViewModeState = create<IChatViewModeState>()(set => ({
	mode: ChatViewModeEnum.DEFAULT,
	setMode: (mode: ChatViewModeEnum) => {
		set({ mode })
	},
}))
