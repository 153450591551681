import React from "react";

import chatIcon from "./chat-icon.svg";
import tasksIcon from "./tasks-icon.svg";
import userIcon from "./user-icon.svg";
import contactsIcon from "./contacts-icon.svg";
import buildingIcon from "./building-icon.svg";
import groupPermIcon from "./group-perm-icon.svg";
import logsIcon from "./logs-icon.svg";
import logsIpIcon from "./logsIp-icon.svg";
import notebookIcon from "./notebook-icon.svg";
import booksIcon from "./books.svg";
import tasksGroupsIcon from "./tasks-groups.svg";
import tasksReasonsIcon from "./tasks-reasons.svg";

function getSVG(link: string) {
  return <object data={link} type="image/svg+xml"></object>;
}

export const ChatIcon = (): JSX.Element => getSVG(chatIcon);
export const FILE_ChatIcon = chatIcon;

export const TaskIcon = (): JSX.Element => getSVG(tasksIcon);
export const FILE_TaskIcon = tasksIcon;

export const UserIcon = (): JSX.Element => getSVG(userIcon);
export const FILE_UserIcon = userIcon;

export const ContactsIcon = (): JSX.Element => getSVG(contactsIcon);
export const FILE_ContactsIcon = contactsIcon;

export const BuildingIcon = (): JSX.Element => getSVG(buildingIcon);
export const FILE_BuildingIcon = buildingIcon;

export const GroupPermIcon = (): JSX.Element => getSVG(groupPermIcon);
export const FILE_GroupPermIcon = groupPermIcon;

export const LogsIcon = (): JSX.Element => getSVG(logsIcon);
export const FILE_LogsIcon = logsIcon;

export const LogsIpIcon = (): JSX.Element => getSVG(logsIpIcon);
export const FILE_LogsIpIcon = logsIpIcon;

export const NotebookIcon = (): JSX.Element => getSVG(notebookIcon);
export const FILE_NotebookIcon = notebookIcon;

export const BooksIcon = (): JSX.Element => getSVG(booksIcon);
export const FILE_BooksIcon = booksIcon;

export const TasksGroupsIcon = (): JSX.Element => getSVG(tasksGroupsIcon);
export const FILE_TasksGroupsIcon = tasksGroupsIcon;

export const TasksReasonsIcon = (): JSX.Element => getSVG(tasksReasonsIcon);
export const FILE_TasksReasonsIcon = tasksReasonsIcon;
