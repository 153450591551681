import { IUser } from "@/shared";
import { Action } from "redux";

export class SaveAccount implements Action {
  readonly type = "SAVE_ACCOUNT";

  constructor(public readonly payload: { account: IUser }) {}
}
export class isLoading {
  readonly type = "IS_LOADING";
  constructor(
    public readonly payload: {
      isLoading: boolean;
    }
  ) {}
}
export class ResetAccount {
  readonly type = "RESET_ACCOUNT";
}
export class isVisibleModal {
  readonly type = "SHOW_MODAL";
  constructor(
    public readonly payload: {
      isVisibleModal: boolean;
    }
  ) {}
}
export class AvatarAccount implements Action {
  readonly type = "SAVE_AVATAR_ACCOUNT";

  constructor(public readonly payload: { avatarUrl: any }) {}
}
export type TAccountActions =
  | SaveAccount
  | isLoading
  | ResetAccount
  | AvatarAccount
  | isVisibleModal;
