import { voidColumn } from "@/components/TableGrid/configs";
import _, { defaultTo } from "lodash";
import moment from "moment";

export const columnsConfig: any = [
  voidColumn,

  {
    name: "Ip",
    key: "ip",
    resizable: true,
    sortable: true,
    filter: true,
    filterType: "search",
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return null;
      return row.ip?.replace("::ffff:", "");
    },
  },
  {
    name: "Відбиток",
    key: "fingreprit",
    resizable: false,
    sortable: false,
    filter: false,
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return null;
      return defaultTo(row.fingreprint, "");
    },
  },
  {
    name: "Користувач",
    key: "user",
    resizable: false,
    sortable: false,
    filter: false,
    formatter: ({ row }) => {
      if (_.isEmpty(row) || !row.user) return null;
      return `${row.user?.email}`;
    },
  },
  {
    name: "Дата блокування",
    key: "createdAt",
    resizable: false,
    sortable: false,
    filter: false,
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return null;
      return moment(row.createdAt).format("DD-MM-YYYY H:mm");
    },
  },
];

export const defaultActiveColumns = [
  "select-row",
  "ip",
  "fingreprit",
  "user",
  "createdAt",
];
