import { IChatBackgroundData } from "@/containers/Chats/interfaces";
import { ChatBgKeys } from "@/shared";

export const defaultChatBgConfig: IChatBackgroundData[] = [
  {
    id: ChatBgKeys.DEF_BG_1,
    img: require("@/assets/img/static-chat-bg/bg-1.png"),
  },
  {
    id: ChatBgKeys.DEF_BG_2,
    img: require("@/assets/img/static-chat-bg/bg-2.png"),
  },
  {
    id: ChatBgKeys.DEF_BG_3,
    img: require("@/assets/img/static-chat-bg/bg-3.png"),
  },
  {
    id: ChatBgKeys.DEF_BG_4,
    img: require("@/assets/img/static-chat-bg/bg-4.png"),
  },
  {
    id: ChatBgKeys.DEF_BG_5,
    img: require("@/assets/img/static-chat-bg/bg-5.png"),
  },
  {
    id: ChatBgKeys.DEF_BG_6,
    img: require("@/assets/img/static-chat-bg/bg-6.png"),
  },
];
