import { ITaxonomy, ICreateOrUpdateTaxonomy, IPermissionsGroup } from '@/shared'
import { createReducer } from '@bitalikrty/redux-create-reducer'
import { TPermissionsGroupActions } from './types'
import _ from 'lodash'

export interface IGroupPermissionState {
	isLoading: boolean
	permissionsGroupList: IPermissionsGroup[],
	targetGroupPermission: IPermissionsGroup,
	count: number
	filter?: any
}

const initialState: IGroupPermissionState = {
	isLoading: false,
	permissionsGroupList: [],
	targetGroupPermission: null,
	count: null
}

export const GroupPermissionReducer = createReducer<IGroupPermissionState, TPermissionsGroupActions>(
	initialState,
	{

		SET_TARGET_PERMISSIONS_GROUP: (state, action) => {
			return {
				...state,
				targetGroupPermission: action.payload.permissionGroup
			}
		},

		SET_PERMISSIONS_GROUPS: (state, action) => {
			console.log('action', action.payload)
			return {
				...state,
				permissionsGroupList: action.payload.data,
				count: action.payload.count
			}
		},

		CREATE_PERMISSIONS_GROUP: (state, action) => {
			return {
				...state,
				permissionsGroupList: [...state.permissionsGroupList, action.payload.permissionsGroup]
			}
		},

		UPDATE_PERMISSIONS_GROUP: (state, action) => {
			return {
				...state,
				permissionsGroupList: state.permissionsGroupList.map((item) => {
					if (item.id === action.payload.permissionsGroup.id) {
						return item = {
							...item,
							name: action.payload.permissionsGroup.name,
							permissions: action.payload.permissionsGroup.permissions
						}
					}
					return item
				})
			}
		},

		DELETE_PERMISSIONS_GROUP: (state, action) => {
			return {
				...state,
				permissionsGroupList: state.permissionsGroupList.filter(item => item.id === action.payload.id)
			}
		},

		IS_LOADING_PERMISSIONS_GROUP: (state, action) => {
			return {
				...state,
				isLoading: action.payload.isLoading,
			}
		},
	},
)
