import { ChatMemberRole, MessageType } from "../enums";

const regexToMatch = /@\[([^[]*)]\(([^(^)]*)\)/g;
const parseMentionsMessage = (message: string, regex: RegExp): string => {
  const subst = ` @$1 `;
  const result = message?.replace(regex, subst);

  return result;
};

export const getMessagePreviewText = (message: Record<string, any>) => {
  if (!message?.type) {
    try {
      return message?.content?.message;
    } catch (e) {
      console.log("ERROR | error message content type", e);
    }
  }

  if (message?.type === MessageType.Forwarded) {
    return getMessagePreviewText(message?.content?.originalMessage);
  } else {
    switch (message?.type) {
      case MessageType.Text:
        return parseMentionsMessage(message?.content?.message, regexToMatch);
      case MessageType.File:
        return "Файлове повідомлення";
      case MessageType.Image:
        return "Зображення";
      case MessageType.Audio:
        return "Аудіо повідомлення";
      case MessageType.Video:
        return "Відео повідомлення";
      case MessageType.NewChatMembers:
        return "Додано нового учасника";
      case MessageType.DeleteChatMember:
        return "Видалено учасника";
      case MessageType.MissedCall:
        return "Пропущений виклик";
      case MessageType.SuccessCall:
        return "Успішний виклик";
      case MessageType.NewChat:
        return "Створена нова група";
      case MessageType.Sticker:
        return "Стікер повідомлення";
      default:
        return null;
    }
  }
};

export const createFullName = (
  firstName?: string,
  middleName?: string,
  lastName?: string
) => [lastName, firstName, middleName].filter((it) => it).join(" ");

export const createFullRolePreviewText = (role: ChatMemberRole) => {
  switch (role) {
    case ChatMemberRole.Admin:
      return "Адміністратор чату";
    case ChatMemberRole.Member:
      return "Учасник";
    default:
      return null;
  }
};
