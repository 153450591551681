import { IPagination } from "@/shared";
import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as req from "./requests.interfaces";
import * as res from "./responses.interfaces";

const fetchTasks = (
  params: req.IFetchTasksParams
): ApiResponse<res.IFetchTasksRes> => {
  return http.get("admin/tasks", { params });
};

const fetchTasksCountByAuthor = (
  userId: number,
  params: req.IFetchTasksCountParams
): ApiResponse<res.IFetchTasksCountByAuthor> => {
  return http.get<res.IFetchTasksCountByAuthor>(
    `admin/tasks/counts-by-author/${userId}`,
    { params }
  );
};

const addTasksToFavorite = (data: req.IAddTasksToFavorite) => {
  return http.post<void>("admin/tasks-favorites", data);
};

const removeTasksFromFavorite = (params: req.IRemoveTasksFromFavorite) => {
  return http.delete<void>("admin/tasks-favorites", { params });
};

const getTaskExecutors = (
  params: IPagination & { includeIds?: number[]; name?: string }
): ApiResponse<res.IFetchExecutorsList> => {
  return http.get<res.IFetchExecutorsList>("admin/tasks/executors", {
    params,
  });
};

const getAllExecutors = (
  params: IPagination & { includeIds?: number[]; name?: string }
): ApiResponse<res.IFetchExecutorsList> => {
  return http.get<res.IFetchExecutorsList>("admin/tasks/executors-all", {
    params,
  });
};

const createTask = (data: req.ICreateTaskData): ApiResponse<number[]> => {
  return http.post<number[]>("admin/tasks", data);
};

export const getTaskDetails = (
  taskId: number
): ApiResponse<res.ITaskDetailsResponse> => {
  return http.get<res.ITaskDetailsResponse>(`admin/tasks/by-id/${taskId}`);
};

const updateTask = (
  taskId: number,
  data: req.IUpdateTaskData
): ApiResponse<res.IUpdateTaskResponse> => {
  return http.patch(`admin/tasks/${taskId}`, data);
};

export const finishTasks = (data: req.IFinishTasksData): ApiResponse<void> => {
  return http.post<void>("admin/tasks/done", data);
};

export const reassignTask = (data: req.IReassignTasks) => {
  return http.post<void>("admin/tasks/reassign", data);
};

export const removeTasks = (ids: number[]) => {
  return http.post("admin/tasks/delete-many", { ids });
};

export const hardDeleteTasks = (ids: number[]) => {
  return http.post("admin/tasks/hard-delete-many", { ids });
};

export const tasksApi = {
  fetchTasks,
  fetchTasksCountByAuthor,
  addTasksToFavorite,
  removeTasksFromFavorite,
  getTaskExecutors,
  createTask,
  getTaskDetails,
  updateTask,
  finishTasks,
  reassignTask,
  removeTasks,
  getAllExecutors,
  hardDeleteTasks,
};
