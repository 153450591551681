import { getNameFromFileUrl, ITaskDocument } from "@/shared";
import moment from "moment";
import { ITaskFileItem } from "../interfaces";

export const transformTaskDocsToFileItems = (
  docs: ITaskDocument[]
): ITaskFileItem[] =>
  docs.map(it => ({
    id: it.id,
    name: getNameFromFileUrl(it.url),
    date: moment(it.createdAt).format("DD-MM-YYYY"),
    url: it.url
  }));
