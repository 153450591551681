import React from "react";

export const PasswordIcon = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="18" fill="#9E2743" fillOpacity="0.1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5265 7.13806C24.7362 7.3846 25.8557 7.95617 26.7649 8.79132C27.6741 9.62648 28.3384 10.6936 28.6865 11.8781C29.009 12.9641 29.057 14.1131 28.826 15.2231C28.4921 16.7438 27.6518 18.1062 26.4428 19.0872C25.2337 20.0682 23.7274 20.6097 22.1705 20.6231C21.5135 20.6231 20.8595 20.5286 20.231 20.3381L19.031 21.7451L18.4625 22.0076H17V24.2576L16.25 25.0076H14V27.2576L13.25 28.0076H8.75L8 27.2576V23.7971L8.219 23.2676L15.68 15.8066C15.4683 15.1246 15.367 14.4131 15.38 13.6991C15.3937 12.7001 15.6275 11.7165 16.0649 10.8183C16.5024 9.92009 17.1325 9.12945 17.9105 8.50272C18.6885 7.87599 19.5952 7.42861 20.5659 7.19246C21.5367 6.95632 22.5475 6.93723 23.5265 7.13656V7.13806ZM25.5035 17.9381C26.4458 17.175 27.1011 16.1146 27.362 14.9306L27.368 14.9381C27.5538 14.0723 27.5204 13.1738 27.2707 12.3242C27.021 11.4746 26.563 10.7008 25.9383 10.0733C25.3135 9.44571 24.5419 8.98419 23.6934 8.73066C22.845 8.47714 21.9466 8.43963 21.08 8.62156C19.9112 8.88098 18.8627 9.52399 18.1016 10.4482C17.3405 11.3724 16.9105 12.5247 16.88 13.7216C16.865 14.4056 16.985 15.0836 17.2385 15.7166L17.0735 16.5311L9.5 24.1076V26.5076H12.5V24.2576L13.25 23.5076H15.5V21.2576L16.25 20.5076H18.1175L19.4315 18.9836L20.273 18.7736C20.8796 19.0106 21.5253 19.1317 22.1765 19.1306C23.3885 19.1213 24.5615 18.7008 25.5035 17.9381ZM24.2465 13.8401C24.3648 13.6766 24.4489 13.491 24.4937 13.2944C24.5386 13.0977 24.5434 12.894 24.5077 12.6954C24.472 12.4969 24.3967 12.3076 24.2861 12.1388C24.1756 11.97 24.0322 11.8253 23.8644 11.7133C23.6967 11.6012 23.508 11.5242 23.3098 11.4867C23.1116 11.4493 22.9078 11.4522 22.7108 11.4953C22.5137 11.5384 22.3273 11.6208 22.1629 11.7376C21.9984 11.8544 21.8592 12.0032 21.7535 12.1751C21.5503 12.5056 21.483 12.9021 21.566 13.2812C21.6489 13.6603 21.8755 13.9925 22.1982 14.208C22.5209 14.4236 22.9146 14.5056 23.2966 14.437C23.6785 14.3684 24.019 14.1544 24.2465 13.8401Z"
          fill="#9E2743"
        />
      </svg>
    </>
  );
};
