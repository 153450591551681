import {
  ETaxonomyType,
  IPagination,
  IPaginationResult,
  ITaxonomy,
  ITaxonomyShortInfo
} from "@/shared";
import _ from "lodash";
import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";
import * as Res from "./responses.interfaces";

const fetchTaxonomies = (): ApiResponse<ITaxonomy[]> => {
  return http.get<ITaxonomy[]>("admin/taxonomies", null);
};

const deleteTaxonomy = (taxonomyId: number): ApiResponse<void> => {
  return http.delete<void>(`admin/taxonomies/${taxonomyId}`);
};

const createTaxonomy = (
  data: Req.ICreateTaxonomyReq
): ApiResponse<Res.ICreateTaxonomyRes> => {
  const formData: any = new FormData();
  if (data.icon) formData.append("icon", data.icon);
  if (data.parentId) formData.append("parentId", data.parentId);

  formData.append("name", data.name);
  formData.append("isDefault", data.isDefault);
  formData.append("type", data.type);

  return http.post<Res.ICreateTaxonomyRes>(`admin/taxonomies`, formData);
};
const updateTaxonomy = (
  taxonomyId: number,
  data: Req.IUpdateTaxonomyReq
): ApiResponse<Res.IUpdateTaxonomyRes> => {
  const formData: any = new FormData();
  if (data.icon) formData.append("icon", data.icon);
  if (data.parentId || _.isNull(data.parentId))
    formData.append("parentId", data.parentId);

  formData.append("name", data.name);
  formData.append("isDefault", data.isDefault);

  return http.put<Res.IUpdateTaxonomyRes>(
    `admin/taxonomies/${taxonomyId}`,
    formData
  );
};

const deleteTaxonomyIcon = (taxonomyId: number): ApiResponse<void> => {
  return http.delete<void>(`admin/taxonomies/delete-icon/${taxonomyId}`);
};

export const fetchTaxonomiesByType = (
  params: IPagination & {
    type: ETaxonomyType;
    includeIds?: number[];
  }
): ApiResponse<IPaginationResult<ITaxonomyShortInfo>> => {
  return http.get<IPaginationResult<ITaxonomyShortInfo>>(
    `admin/taxonomies/short-info`,
    { params }
  );
};

export const taxonomiesApi = {
  fetchTaxonomies,
  deleteTaxonomy,
  createTaxonomy,
  updateTaxonomy,
  deleteTaxonomyIcon,
  fetchTaxonomiesByType
};
