import React, { FC } from "react";

interface Props {
  url: string;
  style?: React.CSSProperties;
}

export const Avatar: FC<Props> = ({ url, style = {} }) => {
  return (
    <img
      style={{
        height: 40,
        width: 40,
        borderRadius: 100,
        objectFit: "fill",
        ...style,
      }}
      src={url || `${process.env.PUBLIC_URL}/img/default-avatar.jpg`}
      alt={url ? "Avatar" : "Default avatar"}
    />
  );
};
