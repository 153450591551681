import { IContactsFilter, IShortUser, IUsersFilter } from "@/shared";
import { Action } from "redux";

export class SelectAll implements Action {
  readonly type = "SELECT_ALL";
  constructor(
    public readonly payload: {
      items: IShortUser[];
    }
  ) {}
}

export class SelectUser implements Action {
  readonly type = "SELECT_USER";
  constructor(
    public readonly payload: {
      user: IShortUser;
    }
  ) {}
}

export class UnselectUser implements Action {
  readonly type = "UNSELECT_USER";
  constructor(
    public readonly payload: {
      userId: number;
    }
  ) {}
}

export class UnselectAllUsers implements Action {
  readonly type = "UNSELECT_ALL_USERS";
}

export class SetUsersFilter {
  readonly type = "SET_USERS_FILTER";
  constructor(public readonly payload: IUsersFilter) {}
}

export class SetContactsFilter {
  readonly type = "SET_CONTACTS_FILTER";
  constructor(public readonly payload: IContactsFilter) {}
}

export class ClearUsersFilter {
  readonly type = "CLEAR_USERS_FILTER";
}

export class ClearContactsFilter {
  readonly type = "CLEAR_CONTACTS_FILTER";
}

export type TUsersActions =
  | SelectAll
  | SelectUser
  | UnselectUser
  | UnselectAllUsers
  | SetContactsFilter
  | SetUsersFilter
  | ClearContactsFilter
  | ClearUsersFilter;
