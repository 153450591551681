import { ChatType, IChatDetails } from "@/shared";
import { getGroupChatInfo, getPersonalChatInfo } from "../helpers";
import { IHeaderChatInfo } from "../interfaces";

export const transformChatDetailToHeaderChatInfo = (
  chatDetail: IChatDetails,
  accountId: number
): Omit<IHeaderChatInfo, "label"> => {
  if (chatDetail.type === ChatType.Personal)
    return getPersonalChatInfo(chatDetail, accountId);

  return getGroupChatInfo(chatDetail);
};
