import React, { FC, useEffect, useState } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { CreateEditGroupPermissionsForm } from "./components";
import Modal from "../../components/Modal";
import {
  checkPermission,
  IUser,
  Permissions,
  SearchInput,
  usePaginationList,
} from "@/shared";
import { getProfile } from "@/store/account";
import { Data } from "./components/Data";
import { useForm } from "react-hook-form";
import { IGroupPermissionsForm } from "./interfaces";
import _ from "lodash";
import { permissionsGroupApi } from "@/api";
import {
  IFetchPermissionsGroupList,
  IUpdatePermissionsGroup,
} from "@/api/permissions-group/responses.interfaces";
import { getMyPermissions } from "@/store/permissions";

const defaultValues = { groupName: "", permissions: null };

export const GroupPermission: FC = () => {
  const [searchString, setSearch] = useState<string>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [groupPermissionsToEdit, setGroupPermissionsToEdit] = useState<
    IFetchPermissionsGroupList["items"][0]
  >();
  const [focusFilterKey, setFocusFilterKey] = useState<string>("");

  const form = useForm<IGroupPermissionsForm>({
    defaultValues,
  });

  const profile: IUser = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const onPressEdit = (id: number) => {
    getGroupPermissionsToEdit(id);
    setShowModal(true);
  };

  const paginationList = usePaginationList<
    IFetchPermissionsGroupList["items"][0]
  >({
    fetchItems: permissionsGroupApi.fetchPermissionsGroupsList,
    loadParams: {
      limit: 10,
      page: 1,
      sort: "ASC",
      sortField: "name",
    },
  });

  const onUpdateGroup = (item: IUpdatePermissionsGroup) => {
    const newItems = paginationList.items.map((it) => {
      if (it.id === item.id) return item;

      return it;
    });

    paginationList._setItems(newItems);
  };

  const onCreateGroup = () => paginationList.resetFlatList();

  useEffect(() => {
    paginationList.setLoadParams({
      searchString,
    });
  }, [searchString]);

  const getGroupPermissionsToEdit = (id: number) => {
    const item = _.find(paginationList.items, (it) => it.id === id);

    if (item) setGroupPermissionsToEdit(item);
  };

  useEffect(() => {
    if (!showModal) {
      form.reset(defaultValues);
      setGroupPermissionsToEdit(null);
    }
  }, [showModal]);

  return (
    <Container className="factory">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Modal
                show={showModal}
                toggle={setShowModal}
                title="Група прав доступу"
              >
                <CreateEditGroupPermissionsForm
                  form={form}
                  toggleModal={setShowModal}
                  groupPermissionsToEdit={groupPermissionsToEdit}
                  onUpdateGroup={onUpdateGroup}
                  onCreateGroup={onCreateGroup}
                />
              </Modal>

              <Row>
                <div
                  className="col-md-12 justify-content-end flex"
                  style={{ marginBottom: 30 }}
                >
                  <SearchInput
                    value={searchString}
                    placeholder="Знайдіть групу"
                    style={{ width: 250, marginRight: 20 }}
                    onChange={setSearch}
                    isFocused={focusFilterKey === "globalSearch"}
                    onFocus={() => setFocusFilterKey("globalSearch")}
                  />

                  <Button
                    color="primary"
                    size="sm"
                    disabled={
                      !checkPermission(
                        "tabs",
                        "group_permission",
                        profile,
                        permissions,
                        Permissions.CREATE
                      )
                    }
                    onClick={() => setShowModal(true)}
                  >
                    Створити
                  </Button>
                </div>
              </Row>

              <Data
                paginationList={paginationList}
                onPressEdit={onPressEdit}
                focusFilterKey={focusFilterKey}
                setFocusFilterKey={setFocusFilterKey}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
