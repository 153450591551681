import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";
import * as Res from "./responses.interfaces";

const signIn = (
  params: Req.ISignInPayload
): ApiResponse<Res.IAuthSuccessResponse> => {
  return http.post<Res.IAuthSuccessResponse>(`admin/auth`, params);
};

const sendRefreshToken = (
  params: Req.IRefreshTokenPayload
): ApiResponse<Res.IAuthSuccessResponse> => {
  return http.post<Res.IAuthSuccessResponse>(
    `admin/auth/refresh-token`,
    params
  );
};

const recoveryCodeReq = (
  params: Req.IRequestCodePayload
): ApiResponse<void> => {
  return http.post<void>(`admin/auth/password-recovery/request-code`, params);
};

const passwordRecoveryReq = (
  params: Req.IPasswordRecoveryPayload
): ApiResponse<Res.IAuthSuccessResponse> => {
  return http.post<Res.IAuthSuccessResponse>(
    `admin/auth/password-recovery`,
    params
  );
};

const logout = (params: Req.ILogoutPayload): ApiResponse<void> => {
  return http.post<void>(`admin/auth/logout`, params, {}, false);
};

export const authApi = {
  signIn,
  sendRefreshToken,
  recoveryCodeReq,
  passwordRecoveryReq,
  logout
};
