import React, { FC } from "react";
import styles from "./style.module.css";

interface IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

export const FormCheckbox: FC<IProps> = ({ checked, onChange, label }) => {
  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        className={styles.input}
        onChange={() => {
          onChange(!checked);
        }}
      />
      <svg
        className={`checkbox ${checked ? styles.svgActive : ""}`}
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        <path
          d="M1 4.5L5 9L14 1"
          strokeWidth="2"
          stroke={checked ? "rgba(158, 39, 67, 1)" : "none"}
        />
      </svg>

      <p>{label}</p>
    </div>
  );
};
