import ModalComponent from "@/components/Modal";
import { useEventsListener } from "@/shared";
import { Row } from "antd";
import React, { useState } from "react";
import "./styles.scss";

interface Config {
  content: React.ReactNode | string;
  title: string;
}

export const ContentModalSmart = () => {
  const [isOpen, setOpen] = useState(false);
  const [settings, setSettings] = useState<Config>(null);

  useEventsListener(
    "contentModal",
    (data) => {
      setOpen(true);
      setSettings(data);
    },
    []
  );

  const close = () => {
    setOpen(false);
    setSettings(null);
  };

  return (
    <ModalComponent title={settings?.title} show={isOpen} toggle={close}>
      <Row className="content-modal">{settings?.content}</Row>
    </ModalComponent>
  );
};
