import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { config } from "@/config";
import { GlobalContainerService } from "../services/system/global-container.service";
import { authService } from "@/services/domain";
import { UserFingerprintGenerator } from "@/shared";

const store = () => GlobalContainerService.get("store");

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  },
  timeout: 180000,
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = store().getState().auth.accessToken;
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;
  }
  config.headers[
    "x-allow-lang"
  ] = UserFingerprintGenerator.generateUserFingerprint();

  return config;
});

// Параметр tryAgainOnError використовується у вийняткових випадках, коли
// не потрібно поновлювати сесію та повторювати запит при помилці на попередній
// спробі зі статусом 401
// Наприклад, якщо повторний запит потрібно надсилати уже з іншими даними
const request = async <T>(
  func: () => any,
  tryAgainOnError = true
): Promise<AxiosResponse<T>> => {
  try {
    const response = await func();
    return (response as any) as AxiosResponse;
  } catch (e) {
    if (e.response.status === 401 && tryAgainOnError) {
      await authService.refreshSession();
      return ((await func()) as any) as AxiosResponse;
    }
    throw e;
  }
};

export type IRequestConfig = AxiosRequestConfig;

const api = {
  get: <T>(url: string, params?: AxiosRequestConfig, tryAgainOnError = true) =>
    request<T>(() => axiosInstance.get<T>(url, params), tryAgainOnError),

  post: <T>(
    url: string,
    data: any,
    params?: AxiosRequestConfig,
    tryAgainOnError = true
  ) =>
    request<T>(() => axiosInstance.post<T>(url, data, params), tryAgainOnError),

  put: <T>(
    url: string,
    data: any,
    params?: AxiosRequestConfig,
    tryAgainOnError = true
  ) =>
    request<T>(() => axiosInstance.put<T>(url, data, params), tryAgainOnError),

  patch: <T>(
    url: string,
    data: any,
    params?: AxiosRequestConfig,
    tryAgainOnError = true
  ) =>
    request<T>(
      () => axiosInstance.patch<T>(url, data, params),
      tryAgainOnError
    ),

  delete: <T>(
    url: string,
    params?: AxiosRequestConfig,
    tryAgainOnError = true
  ) => request<T>(() => axiosInstance.delete<T>(url, params), tryAgainOnError),
};
export default api;
