import _ from "lodash";
import { useEffect, useRef, useState } from "react";

interface ReqParams {
  fromId?: number;
  toId?: number;
  limit?: number;
  sort?: "ASC" | "DESC";
}

export interface BaseItem {
  id: number;
}

export interface UseIdsListParams<T extends BaseItem, K> {
  req: (params: { params: K & ReqParams }) => any;
  serrializatorResponse?: <S>(data: S) => S;
  serrializatorItems?: (
    items: ReturnType<UseIdsListParams<T, K>["req"]>
  ) => T[];
  limit?: number;
  loadParams?: Record<string, string>;
  needInit?: boolean;
  clearWhenReload?: boolean;
  lastMessageId: number;
  firstMessageId: number;
}

export enum LoadingDirection {
  old = "old",
  new = "new",
  none = "none",
}

const defaultProps: UseIdsListParams<any, any> = {
  limit: 20,
  req: () => {} /*  axios request */,
  serrializatorResponse: (data: any) => data,
  serrializatorItems: (items: any) => items,
  loadParams: {},
  needInit: true,
  clearWhenReload: true,
  lastMessageId: 9999999,
  firstMessageId: 0,
};

const getDefaultProps = () => Object.assign({}, defaultProps);

export const useIdsList = <T extends BaseItem, K extends Record<any, any>>(
  _params: UseIdsListParams<T, K>
) => {
  const [items, setItems] = useState<T[]>([]);
  const listEnd = useRef({
    endNew: false,
    endOld: false,
  });
  const blockLoadingRef = useRef(false);
  const [loading, setLoading] = useState<LoadingDirection>(
    LoadingDirection.new
  );

  const params = Object.assign(getDefaultProps(), _params);

  useEffect(() => {
    if (!_.isEmpty(items)) {
      if (items[0].id === params.firstMessageId) listEnd.current.endOld = true;
      if (items[items.length - 1].id === params.lastMessageId)
        listEnd.current.endNew = true;
    }
  }, [params.firstMessageId, params.lastMessageId, items]);

  const loadParams = useRef<any>({
    limit: params.limit,
    ...params.loadParams,
  });

  const load = async (_params: ReqParams) => {
    const reqParams: ReqParams & K = {
      ...loadParams.current,
      fromId: _params.fromId,
      toId: _params.toId,
      sort: _params.sort ? _params.sort : "DESC",
      limit: _params.limit ? _params.limit : loadParams.current?.limit,
    };

    if (
      reqParams.fromId &&
      listEnd.current.endNew &&
      reqParams.fromId >= params.lastMessageId
    )
      return;
    if (reqParams.toId && listEnd.current.endOld) return;
    if (blockLoadingRef.current) return;

    blockLoadingRef.current = true;

    try {
      await setLoadingDirection(reqParams);

      if (params.clearWhenReload) setItems([]);
      const response = params.serrializatorResponse(
        await params.req({ params: reqParams })
      );
      if (!response) throw {};

      const fetchedItems = params.serrializatorItems(response.data.items);

      mergeItems(fetchedItems);
    } finally {
      blockLoadingRef.current = false;
      await setLoading(LoadingDirection.none);
    }
  };

  const mergeItems = (items1: T[]) => {
    setItems((_items) => {
      const result = [..._items];

      items1.map((it) => {
        if (!_.find(result, (res) => res.id === it.id)) result.push(it);
      });

      result.sort((a, b) => a.id - b.id); // need check sort
      return result;
    });
  };

  const setLoadingDirection = (params: ReqParams) => {
    if (params.toId) setLoading(LoadingDirection.old);
    else setLoading(LoadingDirection.new);
  };

  /**
   * по дефолтну юзаємо цю штуку, це аналог loadNext
   */
  const loadOld = async () => {
    const firstItem = items[0];
    const reqParams: ReqParams = {};
    if (firstItem) reqParams.toId = firstItem.id;
    await load(reqParams);
  };

  /**
   * ця функція треба коли ми горнемо вниз
   */
  const loadNew = async (limit?: number) => {
    const lastItem = items[items.length - 1];
    const reqParams: ReqParams = {};
    if (lastItem) reqParams.fromId = lastItem.id;
    if (limit) reqParams.limit = limit;
    reqParams.sort = "ASC";
    await load(reqParams);
  };

  const loadMessage = async (messageId: number) => {
    setLoading(LoadingDirection.old);
    clear();
    await load({
      // fromId: messageId,
      toId: messageId,
    });
  };

  const clear = () => {
    blockLoadingRef.current = false;
    listEnd.current.endOld = false;
    listEnd.current.endNew = false;
    setItems([]);
  };

  const resetFlatList = () => {
    clear();
    load({});
  };

  const setLoadParams = (params: Record<string, any>) => {
    loadParams.current = {
      ...loadParams.current,
      ...params,
    };
    resetFlatList();
  };

  useEffect(() => {
    if (params.needInit) load({});
  }, []);

  return {
    items,
    loadNew,
    loadOld,
    loadMessage,
    isLoadingNew: loading === LoadingDirection.new,
    isLoadingOld: loading === LoadingDirection.old,
    lastMessageLoaded: listEnd.current.endNew,
    firstMessageLoaded: listEnd.current.endOld,
    _setItems: setItems,
    resetList: resetFlatList,
    setLoadParams,
    loadParams: loadParams.current,
  };
};
