import React, { useRef, useState } from "react";
import ModalComponent from "@/components/Modal";
import { Col, Row } from "antd";
import { stickersList } from "@/config/stickers.config";
import "./styles.scss";
import { useEventsListener } from "@/shared";

export const SelectStickersModalSmart = () => {
  const [isOpen, setOpen] = useState(false);
  const settingsRef = useRef({ onSelect: null }).current;

  useEventsListener(
    "selectSticker",
    (data) => {
      settingsRef.onSelect = data.onSelect;
      setOpen(true);
    },
    [settingsRef]
  );

  const select = (key) => {
    setOpen(false);
    if (settingsRef.onSelect) settingsRef.onSelect(key);
  };

  const renderSticker = (key) => {
    return (
      <Col span={12} className="sticker-item" onClick={() => select(key)}>
        <img src={stickersList[key]} alt="" />
      </Col>
    );
  };

  return (
    <ModalComponent title="Стікери" show={isOpen} toggle={() => setOpen(false)}>
      <Row>{Object.keys(stickersList).map(renderSticker)}</Row>
    </ModalComponent>
  );
};
