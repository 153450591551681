import { taskCommentsApi } from "@/api";
import { IComment, notification, useSocketListener } from "@/shared";
import { appEvents, SocketEvents } from "@/shared/events";
import { getProfile } from "@/store/account";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { transformComment, transformComments } from "../transforms";
interface IProps {
  taskId: number;
  needInit: boolean;
}

export const useTaskComments = ({ taskId, needInit }: IProps) => {
  const account = useSelector(getProfile);

  const [taskComments, setComments] = useState([]);
  const [newComment, setNewComment] = useState<string>("");
  const [isSending, setSending] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<{
    isShow: boolean;
    message: string;
    onConfirm: () => any;
  }>(null);
  const [editableComment, setEditableComment] = useState(null);
  const [isInit, setInit] = useState<boolean>(false);

  const fetchComments = async () => {
    try {
      const { data } = await taskCommentsApi.fetchComments({ taskId });
      if (data) {
        const comments = transformComments(data, account?.id);
        setComments(comments);

        if (!_.isEmpty(data)) appEvents.emit("onReadComments", { taskId });
      }
    } catch (e) {}

    return () => setComments([]);
  };

  const addComment = async () => {
    if (_.isEmpty(newComment)) return;
    setSending(true);
    try {
      if (editableComment) {
        await taskCommentsApi.editComment(editableComment.id, newComment);
        setComments(prev => {
          return [...prev].map(it => {
            if (it.id !== editableComment.id) return it;
            return transformComment(
              {
                ...it,
                content: newComment
              },
              account.id
            );
          });
        });
        setNewComment("");
        setEditableComment(null);
      } else {
        const { data } = await taskCommentsApi.createComment({
          taskId,
          content: newComment
        });

        if (_.isEmpty(taskComments))
          appEvents.emit("onFirstTaskComment", { taskId });

        if (data) {
          setComments(prev => {
            return [...prev, ...transformComments([data], account.id)];
          });
        }
        setNewComment("");
      }
    } catch (e) {
      notification.showError("Помилка", "Не вдалось зберегти коментар");
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    if (taskId && needInit && !isInit) {
      fetchComments();
      setInit(true);
    }
  }, [taskId, isInit, needInit]);

  useEffect(() => {
    if (isInit) setInit(false);
  }, [taskId]);

  const resetList = async () => await fetchComments();

  const deleteComment = async (comment: IComment) => {
    try {
      await taskCommentsApi.deleteComment(comment.id);
    } catch (e) {
    } finally {
      setComments(items => {
        const _items = [...items];
        return _items.filter(it => it.id !== comment.id);
      });
      setConfirmModal(null);
    }
  };

  const onPressDelete = async (comment: IComment) => {
    setConfirmModal({
      isShow: true,
      message: "Ви впевненні що хочете видалити коментар ?",
      onConfirm: () => deleteComment(comment)
    });
  };

  const onEdit = (comment: IComment) => {
    setEditableComment(comment);
    setNewComment((comment.content as any)?.message);

    appEvents.emit("onPressEditmessage", { message: null });
  };

  const onMessageActions = (commentId: number) => {
    const comment = taskComments.find(it => it.id === commentId);

    const options = [
      {
        key: "edit",
        label: "Редагувати",
        onClick: () => onEdit(comment)
      },
      {
        key: "delete",
        label: "Видалити",
        onClick: () => onPressDelete(comment)
      }
    ];

    appEvents.emit("openMessageMenuOptions", {
      items: comment.authorId === account.id ? (options as any) : []
    });
  };

  const cancelEdit = () => {
    setNewComment("");
    setEditableComment(null);
  };

  const onNewComment = (data: SocketEvents["task/new-comment"]) => {
    if (data.taskId.toString() !== taskId.toString()) return;
    if (_.find(taskComments, it => it.id === data.commentId)) return;

    resetList();
  };

  const onCommentUpdated = (data: SocketEvents["task/update-comment"]) => {
    if (!data.comment) return;

    const newComments = taskComments.map(it => {
      if (it.id.toString() === data.comment.id.toString())
        return {
          ...it,
          text: data.comment.content,
          content: {
            message: data.comment.content
          }
        };

      return it;
    });

    setComments(newComments);
  };

  const onCommentDeleted = (data: SocketEvents["task/delete-comment"]) => {
    const newComments = taskComments.filter(
      it => it.id.toString() !== data.commentId.toString()
    );
    setComments(newComments);
  };

  const onCommentsRead = (data: SocketEvents["task/read-comments"]) => {
    if (data.taskId.toString() !== taskId.toString()) return;

    const newComments = taskComments.map(it => ({
      ...it,
      read: true
    }));

    setComments(newComments);
  };

  useSocketListener("task/new-comment", onNewComment, [taskId, taskComments]);

  useSocketListener("task/update-comment", onCommentUpdated, [
    taskComments,
    transformComments
  ]);

  useSocketListener("task/delete-comment", onCommentDeleted, [taskComments]);

  useSocketListener("task/read-comments", onCommentsRead, [
    taskId,
    taskComments
  ]);

  return {
    taskComments,
    addComment,
    resetList,
    newComment,
    setNewComment,
    isSending,
    onMessageActions,
    confirmModal,
    hideConfirmModal: () => setConfirmModal(null),
    editableComment,
    clearEditableComment: cancelEdit
  };
};
