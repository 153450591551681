import { createFullName, hasImageUrl, IComment } from "@/shared";
import _ from "lodash";

const checkIsRead = (item: IComment, accountId: number) => {
  if (item.authorId !== accountId) return true;

  return _.some(item.readByUsersId, it => it !== accountId);
};

export const transformComment = (item: IComment, accountId: number) => {
  return {
    id: item.id,
    text: item.content,
    createdAt: item.createdAt,
    authorId: item.authorId,
    author: {
      id: item.authorId,
      name:
        item.authorId !== accountId
          ? createFullName(item.author?.firstName, item.author?.lastName)
          : "",
      avatar:
        item.authorId !== accountId
          ? hasImageUrl(
              item?.author?.avatarUrl,
              createFullName(item.author?.firstName, item.author?.lastName)
            )
          : null
    },
    sent: true,
    read: checkIsRead(item, accountId),
    content: {
      message: item.content
    }
  };
};

export const transformComments = (comments: IComment[], accountId: number) => {
  return comments
    .filter(comment => _.isString(comment.content))
    .map(it => transformComment(it, accountId));
};
