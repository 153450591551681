import React, { FC } from "react";
import { Col, Container, Row } from "reactstrap";
import "./style.scss";
import { ContactMain, ContactExternal } from "./components";
import { useLocation } from "react-router-dom";
import { useContact } from "./hooks/use-contact.hook";

export const ContactPage: FC = () => {
  const location: any = useLocation();

  const contact = location.state?.contactinfo;

  const { contactInfo } = useContact(contact?.userId);

  return contactInfo ? (
    <Container>
      <div className="contact">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ContactMain contact={contactInfo} />
            </Row>
          </Col>
          <Col md={12} lg={12} xl={8}>
            <Row>
              <ContactExternal contact={contactInfo} />
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
};
