import ModalComponent from "@/components/Modal";
import React, { FC, useEffect, useMemo, useState } from "react";
import { TabContent } from "reactstrap";
import { ModalTabs } from "./modal-tabs.component";
import { ETaskModalMode, ETasksModalTabs } from "../enums";
import { ModalTabMainInfo } from "./modal-tab-main-info.component";
import { ModalTabDocument } from "./modal-tab-document.component";
import { useCreateUpdateTask } from "../hooks";
import { ITaskModalState } from "../interfaces";
import { checkPermission, ETaskStatus, ITask, Permissions } from "@/shared";
import { ModalTabDetails } from "./modal-tab-details.component";
import { ModalTabComments } from "./modal-tab-comments.component";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";
import { getMyPermissions } from "@/store/permissions";

interface IProps {
  state: ITaskModalState;
  setOpen: (val: boolean) => void;
  onClose: () => void;
  onSubmitData?: () => void;
  selectedTask?: ITask;
}

export const CreateUpdateTaskModal: FC<IProps> = ({
  state,
  setOpen,
  onSubmitData,
  selectedTask,
  onClose,
}) => {
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const [activeTab, setTab] = useState<ETasksModalTabs>(ETasksModalTabs.Tasks);

  const {
    form,
    submit,
    attachments,
    addAttachment,
    deleteAttachment,
    setCopyDocumentsIds,
    isSaving,
  } = useCreateUpdateTask({
    onSubmitData,
    taskData: state.isOpen ? selectedTask : null,
    mode: state.mode,
  });

  useEffect(() => {
    if (state.isOpen) {
      form.clearErrors();
      if (state.mode === ETaskModalMode.Watch)
        setTab(state.tab || ETasksModalTabs.TaskDetails);
      else setTab(ETasksModalTabs.Tasks);
    }
  }, [state]);

  const title = useMemo(() => {
    if (state.mode === ETaskModalMode.Create) return "Нова задача";

    if (state.mode === ETaskModalMode.Copy)
      return `Копіювати задачу № ${selectedTask?.id}`;

    return `Задача № ${selectedTask?.id}`;
  }, [state.mode, selectedTask]);

  const isDisabledDocsTab =
    selectedTask && selectedTask?.status !== ETaskStatus.Active;

  return (
    <ModalComponent
      title={title}
      show={state.isOpen}
      toggle={() => {
        setOpen(false);
        onClose();
        if (state.mode === ETaskModalMode.Update) form.reset();
        setTab(ETasksModalTabs.Tasks);
      }}
    >
      <ModalTabs type={state.mode} activeTab={activeTab} onToggleTab={setTab} />

      <TabContent activeTab={activeTab}>
        <ModalTabDetails taskId={selectedTask?.id} />

        <ModalTabMainInfo
          mode={state.mode}
          control={form.control}
          form={form}
          onSubmit={submit}
          disableBtn={isSaving}
        />

        <ModalTabComments
          taskId={selectedTask?.id}
          isActive={activeTab === ETasksModalTabs.Comments}
          disabled={selectedTask?.status !== ETaskStatus.Active}
        />

        <ModalTabDocument
          newFiles={attachments}
          onSelect={addAttachment}
          onDelete={deleteAttachment}
          isActive={activeTab === ETasksModalTabs.Docs}
          taskId={selectedTask?.id}
          disabled={isDisabledDocsTab}
          mode={state.mode}
          onTaskFilesChange={setCopyDocumentsIds}
        />
      </TabContent>
    </ModalComponent>
  );
};
