import http from "../http.service";
import * as Res from "./responses.interfaces";

const fetchFilesLimitsConfig = () => {
  return http.get<Res.IGetFilesConfigRes>("common/configs/files-config");
};

export const configsApi = {
  fetchFilesLimitsConfig
};
