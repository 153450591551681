import React, { FC, useRef, useState } from "react";
import { Select, SelectProps } from "antd";
import "./styles.scss";
import { Option } from "@/shared/interfaces";

export interface SelectFieldProps extends SelectProps {
  value: any;
  options: Option[] | any;
  label: string;
  type?: "default" | "loadMore";
  placeholder?: string;
  isDisabled?: boolean;
  defaultValue?: string | Option | Option[];
  touched?: boolean;
  error?: string;
  loadMore?: () => void;
  onChange: (val: string) => void;
  input?: any;
}

export const SelectField: FC<SelectFieldProps> = ({
  value,
  options,
  label,
  type = "default",
  touched,
  error,
  loadMore,
  input,
  ...selectProp
}) => {
  const loadMoreOption: Option = {
    title: "Загрузити ще",
    value: "load-more-option",
  };

  const optionsToRender: Record<string, Option[]> = {
    default: options,
    loadMore: [...options, loadMoreOption],
  };

  return (
    <div className="form__form-group select-field select-field_custom">
      {label ? <div className="label">{label}</div> : null}

      <Select
        value={value || input?.value}
        showSearch
        {...selectProp}
        {...input}
        className="select"
        optionFilterProp="children"
        disabled={selectProp.isDisabled}
        allowClear
        filterOption={(input, option) =>
          ((option!.children as unknown) as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {optionsToRender[type].map((item, index) => (
          <Select.Option
            key={item.value + "-" + index}
            value={item.value}
            disabled={item.value === "load-more-option"}
          >
            {item.value === "load-more-option" ? (
              <div
                onClick={loadMore}
                style={{ cursor: "default", color: "#000", fontWeight: "bold" }}
              >
                {item.title}
              </div>
            ) : (
              item.title
            )}
          </Select.Option>
        ))}
      </Select>

      {touched && error && <span className="error">{error}</span>}
    </div>
  );
};
