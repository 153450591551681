import { ApiResponse } from "../http.types";
import * as req from "./requests.interfaces";
import http from "../http.service";
import { ITaskDocument } from "@/shared";

const addTaskDocuments = async (
  data: req.AddTaskDocumentsParams
): ApiResponse<void> => {
  return http.put<void>("admin/tasks-documents", createFormData(data), null);
};

const getTaskDocuments = async (
  taskId: number
): ApiResponse<ITaskDocument[]> => {
  return http.get<ITaskDocument[]>(`admin/tasks-documents/${taskId}`, {});
};

export const removeTaskDocument = async (
  documentId: number
): ApiResponse<void> => {
  return http.delete(`admin/tasks-documents/${documentId}`);
};

export const tasksDocumentsApi = {
  addTaskDocuments,
  getTaskDocuments,
  removeTaskDocument
};

const createFormData = (data: req.AddTaskDocumentsParams) => {
  const formData = new FormData();
  formData.append("taskId", data.taskId.toString());
  data.files.map(it => formData.append("files", it as any));
  return formData;
};
