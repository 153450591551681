import React from "react";
import { IconComponent } from "./icon.component";

import checkIcon from "@/assets/img/check_1.svg";

export const CheckIndicator = () => {
  return (
    <div className="bg-check-indicator">
      <IconComponent name={checkIcon} />
    </div>
  );
};
