import { IColumn } from "@/shared";
import { voidColumn } from "../configs";
import { IRenderColumnsProps } from "../interfaces";
import { headerRender } from "./header-render";

export const columnsRender = (props: IRenderColumnsProps) => {
  const preparedColumns = props.columnsSort
    .map((key) => {
      const index = props.columns.findIndex((it) => it.key === key);
      return {
        ...props.columns[index],
        width:
          props.columnsWidth[props.columns[index]?.key] ||
          props.columns[index]?.width,
        headerCellClass: "rdg-header-sort-name",
        headerRenderer: (item: { column: IColumn }) =>
          headerRender(item, props),
      };
    })
    .filter((item) => props.columnsToShow.indexOf(item.key) > -1);

  if (
    preparedColumns.length === 1 &&
    preparedColumns.map((it) => it.key).includes("select-row")
  )
    return [...preparedColumns, voidColumn];
  if (
    props.tableName === "tasks" &&
    preparedColumns.map((it) => it.key).includes("select-row")
  ) {
    return preparedColumns.length <= 5 &&
      preparedColumns.length < props.columns.length - 2
      ? [...preparedColumns, { width: null }]
      : preparedColumns;
  } else if (
    preparedColumns.length < props.columns.length - 2 &&
    preparedColumns.map((it) => it.key).includes("select-row")
  ) {
    return [...preparedColumns, { width: null }];
  } else if (preparedColumns.length < props.columns.length - 2) {
    return [...preparedColumns, { width: null }];
  }

  return preparedColumns.length ? preparedColumns : [voidColumn];
};
