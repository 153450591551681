import { IconComponent } from "@/shared";
import React, { FC, useEffect } from "react";
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player";

import playIcon from "@/assets/img/play-1-icon.svg";
import pauseIcon from "@/assets/img/pause-icon.svg";
import { AudioBar } from "./audio-bar.atom";
import "./style.scss";
import _ from "lodash";
import { useChatViewModeState } from "../hooks";
import { ChatViewModeEnum } from "../enums";

export const PlayBar = () => {
  const { position, duration } = useAudioPosition({ highRefreshRate: true });
  // const [percent, setPercent] = React.useState(0);

  // React.useEffect(() => {
  //   setPercent((position / duration) * 100 || 0);
  // }, [position, duration]);

  return (
    <AudioBar duration={duration} curTime={position} onTimeUpdate={_.noop} />
  );
};

interface IAudioPlayerProps {
  fileUrl: string;
  fileId: number;
  isMy: boolean;
  activeAudioId: number;
  onPressPlay?: () => void;
}

export const AudioPlayer: FC<IAudioPlayerProps> = ({
  fileUrl,
  fileId,
  isMy,
  activeAudioId,
  onPressPlay,
}) => {
  const viewMode = useChatViewModeState((s) => s.mode);

  useEffect(() => {
    if (activeAudioId !== fileId && playing) player.pause();
  }, [activeAudioId]);

  useEffect(() => {
    if (viewMode === ChatViewModeEnum.SELECT && playing) player.pause();
  }, [viewMode]);

  const { togglePlayPause, ready, loading, playing, player } = useAudioPlayer({
    src: fileUrl,
    format: "mp4",
    autoplay: false,
    onpause: () => console.log("sound has paused!"),
    onplay: () => console.log("sound started play!"),
  });

  if (!ready && !loading) return <div>No audio to play</div>;
  if (loading) return <div>Loading audio</div>;

  const actionHandler = async () => {
    if (viewMode === ChatViewModeEnum.SELECT) return;

    if (onPressPlay) onPressPlay();
    await togglePlayPause();
  };

  return (
    <div className="player">
      <div className="audio-controls">
        <button
          className={
            "player__button " + (isMy ? "my-sound-icon" : "sound-icon")
          }
          onClick={actionHandler}
        >
          <IconComponent name={playing ? pauseIcon : playIcon} />
        </button>
        <PlayBar />
      </div>
    </div>
  );
};
