import { chatsApi } from "@/api";
import {
  ICreateGroupChat,
  ICreatePersonalChat,
  IEditGroupChat,
  IFetchChatDetail,
  IFetchChatsParams,
  ISetChatMuted,
} from "@/api/chats/requests.interfaces";
import {
  IFetchChatList,
  NewChatGroupId,
} from "@/api/chats/responses.interfaces";
import { IRequestConfig } from "@/api/http.service";
import { ApiResponse } from "@/api/http.types";
import { IChat, IChatDetails } from "@/shared";
import { appEvents } from "@/shared/events";
import { SetChats } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";

// interface IFetchTasksPayload extends IFetchTasksParams {}
// interface IFetchTasksCountPayload extends IFetchTasksCountParams {}

interface IFetchChatsPayload extends IFetchChatsParams {}

const fetchChats = async (
  params: IRequestConfig
): ApiResponse<IFetchChatList> => {
  try {
    const chats = await chatsApi.getChatsListReq(params);

    simpleDispatch(new SetChats({ chats: chats.data.items }));

    return chats;
  } catch (error) {
    console.log("ERROR | FETCH CHATS: ", error);
  }
};

const fetchChatDetails = async (
  params: IFetchChatDetail
): Promise<IChatDetails> => {
  try {
    const { data: chatDetails } = await chatsApi.getChatDetailReq(params);
    return chatDetails;
  } catch (error) {
    console.log("ERROR | FETCH DETAILED CHAT: ", error);
    throw error;
  }
};

const deleteChatId = async (id: number): Promise<void> => {
  try {
    await chatsApi.deleteChatByIdReq(id);

    appEvents.emit("onDeleteChatForMe", { chatId: id });
  } catch (error) {
    console.log("ERROR | DELETE CHAT: ", error);
  }
};

const setChatMuted = async (data: ISetChatMuted): Promise<void> => {
  try {
    await chatsApi.setChatMutedReq(data);
  } catch (error) {
    console.log("ERROR | SET CHAT NOTIFICATION MUTED: ", error);
  }
};

const editGroupChatName = async (
  chatId: number,
  newTitle: string
): Promise<void> => {
  try {
    await chatsApi.editGroupChatReq(chatId, { name: newTitle });
    console.log("EDIT CHAT NAME Api is called with:", newTitle);
  } catch (error) {
    console.log("ERROR | SET NEW CHAT NAME ", error);
  }
};

const editGroupChatImg = async (
  chatId: number,
  previewImg: unknown
): Promise<void> => {
  try {
    await chatsApi.editGroupChatReq(chatId, { previewImg: previewImg });
    console.log("EDIT CHAT AVATAR api is called with:", previewImg);
  } catch (error) {
    console.log("ERROR | SET NEW CHAT AVATAR", error);
  }
};

const createPersonalChat = async (
  data: ICreatePersonalChat
): Promise<NewChatGroupId> => {
  const { data: groupId } = await chatsApi.createPersonalChatReq(data);

  return groupId;
};

const createGroupChat = async (
  params: ICreateGroupChat
): Promise<NewChatGroupId> => {
  const { data: groupId } = await chatsApi.createGroupChatReq(params);

  return groupId;
};

export const chatsService = {
  fetchChats,
  fetchChatDetails,
  deleteChatId,
  setChatMuted,
  editGroupChatName,
  editGroupChatImg,
  createPersonalChat,
  createGroupChat,
};
