import { IFilesConfig } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TSharedActions } from "./types";

export interface ISharedState {
  themeColor: "theme-light" | "theme-dark";
  isLoading: boolean;
  sidebar: {
    show: boolean;
    collapse: boolean;
  };
  filesConfig: IFilesConfig;
}

const initialState: ISharedState = {
  themeColor: "theme-light",
  isLoading: false,
  sidebar: {
    show: false,
    collapse: false
  },
  filesConfig: {
    avatarWidth: 200, // in px
    avatarHeight: 200, // in px
    avatarSize: 500, // in kb
    avatarTypes: "jpg,svg,png,webp",
    taskFilesSize: 100000, // in kb
    taskFilesTypes: "jpeg,jpg,png,svg,webp,tiff,pdf,txt,doc,docx,xls,xlsx",
    chatFilesSize: 50000, // in kb
    chatVideosSize: 100000 // in kb
  }
};

export const sharedReducer = createReducer<ISharedState, TSharedActions>(
  initialState,
  {
    CHANGE_THEME_TO_DARK: (state, action) => {
      return {
        ...state,
        themeColor: "theme-dark"
      };
    },

    CHANGE_THEME_TO_LIGHT: (state, action) => {
      return {
        ...state,
        themeColor: "theme-light"
      };
    },

    CHANGE_SIDEBAR_VISIBILITY: (state, action) => {
      return {
        ...state,
        ...state.sidebar,
        sidebar: {
          show: state.sidebar.show,
          collapse: !state.sidebar.collapse
        }
      };
    },

    CHANGE_MOBILE_SIDEBAR_VISIBILITY: (state, action) => {
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          show: !state.sidebar.show
        }
      };
    },

    RESET: () => {
      return {
        ...initialState
      };
    },

    SET_FILES_CONFIG: (state, action) => {
      return {
        ...state,
        filesConfig: {
          ...state.filesConfig,
          ...action.payload.config
        }
      };
    }
  }
);
