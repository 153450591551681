export const pageLimitConfig = [
   {
      value: 10
   },
   {
      value: 15
   },
   {
      value: 25
   },
   {
      value: 50
   },
   {
      value: 100
   },
   {
      value: 99999
   },
]