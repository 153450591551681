import { ChatMessageActionEnum } from "../enums";

interface IProps {
  onClick: (actionType: ChatMessageActionEnum) => void;
  canDeleteForAll?: boolean;
  canCopy?: boolean;
  canPin?: boolean;
  canUnpin?: boolean;
  canDownload?: boolean;
  canEdit?: boolean;
}

export const getChatMessageMenuOptions = ({
  canDeleteForAll,
  canCopy,
  onClick,
  canPin,
  canUnpin,
  canDownload,
  canEdit,
}: IProps) => {
  const menuOptions = {
    copy: {
      key: "copy",
      label: "Копіювати",
      onClick: () => onClick(ChatMessageActionEnum.COPY),
    },
    edit: {
      key: "edit",
      label: "Редагувати",
      onClick: () => onClick(ChatMessageActionEnum.EDIT),
    },
    forward: {
      key: "forward",
      label: "Переслати",
      onClick: () => onClick(ChatMessageActionEnum.FORWARD),
    },
    reply: {
      key: "reply",
      label: "Відповісти",
      onClick: () => onClick(ChatMessageActionEnum.REPLY),
    },
    pin: {
      key: "pin",
      label: "Закріпити",
      onClick: () => onClick(ChatMessageActionEnum.PIN),
    },
    unpin: {
      key: "unpin",
      label: "Відкріпити",
      onClick: () => onClick(ChatMessageActionEnum.UNPIN),
    },
    download: {
      key: "download",
      label: "Завантажити",
      onClick: () => onClick(ChatMessageActionEnum.DOWNLOAD),
    },
    delete: {
      key: "delete",
      label: "Видалити",
      onClick: () => onClick(ChatMessageActionEnum.DELETE),
    },
    deleteForAll: {
      key: "deleteForAll",
      label: "Видалити для всіх",
      onClick: () => onClick(ChatMessageActionEnum.DELETE_FOR_ALL),
    },
    select: {
      key: "select",
      label: "Вибрати",
      onClick: () => onClick(ChatMessageActionEnum.SELECT),
    },
  };

  const optionsKeys = ["forward", "reply"];

  if (canPin) optionsKeys.push("pin");
  if (canUnpin) optionsKeys.push("unpin");
  if (canDownload) optionsKeys.push("download");
  if (canCopy) optionsKeys.push("copy");
  optionsKeys.push("delete");
  if (canDeleteForAll) optionsKeys.push("deleteForAll");
  if (canEdit) optionsKeys.push("edit");
  optionsKeys.push("select");

  const options = optionsKeys.map((key) => menuOptions[key]);

  return options;
};
