import React, { Component } from "react";
import { Modal } from "reactstrap";
import classNames from "classnames";
class ModalComponent extends Component<any> {
  static defaultProps = {
    title: "",
    colored: false,
    header: true,
    color: "primary",
    full: false,
  };

  constructor(props) {
    super(props);
  }

  toggle = () => {
    this.props.toggle(false);
  };

  toggleIcon = () => {
    if (this.props.toggleIcon) {
      this.props.toggleIcon(false);
    } else {
      this.props.toggle(false);
    }
  };

  render() {
    const {
      color,
      show,
      title,
      colored,
      header,
      full,
      isChat,
      isDoc,
      webcam,
      document,
      onDownload,
      modalHeaderClass,
    } = this.props;

    const modalClass = classNames({
      "modal-dialog--colored": colored,
      "modal-dialog--header": header,
      "modal-dialog--webcam": webcam,
      "modal-dialog--document": document,
    });

    return (
      <Modal
        isOpen={show}
        toggle={this.toggle}
        onExiting={this.props?.onExiting}
        className={`modal-dialog--${color} ${modalClass}`}
        style={{ borderRadius: 25, overflow: "hidden" }}
      >
        <div
          className={classNames(["modal-header", modalHeaderClass])}
          style={{
            background: "rgba(158, 39, 67, 1)",
            width: "100%",
            borderBottom: "none",
            minHeight: 55,
          }}
        >
          <button
            className="lnr lnr-cross modal__close-btn"
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              top: 18,
              left: 20,
              borderRadius: 100,
              background: "rgba(255, 255, 255, 0.5)",
            }}
            type="button"
            onClick={this.toggleIcon}
          />
          <span
            className="bold-text modal__title"
            style={{
              fontSize: 16,
              fontWeight: "500",
              color: "white",
              // width: "100%",
              // maxWidth: "85%",
              textAlign: "center",
              marginLeft: 35,
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexGrow: 1,
              padding: `0 5px`,
            }}
          >
            {title}
          </span>

          {isChat && (
            <button
              className="modal__create-chat-btn"
              style={{ width: 104, height: 30, borderRadius: 10 }}
              type="button"
              title="Створити"
            ></button>
          )}

          {isDoc && (
            <button
              className="modal__download-doc-btn"
              style={{ width: 104, height: 30, borderRadius: 10 }}
              type="button"
              onClick={onDownload}
            >
              Зберегти
            </button>
          )}
        </div>

        {full ? (
          this.props.children
        ) : (
          <div className="modal__body">{this.props.children}</div>
        )}
      </Modal>
    );
  }
}

export default ModalComponent;
