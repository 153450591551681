import React, { FC } from "react";
import moment from "moment";
import { Card, CardBody, Col } from "reactstrap";
import { ContactField, IUser } from "@/shared";

import PhoneIcon from "../../../assets/img/phone-icon.svg";
import HBIcon from "../../../assets/img/hb-icon.svg";
import MailIcon from "../../../assets/img/mail-icon.svg";
import "./style.scss";

interface IProps {
  contact: IUser;
}

export const ContactExternal: FC<IProps> = ({ contact }) => {
  return (
    <>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="contact__card">
            <div
              className="contact__information"
              style={{ border: "none", padding: 0 }}
            >
              <div className="row row-contact-user">
                <div className="col-md-6" style={{ paddingLeft: 0 }}>
                  <ContactField
                    label={"Дата народження"}
                    value={
                      contact.info?.dateOfBirth
                        ? moment(contact.info.dateOfBirth).format("DD MMMM")
                        : null
                    }
                    placeholder={"Дата народження"}
                    onChange={() => {}}
                    svgIcon={HBIcon}
                  />
                </div>

                <div className="col-md-6" style={{ paddingLeft: 0 }}>
                  <ContactField
                    label={"Тел.робочий"}
                    value={contact.phoneNumber}
                    placeholder={"Тел.робочий"}
                    onChange={() => {}}
                    svgIcon={PhoneIcon}
                    phone={true}
                  />
                </div>
              </div>
              <div className="row row-profile-user">
                <div className="col-md-6" style={{ paddingLeft: 0 }}>
                  <ContactField
                    label={"Тел.особистий"}
                    value={contact.info.personalPhoneNumber}
                    placeholder={"Тел.особистий"}
                    onChange={() => {}}
                    svgIcon={PhoneIcon}
                    phone={true}
                  />
                </div>
                <div className="col-md-6" style={{ paddingLeft: 0 }}>
                  <ContactField
                    label={"Тел.внутрішній"}
                    value={contact.info.innerPhoneNumber}
                    placeholder={"Тел.внутрішній"}
                    onChange={() => {}}
                    svgIcon={PhoneIcon}
                  />
                </div>
              </div>
              <div className="row row-profile-user">
                <div
                  className="col-md-6 email-container"
                  style={{ paddingLeft: 0, marginBottom: 35 }}
                >
                  <ContactField
                    label={"Email"}
                    value={contact.email}
                    placeholder={"Email"}
                    onChange={() => {}}
                    svgIcon={MailIcon}
                    onClick={() => window.open(`mailto:${contact.email}`)}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
