import React, { ChangeEvent, FC } from "react";
import { Input } from "antd";
import "./styles.scss";
import { TextAreaProps } from "antd/lib/input";

const { TextArea } = Input;

interface IProps {
  label?: string;
  placeholder: string;
  value: string;
  error?: string;
  onChange: (str: string) => void;
  textAreaProps: TextAreaProps;
}

export const TextField: FC<IProps> = ({
  label,
  value,
  placeholder,
  error,
  onChange,
  textAreaProps,
}) => {
  return (
    <div className="form__form-group text-field">
      {label && <span className="label">{label}</span>}
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <TextArea
            value={value}
            placeholder={placeholder}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              onChange(event.currentTarget.value)
            }
            {...textAreaProps}
          />
          {error && <span className="error">{error}</span>}
        </div>
      </div>
    </div>
  );
};
