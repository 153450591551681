import React from "react";
import { voidColumn } from "@/components/TableGrid/configs";
import { useHistory } from "react-router-dom";
import { createFullName, EUserStatus, IUser } from "@/shared";
import moment from "moment";
import _ from "lodash";

export const usersTableColumns = () => {
  const history = useHistory();
  const onPressName = (row, e) => {
    if (e.detail === 2) {
      history.push(`/user_detail/${row.id}`, { contactinfo: row });
    }
  };
  const result: any = [
    voidColumn,
    {
      name: "Аватар",
      key: "avatarUrl",
      filter: false,
      // width: 45,
      width: 65,
      maxWidth: 75,
      formatter: ({ row }) => {
        if (_.isEmpty(row)) return null;

        return (
          <img
            style={{
              height: 40,
              width: 40,
              borderRadius: 100,
              objectFit: "fill",
            }}
            src={
              row.info.avatarUrl ||
              `${process.env.PUBLIC_URL}/img/default-avatar.jpg`
            }
            alt={row.info?.avatarUrl ? "Avatar" : "Default avatar"}
          />
        );
      },
    },
    {
      name: "П.І.Б.",
      key: "name",
      resizable: true,
      sortable: true,
      sortKey: "lastName",
      width: 160,
      filter: true,
      filterType: "search",
      formatter: ({ row }: { row: IUser }) => {
        return (
          <div className="column-name" onClick={(e) => onPressName(row, e)}>
            <div className="info">
              <div className="ellipsis">
                <span
                  className={
                    row.status === EUserStatus.Active
                      ? "full-name"
                      : "full-name-blocked"
                  }
                >
                  {createFullName(
                    row.info?.firstName,
                    row.info?.middleName,
                    row.info?.lastName
                  )}
                </span>
              </div>
              <span
                className={`${
                  row.status === EUserStatus.Active
                    ? "position"
                    : "blocked-position"
                } ellipsis`}
              >
                {row.info?.position}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      name: "Логін",
      key: "login",
      resizable: true,
      sortable: true,
      width: 100,
      cellClass: (row: IUser) => {
        if (row.status === EUserStatus.Active) return "secondary-text";
        return "blocked-text";
      },
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span>{row?.login}</span>
          </div>
        );
      },
    },
    {
      name: "Email",
      dataIndex: "email",
      key: "email",
      cellClass: (row: IUser) => {
        if (row.status === EUserStatus.Active) return "";
        return "blocked-text";
      },
      width: 246,
      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span>{row?.email}</span>
          </div>
        );
      },
    },
    {
      name: "Статус",
      key: "status",
      minWidth: 150,
      resizable: true,
      sortable: true,
      cellClass: (row: IUser) => {
        if (row.status === EUserStatus.Active) return "secondary-text";
        return "blocked-text";
      },
      filter: true,
      filterType: "userStatus",
      formatter: ({ row }) => {
        if (_.isEmpty(row)) return null;
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span>
              {row.status === EUserStatus.Active ? "Активний" : "Заблоковано"}
            </span>
          </div>
        );
      },
    },

    {
      name: "Підприємство",
      key: "factory",
      resizable: true,
      width: 130,
      sortable: true,
      filter: true,
      cellClass: (row: IUser) => {
        if (row.status === EUserStatus.Active) return "secondary-text";
        return "blocked-text";
      },
      filterType: "search",
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span>{row?.factoryName || ""}</span>
          </div>
        );
      },
    },
    {
      name: "Вхід на десктоп",
      dataIndex: "loginOnDesktopDate",
      key: "loginOnDesktopDate",
      resizable: true,
      sortable: true,
      minWidth: 150,
      filter: true,
      filterType: "datePicker",
      formatter: ({ row }: { row: IUser }) => {
        if (_.isEmpty(row)) return null;
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span
              className={
                row.status === EUserStatus.Active ? "" : "blocked-text"
              }
            >
              {row.loginOnDesktopDate
                ? moment(row.loginOnDesktopDate).format("DD-MM-YYYY, HH:mm")
                : "Ніколи"}
            </span>
          </div>
        );
      },
    },
    {
      name: "Вхід в додаток",
      dataIndex: "loginOnMobileDate",
      key: "loginOnMobileDate",
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: 150,
      filterType: "datePicker",
      formatter: ({ row }: { row: IUser }) => {
        if (_.isEmpty(row)) return null;
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span
              className={
                row.status === EUserStatus.Active ? "" : "blocked-text"
              }
            >
              {row.loginOnMobileDate
                ? moment(row.loginOnMobileDate).format("DD-MM-YYYY, HH:mm")
                : "Ніколи"}
            </span>
          </div>
        );
      },
    },
  ];
  return result;
};
