export enum NotificationsGroup {
  /** Сповіщення, що відносяться до задач */
  Tasks = "t",

  /** Сповіщення, що відносяться до чатів */
  Chats = "c",

  /** Сповіщення, що не відносяться до жодної з груп */
  Other = "oth",

  /** Кастомні сповіщення створенні вручну */
  Custom = "cus",
}

export enum NotificationKeys {
  NEW_MESSAGE = "newMessage",
  NEW_TASK = "newTask",
  NEW_CHAT_MEMBER = "newChatMembers",
  TODAY_BIRTHDAY = "todayBirthday",
  NEW_TASK_COMMENT = "newTaskComment",
  NEW_TASK_FILE = "newTaskFile",
}
