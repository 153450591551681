import { permissionsApi } from "@/api";
import { IFactoryShortInfo, IUserPermissions, IUserShortInfo } from "@/shared";
import { getProfile } from "@/store/account";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { transformPermissionsToInfo } from "../../transforms";

interface IPermissionsInfoProps {
  userId: number;
}

export const PermissionsInfo: FC<IPermissionsInfoProps> = ({ userId }) => {
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>(
    null
  );
  const [users, setUsers] = useState<IUserShortInfo[]>(null);
  const [factories, setFactories] = useState<IFactoryShortInfo[]>(null);
  const profile = useSelector(getProfile);

  const fetchUserPermissions = async () => {
    let permissions;
    try {
      if (userId === profile.id) {
        const {
          data: myPermissions,
        } = await permissionsApi.fetchMyPermissionsOriginal();
        permissions = myPermissions;
      } else {
        const {
          data: userPermissions,
        } = await permissionsApi.fetchUserPermissionsWithInfo(userId, {
          withExtendedInfo: true,
        });
        permissions = userPermissions;
      }

      if (permissions) {
        setUserPermissions(JSON.parse(permissions.permissions));
        if (permissions.users) setUsers(permissions.users);
        if (permissions.factories) setFactories(permissions.factories);
      }
    } catch (e) {
      console.log("GET MY PERMISSIONS ERROR", e);
    }
  };

  useEffect(() => {
    if (userId) fetchUserPermissions();
  }, [userId]);

  const permissionsToRender = useMemo(
    () =>
      transformPermissionsToInfo({
        permissions: userPermissions,
        users,
        factories,
      }),
    [userPermissions, users, factories]
  );

  return (
    <div className="permissions-info">
      {_.isEmpty(permissionsToRender) && <div style={{ height: 150 }}></div>}
      {permissionsToRender.map((it) => (
        <p key={it}>{it}</p>
      ))}
    </div>
  );
};
