/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Select } from "antd";

const Option = Select.Option;

class SelectMultyField extends Component<any> {
  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    } else {
      this.props.input.onChange(typeof value == "undefined" ? null : value);
    }
  };

  render() {
    const {
      input,
      placeholder,
      options,
      label,
      meta: { touched, error },
    } = this.props;
    return (
      <div className="form__form-group select-field">
        {label ? <span className="form__form-group-label">{label}</span> : null}
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <div style={{ position: "relative" }}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={placeholder}
                optionFilterProp="children"
                {...input}
                mode="multiple"
                onChange={this.onChange}
                allowClear
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {options.map((item, index) => (
                  <Option key={input.name + "-" + index} value={item.value}>
                    {item.title}
                  </Option>
                ))}
              </Select>
              {!input.value || !input.value.length ? (
                <span
                  className="ant-select-arrow"
                  style={{ outline: "currentcolor none medium" }}
                >
                  <i
                    aria-label="icon: down"
                    className="anticon anticon-down ant-select-arrow-icon"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      className=""
                      data-icon="down"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                    </svg>
                  </i>
                </span>
              ) : null}
            </div>

            {touched && error && (
              <span className="form__form-group-error">{error}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectMultyField;
