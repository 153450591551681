import _ from "lodash";
import { IFilesConfig } from "../interfaces";

export const transformFilesLimitsConfig = (
  data: Partial<Record<keyof IFilesConfig, string>>
) => {
  const config: Partial<IFilesConfig> = {};

  if (_.isEmpty(data)) return config;

  Object.keys(data).map(it => {
    if (!_.isNaN(Number(data[it]))) config[it] = Number(data[it]);
    else config[it] = data[it].toLowerCase();
  });
  return config;
};
