export enum Permissions {
  FIND = "find",
  CREATE = "create",
  UPDATE = "update",
  DESTROY = "destroy",
  HARD_DELETE = "hard_delete",
  NOT_PERMISSION = "not_permission",
  PERMISSIONS = "permissions",
  LOGOUT = "logout",
  BAN = "ban",
}

export enum PermissionsTabs {
  FACTORY = "factory",
  USER = "user",
  TABS = "tabs",
  GROUP_PERMISSION = "group_permission",
  TAXONOMY = "taxonomy",
}
