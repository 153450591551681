import { notification as antdNotification } from "antd";

const showSuccess = (header: string, description: string) => {
  const args = {
    message: header,
    description,
    className: "success-notification"
  };
  antdNotification.open(args);
};

const showError = (header: string, description: string) => {
  const args = {
    message: header,
    description,
    className: "error-notification"
  };
  antdNotification.open(args);
};

export const notification = {
  showSuccess,
  showError
};
