import { EUserRole, EUserStatus } from "@/shared";
import _ from "lodash";
import { IUser } from "../shared/interfaces/user.interfaces";

export function genTree(
  array: Array<any>,
  parent?: any,
  tree?: any,
  field?: any
) {
  tree = typeof tree !== "undefined" ? tree : [];
  parent = typeof parent !== "undefined" ? parent : { id: null };
  field = typeof field !== "undefined" ? field : "parentId";

  const children = _.filter(array, (child) => child[field] == parent.id);

  if (!_.isEmpty(children)) {
    if (parent.id == null) {
      tree = children;
    } else {
      parent["children"] = children;
    }
    _.each(children, function(child) {
      genTree(array, child, undefined, field);
    });
  }

  return tree;
}

export function disableRouter(exclude, url) {
  let routes = url.split("/").filter((item) => !!item);

  if (!routes.length) {
    return false;
  }

  let exclude_arr = exclude.filter((item) => item.indexOf(routes[0]) > -1);

  let result = true;

  exclude_arr.some((item) => {
    if (validRouter(item, routes)) {
      result = false;
      return true;
    }
  });

  function validRouter(data, routes) {
    let data_array = data.split("/").filter((item) => !!item);

    if (data_array.length != routes.length) {
      return false;
    }

    return !routes.some((item, index) => {
      if (data_array[index] == "{integer}") {
        return isNaN(parseInt(item));
      } else if (data_array[index] == "{*}") {
        return false;
      } else {
        return data_array[index] != item;
      }
    });
  }

  return result;
}

export function getImageThumb(image, thumb) {
  if (!thumb) {
    return image;
  }
  if (!image) {
    return image;
  }

  let img = image.split("/")[image.split("/").length - 1];

  if (!img || img.split(".").length != 2) {
    return image;
  }

  let thumb_name = img.split(".")[0] + "_" + thumb + "." + img.split(".")[1];

  return image.replace(img, thumb_name);
}

export const getPermissionCheck = (
  controller: any,
  action: any,
  user: IUser
) => {
  if (user.status !== EUserStatus.Active) {
    return false
  }

  if (user.role === EUserRole.Admin) {
    return true;
  }

  if (user.role === EUserRole.User) {
    return false;
  }

  const permissions = user.permissions.tabs;

  //@ts-ignore
  const index_controller = _.findIndex(permissions, ["tabs", controller]);

  if (index_controller < 0) {
    return false;
  }

  let actions = permissions[index_controller].actions || [];

  if (actions.indexOf(action) < 0) {
    return false;
  }

  return true;
};
