import { chatsService } from "@/services/domain";
import { IChat, IChatInList, usePaginationList } from "@/shared";
import { useEffect, useMemo, useState } from "react";
import { transformChatToSelectListItem } from "../transforms";

export const useSelectedChats = () => {
  const [selectedChats, setSelectedChats] = useState<IChatInList[]>([]);
  const [searchString, setSearchString] = useState<string>("");

  const { items: chats, loadMore, setLoadParams } = usePaginationList<IChat>({
    fetchItems: params => chatsService.fetchChats(params)
  });

  const transformedChats = useMemo(
    () =>
      chats?.map(item => transformChatToSelectListItem(item, selectedChats)),
    [selectedChats, chats]
  );

  const selectAll = () => setSelectedChats(transformedChats);

  const selectChat = (id: number) => {
    setSelectedChats(state => {
      const chat = transformedChats.find(item => item.id === id);
      if (chat) return [...state, chat];

      return state;
    });
  };

  const unselectChat = (id: number) => {
    setSelectedChats(state => state.filter(it => it.id !== id));
  };

  useEffect(() => {
    if (searchString || searchString === "")
      setLoadParams({ params: { searchString } });
  }, [searchString]);

  return {
    chats: transformedChats,
    selectedChats,
    selectAll,
    selectChat,
    unselectChat,
    searchString,
    setSearchString,
    loadMore
  };
};
