import React, { FC } from "react";

interface AudioBarProps {
  duration: any;
  curTime: any;
  onTimeUpdate: any;
}

export const AudioBar: FC<AudioBarProps> = ({
  duration,
  curTime,
  onTimeUpdate,
}) => {
  const curPercentage = (curTime / duration) * 100;

  // function formatDuration(duration) {
  //   return moment.duration(duration, "seconds");
  // }

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar: HTMLElement = document.querySelector(".bar__progress");
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  return (
    <div className="bar">
      <div
        className="bar__progress"
        style={{
          background: `linear-gradient(to right, #9E2743 ${curPercentage}%, rgba(158, 39, 67, 0.1) 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className="bar__progress__knob"
          style={{ left: `${curPercentage}%` }}
        />
      </div>
    </div>
  );
};
