import React, { FC } from "react";
import { Button as AntdBtn } from "antd";

interface ButtonProps {
  children: string;
  style?: React.CSSProperties;
  onClick: () => void;
}

export const Button: FC<ButtonProps> = ({ children, style, onClick }) => {
  return (
    <AntdBtn
      onClick={onClick}
      size="large"
      style={{
        border: "none",
        borderRadius: 10,
        background: "rgba(158, 39, 67, 0.1)",
        ...style,
      }}
    >
      <span
        style={{ fontWeight: 500, fontSize: 16, color: "rgba(158, 39, 67, 1)" }}
      >
        {children}
      </span>
    </AntdBtn>
  );
};
