import React, { FC } from "react";
import "./style.scss";
import magnifyingGlassSVGIcon from "../../../assets/img/magnifyingGlass-icon.svg";

interface SearchInputProps {
  value: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange: (val: string) => void;
}

export const SearchInput: FC<SearchInputProps> = ({
  value,
  placeholder,
  style,
  onChange,
}) => {
  return (
    <div className="search-input" style={style}>
      <img src={magnifyingGlassSVGIcon} />

      <input
        value={value}
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
      />

      {value && (
        <button onClick={() => onChange("")}>
          <i className="fa fa-times-circle clear" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};
