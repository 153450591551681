import { TreeSelectField } from "@/components/Fields";
import { IContactsFilter } from "@/shared";
import { simpleDispatch } from "@/store/store-helpers";
import { getContactsFilter, SetContactsFilter } from "@/store/users";
import React from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import "./style.scss";
interface IProps {
  factoriesTree: any;
}

const FilterContacts: FC<IProps> = ({ factoriesTree }) => {
  const filter = useSelector(getContactsFilter);

  const onChange = (key: keyof IContactsFilter, value: any) =>
    simpleDispatch(new SetContactsFilter({ ...filter, [key]: value }));

  return (
    <div className="filter-task">
      <div className="filter">
        <Field
          name="factoryId"
          component={TreeSelectField}
          placeholder="Підприємство"
          label="Підприємство"
          hideIcon
          value_field="id"
          tree={factoriesTree}
          register={{
            value: filter.factoryId,
            onChange: (val: string) => onChange("factoryId", val),
          }}
        />
      </div>
    </div>
  );
};

export default reduxForm({
  form: "filter_contacts",
})(FilterContacts);
