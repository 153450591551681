import React from "react";

export const EyeIcon = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.257 16.962C27.731 17.582 27.731 18.419 27.257 19.038C25.764 20.987 22.182 25 18 25C13.818 25 10.236 20.987 8.74301 19.038C8.51239 18.7411 8.38721 18.3759 8.38721 18C8.38721 17.6241 8.51239 17.2589 8.74301 16.962C10.236 15.013 13.818 11 18 11C22.182 11 25.764 15.013 27.257 16.962V16.962Z"
          stroke="#9E2743"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
          stroke="#9E2743"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
