import React, { FC, useMemo } from "react";
import { getStatsConfig } from "./count-user.config";

interface IProps {
  userId: number;
  groupCount: number;
  taskCount: number;
  commentCount: number;
}

export const CountUser: FC<IProps> = ({
  userId,
  groupCount,
  taskCount,
  commentCount,
}) => {
  const renderProfileStats = useMemo(() => {
    const statsConfig = getStatsConfig(groupCount, taskCount, commentCount);

    const profileStatsToRender = statsConfig.map((it) => (
      <div className="profile__stat" key={it.label}>
        <p className="profile__stat-number">{it.value}</p>
        <p className="profile__stat-title">{it.label}</p>
      </div>
    ));

    return profileStatsToRender;
  }, [groupCount, taskCount, commentCount]);

  return (
    <div>
      <p
        style={
          {
            // textAlign: "center",
            // borderBottom: "1px solid #eff1f5",
            // fontWeight: 900,
            // lineHeight: "18px",
            // paddingBottom: "10px",
          }
        }
      >
        {/* ПРОДУКТИВНІСТЬ */}
      </p>

      <div className="profile__stats">{renderProfileStats}</div>
    </div>
  );
};
