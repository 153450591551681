import { notificApi } from "@/api/notifications/request";
import { IShortUser, notification } from "@/shared";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";

export interface SendNotificationsForm {
  title: string;
  content: string;
  haveToSendEmail: boolean;
  users: IShortUser[];
}

export const useSendNotifications = (onSuccess?: () => void) => {
  const [isLoading, setLoading] = useState(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const form = useForm<SendNotificationsForm>({
    defaultValues: {
      users: [],
    },
  });

  const selectedUsers = form.getValues("users");

  form.watch("users");

  const selectUser = (user: IShortUser) => {
    const exist = selectedUsers.find((it) => it.id === user.id);
    if (exist) return;

    form.setValue("users", [...selectedUsers, user]);
    form.setError("users", null);
  };

  const unselectUser = (userId: number) => {
    form.setValue(
      "users",
      cloneDeep(selectedUsers).filter((it) => it.id !== userId)
    );
  };

  const submit = async () => {
    setLoading(true);
    try {
      const values = form.getValues();

      await notificApi.sendNotifications({
        usersIds: values.users.map((it) => it.id),
        title: String(values.title).trim(),
        content: String(values.content).trim(),
        haveToSendEmail: Boolean(values.haveToSendEmail),
        sendToAll: allSelected,
      });
      onSuccess();
      form.reset();
      notification.showSuccess("Успішно", "Пуш-сповіщення надіслано");
    } catch (e) {
      notification.showError("Помилка", "Не вдалось відправити сповіщення");
    } finally {
      setLoading(false);
    }
  };

  const handleSetAllSelected = (selected: boolean) => {
    if (selected) form.setError("users", null);

    setAllSelected(selected);
  };

  return {
    form,
    submit: form.handleSubmit(submit),
    selectUser,
    selectUsers: (items: IShortUser[]) => form.setValue("users", items),
    unselectUser,
    selectedUsers,
    isLoading,
    allSelected,
    setAllSelected: handleSetAllSelected,
  };
};
