import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";
import * as Res from "./responses.interfaces";

const fetchLogsList = (
  params: Req.IFetchLogsListParams
): ApiResponse<Res.IFetchLogsList> => {
  return http.get<Res.IFetchLogsList>(`admin/logs`, { params });
};

const deleteLogs = (ids: number[]) => {
  return http.delete<void>(`admin/logs/${JSON.stringify(ids)}`);
};

export const logsApi = {
  fetchLogsList,
  deleteLogs
};
