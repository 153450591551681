import React, { useState } from "react";
import { IMessage, useEventsListener } from "@/shared";
import ModalComponent from "@/components/Modal";
import { ChatsSelectListSmart } from "../smart-components";
import { IChatMessage } from "@/containers/Chats/plugins/interfaces";

export const ForwardMessageModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState<IMessage | IChatMessage[]>(null);

  useEventsListener(
    "openForwardMessageModal",
    (payload) => {
      setItem(payload.message);
      setIsOpen(payload.isShow);
    },
    [setItem, setIsOpen]
  );

  useEventsListener(
    "closeForwardMessageModal",
    (payload) => {
      setIsOpen(payload.isShow);
    },
    [setIsOpen]
  );

  return (
    <ModalComponent
      title="Переслати повідомлення"
      show={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <ChatsSelectListSmart item={item} />
    </ModalComponent>
  );
};
