import { DateField } from "@/components/Fields";
import ModalComponent from "@/components/Modal";
import moment from "moment";
import React, { useState } from "react";
import { FC } from "react";
import { Button } from "reactstrap";

interface IProps {
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  title: string;
  onSubmit: (date: string) => void;
  selectedTasksStartDate: string;
}

export const FinishTasksModal: FC<IProps> = ({
  isOpen,
  setOpen,
  title,
  onSubmit,
  selectedTasksStartDate,
}) => {
  const [date, setDate] = useState(moment().format());
  const submit = () => {
    onSubmit(date);
    setDate(moment().format());
    setOpen(false);
  };

  return (
    <ModalComponent title={title} show={isOpen} toggle={() => setOpen(false)}>
      <div className="finish-task-modal_content">
        <div className="finish-task-modal_date-field">
          <DateField
            label={"Виконано:"}
            value={date}
            onChange={setDate}
            disabledDate={(current) =>
              current.isBefore(moment(selectedTasksStartDate).startOf("day")) ||
              current.isAfter(moment().endOf("day"))
            }
          />
        </div>

        <Button onClick={submit} color="primary" className="submit-btn">
          Зберегти
        </Button>
      </div>
    </ModalComponent>
  );
};
