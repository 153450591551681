import { IFilesConfig } from "@/shared";
export class ChangeThemeToLight {
  readonly type = "CHANGE_THEME_TO_LIGHT";
}
export class ChangeThemeToDark {
  readonly type = "CHANGE_THEME_TO_DARK";
}
export class ChangeSidebarVisibility {
  readonly type = "CHANGE_SIDEBAR_VISIBILITY";
}
export class ChangeMobileSidebarVisibility {
  readonly type = "CHANGE_MOBILE_SIDEBAR_VISIBILITY";
}
export class Reset {
  readonly type = "RESET";
}
export class SetFilesConfig {
  readonly type = "SET_FILES_CONFIG";
  constructor(
    public readonly payload: {
      config: Partial<IFilesConfig>;
    }
  ) {}
}

export type TSharedActions =
  | ChangeThemeToDark
  | ChangeThemeToLight
  | Reset
  | ChangeSidebarVisibility
  | ChangeMobileSidebarVisibility
  | SetFilesConfig;
