import React, { FC } from "react";
import "./style.scss";
import { ChatItem } from "./chat-item.component";
import { IChatMessage } from "./interfaces";
import { AudioPlayer } from "../atoms";
import { AudioPlayerProvider } from "react-use-audio-player";
interface ChatItemAudioProps extends IChatMessage {
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onRepliedPress?: (id: number) => void;
  activeAudioId?: number;
  onPressPlay?: () => void;
}

export const ChatItemAudio: FC<ChatItemAudioProps> = (props) => {
  return (
    <ChatItem {...props}>
      <div className="chat-item-message-container">
        <AudioPlayerProvider>
          <AudioPlayer
            fileUrl={props.content?.fileUrl}
            fileId={props.id}
            isMy={props.isMy}
            activeAudioId={props.activeAudioId}
            onPressPlay={props.onPressPlay}
          />
        </AudioPlayerProvider>
      </div>
    </ChatItem>
  );
};
