import React, { useEffect, useState } from "react";
import { ISelectedRowsMenuItemConfig } from "@/shared";
import { Table } from "@/components/TableGrid/Table";
import "./style.scss";
import _ from "lodash";

import { columnsConfig, defaultActiveColumnsConfig } from "../../configs";
import { DateField } from "@/components/Fields";
import { getSelectColumn } from "@/components/TableGrid/configs";
import classNames from "classnames";
import { CustomTableRow } from "@/components/TableGrid/components";
import { LogsSelectedTableHeader } from "./logs-selected-table-header.component";
import { ILog } from "@/shared/interfaces/log.interface";

interface IProps {
  paginationList: any;
  selected: Partial<ILog>[];
  setSelected: (logs: Partial<ILog>[]) => void;
  selectedItemsMenuConfig?: ISelectedRowsMenuItemConfig[];
  configDropdownSelected: any;
  allLogs: Partial<ILog>[];
}

export const LogsTable = (props: IProps) => {
  const [startDateFilter, setStartDateFilter] = useState<string>(null);
  const [endDateFilter, setEndDateFilter] = useState<string>(null);
  const [focusFilterKey, setFocusFilterKey] = useState<string>("");

  const selectColumn = getSelectColumn({
    selected: props.selected,
    onSelect: props.setSelected,
  });

  useEffect(() => {
    props.paginationList.setLoadParams({
      dateFrom: startDateFilter,
      dateTo: endDateFilter,
    });
    props.setSelected([]);
  }, [startDateFilter, endDateFilter]);

  const onOpenContextMenu = (isOpen: boolean, logId: number) => {
    if (isOpen && !_.some(props.selected, (it) => it.id === logId))
      props.setSelected([
        props.paginationList?.items?.find((it) => it.id === logId),
      ]);
  };

  const handleSelectAllColumnPress = () => {
    if (props.selected.length === props.allLogs?.length) props.setSelected([]);
    else props.setSelected(props.allLogs);
  };

  const columns = columnsConfig(
    (props?.paginationList?.loadParams?.page - 1 || 0) *
      props?.paginationList?.loadParams?.limit
  );
  return (
    <div className="DataLogs">
      <div
        className={classNames({
          filter: true,
          small: !_.isEmpty(props.selected),
        })}
      >
        <div className="logs__date_item">
          <DateField
            label="Від"
            value={startDateFilter}
            placeholder={"Дата початку"}
            onChange={(date) => {
              setStartDateFilter(date);

              if (endDateFilter && date > endDateFilter) {
                setEndDateFilter(date);
              }
            }}
          />
        </div>

        <div className="logs__date_item">
          <DateField
            label="До"
            placeholder={"Кінцева дата"}
            value={endDateFilter}
            onChange={(date) => {
              setEndDateFilter(date);

              if (startDateFilter && date < startDateFilter)
                setStartDateFilter(date);
            }}
          />
        </div>
      </div>

      <LogsSelectedTableHeader
        selectedRows={props.selected?.map((it) => it.id)}
        selectedItemsMenuConfig={props.selectedItemsMenuConfig}
        configDropdownSelected={props.configDropdownSelected}
      />
      <Table
        tableName={"logs"}
        columns={[selectColumn, ...columns]}
        // setSelectedRows={props.setSelectedIds}
        selectedRows={props.selected?.map((it) => it.id)}
        paginationList={props.paginationList}
        activeColumns={defaultActiveColumnsConfig}
        focusedFilterField={focusFilterKey}
        onFocusFilterField={setFocusFilterKey}
        tableProps={{
          rowRenderer: (data: any) => (
            <CustomTableRow
              rowData={data}
              menuConfig={props.selectedItemsMenuConfig}
              onOpenMenu={onOpenContextMenu}
            />
          ),
        }}
        allSelected={
          !_.isEmpty(props.selected) &&
          props.selected.length === props.allLogs?.length
        }
        selectAll={handleSelectAllColumnPress}
      />
    </div>
  );
};
