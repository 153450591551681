import { createFullName, IShortUser } from "@/shared";

import { IPotentialChatMember } from "./responses.interfaces";

export const transformToShortUsers = (
  items: IPotentialChatMember[]
): IShortUser[] => {
  const transformedItems = items.map((it) => {
    return {
      id: it.userId,
      firstName: it.firstName,
      fullName: createFullName(it.firstName, it.middleName, it.lastName),
      avatarUrl: it.avatarUrl,
    };
  });

  return transformedItems;
};
