import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";
import * as Res from "./responses.interfaces";

const fetchIpsList = (
  params: Req.IFetchIpsListParams
): ApiResponse<Res.IFetchIpsList> => {
  return http.get<Res.IFetchIpsList>(`admin/ips`, { params });
};

const addIp = (payload: Req.IAddIp): ApiResponse<Res.IAddIpRes> => {
  return http.post<Res.IAddIpRes>(`admin/ips`, payload);
};

const updateIpType = (payload: Req.IUpdateIpType): ApiResponse<void> => {
  return http.patch<void>(`admin/ips/set-list-type`, payload);
};

const deleteIp = (ids: number[]): ApiResponse<void> => {
  console.log(ids);
  return http.delete<void>(`admin/ips/${JSON.stringify(ids)}`);
};

const deleteIps = (ips: string[]): ApiResponse<void> => {
  return http.delete<void>(`admin/ips/by-ips`, { params: { ips } });
};

const blockIps = (ips: string[]): ApiResponse<void> => {
  return http.post<void>("admin/ips/block", { ips });
};

export const ipsApi = {
  addIp,
  fetchIpsList,
  updateIpType,
  deleteIp,
  blockIps,
  deleteIps,
};
