import { IShortUser } from "@/shared";
import _ from "lodash";
import React, { FC, useMemo } from "react";
import { transformUserToSelectList } from "../transforms";
import { UserListItem } from "./user-list-item.component";

interface IProps {
  items: IShortUser[];
  selectedUsers: IShortUser[];
  selectOne: (user: IShortUser) => void;
  unSelectOne: (id: number) => void;
  excludeIds?: number[];
  loadMore?: () => void;
  count?: number;
}

export const UsersSelectList: FC<IProps> = ({
  items,
  selectedUsers,
  selectOne,
  unSelectOne,
  excludeIds,
  loadMore,
  count,
}) => {
  const transformedUsers = useMemo(() => {
    const transformedToSelectListUsers = items?.map((item) =>
      transformUserToSelectList(item, selectedUsers)
    );

    const filteredusers = transformedToSelectListUsers?.filter(
      (item) => !_.includes(excludeIds, item.id)
    );

    return filteredusers;
  }, [selectedUsers, items, excludeIds]);

  const loadMoreOptionItem = useMemo(() => {
    if (!_.isEmpty(transformedUsers) && count > items.length)
      return (
        <div onClick={() => loadMore()} className="load-more-btn">
          {`Завантажити ще`}
        </div>
      );

    return null;
  }, [count, items, transformedUsers]);

  return (
    <div className="user-list-container">
      {transformedUsers.map((item, index) => (
        <UserListItem
          key={index}
          id={item.id}
          imageUrl={item.avatarUrl}
          userName={item.fullName}
          isChecked={item.isSelected}
          onSelectItem={(id) => selectOne(items.find((it) => it.id === id))}
          onDeselectItem={unSelectOne}
        />
      ))}
      {loadMoreOptionItem}
    </div>
  );
};
