import React from "react";
import { ButtonToolbar, Button } from "reactstrap";
import _ from "lodash";
import "./style.scss";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Tooltip } from "antd";
import { useState } from "react";
import {
  checkPermission,
  emailPattern,
  getParentsTree,
  IMyPermissions,
  InputField,
  IUser,
  loginPattern,
  Permissions,
  SelectAvatar,
  SelectField,
} from "@/shared";
import { EUserRole } from "@/shared";
import { Controller } from "react-hook-form";
import { DateField, TreeSelectField } from "@/components/Fields";
import { InputPasswordField } from "../../../../shared/components/fields/input-password-field.component";
import { useCreateEditUser } from "../../hooks";
import { UserModalMode } from "../../enums";
import { PermissionsPage } from "@/containers/Permissions";
import { PhoneNumberController } from "@/components/Forms/PhoneNumberController";
import default_avatar from "@/assets/img/default-avatar.jpg";
import moment from "moment";

interface IProps {
  profile: IUser;
  permissions: IMyPermissions;
  onSubmit: () => void;
  mode: UserModalMode;
  selectedUser?: IUser;
}

export const FormUser = (props: IProps) => {
  const [activeTab, setActiveTab] = useState<"1" | "2">("1");

  const {
    form,
    submit,
    generatePassword,
    factories,
    validate,
    errors,
    deleteAvatar,
    isDisableBtns,
  } = useCreateEditUser({
    onSubmitUser: props.onSubmit,
    userData: props.mode === UserModalMode.Edit ? props.selectedUser : null,
    mode: props.mode,
    onInvalidData: () => setActiveTab("1"),
  });

  const watchValues = form.watch();

  const parentCategoryTree = getParentsTree(
    factories,
    form.control._formValues.factory
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!_.isEmpty(errors)) {
      setActiveTab("1");
      return;
    }
    submit(e);
  };

  return (
    <form
      className="form"
      style={{ flexDirection: "column" }}
      onSubmit={(e) => {
        e.preventDefault();
        console.log("errors", errors);
        if (!_.isEmpty(errors)) {
          setActiveTab("1");
          return;
        } else submit(e);
      }}
    >
      <div className="tabs tabs--justify tabs--bordered-modal">
        <div className="FormUser">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => setActiveTab("1")}
              >
                Основне
              </NavLink>
            </NavItem>
            {checkPermission(
              "tabs",
              "user",
              props.profile,
              props.permissions,
              Permissions.PERMISSIONS
            ) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => setActiveTab("2")}
                >
                  Права доступу
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="tab-contact-container">
                <div className="contact-row-field contact-row-name">
                  <Controller
                    name="lastName"
                    control={form.control}
                    rules={{ required: "Заповнити обов'язково" }}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <InputField
                        placeholder={"Прізвище"}
                        label={"Прізвище"}
                        onChange={onChange}
                        value={value}
                        error={fieldState?.error?.message}
                      />
                    )}
                  />

                  {/* <div className="contact-row-field"> */}
                  <Controller
                    name="firstName"
                    control={form.control}
                    rules={{ required: "Заповнити обов'язково" }}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <InputField
                        placeholder={"Ім'я"}
                        label={"Ім'я"}
                        onChange={onChange}
                        value={value}
                        error={fieldState?.error?.message}
                      />
                    )}
                  />
                  {/* </div> */}
                  {/* <div className="contact-row-field"> */}
                  <Controller
                    name="middleName"
                    control={form.control}
                    rules={{ required: "Заповнити обов'язково" }}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <InputField
                        placeholder={"По-батькові"}
                        label={"По-батькові"}
                        onChange={onChange}
                        value={value}
                        error={fieldState?.error?.message}
                      />
                    )}
                  />
                </div>
                {/* </div> */}
                <div className="contact-row-field">
                  <div className="col-12 col-md-4" style={{ padding: 0 }}>
                    <div
                      className="avatar-block"
                      style={{ position: "relative" }}
                    >
                      {(watchValues.avatar || watchValues.avatarUrl) && (
                        <div className="avatar">
                          <Tooltip placement="bottom" title={"Видалити аватар"}>
                            <i
                              className="fal fa-trash-alt"
                              onClick={deleteAvatar}
                            ></i>
                          </Tooltip>
                        </div>
                      )}
                      <Controller
                        name="avatar"
                        control={form.control}
                        render={({ field: { value, onChange } }) => (
                          <div>
                            <SelectAvatar
                              image={watchValues.avatarUrl}
                              title="Зміна аватару користувача"
                              defaultImg={default_avatar}
                              onChange={onChange}
                              className="user__avatar"
                              label="Аватар"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-8 email-date-block"
                    style={{ padding: 0 }}
                  >
                    <Controller
                      name="email"
                      control={form.control}
                      rules={{
                        required: "Заповнити обов'язково",
                        pattern: {
                          value: emailPattern,
                          message: "Email введено не вірно",
                        },
                      }}
                      render={({ field: { value, onChange }, fieldState }) => (
                        <InputField
                          placeholder={"Email"}
                          label={"Email"}
                          onChange={async (value: string) => {
                            form.clearErrors("email");
                            onChange(value);
                            if (emailPattern.test(value))
                              await validate(value, "email");
                          }}
                          value={value}
                          error={fieldState?.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="dateOfBirth"
                      control={form.control}
                      rules={{
                        required: "Заповнити обов'язково",
                        validate: (val) =>
                          moment.utc().subtract(18, "years") > moment(val) ||
                          "Вік не може бути менше 18 років",
                      }}
                      render={({ field: { value, onChange }, fieldState }) => (
                        <DateField
                          label={"Дата народження"}
                          placeholder={"Дата народження"}
                          value={value}
                          onChange={onChange}
                          meta={{
                            error: fieldState?.error?.message,
                          }}
                          disabledDate={(current) => current.isAfter(moment())}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="contact-row-field">
                  <div className="col-12 col-md-12" style={{ padding: 0 }}>
                    <Controller
                      name="position"
                      control={form.control}
                      rules={{ required: "Заповнити обов'язково" }}
                      render={({ field: { value, onChange }, fieldState }) => (
                        <InputField
                          placeholder={"Посада"}
                          label={"Посада"}
                          onChange={onChange}
                          value={value}
                          error={fieldState?.error?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="contact-row-field">
                  <div className="col-12 col-md-6 contact-left-field">
                    <Controller
                      name="innerPhoneNumber"
                      control={form.control}
                      render={({ field: { value, onChange } }) => (
                        <InputField
                          label={"Телефон внутрішній"}
                          value={value}
                          placeholder={"00 000"}
                          onChange={(val: string) =>
                            onChange(val.replace(/[^\d]/g, ""))
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 contact-right-field">
                    <Controller
                      name="login"
                      control={form.control}
                      rules={{
                        required: "Заповнити обов'язково",
                        pattern: {
                          value: loginPattern,
                          message:
                            "Логін може містити тільки цифри та латинські літери",
                        },
                      }}
                      render={({ field: { value, onChange }, fieldState }) => (
                        <InputField
                          placeholder={"Логін"}
                          label={"Логін"}
                          onChange={async (value: string) => {
                            form.clearErrors("login");
                            onChange(value);
                            if (loginPattern.test(value))
                              await validate(value, "login");
                          }}
                          value={value}
                          error={fieldState?.error?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="contact-row-field">
                  <PhoneNumberController
                    rules={{
                      required: "Заповнити обов'язково",
                      validate: (val: string) =>
                        val !== form.getValues("personalPhoneNumber") ||
                        "Не може співпадати з особистим номером",
                    }}
                    form={form}
                    name="phoneNumber"
                    label={"Телефон робочий"}
                    validate={validate}
                    className="col-12 col-md-6 contact-left-field"
                  />
                  <PhoneNumberController
                    rules={{
                      validate: (val: string) => {
                        if (!val) return true;
                        return (
                          val !== form.getValues("phoneNumber") ||
                          "Не може співпадати з робочим номером"
                        );
                      },
                    }}
                    form={form}
                    name="personalPhoneNumber"
                    label={"Телефон особистий"}
                    validate={validate}
                    className="col-12 col-md-6 contact-right-field"
                  />
                </div>
                <div className="contact-row-field">
                  <div className="col-md-6 contact-left-field">
                    <Controller
                      name="password"
                      control={form.control}
                      rules={
                        props.mode === UserModalMode.Create
                          ? { required: "Заповнити обов'язково" }
                          : null
                      }
                      render={({ field: { value, onChange }, fieldState }) => (
                        <div className="password-field">
                          <InputPasswordField
                            placeholder={
                              props.mode === UserModalMode.Edit
                                ? "Новий пароль"
                                : "Пароль"
                            }
                            label={
                              props.mode === UserModalMode.Edit
                                ? "Новий пароль"
                                : "Пароль"
                            }
                            onChange={onChange}
                            value={value}
                            error={fieldState?.error?.message}
                          />
                          <div
                            className="generate-pw-field"
                            onClick={() => generatePassword()}
                          >
                            Згенерувати пароль
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 contact-right-field">
                    <Controller
                      name="confirmPassword"
                      control={form.control}
                      rules={
                        props.mode === UserModalMode.Create
                          ? {
                              required: "Заповнити обов'язково",
                              validate: (val: string) =>
                                val === form.getValues("password") ||
                                "Паролі не співпадають",
                            }
                          : {
                              validate: (val: string) =>
                                val === form.getValues("password") ||
                                "Паролі не співпадають",
                            }
                      }
                      render={({ field: { value, onChange }, fieldState }) => (
                        <InputPasswordField
                          placeholder={"Підтвердіть пароль"}
                          label={"Підтвердіть пароль"}
                          onChange={onChange}
                          value={value}
                          error={fieldState?.error?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                {props.profile.role === EUserRole.Admin && (
                  <div
                    style={{ marginBottom: 20 }}
                    className="contact-row-field factory-field"
                  >
                    <Controller
                      name="role"
                      control={form.control}
                      rules={{ required: `Заповнити обов'язково` }}
                      render={({ field: { value, onChange }, fieldState }) => (
                        <SelectField
                          value={value}
                          options={[
                            { value: EUserRole.Admin, title: "Адміністратор" },
                            { value: EUserRole.User, title: "Користувач" },
                          ]}
                          defaultValue={value}
                          touched={true}
                          label={"Роль"}
                          placeholder={"Роль"}
                          onChange={onChange}
                          error={fieldState?.error?.message}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="contact-row-field factory-field">
                  <Controller
                    name="factoryId"
                    control={form.control}
                    rules={{ required: `Заповнити обов'язково` }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState,
                    }) => (
                      <TreeSelectField
                        register={{ onChange, onBlur, ref, value }}
                        label="Підприємство"
                        placeholder="Підприємство"
                        tree={parentCategoryTree}
                        touched={true}
                        error={fieldState?.error?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" && (
                <PermissionsPage
                  form={form}
                  factoriesTree={parentCategoryTree}
                />
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar">
        <Button
          // onClick={submit}
          onClick={handleSubmit}
          color="primary"
          type="submit"
          className="contact__btn"
        >
          Зберегти
        </Button>

        <Button
          className="contact__btn"
          color="primary"
          type="button"
          disabled={isDisableBtns}
          onClick={(e) => {
            e.preventDefault();
            if (!_.isEmpty(errors)) {
              setActiveTab("1");
              return;
            } else {
              form.setValue("sendPasswordBy", "email");
              submit(e);
            }
          }}
        >
          Зберегти <i style={{ marginLeft: 10 }} className="fal fa-envelope" />
        </Button>

        <Button
          className="contact__btn"
          color="primary"
          type="button"
          disabled={isDisableBtns}
          onClick={(e) => {
            e.preventDefault();
            if (!_.isEmpty(errors)) {
              setActiveTab("1");
              return;
            } else {
              form.setValue("sendPasswordBy", "sms");
              submit(e);
            }
          }}
        >
          Зберегти <i style={{ marginLeft: 10 }} className="fal fa-sms" />
        </Button>
      </ButtonToolbar>
    </form>
  );
};
