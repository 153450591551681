import { PermissionsSection } from "@/containers/Permissions/enums";
import { PermissionForm } from "@/containers/Permissions/interfaces";
import _ from "lodash";
import { EUserRole, EUserStatus, Permissions } from "../enums";
import {
  IMyPermissions,
  IPermissionsAbstract,
  IUser,
  IUserPermissions
} from "../interfaces";

const parsePermissions = (permissions: any): IUserPermissions => {
  if (typeof permissions === "string") {
    try {
      const userPermissions = JSON.parse(permissions);
      return userPermissions;
    } catch (e) {
      return null;
    }
  } else {
    return permissions;
  }
};

export const transformPermissionsToFormValues = (
  permissionsObj: IUserPermissions | string
): PermissionForm[] => {
  const transformed: PermissionForm[] = [];

  const permissions = parsePermissions(permissionsObj);

  if (!_.isEmpty(permissions)) {
    Object.keys(permissions).map(key => {
      if (key === "filter_factory") {
        transformed.push({ section: key as PermissionsSection });
        return true;
      }

      if (_.isEmpty(permissions[key])) return false;

      Object.keys(permissions[key]).map(target => {
        if (_.isEmpty(permissions[key][target])) return false;

        transformed.push({
          section: key as PermissionsSection,
          target,
          permissions: permissions[key][target]
        });

        return true;
      });

      return true;
    });
  }

  return transformed;
};

export const transformFormValuesToPermissions = (
  permissionsForm: PermissionForm[]
): IPermissionsAbstract => {
  const permissions: IPermissionsAbstract = {};

  if (!_.isEmpty(permissionsForm)) {
    permissionsForm.map(it => {
      if (it.section === PermissionsSection.FILTER_FACTORY) {
        permissions.filter_factory = true;
        return true;
      }

      if (!it.section || !it.target || _.isEmpty(it.permissions)) return true;

      if (!permissions[it.section]) permissions[it.section] = {} as any;

      if (!permissions[it.section][it.target])
        permissions[it.section][it.target] = it.permissions;
      else
        permissions[it.section][it.target] = _.union(
          permissions[it.section][it.target],
          it.permissions
        );
    });
  }

  return permissions;
};

export const checkPermission = (
  controller: "tabs" | "user" | "filter_factory",
  target:
    | "factory"
    | "user"
    | "group_permission"
    | "taxonomy"
    | string
    | number
    | null,
  user: IUser,
  permissions: IMyPermissions,
  action?: Permissions,
  authorId?: number
): boolean => {
  if (user.status !== EUserStatus.Active) {
    return false;
  }

  if (user.role === EUserRole.Admin) {
    return true;
  }

  if (controller === "user" && target === user.id && authorId === user.id)
    return true;

  if (_.isEmpty(permissions)) return false;

  const targetPermissions = permissions[controller];

  if (controller === "filter_factory") return !!targetPermissions;

  if (_.isEmpty(targetPermissions)) return false;

  const actions = targetPermissions[target];

  if (!_.isEmpty(actions) && action) return _.includes(actions, action);

  return !_.isEmpty(actions);
};
