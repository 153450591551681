import React, { FC } from "react";
import { Row, Col } from "reactstrap";
import {
  ISelectedRowsMenuItemConfig,
  SelectedDropdownMenu,
  SelectedRowsMenu,
} from "@/shared";
import _ from "lodash";

interface IProps {
  selectedRows: number[];
  selectedItemsMenuConfig: ISelectedRowsMenuItemConfig[];
  configDropdownSelected: any;
}

export const UsersSelectedTableHeader: FC<IProps> = ({
  selectedRows,
  selectedItemsMenuConfig,
  configDropdownSelected,
}) => {
  if (_.isEmpty(selectedRows)) {
    return <div style={{ height: 30 }}></div>;
  }

  return (
    <Row>
      <Col md={12}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(selectedItemsMenuConfig) && (
            <SelectedDropdownMenu
              configSelected={configDropdownSelected}
              selected={selectedRows.length}
            />
          )}

          <SelectedRowsMenu itemsConfig={selectedItemsMenuConfig} />
        </div>
      </Col>
    </Row>
  );
};
