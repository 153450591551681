import React, { useEffect } from "react";
import { Table } from "@/components/TableGrid/Table";
import { secretUsersTableColumns } from "../config";
import { CustomTableRow } from "@/components/TableGrid/components";
import { usePaginationList } from "@/shared";
import { secretModApi } from "@/api/secret-mod/requests";
import { useSearchState, useSecretUsersList } from "../states";
import { useSecretEventsListener } from "../events";
import "./style.scss";
import { useSecretUsersEdit } from "../hooks";

export const SecretUsersTable = () => {
  const navigateToUser = () => {};
  const { remove } = useSecretUsersEdit();
  const { value } = useSearchState();

  const defaultColumnsActive = ["avatarUrl", "name", "email", "actions"];

  const paginationList = usePaginationList<any>({
    fetchItems: secretModApi.getUsers,
    loadParams: {
      limit: 99999,
      page: 1,
      sort: "ASC",
      sortField: "lastName",
    },
  });

  useEffect(() => {
    useSecretUsersList.getState().setUsers(paginationList.items);
  }, [paginationList.items]);

  useEffect(() => {
    if (value !== null) {
      paginationList.setLoadParams({ searchString: value });
    }
  }, [value]);

  useSecretEventsListener("addUsers", () => {
    paginationList.resetFlatList();
  });

  useSecretEventsListener("removeUsers", () => {
    paginationList.resetFlatList();
  });

  return (
    <div className="secret-users-table">
      <Table
        tableName={"secret-users"}
        columns={secretUsersTableColumns({
          onPressName: navigateToUser,
          onPressRemove: (id) => {
            remove([id]);
          },
        })}
        paginationList={paginationList}
        activeColumns={defaultColumnsActive}
        showActionBottomBar={false}
        tableProps={{
          rowRenderer: (data: any) => (
            <CustomTableRow
              rowData={data}
              menuConfig={[]}
              onOpenMenu={() => {}}
            />
          ),
        }}
      />
    </div>
  );
};
