import React from "react";
import { Container } from "reactstrap";
import { SecretModControllerWidget, SecretUsersWidget } from "./widgets";
import { useSelector } from "react-redux";

import { getProfile } from "@/store/account";
import { useHistory } from "react-router-dom";
import { EUserRole } from "@/shared";

export const SecretModPage = () => {
  const profile = useSelector(getProfile);
  const history = useHistory();

  if (profile?.role !== EUserRole.Admin) {
    history.push("/");
    return null;
  }

  return (
    <Container className="factory">
      <SecretModControllerWidget />
      <SecretUsersWidget />
    </Container>
  );
};
