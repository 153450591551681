import { tasksApi } from "@/api";
import { ITask, notification } from "@/shared";
import { useState } from "react";
import { EPrintTasksMode } from "../enums";
import { IFetchTasksParams } from "@/api/tasks/requests.interfaces";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getTaskFilter } from "@/store/task";
import { getProfile } from "@/store/account";
import moment from "moment";

export const usePrintTasks = () => {
  const [mode, setMode] = useState<EPrintTasksMode>(EPrintTasksMode.Selected);
  const [withComments, setWithComments] = useState<boolean>(false);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const filter = useSelector(getTaskFilter);
  const account = useSelector(getProfile);

  const loadTasks = async (tasksIds: number[], searchString?: string) => {
    setLoading(true);

    const loadParams = createLoadParams(tasksIds, searchString);

    try {
      const { data: tasks } = await tasksApi.fetchTasks(loadParams);

      setTasks(tasks.items);
    } catch (e) {
      notification.showError("Помилка", "Не вдалось завантажити дані");
      throw new Error("Error load tasks");
    } finally {
      setLoading(false);
    }
  };

  const createLoadParams = (tasksIds: number[], searchString?: string) => {
    const loadParams: IFetchTasksParams = {
      limit: 999999,
      sortField: "id",
      page: 1,
      withGroup: true,
      withoutAdditionalData: true,
    };
    if (withComments) loadParams.withComments = true;
    if (
      mode === EPrintTasksMode.Selected ||
      mode === EPrintTasksMode.SelectedSortByExecutor
    ) {
      loadParams.tasksIds = tasksIds;
      return loadParams;
    }

    if (searchString) loadParams.globalSearchString = searchString;

    loadParams.isFavorite = filter?.isStar;
    loadParams.isOutdate = filter?.isOverdue;
    loadParams.status = filter?.status;
    loadParams.executorId = filter?.isMy ? account.id : filter?.executor;
    loadParams.authorId = filter?.isMy ? account.id : filter?.creator;
    loadParams.groupsIds = !_.isEmpty(filter?.group) ? filter?.group : null;
    loadParams.targetDate = filter?.date
      ? moment(filter?.date).format("MM-DD-YYYY")
      : null;
    loadParams.startDateFrom = filter?.startDate
      ? moment(filter?.startDate).format("MM-DD-YYYY")
      : null;
    loadParams.startDateTo = filter?.endDate
      ? moment(filter?.endDate).format("MM-DD-YYYY")
      : null;

    return loadParams;
  };

  return {
    withComments,
    setWithComments,
    mode,
    setMode,
    tasks,
    loading,
    loadTasks,
  };
};
