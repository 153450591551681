import React, { useState } from "react";

import { SendNotificationsWidget } from "./send-notifications.widget";
import { ButtonAtom } from "./atoms";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";
import { EUserRole } from "@/shared";

export const SendNotificationsButtonWidget = () => {
  const [isOpen, setOpen] = useState(false);
  const user = useSelector(getProfile);

  if (user.role !== EUserRole.Admin) return null;
  return (
    <>
      <ButtonAtom onClick={() => setOpen(true)} />
      <SendNotificationsWidget isOpen={isOpen} onClose={() => setOpen(false)} />
    </>
  );
};
