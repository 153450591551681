import React from "react";
import { TreeSelect } from "antd";
import "./TreeSelectField.scss";
import "./SelectField.scss";

const TreeNode = TreeSelect.TreeNode;

interface IProps {
  register: any;
  label: string;
  tree: any[];
  placeholder?: string;
  multiple?: boolean;
  touched?: boolean;
  error?: string;
}

export const TreeSelectField = (props: IProps) => {

  const renderTree = (tree) => {
    if (Array.isArray(tree)) {
      return tree.map((item) => (
        <TreeNode value={item["" || "id"]} title={item.name} key={item.id}>
          {item.children && renderTree(item.children)}
        </TreeNode>
      ));
    }
  };

  return (
    <div className="form__form-group tree-select-field">
      <span className="label">{props.label}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <div style={{ position: "relative" }}>
            <TreeSelect
              className="tree-select"
              showSearch
              treeNodeFilterProp="title"
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder={props.placeholder}
              allowClear
              defaultValue={null}
              multiple={props.multiple}
              {...props.register}
            >
              {renderTree(props.tree)}
            </TreeSelect>
          </div>
          {props.touched &&
            props.error && (
              <span className="error">{props.error}</span>
            )}
        </div>
      </div>
    </div>
  );
};
