import React, { FC } from "react";
import ModalComponent from "@/components/Modal";

interface IProps {
  isShow: boolean;
  setOpen: (val: boolean) => void;
  imageSrc: string;
}

export const ShowImageModal: FC<IProps> = ({ isShow, setOpen, imageSrc }) => {
  return (
    <ModalComponent title="" show={isShow} toggle={() => setOpen(false)}>
      <div>
        <img src={imageSrc}></img>
      </div>
    </ModalComponent>
  );
};
