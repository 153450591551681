import React, { useMemo } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";
import { ConfirmModal, dropdownMenuConfig, EUserRole } from "@/shared";
import { useHistory } from "react-router-dom";
import { LogsTable } from "./components/Data";
import { useLogs } from "./hooks";
import { getSelectedLogsMenuConfig } from "./configs";

export const Logs = () => {
  const history = useHistory();
  const profile = useSelector(getProfile);

  const {
    paginationList,
    selectedLogs,
    setSelectedLogs,
    confirmModal,
    actions,
    allIds,
  } = useLogs();

  const onLogsAction = (
    key: "delete" | "block_users" | "unblock_users" | "block_ip" | "unblock_ip"
  ) => {
    const actionByKey = {
      delete: () => actions.delete(),
      block_users: () => actions.blockUsers(),
      unblock_users: () => actions.unblockUsers(),
      block_ip: () => actions.blockIps(),
      unblock_ip: () => actions.unblockIPs(),
    };

    actionByKey[key]();
  };

  const selectedItemsMenuConfig = useMemo(
    () =>
      getSelectedLogsMenuConfig({
        onClick: onLogsAction,
        selectedIds: selectedLogs.map((it) => it.id),
      }),
    [selectedLogs]
  );

  if (profile.role !== EUserRole.Admin) return history.push("/");

  const selectAllLogs = () => {
    setSelectedLogs(paginationList?.data);
  };
  const unSelectAllLogs = () => {
    setSelectedLogs([]);
  };
  const configDropdownSelected = useMemo(() => {
    return dropdownMenuConfig({
      onSelectAll: selectAllLogs,
      onUnSelectAll: unSelectAllLogs,
      all: allIds?.length === selectedLogs.length,
    });
  }, [paginationList, selectedLogs]);

  return (
    <Container className="task">
      <Row>
        <Col md={12}>
          <Card>
            <ConfirmModal
              isShow={confirmModal.state.isOpen}
              message={confirmModal.state.message}
              onConfirm={confirmModal.state.onConfirm}
              setShow={(state: boolean) =>
                confirmModal.set({ ...confirmModal.state, isOpen: state })
              }
            />

            <CardBody>
              <LogsTable
                selected={selectedLogs}
                paginationList={paginationList}
                setSelected={setSelectedLogs}
                selectedItemsMenuConfig={selectedItemsMenuConfig}
                configDropdownSelected={configDropdownSelected}
                allLogs={paginationList?.data}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
