import { getProfile } from "@/store/account";
import { Col, Container, Row } from "reactstrap";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { match } from "react-router";
import { useContact } from "../Contact/hooks/use-contact.hook";
import { ProfileMain, ProfileTabs } from "../Profile/components";
import "./style.scss";
import { useUserActions } from "../Profile/hooks";
import { checkPermission, ConfirmModal, Permissions } from "@/shared";
import { getMyPermissions } from "@/store/permissions";

interface IProps {
  match: match<{ id: string }>;
}

export const UserDetail: FC<IProps> = ({ match: { params: id } }) => {
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const contactId = parseInt(id.id);
  const {
    contactInfo,
    fetchContact,
    contactStats,
    contactTasksCount,
  } = useContact(contactId);

  const { confirmModal, onPressAction } = useUserActions({
    user: contactInfo,
    onAction: fetchContact,
  });

  const isUpdated = checkPermission(
    "tabs",
    "user",
    profile,
    permissions,
    Permissions.UPDATE
  );

  return contactInfo ? (
    <Container>
      <ConfirmModal
        isShow={confirmModal.state.isOpen}
        message={confirmModal.state.message}
        onConfirm={confirmModal.state.onConfirm}
        setShow={(state: boolean) =>
          confirmModal.set({ ...confirmModal.state, isOpen: state })
        }
      />
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain
                isUpdate={isUpdated}
                profile={contactInfo}
                authProfile={profile}
                countStats={contactStats}
                countTasksStats={contactTasksCount}
                onPressUserAction={onPressAction}
                onChangeAvatar={fetchContact}
                onDeleteAvatar={fetchContact}
              />
            </Row>
          </Col>

          <ProfileTabs
            isUpdate={isUpdated}
            profile={contactInfo}
            authProfile={profile}
            onUpdate={fetchContact}
          />
        </Row>
      </div>
    </Container>
  ) : null;
};
