import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { Form } from "./components/Form";
import Modal from "../../components/Modal";
import { TreeTaxonomy } from "./components/TreeTaxonomy";
import { getProfile } from "@/store/account";
import * as taxonomySelectors from "@/store/taxonomies/selectors";
import { match } from "react-router-dom";
import { taxonomiesService } from "@/services/domain/taxonomies.service";
import {
  checkPermission,
  ETaxonomyType,
  Permissions,
  SearchInput,
} from "@/shared";
import { getMyPermissions } from "@/store/permissions";

interface IProps {
  match: match<{ type: string }>;
}

enum ETaxonomyRouteType {
  "task" = ETaxonomyType.taskCategory,
  "reasons_task" = ETaxonomyType.taskReason,
}

export const Taxonomy = (props: IProps) => {
  const isLoading = useSelector(taxonomySelectors.isLoadingTaxonomies);
  const taxonomiesList = useSelector(taxonomySelectors.getTaxonomiesList);
  const targetTaxonomy = useSelector(taxonomySelectors.getTargetTaxonomy);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>("");
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  useEffect(() => {
    taxonomiesService.fetchTaxonomies();
  }, []);

  return (
    <Container className="factory">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Modal
                show={showModal}
                toggle={setShowModal}
                title={
                  props.match.params.type === "task"
                    ? "Група задачі"
                    : "Підстава задачі"
                }
              >
                <Form
                  paramsType={props.match.params.type}
                  type={ETaxonomyRouteType[props.match.params.type]}
                  setShowModal={setShowModal}
                  targetTaxonomy={targetTaxonomy}
                  taxonomiesList={taxonomiesList.list}
                  editable={
                    targetTaxonomy?.id
                      ? checkPermission(
                          "tabs",
                          "taxonomy",
                          profile,
                          permissions,
                          Permissions.UPDATE
                        )
                      : true
                  }
                />
              </Modal>

              <Row>
                <div
                  className="col-md-12 justify-content-end flex"
                  style={{ marginBottom: 10 }}
                >
                  <SearchInput
                    value={searchStr}
                    placeholder="Знайдіть задачу, групу"
                    style={{ width: 250, marginRight: 20 }}
                    onChange={setSearchStr}
                  />

                  <Button
                    color="primary"
                    size="sm"
                    disabled={
                      !checkPermission(
                        "tabs",
                        "taxonomy",
                        profile,
                        permissions,
                        Permissions.CREATE
                      )
                    }
                    onClick={() => {
                      taxonomiesService.setTargetTaxonomy({
                        type: ETaxonomyRouteType[props.match.params.type],
                      });
                      setShowModal(true);
                    }}
                  >
                    Створити
                  </Button>
                </div>
              </Row>

              <TreeTaxonomy
                searchVal={searchStr}
                type={ETaxonomyRouteType[props.match.params.type]}
                isLoading={isLoading}
                onShowModal={setShowModal}
                taxonomiesList={taxonomiesList.list}
                profile={profile}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
