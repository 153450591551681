import ModalComponent from "@/components/Modal";
import React, { useEffect, useState } from "react";
import { CropperComponent } from "./cropper.component";
import { ButtonToolbar, Button } from "reactstrap";
import { dataURLtoFile } from "@/containers/Profile/helper";

interface IProps {
  image: any;
  title: string;
  onChange: (file: any) => void;
  defaultImg: any;
  className?: string;
  label?: string;
  previewMessage?: string;
  disabled?: boolean;
}

export const SelectAvatar = (props: IProps) => {
  const [isShowModal, setModal] = useState<boolean>(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    setPreview(props.image);
  }, [props.image]);

  const previewImageBlock = () => {
    if (preview || props.defaultImg)
      return (
        <img
          className={preview ? "" : "default-img"}
          src={preview || props.defaultImg}
          alt="avatar"
        />
      );
    if (!props.image && props.previewMessage)
      return (
        <div className="avatar-noImage">
          <p>{props.previewMessage}</p>
        </div>
      );

    return <></>;
  };

  return (
    <div>
      {props.label && <p className="input-label">{props.label}</p>}

      <div
        className={props.className ? props.className : "profile__avatar"}
        onClick={() => {
          if (!props.disabled) setModal(true);
        }}
      >
        {previewImageBlock()}

        <ModalComponent
          show={isShowModal}
          toggle={() => {
            setModal(!isShowModal);
            if (isShowModal) setImage(null);
          }}
          title={props.title}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CropperComponent src={""} setImage={setImage} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: 16,
                }}
              >
                Ви бажаєте завантажити фото?
              </p>
              <ButtonToolbar className="form__button-toolbar">
                <Button
                  style={{ width: 80 }}
                  color="primary"
                  type="button"
                  onClick={() => {
                    if (!image) return;
                    props.onChange(dataURLtoFile(image, "avatar"));
                    setPreview(image);
                    setModal(false);
                    setImage(null);
                  }}
                >
                  Так
                </Button>
                <Button
                  style={{ width: 80 }}
                  color="primary"
                  type="button"
                  onClick={() => {
                    setImage(null);
                    setModal(false);
                  }}
                >
                  Ні
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};
