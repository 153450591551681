import React from 'react';

interface IProps {
  theme: any
  children: JSX.Element
}

export const MainWrapper = (props: IProps) => {
  return (
    <div className={props.theme}>
      <div className="wrapper">
        {props.children}
      </div>
    </div>
  )
}