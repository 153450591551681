import React, { FC } from "react";
import { IMessage } from "@/shared";
import { ChatItem } from "./chat-item.component";

import { IOptionsData, Linkify } from "react-easy-linkify";
import _ from "lodash";

interface ChatItemMessageProps extends IMessage {
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onMentionPress?: (id: number) => void;
  onForwardedAuthorPress?: (id: number) => void;
  onRepliedPress?: (id: number) => void;

  onForwardPressMessage?: () => void;
}

const regexToMatch = /@\[([^[]*)]\(([^(^)]*)\)/g;


const parseMessage = (message: string, regex: RegExp): string => {
  if (!message) return ''
  const subst = ` @$1($2) `;
  const result = message.replace(regex, subst);
  return result.replace(/\n{3,}\s*/g, "\n\n");
};

const findMentionId = (user: string): number => {
  const preparedId = user.substring(
    user.lastIndexOf("(") + 1,
    user.lastIndexOf(")")
  );

  if (preparedId) return Number(preparedId);

  return null;
};

const _ChatItemMessage: FC<ChatItemMessageProps> = (props) => {
  const resultMessage = parseMessage(props.content?.message, regexToMatch);

  if (!resultMessage) return null

  return (
    <ChatItem {...props}>
      <div className="chat-item-message-container">
        {props.content?.message && (
          <div className="message-container">
            <Linkify
              options={
                {
                  linkWrapper: (props) => (
                    <span className="wrapper">
                      <a {...props} id="link">
                        {props.children}
                      </a>
                    </span>
                  ),
                } as IOptionsData
              }
            >
              {resultMessage.split("  ").map((item, key) =>
                item.includes("@") && findMentionId(item) ? (
                  <a
                    key={key}
                    onClick={() => {
                      const userId = findMentionId(item);
                      props.onMentionPress(userId);
                    }}
                  >{` ${item.substring(0, item.lastIndexOf("("))} `}</a>
                ) : item.includes("http") ? (
                  <span>{item}</span>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.replaceAll("\n", "<br/>"),
                    }}
                  />
                )
              )}
            </Linkify>
          </div>
        )}
      </div>
    </ChatItem>
  );
};

export const ChatItemMessage = React.memo(_ChatItemMessage);
