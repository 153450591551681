// import { Action } from "history";
import { IChat } from "@/shared";
import { Action } from "redux";

export class SetChats implements Action {
  readonly type = "SET_CHATS";
  constructor(
    public readonly payload: {
      chats: IChat[];
    }
  ) {}
}

export class SelectChatId implements Action {
  readonly type = "SELECT_CHAT_ID";
  constructor(
    public readonly payload: {
      id: number;
    }
  ) {}
}

export class SelectAllChats implements Action {
  readonly type = "SELECT_ALL_CHATS";
  constructor(
    public readonly payload: {
      chats: IChat[];
    }
  ) {}
}

export class SelectChat implements Action {
  readonly type = "SELECT_CHAT";
  constructor(
    public readonly payload: {
      chat: IChat;
    }
  ) {}
}

export class UnselectChat implements Action {
  readonly type = "UNSELECT_CHAT";
}

export class SetUnreadMessagesCount implements Action {
  readonly type = "SET_UNREAD_MESSAGES_COUNT";
  constructor(public readonly payload: number) {}
}

export class IncreaseUnreadMessagesCount implements Action {
  readonly type = "INCREASE_UNREAD_MESSAGES_COUNT";
  constructor(public readonly payload: number) {}
}

export class DecreaseUnreadMessagesCount implements Action {
  readonly type = "DECREASE_UNREAD_MESSAGES_COUNT";
  constructor(public readonly payload: number) {}
}

export type TChatActions =
  | SetChats
  | SelectChatId
  | SelectAllChats
  | SelectChat
  | UnselectChat
  | SetUnreadMessagesCount
  | IncreaseUnreadMessagesCount
  | DecreaseUnreadMessagesCount;
