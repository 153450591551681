import React, { useEffect, useState } from "react";
import { Tree, Popconfirm, Spin } from "antd";
import { genTree } from "../../../../lib/helper";
import _ from "lodash";
import "./style.scss";
import {
  ITaxonomy,
  IUser,
  ETaxonomyType,
  ConfirmModal,
  notification,
  checkPermission,
  Permissions,
} from "@/shared";
import { taxonomiesService } from "@/services/domain/taxonomies.service";
import TrashSvgIcon from "../../../../assets/img/trash-icon.svg";
import GroupTaskIcon from "../../../../assets/img/tasks-group-icon.svg";
import { useSelector } from "react-redux";
import { getMyPermissions } from "@/store/permissions";

interface IProps {
  taxonomiesList: ITaxonomy[];
  type: string;
  isLoading: boolean;
  profile: IUser;
  onShowModal: (value: boolean) => void;
  searchVal: string;
}

export const TreeTaxonomy = (props: IProps) => {
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>(null);
  const [isOpenConfirmModal, setConfirmModal] = useState<boolean>(false);
  const [taxonomyIdToDelete, setTaxonomyIdToDelete] = useState<number>();
  const [showPopconfirm, setShowPopconfirm] = useState<number>(null);
  const permissions = useSelector(getMyPermissions);

  const preparedTree = genTree(
    _.cloneDeep(props.taxonomiesList).filter((item) => {
      if (!props.type) {
        return true;
      }
      return item.type === props.type;
    }),
    undefined,
    undefined,
    "parent_id"
  );

  const getParentKey = (id, tree) => {
    let parentKey;

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.id === id)) {
          parentKey = node.id;
        } else if (getParentKey(id, node.children)) {
          parentKey = getParentKey(id, node.children);
        }
      }
    }
    return parentKey;
  };

  const findParentKeys = (arr: ITaxonomy[], str: string): string[] =>
    arr
      .map((item) => {
        if (item.name.toLowerCase().indexOf(str.toLowerCase()) > -1) {
          const parentKey = getParentKey(item.id, preparedTree);

          return parentKey;
        }
        if (item.children.length) return findParentKeys(item.children, str);
      })
      .filter((item, i, self) => {
        return item && self.indexOf(item) === i;
      });

  const onExpand = (expandedKeys: string[]) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  useEffect(() => {
    if (!props.searchVal) {
      setExpandedKeys([]);
      setAutoExpandParent(true);
      return;
    }

    const expandedKeys = findParentKeys(props.taxonomiesList, props.searchVal);

    setAutoExpandParent(true);
    setExpandedKeys(expandedKeys.map((key) => String(key)));
  }, [props.searchVal]);

  const onClickDelete = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    taxonomy: ITaxonomy
  ) => {
    e.preventDefault();

    if (taxonomy.isDefault) {
      setShowPopconfirm(taxonomy.id);
    } else {
      setTaxonomyIdToDelete(taxonomy.id);
      setConfirmModal(true);
    }
  };

  const renderTitle = (item: ITaxonomy) => {
    const index = item.name.indexOf(props.searchVal);
    const beforeStr = item.name.substr(0, index);
    const afterStr = item.name.substr(index + props.searchVal.length);

    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span className="search-title">{props.searchVal}</span>
          {afterStr}
        </span>
      ) : (
        <span>{item.name}</span>
      );

    return (
      <div className="tree-title">
        <div
          onClick={() => {
            taxonomiesService.setTargetTaxonomy(item);
            props.onShowModal(true);
          }}
        >
          {item.iconUrl ? (
            <img src={item.iconUrl} alt="" />
          ) : (
            props.type === ETaxonomyType.taskCategory && (
              <img src={GroupTaskIcon} alt="" />
            )
          )}
          <div
            className="text"
            style={{
              fontWeight:
                item.type === ETaxonomyType.taskCategory && item.isDefault
                  ? "bold"
                  : "normal",
            }}
          >
            {title}
          </div>
        </div>

        {checkPermission(
          "tabs",
          "taxonomy",
          props.profile,
          permissions,
          Permissions.DESTROY
        ) && (
          <Popconfirm
            visible={showPopconfirm === item.id}
            title="Для видалення даної групи встановіть спочатку іншу групу групою по-замовчуванню "
            showCancel={false}
            okText="OK"
            onConfirm={() => setShowPopconfirm(null)}
          >
            <img
              alt="icon-trash"
              src={TrashSvgIcon}
              className="icon-trash"
              onClick={(e) => onClickDelete(e, item)}
            />
          </Popconfirm>
        )}
      </div>
    );
  };

  let tree = genTree(
    _.cloneDeep(props.taxonomiesList).filter((item) => {
      if (!props.type) {
        return true;
      }
      return item.type === props.type;
    }),
    undefined,
    undefined,
    "parent_id"
  );

  const renderTree = (tree: any) => {
    return _.sortBy(tree, (taxonomy) => taxonomy.name.toLowerCase()).map(
      (item) => {
        return (
          <Tree.TreeNode title={renderTitle(item)} key={item.id}>
            {item.children && renderTree(item.children)}
          </Tree.TreeNode>
        );
      }
    );
  };

  const deleteTaxonomy = async () => {
    try {
      await taxonomiesService.deleteTaxonomy(taxonomyIdToDelete);
    } catch (e) {
      notification.showError(
        "Помилка",
        `Не вдалось видалити ${
          props.type === ETaxonomyType.taskCategory ? "групу" : "підставу"
        }`
      );
    } finally {
      setConfirmModal(false);
    }
  };

  return (
    <div className="TreeTaxonomy">
      {props.isLoading && (
        <div className="taxonomy-spinner">
          <Spin />
        </div>
      )}

      <ConfirmModal
        isShow={isOpenConfirmModal}
        message={`Ви справді хочете видалити ${
          props.type === ETaxonomyType.taskCategory ? "групу" : "підставу"
        }?`}
        onConfirm={() => deleteTaxonomy()}
        setShow={setConfirmModal}
      />
      <Tree
        style={{ background: "none" }}
        blockNode
        onExpand={onExpand}
        autoExpandParent={autoExpandParent}
        expandedKeys={expandedKeys}
      >
        {renderTree(tree)}
      </Tree>
    </div>
  );
};
