import { ChatMemberRole, IconComponent } from "@/shared";
import React, { FC } from "react";
import trashIcon from "@/assets/img/trash-icon.svg";

interface IProps {
  role: ChatMemberRole;
  deleteUser: () => void;
  possibleToRemoveAdmin: boolean;
}
export const DeleteUserButton: FC<IProps> = ({
  deleteUser,
  possibleToRemoveAdmin,
  role,
}) => {
  const isAdmin = role === ChatMemberRole.Admin;
  return (
    <div className="delete-user-btn">
      <button onClick={deleteUser} disabled={!possibleToRemoveAdmin && isAdmin}>
        <IconComponent name={trashIcon} />
      </button>
    </div>
  );
};
