import React, { FC } from "react";
import "./style.scss";

interface IProps {
  title: string;
}

export const ErrorLabel: FC<IProps> = ({ title }) => {
  return <div className="error-tooltip">{title}</div>;
};
