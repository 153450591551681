import { createStore, combineReducers } from "redux";
import { GlobalContainerService } from "@/services/system";
import { authReducer, IAuthState } from "./auth";
import { composeWithDevTools } from "redux-devtools-extension";
import { accountReducer, IAccountState } from "./account";
import { ISharedState, sharedReducer } from "./shared";
import { ITaxonomiesState, TaxonomiesReducer } from "./taxonomies/reducer";
import {
  GroupPermissionReducer,
  IGroupPermissionState,
} from "./group-permission";
import { INotificationState, NotificationReducer } from "./notification";
import { ErrorReducer, IErrorState } from "./error/reducer";
import { factoriesReducer, IFactoriesState } from "./factories";
import { chatsReducer, IChatsState } from "./chats";
import { IPermissionsState, PermissionsReducer } from "./permissions";
import { ITaskState, taskReducer } from "./task";
import { IUsersState, usersReducer } from "./users";
import { chatBgReducer, IChatBgState } from "./chat-bg/reducer";

const rootReducer = combineReducers<{
  auth: IAuthState;
  account: IAccountState;
  taxonomies: ITaxonomiesState;
  groupPermission: IGroupPermissionState;
  permissions: IPermissionsState;
  notification: INotificationState;
  error: IErrorState;
  factories: IFactoriesState;
  shared: ISharedState;
  avatar: IAccountState;
  chats: IChatsState;
  task: ITaskState;
  users: IUsersState;
  chatBg: IChatBgState;
}>({
  auth: authReducer,
  account: accountReducer,
  taxonomies: TaxonomiesReducer,
  groupPermission: GroupPermissionReducer,
  permissions: PermissionsReducer,
  notification: NotificationReducer,
  error: ErrorReducer,
  avatar: accountReducer,
  task: taskReducer,
  factories: factoriesReducer,
  shared: sharedReducer,
  chats: chatsReducer,
  users: usersReducer,
  chatBg: chatBgReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, composeWithDevTools());

GlobalContainerService.set("store", store);

export default store;
