import { IColumn } from "@/shared"
import React from "react"

export const VOID_COLUMN_KEY = 'void-column'

export const voidColumn: IColumn = {
    key: VOID_COLUMN_KEY,
    name: " ",
    resizable: false,
    sortable: false,
    frozen: true,
    formatter: () => <div/>
}