import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { pageLimitConfig } from "../configs";
import { SelectNumberRow } from "./selectNumberRow";
interface IProps {
  isShow: boolean;
  toggle: () => void;
  onSetPaginationParams: any;
  numberRow: number;
}
export const FilterNumberRow = (props: IProps) => {
  const [numberRow, setNumberRow] = useState(10);

  useEffect(() => {
    if (props.numberRow && props.numberRow !== numberRow)
      setNumberRow(props.numberRow);
  }, [props.numberRow]);

  return (
    <Dropdown
      placement="topLeft"
      visible={props.isShow}
      onVisibleChange={() => props.toggle()}
      overlay={() => (
        <Menu
          style={{
            borderRadius: 20,
            padding: 10,
            top: -8,
            backgroundColor: "#FFFF",
            textAlign: "center",
          }}
        >
          {pageLimitConfig.map((el) => (
            <Menu.Item
              key={`item-count-${el}`}
              onClick={() => {
                props.onSetPaginationParams({ limit: el.value, page: 1 });
                setNumberRow(el.value);
                props.toggle();
              }}
              style={{ fontSize: 14 }}
            >{`${el.value === 99999 ? "Всі" : el.value} / сторінці`}</Menu.Item>
          ))}
        </Menu>
      )}
      trigger={["click"]}
    >
      <SelectNumberRow limitRow={numberRow} onPress={props.toggle} />
    </Dropdown>
  );
};
