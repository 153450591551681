import React, { FC, useMemo } from "react";
import "./style.scss";

interface IProps {
  label: string;
  message: string;
}

const LENGTH_STRING_TO_RENDER = 45;
const ALLOWED_NEW_LINES = 3;

const regexToMatch = /@\[([^[]*)]\(([^(^)]*)\)/g;
const parseMentionMessage = (message: string, regex: RegExp): string => {
  const subst = ` @$1 `;
  const result = message.replace(regex, subst);

  return result;
};

export const ChatCardInfo: FC<IProps> = ({ label, message }) => {
  const messageToRender = useMemo(() => {
    if (!message) return "ще немає повідомлень.";

    const sliceMessageOnNewLines = message.split("\n");
    const newLines = sliceMessageOnNewLines.length;

    if (message.length > LENGTH_STRING_TO_RENDER) {
      const preparedMessage = message.substr(0, LENGTH_STRING_TO_RENDER);

      return `${preparedMessage}...`;
    }

    if (newLines > ALLOWED_NEW_LINES) {
      const preparedMessage = sliceMessageOnNewLines
        .splice(0, ALLOWED_NEW_LINES)
        .join("\n");

      return `${preparedMessage}...`;
    }

    return message;
  }, [message]);

  return (
    <div className="chat-info-container">
      <p className="chat-label">{label}</p>

      <p className="last-message-info">
        {parseMentionMessage(messageToRender, regexToMatch)}
      </p>
    </div>
  );
};
