import { ETaskStatus, ITaskFilter } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TTaskActions } from "./types";

export const filterInitialState = {
  isMy: false,
  isOverdue: false,
  isStar: false,
  executor: null,
  creator: null,
  group: [],
  status: ETaskStatus.Active,
  date: new Date(),
  startDate: null,
  endDate: null
};
export interface ITaskState {
  filter: ITaskFilter;
}

const initialState: ITaskState = {
  filter: filterInitialState
};

export const taskReducer = createReducer<ITaskState, TTaskActions>(
  initialState,
  {
    SET_TASK_FILTER: (state, action) => {
      return {
        ...state,
        filter: action.payload.filter
      };
    },
    CLEAR_FILTER: state => {
      return {
        ...state,
        filter: filterInitialState
      };
    }
  }
);
