import React, { FC } from "react";
import { AttachmentsMenuItem } from "../atoms";
import { attachmentsMenuConfig } from "../configs";
import { IChatMessage } from "../plugins";
import "./style.scss";

interface IAttachmentsProps {
  isMenuOpen: boolean;
  chatId: number;
  onPressItem: (key: string) => void;
  replyToMessage?: IChatMessage;
  onSend: () => void;
}

export const AttachmentsMenu: FC<IAttachmentsProps> = ({
  isMenuOpen,
  chatId,
  onPressItem,
  replyToMessage,
  onSend,
}) => {
  if (!isMenuOpen) return null;
  return (
    <div className={"attachments-container"}>
      {attachmentsMenuConfig.map((it, i) => (
        <AttachmentsMenuItem
          key={i}
          iconName={it.icon}
          title={it.title}
          name={it.name}
          chatId={chatId}
          type={it.type}
          onPress={onPressItem}
          replyToMessage={replyToMessage}
          onSend={onSend}
        />
      ))}
    </div>
  );
};
