import React from "react";
import { Avatar, createFullName, EUserStatus, IUser } from "@/shared";
import _ from "lodash";
import TrashSvgIcon from "../../../assets/img/trash-icon.svg";

export const secretUsersTableColumns = ({
  onPressName,
  onPressRemove,
}): any => {
  return [
    {
      name: "Аватар",
      key: "avatarUrl",
      filter: false,
      width: 65,
      maxWidth: 75,
      formatter: ({ row }) => {
        if (_.isEmpty(row)) return null;

        return <Avatar url={row?.info?.avatarUrl} />;
      },
    },
    {
      name: "П.І.Б.",
      key: "name",
      resizable: true,
      sortable: true,
      sortKey: "lastName",
      flex: 1,
      filter: true,
      filterType: "search",
      formatter: ({ row }: { row: IUser }) => {
        return (
          <div className="column-name" onClick={(e) => onPressName(row, e)}>
            <div className="info">
              <div className="ellipsis">
                <span
                  className={
                    row.status === EUserStatus.Active
                      ? "full-name"
                      : "full-name-blocked"
                  }
                >
                  {createFullName(
                    row.info?.firstName,
                    row.info?.middleName,
                    row.info?.lastName
                  )}
                </span>
              </div>
              <span
                className={`${
                  row.status === EUserStatus.Active
                    ? "position"
                    : "blocked-position"
                } ellipsis`}
              >
                {row.info?.position}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      name: "Email",
      dataIndex: "email",
      key: "email",
      cellClass: (row: IUser) => {
        if (row.status === EUserStatus.Active) return "";
        return "blocked-text";
      },

      resizable: true,
      sortable: true,
      filter: true,
      filterType: "search",
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div className="ellipsis" onClick={(e) => onPressName(row, e)}>
            <span>{row?.email}</span>
          </div>
        );
      },
    },
    {
      name: "",
      key: "actions",
      width: 90,
      formatter: ({ row }) => {
        return (
          <img
            src={TrashSvgIcon}
            className="icon-trash"
            onClick={() => onPressRemove(row.id)}
          />
        );
      },
    },
  ];
};
