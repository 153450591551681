import React, { FC } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { createFullName, IUser } from "@/shared";
import { Actions } from "./Actions";
import { Avatar } from "@/containers/Profile/components/avatar";

interface IProps {
  //   isUpdate: boolean;
  contact: IUser;
}

export const ContactMain: FC<IProps> = ({ contact }) => {
  const getContactWorkTxt = () => {
    if (contact.factoryName) {
      if (contact.info?.position)
        return `${contact.factoryName}, ${contact.info.position}`;

      return contact.factoryName;
    }
    return contact.info?.position ? contact.info.position : "";
  };
  const contactWorkTxt = getContactWorkTxt();

  return (
    <>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="contact__card">
            <div
              className="contact__information"
              style={{ border: "none", padding: 0 }}
            >
              <div>
                <Avatar
                  profile={contact}
                  authProfile={contact}
                  isUpdate={false}
                  onChangeAvatar={() => {}}
                  onDeleteAvatar={() => {}}
                />
              </div>

              <div className="contact__data">
                <p className="contact__name">
                  {createFullName(
                    contact.info?.firstName,
                    contact.info?.middleName,
                    contact.info?.lastName
                  )}
                </p>
                <p
                  className="contact__work"
                  dangerouslySetInnerHTML={{
                    __html: contactWorkTxt,
                  }}
                />
              </div>
            </div>

            <Actions contact={contact} />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
