import { sessionsApi, usersApi } from "@/api";
import { EUserStatus, IUser } from "@/shared";
import { useState } from "react";

interface IProps {
  user: IUser;
  onAction?: (type?: "block" | "logout") => void;
}

interface IModalState {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
}

const defaultModalState = {
  isOpen: false,
  message: "",
  onConfirm: () => {}
};

export const useUserActions = ({ user, onAction }: IProps) => {
  const [confirmModal, setConfirmModal] = useState<IModalState>(
    defaultModalState
  );

  const openConfirmModal = (message: string, onConfirm: () => void) => {
    setConfirmModal({ isOpen: true, message, onConfirm });
  };

  const afterAction = (type?: "block" | "logout") => {
    setConfirmModal(defaultModalState);
    if (onAction) onAction(type);
  };

  const onChangeStatus = async (id: number, status: EUserStatus) => {
    await usersApi.changeStatus({ id, status });
    afterAction("block");
  };

  const onPressBanUser = async () => {
    if (user.status === EUserStatus.Active)
      openConfirmModal("Ви справді хочете заблокувати?", () =>
        onChangeStatus(user.id, EUserStatus.Blocked)
      );

    if (user.status === EUserStatus.Blocked)
      openConfirmModal("Ви справді хочете розблокувати?", () =>
        onChangeStatus(user.id, EUserStatus.Active)
      );
  };

  const onLogoutUser = async () => {
    openConfirmModal("Закрити всі активні сесії?", async () => {
      await sessionsApi.logoutUser(user.id);
      afterAction("logout");
    });
  };

  return {
    confirmModal: {
      state: confirmModal,
      set: setConfirmModal,
      open: openConfirmModal
    },
    onPressAction: {
      ban: onPressBanUser,
      logout: onLogoutUser
    }
  };
};
