import { Location } from "history";
import _ from "lodash";

export const hasHash = (location: Location, hashStr: string) => {
  const { hash } = location;
  return _.includes(hash, hashStr);
};

export const getIdFromHash = (hash: string) => {
  const hashArr = hash.split("_");
  if (hashArr.length <= 1) return null;

  return hashArr[hashArr.length - 1];
};
