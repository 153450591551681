export enum FileType {
  DOC = "doc",
  GIF = "gif",
  JPEG = "jpeg",
  JPG = "jpg",
  MP3 = "mp3",
  MP4 = "mp4",
  PDF = "pdf",
  PNG = "png",
  SVG = "svg",
  TXT = "txt",
  XLS = "xls",
  ZIP = "zip"
}
