import React, { useEffect, useState } from "react";
import { ButtonToolbar, Button } from "reactstrap";
import _ from "lodash";
import { Tooltip } from "antd";
import {
  ITaxonomy,
  ETaxonomyType,
  CheckBoxForm,
  ICreateOrUpdateTaxonomy,
  SelectAvatar,
} from "@/shared";
import { Controller, useForm } from "react-hook-form";
import { InputField, TreeSelectField } from "@/components/Fields";
import { taxonomiesService } from "@/services/domain/taxonomies.service";
import groupIcon from "@/assets/img/group_icon.png";
import "../../style.scss";

interface IProps {
  type: ETaxonomyType;
  targetTaxonomy: ICreateOrUpdateTaxonomy;
  taxonomiesList: ITaxonomy[];
  setShowModal: (val: boolean) => void;
  paramsType: any;
  editable?: boolean;
}

interface IFormData {
  name: string;
  parentId: number;
  isDefault: boolean;
}

const defaultValues = {
  name: "",
  parentId: null,
  isDefault: false,
};

export const Form = (props: IProps) => {
  const [file, setFile] = useState<any>(null);

  const { handleSubmit, setError, control, reset } = useForm<IFormData>({
    defaultValues,
  });

  useEffect(() => {
    if (props.targetTaxonomy)
      reset({
        name: props.targetTaxonomy.name,
        parentId: props.targetTaxonomy.parentId,
        isDefault: props.targetTaxonomy.isDefault,
      });
  }, [props.targetTaxonomy]);

  const onSubmit = (data: IFormData) => {
    if (data.parentId && props.targetTaxonomy?.id === data.parentId) {
      setError("parentId", {
        type: "manual",
        message: "Невірна батьківська категорія",
      });
      return;
    }
    if (props.targetTaxonomy.id) {
      props.setShowModal(false);
      taxonomiesService.updateTaxonomy(props.targetTaxonomy.id, {
        ...data,
        icon: file,
      });
    } else {
      props.setShowModal(false);
      taxonomiesService.createTaxonomy({
        ...data,
        type: props.targetTaxonomy?.type,
        icon: file,
      });
    }
  };

  let av = props.targetTaxonomy.iconUrl;
  return (
    <form
      className="form form__add-taxonomy"
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingTop: 20 }}
    >
      {props.type === ETaxonomyType.taskCategory && (
        <div style={{ position: "relative", marginBottom: 20 }}>
          <SelectAvatar
            image={av}
            title="Зміна аватару групи"
            defaultImg={groupIcon}
            onChange={setFile}
            className="taxonomy__avatar"
            label="Аватар"
            disabled={!props.editable}
          />
          {props.targetTaxonomy.iconUrl && props.editable && (
            <div
              style={{
                position: "absolute",
                color: "red",
                top: 20,
                left: 0,
                cursor: "pointer",
              }}
            >
              <Tooltip placement="bottom" title={"Видалити іконку"}>
                <i
                  className="fal fa-trash-alt"
                  onClick={() => {
                    setFile(null);
                    taxonomiesService.deleteTaxonomyIcon(
                      props.targetTaxonomy.id
                    );
                  }}
                ></i>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      <Controller
        control={control}
        name="name"
        rules={
          !props?.targetTaxonomy?.id && { required: "Заповнити обовязково" }
        }
        defaultValue={props?.targetTaxonomy?.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <InputField
            register={{ onChange, onBlur, value, disabled: !props.editable }}
            error={error?.message}
            label={"Назва"}
          />
        )}
      />

      <div style={{ marginTop: 20, width: "100%" }}>
        <Controller
          control={control}
          name="parentId"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <TreeSelectField
              register={{
                ref,
                value,
                onBlur,
                onChange: (val: number) => {
                  if (!val) onChange(null);
                  else onChange(val);
                },
                disabled: !props.editable,
              }}
              error={error?.message}
              touched={isTouched}
              placeholder="Батьківська категорія"
              label="Батьківська категорія"
              tree={props.taxonomiesList.filter(
                (item) => props.type === item.type
              )}
            />
          )}
        />
      </div>

      <div style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: 20 }}>
          {props.type === ETaxonomyType.taskCategory && (
            <div
              className="form__form-group"
              style={{ display: "flex", alignItems: "center" }}
            ></div>
          )}
        </div>
        {props.type === ETaxonomyType.taskCategory && (
          <div className="form__form-group-field" style={{ marginBottom: 30 }}>
            <Controller
              control={control}
              name={"isDefault"}
              defaultValue={false}
              render={({ field }) => (
                <CheckBoxForm
                  label={"По-замовчуванню"}
                  field={{
                    ...field,
                    onChange: (val: any) => {
                      if (props.editable) field.onChange(val);
                    },
                    checked: field.value,
                  }}
                />
              )}
            />
          </div>
        )}

        {props.editable && (
          <ButtonToolbar
            className="form__button-toolbar"
            style={{ marginTop: -5 }}
          >
            <Button color="primary" type="submit" style={{ width: 150 }}>
              Зберегти
            </Button>
          </ButtonToolbar>
        )}
      </div>
    </form>
  );
};
