import { IChatDetails, IMessage, MessageType } from "@/shared";
import { appEvents } from "@/shared/events";
import _ from "lodash";
import moment from "moment";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ChatDownButton } from "../atoms";
import ChatLoader from "../atoms/chatloader.atom";
import { ChatItemAudio } from "./chat-item-audio.component";
import { ChatItemFile } from "./chat-item-file.component";
import { ChatItemImage } from "./chat-item-image.component";
import { ChatItemMessage } from "./chat-item-message.component";
import { ChatItemSticker } from "./chat-item-sticker.component";
import { ChatItemSystem } from "./chat-item-system.component";
import { ChatItemVideo } from "./chat-item-video.component";
import "./style.scss";

interface ChatMessagesProps {
  items: IMessage[];
  selectedChatId?: number;
  chatDetails?: IChatDetails;
  userId: number;
  onMessageMenuPress?: (id: number) => void;
  onProfilePress?: (id: number) => void;
  onRefresh?: () => void;
  loadPrev?: () => void;
  loadNext?: (limit?: number) => void;
  isRefresh?: boolean;
  isLoadingPrev?: boolean;
  isLoadingNext?: boolean;
  lastMessageLoaded?: boolean;
  firstMessageLoaded?: boolean;
  //   suggestionsItems?: ISuggestionUser[]
  onPressSuggestion?: (id: number) => void;
  onMentionPress?: (id: number) => void;
  onForwardedAuthorPress?: (id: number) => void;
  onPressReplied?: (id: number) => void;
  scrollToId?: number;
  afterScroll?: () => void;
  height?: string | number;

  onForwardPressMessage?: (message: IMessage) => void;
}

export const ChatMessages: FC<ChatMessagesProps> = ({
  items,
  selectedChatId,
  chatDetails,
  height = "62vh",
  ...props
}) => {
  const [activeAudioId, setActiveAudioId] = useState<number>(null);
  const [lastOffset, setOffset] = useState(0);

  const listRef = useRef(null);
  const ref = useRef(null);
  // const lastIdRef = useRef(null);

  const messagesGroups = useMemo(() => {
    const groups: Record<string, any> = {};

    items
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((it) => {
        const createdAt = moment(it.createdAt);

        const key =
          createdAt >= moment().startOf("day")
            ? "Сьогодні"
            : createdAt >=
              moment()
                .subtract(1, "days")
                .startOf("day")
            ? "Вчора"
            : createdAt.format("DD-MM-YYYY");

        it.isMy = it.authorId === props.userId;
        it.time = moment(it.createdAt).format("HH:mm");

        if (groups[key]) {
          groups[key].data.push(it);
        } else {
          groups[key] = {
            title: key,
            data: [it],
          };
        }
      });

    const result = [];

    Object.keys(groups).map((key) => {
      const group = groups[key];
      group.data.map((item) => {
        result.push(item);
      });
      result.push({
        id: key,
        content: key,
        authorId: null,
        type: MessageType.Date,
      });
    });

    return result;
  }, [items]);

  const isSystem = (type: MessageType) =>
    type === MessageType.Date ||
    type === MessageType.MissedCall ||
    type === MessageType.SuccessCall ||
    type === MessageType.DeleteChatMember ||
    type === MessageType.NewChatMembers ||
    type === MessageType.NewChat;

  const getItem = (item: IMessage) => {
    if (!item?.type || item?.type === MessageType.Text) {
      return (
        <ChatItemMessage
          {...item}
          onMenuPress={() => props.onMessageMenuPress(item.id)}
          onMentionPress={props.onMentionPress}
          onForwardPressMessage={() => props.onForwardPressMessage(item)}
          onForwardedAuthorPress={props.onForwardedAuthorPress}
        />
      );
    }
    if (item?.type === MessageType.Image) {
      return (
        <ChatItemImage
          {...item}
          onMenuPress={() => props.onMessageMenuPress(item.id)}
          onForwardPressMessage={() => props.onForwardPressMessage(item)}
          onForwardedAuthorPress={props.onForwardedAuthorPress}
        />
      );
    }
    if (item?.type === MessageType.File) {
      return (
        <ChatItemFile
          {...item}
          onMenuPress={() => props.onMessageMenuPress(item.id)}
          onForwardPressMessage={() => props.onForwardPressMessage(item)}
          onForwardedAuthorPress={props.onForwardedAuthorPress}
        />
      );
    }

    if (item?.type === MessageType.Audio) {
      return (
        <ChatItemAudio
          {...item}
          onMenuPress={() => props.onMessageMenuPress(item.id)}
          onPressPlay={() => setActiveAudioId(item.id)}
          activeAudioId={activeAudioId}
        />
      );
    }

    if (item?.type === MessageType.Video) {
      return (
        <ChatItemVideo
          {...item}
          onMenuPress={() => props.onMessageMenuPress(item.id)}
          onForwardPressMessage={() => props.onForwardPressMessage(item)}
          onForwardedAuthorPress={props.onForwardedAuthorPress}
        />
      );
    }

    if (item?.type === MessageType.Sticker) {
      return (
        <ChatItemSticker
          {...item}
          onMenuPress={() => props.onMessageMenuPress(item.id)}
          onForwardPressMessage={() => props.onForwardPressMessage(item)}
          onForwardedAuthorPress={props.onForwardedAuthorPress}
        />
      );
    }

    if (isSystem(item?.type)) {
      return <ChatItemSystem {...item} />;
    }
  };

  const renderItems = useCallback(
    ({ item, index }) => {
      const itemByType = getItem({
        ...item,
        onProfilePress: () => props.onProfilePress(item.author.id),
      });
      if (itemByType)
        return (
          <div id={item.id} key={index}>
            {itemByType}
          </div>
        );

      return null;
    },
    [items, activeAudioId]
  );

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });

    if (selectedChatId) {
      appEvents.emit("onReadChat", {
        chatId: selectedChatId,
        unreadCount: chatDetails.unreadMessagesCount || 0,
      });
    }
  };

  const onScrollStart = (e: MouseEvent) => {
    const { lastScrollTop } = listRef.current;

    const infiniteOffset = Math.abs(lastScrollTop);

    setOffset(infiniteOffset);

    // props.loadNext();
  };

  useEffect(() => {
    if (!props.scrollToId) return null;

    const index = _.findIndex(
      messagesGroups,
      (item) => item.id === props.scrollToId
    );

    const { lastScrollTop } = listRef.current;
    if (lastScrollTop <= 5) props.loadNext();

    const element = document.getElementById(props.scrollToId.toString());

    setTimeout(() => {
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      props.afterScroll();
    }, 300);
  }, [props.scrollToId, messagesGroups, listRef]);

  const bottomBtn = useMemo(() => {
    if (!props.lastMessageLoaded && !_.isEmpty(items))
      return <ChatDownButton onPress={props.onRefresh} />;
    if (!_.isEmpty(items) && lastOffset > 100)
      return <ChatDownButton onPress={handleClick} />;
  }, [items, lastOffset, props.lastMessageLoaded]);

  return (
    <div
      id="scrollableDiv"
      style={{
        height,
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <InfiniteScroll
        ref={listRef}
        className="infinite-scroll-messages-container"
        next={props.loadPrev}
        hasMore={!props.firstMessageLoaded}
        loader={
          <div className="loader-styles">
            <ChatLoader customText={"Loading..."} />
          </div>
        }
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse={true}
        height={height}
        dataLength={messagesGroups.length}
        refreshFunction={props.onRefresh}
        onScroll={onScrollStart}
        endMessage={
          !_.isEmpty(chatDetails?.pinedMessages) && (
            <div style={{ minHeight: "80px" }}></div>
          )
        }
        scrollableTarget="scrollableDiv"
      >
        <div ref={ref} />
        {messagesGroups.map((item, index) => renderItems({ item, index }))}
      </InfiniteScroll>

      {bottomBtn}
    </div>
  );
};
