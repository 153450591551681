import { voidColumn } from "@/components/TableGrid/configs";
import {
  checkPermission,
  IColumn,
  IMyPermissions,
  IUser,
  Permissions,
} from "@/shared";
import React from "react";

interface IProps {
  profile: IUser;
  permissions: IMyPermissions;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export const getColumnsConfig = (props: IProps): IColumn[] => [
  voidColumn,
  {
    name: "Назва групи прав",
    key: "name",
    resizable: true,
    sortable: true,
    filter: true,
    filterType: "search",
    headerRenderer: ({ column }) => {
      return <div style={{ textAlign: "left" }}>{column.name}</div>;
    },
    formatter: ({ row }) => {
      return (
        <div className="ellipsis" style={{ textAlign: "left" }}>
          {row.name}
        </div>
      );
    },
  },
  {
    name: "Дії",
    key: "action",
    width: 100,
    formatter: ({ row }) => {
      return (
        <span>
          {checkPermission(
            "tabs",
            "group_permission",
            props.profile,
            props.permissions,
            Permissions.UPDATE
          ) && (
            <a
              className="lnr lnr-pencil"
              href="# "
              style={{ color: "rgba(158, 39, 67, 1)", fontSize: 18 }}
              onClick={() => props.onEdit(row.id)}
            />
          )}
          <span style={{ padding: "10px" }}> </span>

          {checkPermission(
            "tabs",
            "group_permission",
            props.profile,
            props.permissions,
            Permissions.DESTROY
          ) && (
            <a
              onClick={() => props.onDelete(row.id)}
              className="lnr lnr-trash"
              style={{ color: "rgba(158, 39, 67, 1)", fontSize: 18 }}
              href="# "
            />
          )}
        </span>
      );
    },
  },
];

export const defaultColumnsActive = ["name", "action"];
