import { ChatMessageActionEnum } from "@/containers/Chats/enums";
import { UserInfoModal } from "@/containers/Chats/modals";
import { ChatBar, ChatMessages } from "@/containers/Chats/plugins";
import { ConfirmModal } from "@/shared";
import { getProfile } from "@/store/account";
import _ from "lodash";
import React, { useState } from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { TabPane } from "reactstrap";
import { ETasksModalTabs } from "../enums";
import { useTaskComments } from "../hooks";

interface IProps {
  taskId: number;
  isActive: boolean;
  disabled?: boolean;
}

export const ModalTabComments: FC<IProps> = ({
  taskId,
  isActive,
  disabled,
}) => {
  const [isOpenUserInfoModal, setUserInfoModal] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const account = useSelector(getProfile);

  const {
    taskComments,
    addComment,
    resetList,
    newComment,
    setNewComment,
    isSending,
    onMessageActions,
    confirmModal,
    hideConfirmModal,
    editableComment,
    clearEditableComment,
  } = useTaskComments({ taskId, needInit: isActive });

  return (
    <TabPane tabId={ETasksModalTabs.Comments}>
      <UserInfoModal
        isOpen={isOpenUserInfoModal}
        userId={selectedUserId}
        toggle={() => setUserInfoModal(false)}
      />
      <div className="task-coments-field-area">
        <ChatMessages
          onMessageMenuPress={onMessageActions}
          userId={account.id}
          items={taskComments}
          onRefresh={resetList}
          onProfilePress={(id) => {
            setSelectedUserId(id);
            setUserInfoModal(true);
          }}
          firstMessageLoaded={true}
          lastMessageLoaded={true}
          height={_.isEmpty(taskComments) ? "20vh" : "45vh"}
        />

        {!disabled && (
          <ChatBar
            onPressSend={addComment}
            message={newComment}
            onChangeMessage={setNewComment}
            isSending={isSending}
            editedMessage={editableComment}
            onPressClearEditedMessage={clearEditableComment}
            onSend={_.noop}
          />
        )}

        <ConfirmModal
          isShow={confirmModal?.isShow}
          message={confirmModal?.message}
          onConfirm={confirmModal?.onConfirm}
          setShow={hideConfirmModal}
        />
      </div>
    </TabPane>
  );
};
