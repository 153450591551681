import { chatMessagesApi } from "@/api";
import { ChatType, IMessage } from "@/shared";
import { appEvents } from "@/shared/events";
import { SelectChatId } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";
import _ from "lodash";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { ChatsSelectListWithSearch } from "../components";
import { useSelectedChats } from "../hooks";
import { IChatMessage } from "../plugins/interfaces";

interface IProps {
  item: IMessage | IChatMessage[];
}

export const ChatsSelectListSmart: FC<IProps> = ({ item }) => {
  const history = useHistory();

  const {
    chats,
    selectedChats,
    selectAll,
    selectChat,
    unselectChat,
    searchString,
    setSearchString,
  } = useSelectedChats();

  const forwardMessage = async () => {
    const chatsIds = selectedChats.map((item) => item.id);
    if (_.isEmpty(chatsIds)) return;

    const messages = _.isArray(item)
      ? (item as IChatMessage[])
      : [item as IMessage];

    try {
      for await (const message of messages) {
        await chatMessagesApi.forwardMessageReq({
          messageId: message.id,
          chatsIds: chatsIds,
        });
      }

      appEvents.emit("closeForwardMessageModal", { isShow: false });

      if (
        selectedChats.length === 1 &&
        selectedChats[0].type === ChatType.Personal
      ) {
        await simpleDispatch(new SelectChatId({ id: selectedChats[0].id }));
        history.push(`/chats?id=${selectedChats[0].id}`);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  return (
    <ChatsSelectListWithSearch
      chats={chats}
      selectOne={selectChat}
      unSelectOne={unselectChat}
      searchString={searchString}
      onChange={setSearchString}
      selectAll={selectAll}
      forwardMessage={forwardMessage}
    />
  );
};
