import { useEffect, useState } from "react";
import { contactsService, usersService, tasksService } from "@/services/domain";
import { ITasksCountsByAuthor, IUser, IUserStats } from "@/shared";

export const useContact = (contactId: number) => {
  const [contactInfo, setContactInfo] = useState<IUser>();
  const [contactStats, setContactsStats] = useState<IUserStats>();
  const [contactTasksCount, setContactTasksCount] = useState<
    ITasksCountsByAuthor
  >();

  const fetchContact = async () => {
    try {
      const data = await contactsService.fetchContact(contactId);
      setContactInfo(data);
    } catch (error) {
      console.log("FETCH CONTACT DETAIL FAIL: ", error);
    }
  };

  const fetchContactStats = async () => {
    const data = await usersService.fetchUserStats(contactId);
    setContactsStats(data);
  };

  const fetchContactTasksStats = async () => {
    const data = await tasksService.fetchTasksCountsByAuthor(contactId);
    setContactTasksCount(data);
  };

  useEffect(() => {
    if (contactId) fetchContact();
  }, [contactId]);

  useEffect(() => {
    fetchContactStats();
  }, []);

  return {
    contactInfo,
    contactStats,
    contactTasksCount,
    fetchContact,
    fetchContactTasksStats,
  };
};
