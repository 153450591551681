import React, { FC } from "react";
import classNames from "classnames";
import CheckIcon from "mdi-react/CheckIcon";
import "./CheckField.scss";

interface IProps {
  label: string;
  isChecked: boolean;
  onChange: (value: any) => void;

  input: any;
  color: string;
  disabled: any;
  className: any;

  meta: { touched; error };
}

export const CheckField: FC<IProps> = ({
  label,
  disabled,
  className,
  input,
  color,
  isChecked,
  onChange,
}) => {
  const onCheckBoxChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    } else {
      input.onChange(e.target.checked);
    }
  };

  const CheckboxClass = classNames({
    "checkbox-btn": true,
    disabled,
  });

  const name = input.name;

  return (
    <div className="form__form-group-filter">
      <div className="form__form-group-field">
        <label
          className={`${CheckboxClass} ${
            className ? ` checkbox-btn--${className}` : ""
          }`}
          htmlFor={name}
        >
          <input
            className="checkbox-btn__checkbox"
            type="checkbox"
            id={name}
            name={name}
            onChange={onCheckBoxChange}
            checked={isChecked ? isChecked : input.value}
            // checked={true}
            disabled={disabled}
          />
          <span
            className="checkbox-btn__checkbox-custom"
            style={color ? { background: color, borderColor: color } : {}}
          >
            <CheckIcon />
          </span>
          {className === "button" ? (
            <span className="checkbox-btn__label-svg">
              <CheckIcon className="checkbox-btn__label-check" />
            </span>
          ) : (
            ""
          )}
          <span className="checkbox-btn__label">{label}</span>
        </label>
      </div>
    </div>
  );
};
