/* eslint-disable @typescript-eslint/no-unused-expressions */
import { isVisibleModal } from "./../../../../../store/account/types";
import { useMemo, useState, useEffect } from "react";
import { IUser, IUsersInfo } from "@/shared";
import { useForm } from "react-hook-form";
import { accountService } from "@/services/domain";
import { errorsKey } from "@/containers/Profile/config/errorsKey";
import { simpleDispatch } from "@/store/store-helpers";
import { IUpdateAccountReq } from "@/api/account/requests.interfaces";
import _ from "lodash";
import moment from "moment";

type ErrorsT = {
  login: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  personalPhoneNumber: string;
  loginExist: string;
  userExist: string;
};

const defaultErrorsState = {
  login: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  personalPhoneNumber: "",
  loginExist: "",
  userExist: "",
};

interface IProfileForm {
  fullName: string;
  email: string;
  dateOfBirth: string;
  phoneNumber: string;
  innerPhoneNumber: string;
  personalPhoneNumber: string;
  position: string;
  login: string;
  newPassword: string;
  confirmPassword: string;
  sendPasswordBy: "sms" | "email";
}

interface IProfileProps {
  profile: IUser;
  authId: number;
  onSuccessUpdate?: () => void;
  onError?: () => void;
}

export const useProfileForm = ({
  profile,
  authId,
  onSuccessUpdate,
  onError,
}: IProfileProps) => {
  const [errors, setError] = useState<ErrorsT>(defaultErrorsState);

  const [isDisableBtns, setBtns] = useState<boolean>(false);
  const [isErrorModal, setIsErrorModal] = useState({
    isShow: false,
    message: "",
  });
  const [prepareInfoUser, setPrepareInfoUser] = useState<IUpdateAccountReq>();
  useEffect(() => {
    if (!isErrorModal.isShow) {
      errorsKey.email.exist = null;
      errorsKey.phone_number.exist = null;
      errorsKey.personalPhone_number.exist = null;
      errorsKey.login_exist.exist = null;
    }
  }, [isErrorModal.isShow]);

  const preparedDefaultValues: IProfileForm = useMemo(() => {
    const profileInfo = profile.info;

    const login = profile?.login;
    const fullName = [
      profileInfo?.lastName,
      profileInfo?.firstName,
      profileInfo?.middleName,
    ]
      .filter((it) => it)
      .join(" ");

    return {
      fullName,
      email: profile.email,
      dateOfBirth: profileInfo.dateOfBirth,
      phoneNumber: profile.phoneNumber,
      innerPhoneNumber: profileInfo.innerPhoneNumber,
      personalPhoneNumber: profileInfo.personalPhoneNumber,
      position: profileInfo.position,
      login,
      newPassword: "",
      confirmPassword: "",
      sendPasswordBy: null,
    };
  }, [profile]);

  const { control, watch, handleSubmit, setValue, getValues } = useForm<
    IProfileForm
  >({
    defaultValues: preparedDefaultValues,
  });

  const generatePassword = (e) => {
    e.preventDefault();
    let genPassword = "";

    while (genPassword.length < 7)
      genPassword += Math.random()
        .toString(36)
        .substring(2);

    genPassword.substring(0, 7);

    setValue("newPassword", genPassword);
    setValue("confirmPassword", genPassword);
  };

  const watchNewPassword = watch("newPassword");
  const watchConfirmPassword = watch("confirmPassword");

  useEffect(() => {
    const { newPassword, confirmPassword } = getValues();

    if (newPassword && confirmPassword && newPassword === confirmPassword)
      setBtns(false);
    else setBtns(true);
  }, [watchNewPassword, watchConfirmPassword]);

  const showErrorSubmit = (value, message) => {
    if (value) {
      setIsErrorModal({ isShow: value, message: message });
    } else {
      setIsErrorModal({ isShow: false, message: "" });
    }
  };

  const catchErrorSubmit = () => {
    const {
      email,
      login_exist,
      personalPhone_number,
      phone_number,
      default_err,
    } = errorsKey;

    if (email.exist === true) {
      showErrorSubmit(email.exist, email.description);
      setError({ ...errors, email: email.description });
    } else if (login_exist.exist === true) {
      showErrorSubmit(login_exist.exist, login_exist.description);
      setError({ ...errors, loginExist: login_exist.description });
    } else if (phone_number.exist === true) {
      showErrorSubmit(phone_number.exist, phone_number.description);
      setError({ ...errors, phoneNumber: phone_number.description });
    } else if (personalPhone_number.exist === true) {
      showErrorSubmit(
        personalPhone_number.exist,
        personalPhone_number.description
      );
      setError({
        ...errors,
        personalPhoneNumber: personalPhone_number.description,
      });
    } else if (default_err) {
      if (onError) onError();
    } else if (onSuccessUpdate) onSuccessUpdate();
  };

  useEffect(() => {
    const idTimeOut = setTimeout(
      () => setIsErrorModal({ ...isErrorModal, isShow: false }),
      2000
    );
    return () => clearTimeout(idTimeOut);
  }, [isErrorModal.isShow]);

  const fullNameProfile = [
    profile.info?.lastName,
    profile.info?.firstName,
    profile.info?.middleName,
  ].join("");

  const validateFullName = async (value) => {
    const slicedFullName = value.split(" ");

    const existUser = await accountService.fetchExistUserData({
      lastName: slicedFullName[0],
      firstName: slicedFullName[1],
      middleName: slicedFullName[2],
    });
    const replaseValue = value.replace(/\s+/g, "");
    if (existUser.name && replaseValue !== fullNameProfile) {
      setError({ ...errors, userExist: "Такий користувач вже існує" });
    } else {
      setError({ ...errors, userExist: "", fullName: "" });
    }
  };

  const validateLogin = async (value) => {
    if (!value) return;
    const existLogin = await accountService.fetchExistUserData({
      login: value,
    });
    if (existLogin.login && profile.login !== value) {
      setError({ ...errors, loginExist: "Такий логін вже існує" });
      // } else {
      //   setError({ ...errors, loginExist: "" });
    }
  };

  const validatePhoneNumber = async (value) => {
    const telPhone = value.match(/\d/g).join("");

    const existPhoneNumber = await accountService.fetchExistUserData({
      phoneNumber: value,
    });
    if (
      existPhoneNumber.phoneNumber &&
      Number(telPhone) !== Number(profile.phoneNumber)
    ) {
      setError({ ...errors, phoneNumber: "Такий номер телефону вже існує" });
      // } else {
      //   setError({
      //     ...errors,
      //     phoneNumber: ""
      //   });
    }
  };

  const validatePersonalPhoneNumber = async (value) => {
    if (!value) return;
    const telPersonal = value.match(/\d/g).join("");

    const existPersPhoneNumber = await accountService.fetchExistUserData({
      personalPhoneNumber: value,
    });

    if (
      existPersPhoneNumber.personalPhoneNumber &&
      Number(profile.info.personalPhoneNumber) !== Number(telPersonal)
    ) {
      setError({
        ...errors,
        personalPhoneNumber: "Такий номер телефону вже існує",
      });
      // } else {
      //   setError({ ...errors, personalPhoneNumber: "" });
    }
  };

  const validateEmail = async (value) => {
    if (!value) return;
    const existEmail = await accountService.fetchExistUserData({
      email: value,
    });
    if (existEmail?.email && profile?.email !== value) {
      setError({ ...errors, email: "Такий email вже існує" });
      // } else {
      //   setError({ ...errors, email: "" });
    }
  };

  const submitChageAccount = async () => {
    try {
      await accountService.updateAccount(
        prepareInfoUser,
        profile.id,
        profile.id === authId
      );
      simpleDispatch(new isVisibleModal({ isVisibleModal: false }));
      setError(defaultErrorsState);
    } catch (e) {
      if (onError) onError();
      return console.log("errorPathUser=>", e);
    }
    catchErrorSubmit();
  };

  const existanceUserName = async (values) => {
    const slicedFullName = values.fullName.split(" ");
    return accountService.existenceUserName({
      firstName: slicedFullName[1],
      lastName: slicedFullName[0],
      middleName: slicedFullName[2],
    });
  };

  const choiceToSaveUserName = async (existName: boolean, updateProfile) => {
    if (existName) {
      simpleDispatch(new isVisibleModal({ isVisibleModal: true }));
    } else {
      simpleDispatch(new isVisibleModal({ isVisibleModal: false }));

      if (updateProfile.email) {
        updateProfile.email = updateProfile.email.toLowerCase();

        setValue("email", updateProfile.email);
      }

      await accountService.updateAccount(
        updateProfile,
        profile.id,
        profile.id === authId
      );

      setError(defaultErrorsState);
      catchErrorSubmit();
      setValue("sendPasswordBy", null);
    }
  };

  const onSubmit = async (values: IProfileForm) => {
    const dataToUpdate = _.omitBy(
      values,
      (it, key) =>
        preparedDefaultValues[key] === it || key === "confirmPassword"
    );

    if (_.isEmpty(dataToUpdate)) return;

    const existUser = !!errors["userExist"];

    const updateUserInfoValue: Partial<IUser &
      IUsersInfo & { password?: string }> = {};
    Object.keys(dataToUpdate).map((key) => {
      if (key === "fullName") {
        const slicedFullName = dataToUpdate[key].split(" ");
        updateUserInfoValue.firstName = slicedFullName[1];
        updateUserInfoValue.middleName = slicedFullName[2];
        updateUserInfoValue.lastName = slicedFullName[0];
        return;
      }
      if (key === "personalPhoneNumber" && _.isEmpty(dataToUpdate[key])) {
        updateUserInfoValue[key] = "deleted";
        return;
      }

      if (key === "newPassword") {
        updateUserInfoValue.password = dataToUpdate[key];
        return;
      }

      if (key === "dateOfBirth") {
        updateUserInfoValue.dateOfBirth = moment(dataToUpdate[key]).format(
          "YYYY-MM-DD"
        );
        return;
      }

      updateUserInfoValue[key] = dataToUpdate[key];
    });
    setPrepareInfoUser(updateUserInfoValue);
    choiceToSaveUserName(existUser, updateUserInfoValue);
  };

  const sendPasswordToEmail = (flag: boolean) => {
    const { newPassword, confirmPassword } = getValues();
    try {
      if (flag) {
        accountService.updatePassword({
          password: newPassword,
          comparePassword: confirmPassword,
          sendToEmail: true,
          sendToSms: false,
        });
      } else {
        accountService.updatePassword({
          password: newPassword,
          comparePassword: confirmPassword,
          sendToEmail: false,
          sendToSms: true,
        });
      }
    } catch (e) {
      console.log("error send email", e);
    }
  };

  return {
    errors,
    control,
    isDisableBtns,
    onSubmit: handleSubmit(onSubmit),
    generatePassword,
    isErrorModal,
    setIsErrorModal,
    submitChageAccount,
    validateFullName,
    validateLogin,
    validatePhoneNumber,
    validatePersonalPhoneNumber,
    validateEmail,
    sendPasswordToEmail,
    getValues,
    setError,
    setValue,
  };
};
