import { IPushNotification, NotificationsGroup } from "@/shared";
import moment from "moment";
import React from "react";
import BirthdayIcon from "@/assets/img/hb-icon-custom.svg";
import ChatIcon from "@/assets/img/chat-icon-custom.svg";
import TaskIcon from "@/assets/img/task-icon-custom.svg";

const iconByGroup: Record<NotificationsGroup, string> = {
  [NotificationsGroup.Chats]: ChatIcon,
  [NotificationsGroup.Tasks]: TaskIcon,
  [NotificationsGroup.Other]: BirthdayIcon,
  [NotificationsGroup.Custom]: TaskIcon,
};

interface IProps {
  item: IPushNotification;
  onPressItem: (item: IPushNotification) => void;
}

export const NotificationItem = ({ item, onPressItem }: IProps) => {
  const today = moment().startOf("day");
  const isTodayNotification = !moment(item.createDate).isBefore(today);
  const date = isTodayNotification
    ? moment(item.createDate).format("HH:mm")
    : moment(item.createDate).format("DD-MM-YYYY");

  const showTitle = item.group === NotificationsGroup.Custom;

  return (
    <div key={item.id} className="notification_content_item_wrapper">
      <div style={{ display: "flex" }}>
        <div className="notification_content_item">
          <div style={{ width: "13%" }}>
            <div className="icon">
              <img
                style={{
                  width: 10,
                }}
                src={iconByGroup[item.group]}
              />
            </div>
          </div>

          <div className="notification_text">
            {showTitle ? <p style={{ fontWeight: 500 }}>{item.title}</p> : null}
            <p>{item.content}</p>
          </div>
        </div>
        <div className="notification_right-block">
          <p>{date}</p>
        </div>
      </div>

      <div className="details_btn-block">
        {item?.data?.type ? (
          <button className="details_btn" onClick={() => onPressItem(item)}>
            <p style={{ color: "#9E2743" }}>Детальніше</p>
          </button>
        ) : null}
      </div>
      <div />
    </div>
  );
};
