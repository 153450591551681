import { BYTES_IN_KILOBYTE, BYTES_IN_MEGABYTE } from "../consts";

export const convertToMegabytes = (bytes: number) => {
  const MB = bytes / BYTES_IN_MEGABYTE;
  if (MB > 100) return Math.ceil(MB);
  return MB.toFixed(2);
};

export const convertToKilobytes = (bytes: number) => {
  const KB = bytes / BYTES_IN_KILOBYTE;
  if (KB > 100) return Math.ceil(KB);
  return KB.toFixed(2);
};
