import React, { FC } from "react";
import { Button } from "reactstrap";
import { SearchInput } from "../atoms";

interface IProps {
  disableIds?: number[];
  searchStr: string;
  onChange: (val: string) => void;
  selectAll: () => void;
}

export const ChatsSearchWithBtn: FC<IProps> = ({
  searchStr,
  onChange,
  selectAll,
}) => {
  return (
    <div className="forward-message-search-row">
      <SearchInput
        value={searchStr}
        onChange={onChange}
        placeholder="Знайдіть бесіду"
      />

      <Button
        style={{ width: "110px", height: "40px" }}
        name={"allSelect"}
        color="primary"
        onClick={selectAll}
      >
        Додати всіх
      </Button>
    </div>
  );
};
