import { ChatBgKeys, StorageKey } from "@/shared";
import { SetChatBg } from "@/store/chat-bg";
import { simpleDispatch } from "@/store/store-helpers";

const initApp = async () => {
  const currentChatBg = await localStorage.getItem(StorageKey.ChatBgId);

  simpleDispatch(
    new SetChatBg({
      bgId: currentChatBg ? (currentChatBg as ChatBgKeys) : ChatBgKeys.DEFAULT,
    })
  );
};

export const appService = {
  initApp,
};
