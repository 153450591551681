import React, { FC } from "react";
import { IUser } from "@/shared";
import "./style.scss";
import moment from "moment";

interface IProps {
  profile: IUser;
}

export const UserInfo: FC<IProps> = ({ profile }) => {
  const fullName = [
    profile.info?.lastName,
    profile.info?.firstName,
    profile.info?.middleName,
  ]
    .filter((it) => it)
    .join(" ");

  return (
    <div className="user-info_container">
      <div className="block left-block">
        <p>ПІБ: {fullName}</p>
        <p>Email: {profile.email}</p>
        <p>Посада: {profile.info?.position}</p>
        <p>Логін: {profile.login}</p>
      </div>
      <div className="block right-block">
        <p>
          Дата народження:{" "}
          {profile.info?.dateOfBirth
            ? moment(profile.info.dateOfBirth).format("DD-MM-YYYY")
            : ""}
        </p>
        <p>Телефон робочий: {profile.phoneNumber}</p>
        <p>Телефон внутрішній: {profile.info?.innerPhoneNumber}</p>
        <p>Телефон особистий: {profile.info?.personalPhoneNumber}</p>
      </div>
    </div>
  );
};
