import { IShortUser } from "@/shared";
import _ from "lodash";
import { ISelectUser } from "../interfaces";

export const transformUserToSelectList = (
  user: IShortUser,
  selectedUsers: IShortUser[],
  disabledIds?: number[]
): ISelectUser => ({
  ...user,
  isSelected: !_.isNil(_.find(selectedUsers, { id: user.id })),
  isDisabled: _.includes(disabledIds, user.id),
});
