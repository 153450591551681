import {
  checkPermission,
  ETaskStatus,
  IMyPermissions,
  ISelectedRowsMenuItemConfig,
  ITask,
  IUser,
  Permissions,
} from "@/shared";
import iconPencil from "@/assets/img/pencil.svg";
import iconDoubleCheck from "@/assets/img/doubleCheck.svg";
import iconIncomeFile from "@/assets/img/iconPaper.svg";
import iconDoublePaper from "@/assets/img/doublePaper.svg";
import iconBasket from "@/assets/img/basketUser.svg";
import iconPrinter from "@/assets/img/iconPrinter.svg";
import iconHardDelete from "@/assets/img/hardDelete.svg";
import _ from "lodash";

interface IProps {
  onClick: (
    key:
      | "edit"
      | "finish"
      | "reassign"
      | "copy"
      | "delete"
      | "print"
      | "hardDelete"
  ) => void;
  selectedItems: Partial<ITask>[];
  profile: IUser;
  permissions: IMyPermissions;
}

export const getSelectedTasksMenuConfig = (
  props: IProps
): ISelectedRowsMenuItemConfig[] => {
  if (_.isEmpty(props.selectedItems)) return [];

  const items = [];

  const menuItems = {
    edit: {
      onClick: () => props.onClick("edit"),
      key: "edit",
      label: "Редагувати",
      icon: {
        scr: iconPencil,
        name: "pencil",
      },
    },
    finish: {
      onClick: () => props.onClick("finish"),
      key: "finish",
      label: "Відмітити виконання",
      icon: {
        scr: iconDoubleCheck,
        name: "double check",
        className: "double-check-icon",
      },
    },
    reassign: {
      onClick: () => props.onClick("reassign"),
      key: "reassign",
      label: "Перепоставити",
      icon: {
        scr: iconIncomeFile,
        name: "income file",
        className: "reassign-icon",
      },
    },
    copy: {
      onClick: () => props.onClick("copy"),
      key: "copy",
      label: "Копіювати",
      icon: {
        scr: iconDoublePaper,
        name: "double paper",
        className: "double-paper-icon",
      },
    },
    delete: {
      onClick: () => props.onClick("delete"),
      key: "delete",
      label: "Видалити",
      icon: {
        scr: iconBasket,
        name: "basket",
        className: "basket-icon",
      },
    },
    print: {
      onClick: () => props.onClick("print"),
      key: "print",
      label: "Друк",
      icon: {
        scr: iconPrinter,
        name: "printer",
        className: "printer-icon",
      },
    },
    hardDelete: {
      onClick: () => props.onClick("hardDelete"),
      key: "hardDelete",
      label: "Видалити повністю",
      icon: {
        scr: iconHardDelete,
        name: "hard delete",
        className: "hard-delete-icon",
      },
    },
  };

  if (
    props.selectedItems.length === 1 &&
    props.selectedItems[0].status === ETaskStatus.Active &&
    checkPermission(
      "user",
      props.selectedItems[0]?.executorId,
      props.profile,
      props.permissions,
      Permissions.UPDATE,
      props.selectedItems[0]?.authorId
    )
  )
    items.push(menuItems.edit);

  if (
    _.every(
      props.selectedItems,
      (it) =>
        it.status === ETaskStatus.Active &&
        checkPermission(
          "user",
          it?.executorId,
          props.profile,
          props.permissions,
          Permissions.UPDATE,
          it?.authorId
        )
    )
  ) {
    items.push(menuItems.finish);
  }

  if (
    _.every(
      props.selectedItems,
      (it) =>
        it.status === ETaskStatus.Active &&
        checkPermission(
          "user",
          it?.executorId,
          props.profile,
          props.permissions,
          Permissions.DESTROY,
          it?.authorId
        )
    )
  ) {
    items.push(menuItems.reassign);
  }

  if (props.selectedItems.length === 1) items.push(menuItems.copy);

  if (
    _.every(
      props.selectedItems,
      (it) =>
        it.status !== ETaskStatus.Deleted &&
        checkPermission(
          "user",
          it?.executorId,
          props.profile,
          props.permissions,
          Permissions.DESTROY,
          it?.authorId
        )
    )
  )
    items.push(menuItems.delete);

  items.push(menuItems.print);

  if (
    _.every(props.selectedItems, (it) =>
      checkPermission(
        "user",
        it?.executorId,
        props.profile,
        props.permissions,
        Permissions.HARD_DELETE,
        it?.authorId
      )
    )
  )
    items.push(menuItems.hardDelete);

  return items;
};
