import React, { FC, useState } from "react";
import { IconButton, MenuItem, Menu } from "@material-ui/core";
import { CameraAlt } from "@material-ui/icons";
import ModalComponent from "../../../../components/Modal";
import { ButtonToolbar, Button } from "reactstrap";
import { IUser, notification } from "@/shared";
import default_avatar from "@/assets/img/default-avatar.jpg";
import { CropperComponent } from "./cropper.component";
import { accountService } from "@/services/domain";
import { dataURLtoFile } from "../../helper/createFile";
import { accountApi } from "@/api";
import "./style.scss";
interface IProps {
  profile: IUser;
  authProfile?: IUser;
  isUpdate: boolean;
  error?: string;
  onChangeAvatar?: any;
  onDeleteAvatar?: () => void;
}

export const Avatar: FC<IProps> = ({
  profile,
  authProfile,
  isUpdate,
  error,
  onDeleteAvatar,
  onChangeAvatar,
}) => {
  const [isShowModal, setModal] = useState<boolean>(false);
  const [profileImage, setImage] = useState<string>(null);

  const avatarUser = profile.info.avatarUrl;
  const [anchorEl, setAnchorEl] = useState(null);

  const updateAvatar = async () => {
    try {
      if (!profileImage) return null;
      const fileAvatar = dataURLtoFile(profileImage, "avatar");

      await accountService.changeAvatarAccount(
        {
          userId: profile.id,
        },

        { avatar: fileAvatar },
        authProfile
      );
      setModal(false);

      if (onChangeAvatar) onChangeAvatar();
      notification.showSuccess("Профайл", "Дані успішно збережені");
    } catch (e) {
      console.log("errorAvatar=>", e);
      notification.showError("Помилка", "Не вдалось видалити аватар");
    }
  };

  const deleteAvatar = async () => {
    if (!avatarUser) return;
    try {
      await accountService.deleteAvatarAccount(
        profile?.id,
        profile?.id === authProfile?.id
      );
      setImage(default_avatar);
      if (onDeleteAvatar) onDeleteAvatar();
      notification.showSuccess("Профайл", "Дані успішно збережені");
    } catch (e) {
      notification.showError("Помилка", "Не вдалось видалити аватар");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="profile__avatar">
        <img src={avatarUser || default_avatar} alt="avatar" />

        {isUpdate ? (
          <div>
            <IconButton
              className="cameraIcon"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <CameraAlt />
            </IconButton>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              onClose={handleClose}
              open={Boolean(anchorEl)}
            >
              <MenuItem
                onClick={() => {
                  setModal(true);
                  handleClose();
                }}
              >
                Змінити
              </MenuItem>
              <MenuItem
                onClick={() => {
                  deleteAvatar();
                  handleClose();
                }}
              >
                Видалити
              </MenuItem>
            </Menu>
          </div>
        ) : null}

        <ModalComponent
          show={isShowModal}
          toggle={() => setModal(!isShowModal)}
          title="Зміна аватару користувача"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CropperComponent src={""} setImage={setImage} />
            <p>{error}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center", fontSize: 16 }}>
                Ви бажаєте завантажити фото?
              </p>
              <ButtonToolbar className="form__button-toolbar">
                <Button
                  style={{ width: 80 }}
                  color="primary"
                  type="button"
                  onClick={updateAvatar}
                >
                  Так
                </Button>
                <Button
                  style={{ width: 80 }}
                  color="primary"
                  type="button"
                  onClick={() => setModal(false)}
                >
                  Ні
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};
