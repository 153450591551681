import { ChatAvatarWithOnlineIndicator } from "@/containers/Chats/atoms";
import { hasImageUrl } from "@/shared";
import React, { FC } from "react";

interface IProps {
  id: number;
  imageUrl: string;
  userName: string;
  isChecked: boolean;
  onSelectItem: (id: number) => void;
  onDeselectItem?: (id: number) => void;
  isDisabled?: boolean;
}

export const UserListItem: FC<IProps> = ({
  id,
  imageUrl,
  userName,
  isChecked,
  onSelectItem,
  onDeselectItem,
}) => {
  return (
    <div className="user-row-container">
      <div className="user-info">
        <ChatAvatarWithOnlineIndicator
          isOnline={false}
          imageUrl={hasImageUrl(imageUrl, userName)}
          size={35}
        />
        <span>{userName}</span>
      </div>

      <label>
        <input
          id={id.toString()}
          name={userName}
          type="checkbox"
          onChange={() => {
            !isChecked ? onSelectItem(id) : onDeselectItem(id);
          }}
        />
        <svg
          className={`checkbox ${isChecked ? "checkbox--active" : ""}`}
          aria-hidden="true"
          viewBox="0 0 15 11"
          fill="none"
        >
          <path
            d="M1 4.5L5 9L14 1"
            strokeWidth="2"
            stroke={isChecked ? "rgba(158, 39, 67, 1)" : "none"}
          />
        </svg>
      </label>
    </div>
  );
};
