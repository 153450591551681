import moment from "moment";

export const getTimeFromMessageSend = (date: string) => {
  const sendDate = new Date(date);
  const todayDate = new Date();

  const differenceInTime = todayDate.getTime() - sendDate.getTime();
  const differenceInHours = differenceInTime / (1000 * 3600);

  if (differenceInHours < 24) {
    const sendTimeToRender = sendDate.toString().substr(16, 5);
    return sendTimeToRender;
  }
  console.log(sendDate);

  const sendDateToRender = moment(sendDate).format("DD-MM-YYYY");
  return sendDateToRender;
};
