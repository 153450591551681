import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./request.interfaces";
import * as Res from "./response.interfaces";

const fetchNotificationsList = (
  params: Req.IFetchNotification
): ApiResponse<Res.INotificationsList> => {
  return http.get<Res.INotificationsList>(`admin/notifications`, { params });
};

export const saveUserDevice = (payload: Req.ISaveDevicePayload) => {
  return http.post("admin/notifications/device", payload);
};

const readAll = () => {
  return http.put<void>("admin/notifications/read", {});
};

const readByIds = (payload: Req.IReadNotificationsPayload) => {
  return http.put<void>("admin/notifications/read-by-ids", payload);
};

const sendNotifications = (payload: Req.ISendNotificationsPayload) => {
  return http.post<void>("admin/notifications-send", payload);
};

export const notificApi = {
  fetchNotificationsList,
  saveUserDevice,
  readAll,
  readByIds,
  sendNotifications,
};
