import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import {
  accountService,
  ICreateUserPayload,
  usersService,
} from "@/services/domain";
import {
  EUserRole,
  extractMetaFromException,
  IFactory,
  isHttpErrorEqual,
  IUser,
  IUserPermissions,
  notification,
  transformFormValuesToPermissions,
  transformPermissionsToFormValues,
} from "@/shared";
import moment from "moment";
import { convertUserDataToFormData, IUserForm } from "../helpers";
import { UserModalMode } from "../enums";
import { accountApi, factoriesApi, permissionsApi, usersApi } from "@/api";
import { appEvents } from "@/shared/events";

interface ICreateEditUserProps {
  onSubmitUser: () => void;
  userData?: IUser;
  mode?: UserModalMode;
  onInvalidData?: () => void;
}

export const useCreateEditUser = ({
  onSubmitUser,
  userData,
  mode,
  onInvalidData,
}: ICreateEditUserProps) => {
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>(
    null
  );
  const [isDisableBtns, setBtns] = useState<boolean>(false);
  const [factories, setFactories] = useState<IFactory[]>([]);

  const fetchUserPermissions = async () => {
    const { data } = await permissionsApi.fetchUserPermissions(userData.id);
    if (data) setUserPermissions(JSON.parse(data.permissions));
  };

  useEffect(() => {
    if (userData?.id) fetchUserPermissions();
  }, [userData]);

  useEffect(() => {
    form.setValue(
      "permissions",
      transformPermissionsToFormValues(userPermissions)
    );
  }, [userPermissions]);

  const defaultValues = useMemo(
    () => convertUserDataToFormData(userData, userPermissions),
    [userData, userPermissions]
  );

  const form = useForm<IUserForm>({
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState,
    clearErrors,
    watch,
  } = form;

  const watchNewPassword = watch("password");
  const watchConfirmPassword = watch("confirmPassword");

  useEffect(() => {
    const { password, confirmPassword } = form.getValues();

    if (password && confirmPassword && password === confirmPassword)
      setBtns(false);
    else setBtns(true);
  }, [watchNewPassword, watchConfirmPassword]);

  const fetchFactories = async () => {
    try {
      const { data: factories } = await factoriesApi.fetchFactoriesFullList();
      setFactories(factories);
    } catch (e) {
      console.log("FETCH FACTORIES LIST ERROR", e);
    }
  };

  useEffect(() => {
    fetchFactories();
  }, []);

  useEffect(() => {
    if (mode === UserModalMode.Create) form.setValue("role", EUserRole.User);
  }, [mode]);

  const validate = async (
    value: string,
    key: "email" | "login" | "phoneNumber" | "personalPhoneNumber"
  ) => {
    const fieldNameByKey = {
      email: "email",
      login: "логін",
      phoneNumber: "номер телефону",
      personalPhoneNumber: "номер телефону",
    };

    if (!value || (defaultValues && defaultValues[key] === value)) {
      clearErrors(key);
    } else {
      const exist = await accountService.fetchExistUserData({
        [key]: value,
      });
      if (exist[key])
        setError(key, { message: `Такий ${fieldNameByKey[key]} вже існує` });
      // else
      //   clearErrors(key);
    }
  };

  const createUser = async (value: IUserForm) => {
    try {
      if (value.email) {
        value.email = value.email.toLowerCase();

        setValue("email", value.email);
      }

      const dataToSave: ICreateUserPayload = _.omit(value, [
        "confirmPassword",
        "permissions",
      ]);

      const userId = await usersService.createUser({
        ...(_.omitBy(dataToSave, (it) => !it) as ICreateUserPayload),
        dateOfBirth: moment(value.dateOfBirth).format("YYYY-MM-DD"),
      });

      const permissionsToSave = transformFormValuesToPermissions(
        value.permissions
      );

      if (!_.isEmpty(permissionsToSave))
        await permissionsApi.saveUserPermissions(
          { permissions: permissionsToSave },
          userId
        );
    } catch (e) {
      if (isHttpErrorEqual(e, "user_already_exists")) {
        const meta = extractMetaFromException(e);
        const duplicateFields = meta?.duplicateFields || [];
        console.log(meta, duplicateFields);
        if (duplicateFields.includes("phoneNumber")) {
          setError("phoneNumber", {
            message: "Такий номер телефону вже існує",
          });
        }

        if (duplicateFields.includes("email")) {
          setError("email", {
            message: "Такий номер email вже існує",
          });
        }

        if (duplicateFields.includes("login")) {
          setError("login", {
            message: "Такий логін вже існує",
          });
        }
      }

      throw e;
    }
  };

  const updateUser = async (value: IUserForm) => {
    try {
      if (value.email) {
        value.email = value.email.toLowerCase();

        setValue("email", value.email);
      }

      const dataToUpdate = _.omitBy(
        value,
        (it, key) =>
          _.isNil(it) || defaultValues[key] === it || key === "permissions"
      );

      if (
        _.has(dataToUpdate, "personalPhoneNumber") &&
        _.isEmpty(dataToUpdate.personalPhoneNumber)
      )
        dataToUpdate.personalPhoneNumber = "deleted";

      if (dataToUpdate.dateOfBirth)
        dataToUpdate.dateOfBirth = moment(dataToUpdate.dateOfBirth).format(
          "YYYY-MM-DD"
        );

      if (!_.isEmpty(dataToUpdate))
        await accountApi.updateUser(dataToUpdate, value.id);

      if (dataToUpdate.factoryId)
        await usersApi.updateFactoryRelations([
          {
            userId: defaultValues.id,
            factoryId: Number(dataToUpdate.factoryId),
          },
        ]);

      if (dataToUpdate.avatar)
        await accountApi.changeAvatar(
          { userId: value.id },
          { avatar: dataToUpdate.avatar }
        );

      const permissionsToSave = transformFormValuesToPermissions(
        value.permissions
      );
      const prevPermissions = transformFormValuesToPermissions(
        defaultValues.permissions
      );

      if (!_.isEqual(permissionsToSave, prevPermissions)) {
        if (_.isEmpty(permissionsToSave))
          await permissionsApi.deleteUserPermissions(value.id);
        else
          await permissionsApi.saveUserPermissions(
            { permissions: permissionsToSave },
            value.id
          );
      }
    } catch (e) {
      if (isHttpErrorEqual(e, "user_already_exists")) {
        const meta = extractMetaFromException(e);
        if (meta.field === "phoneNumber") {
          setError("phoneNumber", {
            message: "Такий номер телефону вже існує",
          });
        }
      }

      throw e;
    }
  };

  const onSubmit = async (value: IUserForm) => {
    if (mode === UserModalMode.Create) {
      await createUser(value);
    } else {
      await updateUser(value);
    }
    await onSubmitUser();
  };

  const generatePassword = () => {
    let genPassword = "";

    while (genPassword.length < 7)
      genPassword += Math.random()
        .toString(36)
        .substring(2);

    genPassword.substring(0, 7);

    setValue("password", genPassword);
    setValue("confirmPassword", genPassword);
  };

  const deleteAvatar = async () => {
    try {
      if (mode === UserModalMode.Edit && userData?.info?.avatarUrl) {
        await accountApi.deleteAvatar(userData?.id);
        notification.showSuccess("Профайл", "Дані успішно збережені");
        appEvents.emit("onDeleteUserAvatar", { userId: userData?.id });
      }

      setValue("avatar", null);
      setValue("avatarUrl", null);
    } catch (e) {
      notification.showError("Помилка", "Не вдалось видалити аватар");
    }
  };

  return {
    form,
    submit: handleSubmit(onSubmit, onInvalidData),
    generatePassword,
    setValue,
    factories,
    validate,
    errors: formState.errors,
    deleteAvatar,
    isDisableBtns,
  };
};
