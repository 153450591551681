import { ChatMessageActionEnum } from "../enums";

interface IProps {
  onClick: (actionType: ChatMessageActionEnum) => void;
  canCopy?: boolean;
  canDeleteForAll?: boolean;
}

export const getSelectedMessagesMenuOptions = ({
  onClick,
  canCopy,
  canDeleteForAll,
}: IProps) => {
  const menuOptions = {
    forward: {
      key: "forward",
      label: "Переслати",
      onClick: () => onClick(ChatMessageActionEnum.FORWARD),
    },
    copy: {
      key: "copy",
      label: "Копіювати",
      onClick: () => onClick(ChatMessageActionEnum.COPY),
    },
    delete: {
      key: "delete",
      label: "Видалити",
      onClick: () => onClick(ChatMessageActionEnum.DELETE),
    },
    deleteForAll: {
      key: "deleteForAll",
      label: "Видалити для всіх",
      onClick: () => onClick(ChatMessageActionEnum.DELETE_FOR_ALL),
    },
  };

  const optionsKeys = ["forward"];
  if (canCopy) optionsKeys.push("copy");
  optionsKeys.push("delete");
  if (canDeleteForAll) optionsKeys.push("deleteForAll");

  const options = optionsKeys.map((key) => menuOptions[key]);

  return options;
};
