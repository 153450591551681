import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Res from "./responses.interfaces";
import * as Req from "./requests.interfaces";
import { convertToFormData } from "@/shared/helpers/api.helpers";

const fetchAccount = (): ApiResponse<Res.IFetchAccountResponse> => {
  return http.get<Res.IFetchAccountResponse>("app/account", {});
};

const updateUser = (
  params: Req.IUpdateAccountReq,
  id: number
): ApiResponse<Res.IUpdateAccountResponse> => {
  return http.patch<Res.IUpdateAccountResponse>(
    `admin/users/account/${id}`,
    params
  );
};

const updateAccount = (
  params: Req.IUpdateAccountReq
): ApiResponse<Res.IUpdateAccountResponse> => {
  return http.patch<Res.IUpdateAccountResponse>(`admin/account`, params);
};

const existUserData = (
  params: Req.IExistDataReq
): ApiResponse<Res.IExistUserDataResponse> => {
  return http.post<Res.IExistUserDataResponse>(
    "admin/users/check-exist-data",
    params
  );
};
const verificationUserName = (
  params: Req.IVerificationUserName
): ApiResponse<boolean> => {
  return http.post<boolean>("admin/users/check-exist-pib", params);
};

const changeAvatar = (params: Req.IChangeAvatarReq, avatar: any) => {
  return http.put<{ avatarUrl: string }>(
    `admin/users/avatar/${params.userId}`,
    convertToFormData(avatar)
  );
};

const deleteAvatar = (userId: number): ApiResponse<void> => {
  return http.delete(`admin/users/avatar/${userId}`);
};

const changeMyAvatar = (params: Req.IChangeAvatarReq, avatar: any) => {
  return http.put<{ avatarUrl: string }>(
    `admin/account/avatar`,
    convertToFormData(avatar)
  );
};

const deleteMyAvatar = (): ApiResponse<void> => {
  return http.delete(`admin/account/avatar`);
};

const updatePassword = (
  params: Req.IUpdatePasswordReq
): ApiResponse<Res.IUpdatePasswordResp> => {
  return http.patch<Res.IUpdatePasswordResp>("admin/account/password", params);
};

export const accountApi = {
  fetchAccount,
  updateUser,
  verificationUserName,
  changeAvatar,
  deleteAvatar,
  existUserData,
  updatePassword,
  updateAccount,
  changeMyAvatar,
  deleteMyAvatar,
};
