import { IMyPermissions } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TPermissionsActions } from "./types";

export interface IPermissionsState {
  isLoading: boolean;
  availablePermissions: {
    [x: string]: string[] | { [x: string]: string[] | boolean };
  };
  myPermissions: IMyPermissions;
}

const initialState: IPermissionsState = {
  isLoading: false,
  availablePermissions: {},
  myPermissions: {}
};

export const PermissionsReducer = createReducer<
  IPermissionsState,
  TPermissionsActions
>(initialState, {
  SET_AVAILABLE_PERMISSIONS: (state, action) => {
    return {
      ...state,
      availablePermissions: action.payload.data
    };
  },

  SET_MY_PERMISSIONS: (state, action) => {
    return {
      ...state,
      myPermissions: action.payload.data
    };
  },

  IS_LOADING_PERMISSIONS: (state, action) => {
    return {
      ...state,
      isLoading: action.payload.isLoading
    };
  }
});
