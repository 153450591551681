export enum EUserRole {
  Admin = "a",
  User = "u"
}

export enum EUserStatus {
  Blocked = "b",
  Active = "a",
  Deleted = "d"
}

export enum EUsersListType {
  All = "a",
  Executors = "e",
  ExecutorsAll = "ea"
}
