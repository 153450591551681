import { ETaskStatus } from "@/shared";
import moment from "moment";
import { EPrintTasksMode } from "../enums";
import { ITaskForm, ITaskModalState } from "../interfaces";

export const taskStatusTitleRelation = {
  [ETaskStatus.Active]: "Активний",
  [ETaskStatus.Finished]: "Виконаний",
  [ETaskStatus.Deleted]: "Видалений"
};

export const defaultTaskValues: ITaskForm = {
  name: "",
  startDate: moment().format(),
  endDate: moment()
    .add(1, "day")
    .format(),
  description: "",
  initiatorId: "",
  executorsId: [],
  groupId: undefined,
  reasonId: undefined,
  createDate: undefined
};

export const defaultModalState: ITaskModalState = {
  isOpen: false,
  mode: null
};

export const defaultConfirmModalState = {
  isOpen: false,
  message: "",
  onConfirm: () => {}
};

export const printModalOptions: Record<EPrintTasksMode, string> = {
  [EPrintTasksMode.Selected]: "Друкувати позначені",
  [EPrintTasksMode.SelectedSortByExecutor]: "Друкувати позначені по виконавцях",
  [EPrintTasksMode.All]: "Друкувати всі",
  [EPrintTasksMode.AllSortedByExecutor]: "Друкувати всі по виконавцях"
};
