import store from '@/store'

interface Action<T = any> {
	type: T
	payload?: any
}

const storeDispatchMiddleware = (action: Action) => {
	store.dispatch({
		type: action.type,
		payload: action.payload,
	})
}

export const simpleDispatch = storeDispatchMiddleware
