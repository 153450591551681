import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PhoneField, CodeField, NewPasswordField } from "./fields";
import { authService } from "@/services/domain";
import { StorageKey } from "@/shared";
import { RouteEnum } from "@/containers/App/router";
import { GlobalContainerService } from "@/services/system";
import { notification } from "antd";
import _ from "lodash";

interface IPasswordRecoveryForm {
  phoneNumber: string;
  code: string;
  newPassword: string;
}

const openCodeNotification = () => {
  const args = {
    message: "Код підтвердження",
    description:
      "Недійсний код підтвердження. Перевірте правильність вводу і спробуйте ще раз",
    duration: 0,
  };
  setTimeout(() => {
    notification.open(args);
  }, 500);
};

export const PasswordRecoveryForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<IPasswordRecoveryForm>();

  const history = useHistory();

  const _getTokensFromStorage = async () => {
    const accessToken: string = await GlobalContainerService.get(
      "store"
    ).getState().auth.accessToken;
    const refreshToken: string = await localStorage.getItem(
      StorageKey.RefreshToken
    );

    return { accessToken, refreshToken };
  };

  const phoneValue = localStorage.getItem(StorageKey.PhoneNumber);

  const onSubmit = async (data: IPasswordRecoveryForm) => {
    await authService.recoverAccountPassword({
      ...data,
      phoneNumber: phoneValue,
    });

    const existTokens = await _getTokensFromStorage();
    if (!existTokens.refreshToken) {
      history.push(RouteEnum.Recover);
      openCodeNotification();
      return;
    }

    localStorage.removeItem(StorageKey.PhoneNumber);
    history.push(RouteEnum.Tasks);
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <Link
        to="/forgot"
        style={{
          color: "rgba(158, 39, 67)",
          marginBottom: "10px",
        }}
      >
        {`Змінити номер для відновлення аккаунту`}
      </Link>

      <PhoneField
        register={register("phoneNumber")}
        value={phoneValue}
        onChange={_.noop}
        error={errors?.phoneNumber?.message}
      />

      <NewPasswordField
        register={register("newPassword", {
          required: "Заповнити обов'язково",
        })}
        error={errors?.newPassword?.message}
      />
      <CodeField
        register={register("code", {
          required: "Заповнити обов'язково",
        })}
        error={errors?.code?.message}
      />

      <button className="btn btn-primary btn-recover-pw saccount__btn account__btn--small">
        Відновити пароль
      </button>
    </form>
  );
};
