export const cutLongText = (txt: string, length: number): string => {
  if (txt.length > length) {
    const cuttingTxt = txt.slice(0, length);

    return `${cuttingTxt}...`;
  }

  return txt;
};

export const getLinksFromTxt = (txt: string) =>
  txt?.match(/\bhttp(s)?:(:)?\/\/\S+/gi);
