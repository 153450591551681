import { factoriesApi, permissionsApi } from "@/api";
import { PermissionForm } from "@/containers/Permissions/interfaces";
import {
  IFactory,
  IUserPermissions,
  transformFormValuesToPermissions,
  transformPermissionsToFormValues
} from "@/shared";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

export interface IUserPermissionsForm {
  permissions: PermissionForm[];
}

interface IEditUserPermissionsProps {
  userId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useEditUserPermissions = ({
  userId,
  onSuccess,
  onError
}: IEditUserPermissionsProps) => {
  const [factories, setFactories] = useState<IFactory[]>([]);
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>(
    null
  );

  const fetchUserPermissions = async () => {
    const { data } = await permissionsApi.fetchUserPermissions(userId);
    if (data) setUserPermissions(JSON.parse(data.permissions));
  };

  useEffect(() => {
    if (userId) fetchUserPermissions();
  }, [userId]);

  const defaultValues = useMemo(
    () => ({ permissions: transformPermissionsToFormValues(userPermissions) }),
    [userId, userPermissions]
  );

  const form = useForm<IUserPermissionsForm>({
    defaultValues
  });

  useEffect(() => {
    form.setValue(
      "permissions",
      transformPermissionsToFormValues(userPermissions)
    );
  }, [userPermissions]);

  const fetchFactories = async () => {
    try {
      const { data: factories } = await factoriesApi.fetchFactoriesFullList();
      setFactories(factories);
    } catch (e) {
      console.log("FETCH FACTORIES LIST ERROR", e);
    }
  };

  useEffect(() => {
    fetchFactories();
  }, []);

  const onSubmit = async (value: IUserPermissionsForm) => {
    const permissionsToSave = transformFormValuesToPermissions(
      value.permissions
    );
    const prevPermissions = transformFormValuesToPermissions(
      defaultValues.permissions
    );

    if (!_.isEqual(permissionsToSave, prevPermissions)) {
      try {
        if (_.isEmpty(permissionsToSave))
          await permissionsApi.deleteUserPermissions(userId);
        else
          await permissionsApi.saveUserPermissions(
            { permissions: permissionsToSave },
            userId
          );

        if (onSuccess) onSuccess();
        fetchUserPermissions();
      } catch (e) {
        console.log("Error on save user permissions");
        if (onError) onError();
      }
    }
  };

  return {
    form,
    factories,
    submit: form.handleSubmit(onSubmit)
  };
};
