import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./request.interfaces";

export const addNewMemberReq = (
  params: Req.IAddNewMember
): ApiResponse<void> => {
  return http.post<void>(`app/chats-members`, params);
};

export const changeMemberRoleReq = (
  params: Req.IChangeMemberRole
): ApiResponse<void> => {
  return http.post<void>(`app/chats-members/change-role`, params);
};

export const deleteMemeberReq = (
  params: Req.IDeleteMember
): ApiResponse<void> => {
  return http.delete<void>(`app/chats-members`, { params });
};

export const chatMembersApi = {
  addNewMemberReq,
  deleteMemeberReq,
  changeMemberRoleReq,
};
