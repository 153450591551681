import React, { FC, useRef } from "react";
import ModalComponent from "@/components/Modal";
import Webcam from "react-webcam";
import { ButtonToolbar, Button } from "reactstrap";
import { dataURLtoFile } from "@/containers/Profile/helper";
import { chatMessagesService } from "@/services/domain";
import { ISendFileMessage } from "@/api/chats-messages/request.interfaces";
import { IChatMessage } from "../plugins";

interface IProps {
  isShow: boolean;
  setOpen: (val: boolean) => void;
  chatId: number;
  replyToMessage?: IChatMessage;
  onSend: () => void;
}

const MIN = 1000000000000000;
const MAX = 3000000000000000;

export const CreateWebcamMediaModal: FC<IProps> = ({
  isShow,
  setOpen,
  chatId,
  replyToMessage,
  onSend,
}) => {
  const webcamRef = useRef(null);
  // const [imgSrc, setImgSrc] = React.useState(null);

  const generateRandomIntName = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const sendWebcamMessage = async (image: File) => {
    try {
      if (!image) return;
      // setSending(true);
      onSend();
      await chatMessagesService.sendMediaMessage(
        [image].map((it) => {
          const dataToSend: ISendFileMessage = {
            file: it,
            chatId: chatId,
            fileName: it.name,
          };
          if (replyToMessage) dataToSend.replyToId = replyToMessage.id;

          return dataToSend;
        })
      );
    } finally {
      // setSending(false);
    }
  };

  const captureFoto = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    const fileData = dataURLtoFile(
      imageSrc,
      `${generateRandomIntName(MIN, MAX)}.jpeg`
    );
    console.log(fileData);
    // setImgSrc(imageSrc);

    sendWebcamMessage(fileData);
    setOpen(false);
  }, [webcamRef]);

  return (
    <ModalComponent
      webcam="webcam"
      title="Записати фото/відео з фронталки"
      show={isShow}
      toggle={() => setOpen(false)}
    >
      <>
        <Webcam
          audio={false}
          ref={webcamRef}
          height={600}
          width={700}
          screenshotFormat="image/jpeg"
        />
        <ButtonToolbar className="webcam-capture-btns">
          <Button
            style={{ width: 152 }}
            color="primary"
            type="submit"
            onClick={captureFoto}
          >
            Зробити фото
          </Button>
        </ButtonToolbar>
      </>
    </ModalComponent>
  );
};
