import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { objectToOption } from "@/shared";
import _ from "lodash";
import { Button } from "reactstrap";
import { PermissionsSection } from "../../enums";
import "../../style.scss";
import {
  initialNewPermissionsState,
  permissionsSections,
  permissionsTabsActions,
  permissionsTabsTargets,
  permissionsTasksActions,
  selectedOptionTargetTitle,
} from "../../consts";
import { useSelector } from "react-redux";
import { FormPermissionsItem } from "../FormPermissionsItem";
import { getAvailablePermissions } from "@/store/permissions";

interface PermissionsFormProps {
  form: any;
  userPaginationList: any;
  factoriesTree: any;
}

export const PermissionsForm: FC<PermissionsFormProps> = ({
  form,
  userPaginationList,
  factoriesTree,
}) => {
  const [dataBySection, setDataBySection] = useState<any>({});
  const availablePermissions = useSelector(getAvailablePermissions);

  const bottomRef = useRef(null);

  const watchValues = form.watch();
  const values = form.getValues();

  useEffect(() => {
    setDataBySection({
      [PermissionsSection.FACTORY]: factoriesTree,
      [PermissionsSection.USER]: userPaginationList.items,
      [PermissionsSection.TABS]: objectToOption(permissionsTabsTargets),
      [PermissionsSection.FILTER_FACTORY]: null,
    });
  }, [factoriesTree, userPaginationList.items]);

  const sectionOptions = objectToOption(
    _.pick(permissionsSections, Object.keys(availablePermissions))
  );

  const getTargetActions = (section: PermissionsSection, target: string) => {
    if (
      section === PermissionsSection.FACTORY ||
      section === PermissionsSection.USER
    )
      return _.pick(
        permissionsTasksActions,
        availablePermissions[section] as string[]
      );

    if (section === PermissionsSection.TABS && target)
      return _.pick(permissionsTabsActions, availablePermissions?.tabs[target]);

    return [];
  };

  const addPermission = () => {
    if (_.isEmpty(values?.permissions))
      return form.setValue("permissions", [initialNewPermissionsState]);

    if (!values.permissions[values.permissions.length - 1].section) return;

    if (values?.permissions?.length)
      return form.setValue("permissions", [
        ...values.permissions,
        initialNewPermissionsState,
      ]);
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView(false);
  }, [values]);

  const onPermissionChange = (key: string, val: any, index: number) => {
    const values = form.getValues();

    const newPermissions = values.permissions.map((it, i) => {
      if (index === i) return { ...it, [key]: val };
      return it;
    });

    form.setValue("permissions", newPermissions);
  };

  const onDeletePermissions = (index: number) => {
    values.permissions.splice(index, 1);
    form.setValue("permissions", values.permissions);
  };

  const renderPermissions = useMemo(() => {
    const preparedPermissions = values.permissions?.map((it, i) => {
      const actionOptions = objectToOption(
        getTargetActions(
          values.permissions[i].section,
          values.permissions[i].target
        )
      );

      return (
        <FormPermissionsItem
          index={i}
          key={i}
          isLoadMoreTargetOptions={
            it?.section === PermissionsSection.USER &&
            userPaginationList.items.length <
              userPaginationList.loadParams.count
          }
          onLoadMore={() =>
            userPaginationList.setLoadParams({
              limit: userPaginationList.loadParams.limit * 2,
            })
          }
          onChange={(key, val) => onPermissionChange(key, val, i)}
          values={values.permissions[i]}
          options={{
            section: sectionOptions,
            target: dataBySection[it?.section],
            actions: actionOptions,
          }}
          targetTitle={selectedOptionTargetTitle[it.section]}
          onDelete={() => onDeletePermissions(i)}
          targetTotalLength={
            it?.section === PermissionsSection.USER &&
            userPaginationList.loadParams?.count
          }
        />
      );
    });

    return preparedPermissions;
  }, [watchValues, dataBySection, factoriesTree, userPaginationList.items]);

  return (
    <div className="permissions-form">
      <div className="permission-field_container">
        <div className="permission-field">Права доступу</div>

        <Button
          color="primary"
          className="permissions__btn"
          onClick={addPermission}
        >
          Додати
        </Button>
      </div>

      <div className="permissions_container">
        {renderPermissions}
        <div
          ref={(el) => {
            bottomRef.current = el;
          }}
        ></div>
      </div>
    </div>
  );
};
