import { IconComponent, useEventsListener } from "@/shared";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  EmojiClickData,
  SuggestionMode,
} from "emoji-picker-react";

import paperClipIcon from "@/assets/img/paperClip-icon.svg";
import x1Icon from "@/assets/img/x-1-icon.svg";
import sendIcon from "@/assets/img/send-icon.svg";
import smileIcon from "@/assets/img/Smiley.svg";

import { AttachmentsMenu } from "../components";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import _ from "lodash";
import { IChatMessage, ISuggestionUser } from "./interfaces";
import { Avatar } from "../atoms";
import { Button } from "antd";
import { ReplyBarSection } from "./reply-bar-section.component";
import { EditedBarSection } from "./edted-bar-section.component";
import "./style.scss";

interface ChatBarProps {
  onPressSend: () => void;
  message: string;
  chatId?: number;
  onChangeMessage: (val: string) => void;
  withAttachmentsBtn?: boolean;
  onPressAttachments?: () => void;
  onPressMenuItem?: (key: string) => void;
  isMenuOpen?: boolean;
  isSending?: boolean;
  suggestions?: ISuggestionUser[];
  replyToMessage?: IChatMessage;
  replyToName?: string;
  clearReplyTo?: () => void;
  editedMessage?: IChatMessage;
  onPressClearEditedMessage?: () => void;
  onSend: () => void;
}

export const ChatBar: FC<ChatBarProps> = (props) => {
  const ref = useRef<any>(null);
  const [inputHeight, setInputHeight] = useState<number>(85);

  const [isSmilesOpen, setSmilesOpen] = useState(false);
  onClickOutside(ref, () => {
    setSmilesOpen(!isSmilesOpen);
  });

  function onClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  const onEmojiClick = (emojiData: EmojiClickData) => {
    const newVal = props.message + emojiData.emoji;

    props.onChangeMessage(newVal);
  };

  const inputRef = useRef<any>(null);

  useEventsListener(
    "onPressEditmessage",
    () => {
      if (inputRef.current) inputRef.current?.focus();
    },
    [inputRef.current]
  );

  const renderLeftPart = () => {
    if (props?.withAttachmentsBtn)
      return (
        <div className="chat-bar-left">
          <div className="chat-bar-settings">
            <button
              type="button"
              className="attachments-icon-button"
              onClick={props?.onPressAttachments}
            >
              <IconComponent
                name={props?.isMenuOpen ? x1Icon : paperClipIcon}
              />
            </button>
          </div>
          <AttachmentsMenu
            isMenuOpen={props.isMenuOpen}
            chatId={props.chatId}
            onPressItem={props.onPressMenuItem}
            replyToMessage={props.replyToMessage}
            onSend={props.onSend}
          />
        </div>
      );
  };

  const field = document.getElementById("chat-bar-input-field");

  const getFieldSize = () => {
    const height = field.offsetHeight;
    setInputHeight(height + 5);
  };

  const renderRightPart = () => {
    if (props.isSending) {
      return <Button type="link" size="large" loading={true}></Button>;
    } else {
      return (
        <>
          <div className="chat-bar-smiles">
            <button
              type="button"
              className="smile-icon-button"
              onClick={() => {
                if (!isSmilesOpen) getFieldSize();

                setSmilesOpen(!isSmilesOpen);
              }}
            >
              <IconComponent name={smileIcon} />
            </button>
          </div>
          <div className="chat-bar-sending">
            <button
              type="button"
              className="send-icon-button"
              onClick={onSendClickPress}
            >
              <IconComponent name={sendIcon} />
            </button>
          </div>
        </>
      );
    }
  };

  const onSendEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      props.onPressSend();
      props.clearReplyTo();

      if (_.isEmpty(props.message) || props.message?.trim()?.length === 0) {
        return;
      }
      props.onChangeMessage("");
    }
  };

  const onSendClickPress = () => {
    props.onPressSend();
    props.clearReplyTo();
    if (_.isEmpty(props.message) || props.message?.trim()?.length === 0) {
      return;
    }
    props.onChangeMessage("");
  };

  const renderSuggestion = useCallback(
    (
      suggestion: SuggestionDataItem,
      search: string,
      higllightedDisplay: React.ReactNode,
      index: number
      // focus: boolean
    ): React.ReactNode => {
      if (_.isEmpty(props.suggestions)) return;
      return (
        <div className="suggestion-item">
          <div className="chat-avatar-style">
            <Avatar
              imageUrl={props.suggestions[index].avatar}
              maxHeight={35}
              maxWidth={35}
            />
          </div>
          <div className="user-name">
            <span>{higllightedDisplay}</span>
          </div>
        </div>
      );
    },
    [props.suggestions]
  );

  return (
    <>
      <div className="chat-bar-container">
        <ReplyBarSection
          message={props.replyToMessage}
          onPressClose={props.clearReplyTo}
          name={props.replyToName}
        />
        <EditedBarSection
          message={props.editedMessage}
          onPressClose={props.onPressClearEditedMessage}
        />

        {isSmilesOpen ? (
          <div
            ref={ref}
            className="smile-component"
            style={{ bottom: inputHeight }}
          >
            <EmojiPicker
              skinTonesDisabled
              searchDisabled
              theme={Theme.LIGHT}
              height={300}
              emojiVersion="0.6"
              previewConfig={{
                defaultCaption: "Pick one!",
                defaultEmoji: "1f92a", // 🤪
                showPreview: false,
              }}
              suggestedEmojisMode={SuggestionMode.RECENT}
              defaultSkinTone={SkinTones.MEDIUM}
              emojiStyle={EmojiStyle.NATIVE}
              onEmojiClick={onEmojiClick}
            />
          </div>
        ) : null}

        <div className="chat-bar-input-field" id="chat-bar-input-field">
          {renderLeftPart()}
          <div className="chat-bar-input-container">
            <MentionsInput
              inputRef={inputRef}
              className="message-input"
              value={props.message}
              onChange={(e) => {
                props.onChangeMessage(e.target.value);
              }}
              onKeyDown={onSendEnterPress}
              placeholder={"Повідомлення..."}
              rows={1}
              wrap={"hard"}
              allowSuggestionsAboveCursor
            >
              <Mention
                appendSpaceOnAdd
                displayTransform={(id, display) => `@${display}`}
                trigger="@"
                data={
                  props.suggestions?.map((user) => ({
                    id: user.id.toString(),
                    display: user.name,
                  })) || []
                }
                renderSuggestion={renderSuggestion}
              />
            </MentionsInput>
          </div>

          <div className="chat-bar-right">{renderRightPart()}</div>
        </div>
      </div>
    </>
  );
};
