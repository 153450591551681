import React, { FC, useMemo } from "react";
import { getMessagePreviewText, IChatMessage, MessageType } from "@/shared";
import { PinnedIndicator } from "../atoms";
import { MessageMediaPreview } from "./message-media-preview.component";
import { IActionOptions } from "./interfaces";
import { PinnedMessageActions } from "../components";

interface IProps {
  message: IChatMessage;
  onPress?: () => void;
  options: IActionOptions[];
}

export const PinnedMessage: FC<IProps> = ({ message, onPress, options }) => {
  if (!message) return null;

  const text = useMemo(() => getMessagePreviewText(message), [message]);

  const copyEnabledTypes = [MessageType.Text, MessageType.Image];
  const canCopy = copyEnabledTypes.includes(message.type);

  if (!canCopy)
    options = options.filter((item) => {
      return item.name !== "copy";
    });

  return (
    <div className="pinned-message-container">
      <div className="pinned-message-content" onClick={onPress}>
        <div className="pinned-message-indicator">
          <PinnedIndicator />
        </div>
        <div className="pinned-message-media">
          <MessageMediaPreview
            type={message?.type}
            uri={message?.content?.fileUrl}
          />
        </div>
        <div className="pinned-message-textblock">
          <span>{text}</span>
        </div>
      </div>

      <div className="pinned-message-actions">
        <PinnedMessageActions options={options} message={message} />
      </div>
    </div>
  );
};
