import { InputMaskField } from "@/shared";
import React, { FC } from "react";
import { Controller } from "react-hook-form";

interface PhoneNumberControllerProps {
  form: any;
  name: string;
  label: string;
  validate: (value, name) => void;
  className?: string;
  rules?: any;
}

export const PhoneNumberController: FC<PhoneNumberControllerProps> = ({
  form,
  name,
  label,
  validate,
  className = "",
  rules,
}) => {
  return (
    <div className={className}>
      <Controller
        name={name}
        control={form.control}
        rules={rules ? rules : null}
        render={({ field: { value, onChange }, fieldState }) => (
          <>
            <InputMaskField
              label={label}
              value={value}
              placeholder={"(xx) xxx xx xx"}
              onChange={async (value: string) => {
                form.clearErrors(`${name}`);
                const toSave = value.replace(/[^+\d]/g, "");
                onChange(toSave);
                // if (!fieldState?.error?.message && toSave.length > 11)
                if (toSave.length > 20 || toSave.length === 3)
                  validate(toSave, name);
              }}
              error={fieldState?.error?.message}
            />
          </>
        )}
      />
    </div>
  );
};
