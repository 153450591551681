import { IconComponent } from "@/shared";
import React, { FC } from "react";
import downIcon from "@/assets/img/forward-icon.svg";

interface IProps {
  onPress: () => void;
}

export const ForwardMessageButton: FC<IProps> = ({ onPress }) => {
  return (
    <div className="forward-btn-container" onClick={onPress}>
      <button className="chat-forward-button" type="button" onClick={onPress}>
        <IconComponent name={downIcon} />
      </button>
    </div>
  );
};
