import * as Req from "./request.interfaces";
import * as Res from "./responses.interfaces";
import http from "../http.service";
import { ApiResponse } from "../http.types";
import { convertToFormData, prepareFormData } from "@/shared";

export const getChatMessagesListReq = (params: {
  params: Req.IFetchChatMessages;
}): ApiResponse<Res.IFetchChatMessagesList> => {
  return http.get<Res.IFetchChatMessagesList>("app/chats-messages", params);
};

export const sendTextMessageReq = (
  data: Req.ISendTextMessage
): ApiResponse<void> => {
  return http.post<void>(`app/chats-messages/text-message`, data);
};

export const editTextMessageReq = (data: Req.IEditTextMessage) => {
  return http.patch<void>("app/chats-messages/text-message", data);
};

export const sendStickerMessageReq = (data: Req.ISendStickerMessage) => {
  return http.post<void>(`app/chats-messages/sticker-message`, data);
};

export const sendImageMessageReq = (data: Req.ISendFileMessage) => {
  return http.post(
    `common/chats-messages/image`,
    convertToFormData(data),
    null
  );
};

export const sendVideoMessageReq = (data: Req.ISendFileMessage) => {
  return http.post(
    `common/chats-messages/video`,
    convertToFormData(data),
    null
  );
};
export const sendFileMessageReq = (data: Req.ISendFilesMessages) => {
  return http.post(
    `app/chats-messages/file-message`,
    createFormData(data),
    null
  );
};

export const forwardMessageReq = (data: Req.IForwardMessage) => {
  return http.post<void>(`app/chats-messages/forward-message`, data);
};

export const deleteMessageByIdReq = (
  messageId: number,
  params?: Req.IDeleteMessageParams
): ApiResponse<void> => {
  return http.delete<void>(`app/chats-messages/${messageId}`, { params });
};

export const pinMessageReq = (messageId: number) => {
  return http.post<void>(`common/chats-messages/${messageId}/pin`, {}, {});
};

export const unpinMessageReq = (messageId: number) => {
  return http.post<void>(`common/chats-messages/${messageId}/unpin`, {}, {});
};

export const clearChatReq = (data?: Req.IClearChatPayload) => {
  return http.post(`app/chats-messages/clear-chat`, data);
};

const createFormData = (data: Req.ISendFilesMessages) => {
  const formData = new FormData();
  formData.append("chatId", data.chatId.toString());

  if (data.replyToId) formData.append("replyToId", data.replyToId.toString());

  data.files.map((it) => prepareFormData(it, formData));

  return formData;
};

export const chatMessagesApi = {
  getChatMessagesListReq,
  sendTextMessageReq,
  sendImageMessageReq,
  sendFileMessageReq,
  sendVideoMessageReq,
  forwardMessageReq,
  deleteMessageByIdReq,
  pinMessageReq,
  unpinMessageReq,
  clearChatReq,
  sendStickerMessageReq,
  editTextMessageReq,
};
