import { IChat } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TChatActions } from "./types";

export interface IChatsState {
  chats: IChat[];
  selectedChats: IChat[];
  selectedChatId: number;
  unreadMessagesCount: number;
}

const initialState: IChatsState = {
  chats: [],
  selectedChats: [],
  selectedChatId: null,
  unreadMessagesCount: 0,
};

export const chatsReducer = createReducer<IChatsState, TChatActions>(
  initialState,
  {
    SET_CHATS: (state, action) => {
      return { ...state, chats: action.payload.chats };
    },

    SELECT_CHAT_ID: (state, action) => {
      return { ...state, selectedChatId: action.payload.id };
    },

    SELECT_ALL_CHATS: (state, action) => {
      return {
        ...state,
        selectedChats: [...action.payload.chats],
      };
    },

    SELECT_CHAT: (state, action) => {
      return {
        ...state,
        selectedChats: [...state.selectedChats, action.payload.chat],
      };
    },

    UNSELECT_CHAT: (state, action) => {
      return {
        ...state,
        selectedChatId: null,
      };
    },

    SET_UNREAD_MESSAGES_COUNT: (state, action) => {
      return {
        ...state,
        unreadMessagesCount: action.payload,
      };
    },

    INCREASE_UNREAD_MESSAGES_COUNT: (state, action) => {
      return {
        ...state,
        unreadMessagesCount: state.unreadMessagesCount + action.payload,
      };
    },

    DECREASE_UNREAD_MESSAGES_COUNT: (state, action) => {
      return {
        ...state,
        unreadMessagesCount:
          state.unreadMessagesCount > action.payload
            ? state.unreadMessagesCount - action.payload
            : 0,
      };
    },
  }
);
