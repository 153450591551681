/* eslint-disable react/no-deprecated */
import React, { Component, CSSProperties } from "react";
import config from "../../config";
import ModalComponent from "../Modal";
import { ButtonToolbar, Button } from "reactstrap";
import defaultImage from "@/assets/img/default-img.png";

interface IProps {
  defaultImg: any;
  validModal: any;
  image: any;
  onChange: any;
  label: string;
  alt?: string;
  alt2?: string;
  file?: any;
  disabled?: boolean;
  styleImg?: CSSProperties;
}

interface IState {
  tmp_file?: any;
  tmp_imagePreviewUrl?: any;
  imagePreviewUrl: any;
  file: any;
  error: string;
  showModal: boolean;
}

class ImageField extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl: this.props.defaultImg || defaultImage,
      file: null,
      error: "",
      tmp_file: null,
      showModal: false,
    };
  }

  componentDidMount() {
    if (this.props.image) {
      this.setState({
        imagePreviewUrl: this.props.image,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.image !== this.props.image) {
      if (nextProps.image) {
        this.setState({
          imagePreviewUrl: config.apiUrl + nextProps.image,
        });
      } else {
        this.setState({
          imagePreviewUrl: this.props.defaultImg || defaultImage,
        });
      }
    }

    if (nextProps.file !== this.props.file) {
      if (nextProps.file) {
        this.setState({
          file: nextProps.file,
        });
      } else {
        this.setState({
          imagePreviewUrl: this.props.defaultImg || defaultImage,
          file: null,
        });
      }
    }
  }

  setDefaultImg = () => {
    this.setState({
      imagePreviewUrl: this.props.defaultImg || defaultImage,
      file: null,
      error: "",
    });
  };

  onChange = (e) => {
    const { validModal } = this.props;
    e.preventDefault();
    this.setState({
      error: "",
    });
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file) return;

    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg|\.webp)$/i;
    if (!allowedExtensions.exec(file?.name)) {
      this.setState({
        error:
          "Зображення можна завантажувати з наступними розширеннями .jpeg/.jpg/.png/.svg/.webp",
      });
      return false;
    }

    if (file.size / 1024 >= 500) {
      this.setState({
        error: "Зображення не повинно перевищувати 500kB",
      });
      return false;
    }

    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        // alert(img.width + 'x' + img.height);
        if (img.width <= 200 && img.height <= 200) {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
          });
          if (this.props.onChange) {
            this.props.onChange(file);
          }
        } else {
          if (validModal) {
            this.setState({
              error: "Розмір зображення не більший 200px/200px",
              tmp_file: file,
              tmp_imagePreviewUrl: reader.result,
              showModal: true,
            });
          } else {
            this.setState({
              error: "Розмір зображення не більший 200px/200px",
            });
          }
        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  };

  clickBtn = () => {
    const { tmp_file, tmp_imagePreviewUrl } = this.state;
    this.setState({
      file: tmp_file,
      imagePreviewUrl: tmp_imagePreviewUrl,
      showModal: false,
      error: "",
    });
    if (this.props.onChange) {
      this.props.onChange(tmp_file);
    }
  };

  render() {
    const { label, alt, alt2, defaultImg } = this.props;
    const { showModal } = this.state;
    const styles = { width: "80px", height: "", objectFit: "cover" };

    if (defaultImg) {
      styles.width = "100px";
      styles.height = "100px";
    }

    return (
      <div className="form__form-group">
        <ModalComponent
          show={showModal}
          toggle={() =>
            this.setState({
              showModal: false,
            })
          }
        >
          <p>{this.state.error}</p>
          <p>Ви бажаєте автоматично зменшити та завантажити фото?</p>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="button" onClick={this.clickBtn}>
              Так
            </Button>
            <Button onClick={() => this.setState({ showModal: false })}>
              Ні
            </Button>
          </ButtonToolbar>
        </ModalComponent>
        {label ? (
          <span
            className="form__form-group-label"
            style={{ marginBottom: 10, color: "#7F7F7F" }}
          >
            {label}
          </span>
        ) : null}
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <label style={{ cursor: "pointer" }}>
              <img
                style={
                  this.props.styleImg
                    ? this.props.styleImg
                    : (styles as CSSProperties)
                }
                src={this.state.imagePreviewUrl}
                alt=""
              />
              {!this.props.disabled && (
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={this.onChange}
                />
              )}
            </label>
            {alt ? (
              <p style={{ padding: 0, margin: 0, fontSize: "10px" }}>{alt}</p>
            ) : null}
            {alt2 ? (
              <p style={{ padding: 0, margin: 0, fontSize: "10px" }}>{alt2}</p>
            ) : null}
            {this.state.error ? (
              <span className="form__form-group-error">{this.state.error}</span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ImageField;
