import { simpleDispatch } from "@/store/store-helpers";
import { IFactory } from "@/shared";
import { factoriesApi } from "@/api";
import {
  ICreateFactory,
  IUpdateFactory,
} from "@/api/factories/requests.interface";
import { FactoriesIsLoading } from "@/store/factories";

const fetchFactories = async (): Promise<IFactory[]> => {
  simpleDispatch(new FactoriesIsLoading({ isLoading: true }));
  try {
    const { data: factoriesList } = await factoriesApi.fetchFactories();

    return factoriesList;
  } catch (err) {
    console.log("FETCH FACTORIES ERROR: ", err);
  } finally {
    simpleDispatch(new FactoriesIsLoading({ isLoading: false }));
  }
};

const createFactory = async (payload: ICreateFactory): Promise<IFactory> => {
  simpleDispatch(new FactoriesIsLoading({ isLoading: true }));
  try {
    const { data: newFactory } = await factoriesApi.createFactory(payload);

    return newFactory;
  } catch (err) {
    console.log("CREATE FACTORY ERROR: ", err);
  } finally {
    simpleDispatch(new FactoriesIsLoading({ isLoading: false }));
  }
};

const updateFactory = async (payload: IUpdateFactory): Promise<IFactory> => {
  simpleDispatch(new FactoriesIsLoading({ isLoading: true }));
  try {
    const { data: updatedFactory } = await factoriesApi.updateFactory(payload);

    return updatedFactory;
  } catch (err) {
    console.log("UPDATE FACTORY ERROR: ", err);
  } finally {
    simpleDispatch(new FactoriesIsLoading({ isLoading: false }));
  }
};

const deleteFactory = async (id: number): Promise<void> => {
  simpleDispatch(new FactoriesIsLoading({ isLoading: true }));
  try {
    await factoriesApi.deleteFactory(id);
  } catch (err) {
    console.log("DELETE FACTORY ERROR: ", err);
  } finally {
    simpleDispatch(new FactoriesIsLoading({ isLoading: false }));
  }
};

export const factoriesService = {
  fetchFactories,
  createFactory,
  updateFactory,
  deleteFactory,
};
