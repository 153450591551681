import { Tooltip } from "antd";
import React from "react";
interface IProps {
  onPress: any;
  limitRow: any;
}
export const SelectNumberRow = (props: IProps) => {
  return (
    <div style={{ marginRight: 20 }}>
      <Tooltip
        title={"Вибрати кількість рядків"}
        key={"select-rows-count"}
        mouseEnterDelay={0.5}
        placement="bottom"
      >
        <button
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 46,
            width: 125,
            alignItems: "center",
            padding: "15px",
            border: "none",
            borderRadius: 10,
            backgroundColor: "#F8F8F8",
          }}
          onClick={() => props.onPress()}
        >
          <p>{props.limitRow === 99999 ? "Всі" : props.limitRow} / сторінці</p>
          <i
            className="fal fa-chevron-down"
            style={{ fontWeight: 500 }}
            color="rgba(127, 127, 127, 1)"
          />
        </button>
      </Tooltip>
    </div>
  );
};
