import { useState } from "react";
import { chatMessagesService } from "@/services/domain";
import { ISendFileMessage } from "@/api/chats-messages/request.interfaces";

interface IProps {
  //   replyToMessage: IChatMessage;
  chatId: number;
  onSend: () => void;
}

export const useSendFiles = ({ chatId, onSend }: IProps) => {
  const [imageMessages, setImages] = useState<File[]>(null);
  const [isSending, setSending] = useState<boolean>(false);

  const sendImageMessage = async () => {
    console.log("images :", imageMessages);
    try {
      if (!imageMessages) return;
      setSending(true);

      onSend();

      await chatMessagesService.sendMediaMessage(
        imageMessages.map(it => {
          const dataToSend: ISendFileMessage = {
            file: it,
            chatId,
            fileName: it.name
          };

          return dataToSend;
        })
      );
      console.log("send image message", imageMessages);
    } finally {
      setSending(false);
    }
  };

  //   const handlePressMenuItem = async (itemName: string, chatId: number) => {
  //     const menuHandler = {
  //       gallery: sendImageMessage,
  //     };

  //     await menuHandler[itemName](chatId);
  //     // await handlePressGallery(chatId);
  //   };

  return {
    imageMessages,
    setImages,
    sendImageMessage,
    // handlePressMenuAction,
    isSending
  };
};
