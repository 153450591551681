import React, { FC } from "react";
import { IChatMessage } from "./interfaces";
import "./style.scss";

export const ChatItemSystem: FC<IChatMessage> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <span className="system-text">{props.content}</span>
    </div>
  );
};
