import React, { FC, CSSProperties } from "react";
import { Input } from "antd";

interface IProps {
  label: string;
  value: string | number;
  placeholder: string;
  style?: CSSProperties;
  visibilityToggle?: boolean;
  onChange: (val: string) => void;
  required?: boolean;
  error?: string;
}

export const InputPasswordField: FC<IProps> = ({
  label,
  value,
  placeholder,
  style,
  onChange,
  required,
  error,
}) => {
  return (
    <div className="input-password-field">
      <p className="profile__data-tooltip">{label}</p>
      <Input
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};
