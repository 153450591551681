import React, { useState } from "react";
import { chatMessagesService } from "@/services/domain";
import { IChatMessage, useEventsListener } from "@/shared";
import Modal from "../../../components/Modal";
import { IChatMessage as IChatMessageItem } from "../plugins/interfaces";

export const ChatConfirmDeleteModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState<IChatMessage | Partial<IChatMessageItem>[]>(
    null
  );
  const [isDeleteForAll, setDeleteForAll] = useState<boolean>(false);
  const [actions, setActions] = useState(null);

  const toogle = () => setIsOpen((prev) => !prev);

  useEventsListener(
    "openConfirmDeleteMessageModal",
    (payload) => {
      setItem(payload.message);
      setIsOpen(payload.isShow);
      setDeleteForAll(payload.deleteForAll);

      if (payload.onSuccess) setActions({ onSuccess: payload.onSuccess });
    },
    [setItem, setIsOpen]
  );

  useEventsListener(
    "closeConfirmDeleteMessageModal",
    (payload) => {
      setIsOpen(payload.isShow);
    },
    [setIsOpen]
  );

  const onConfirm = async () => {
    await chatMessagesService.deleteChatMessage(item, isDeleteForAll);
    if (actions?.onSuccess) actions?.onSuccess();

    toogle();
  };

  return (
    <Modal full show={isOpen} toggle={toogle}>
      <div className="confirm-modal">
        <span className="message">
          {`Ви впевнені, що хочете видалити повідомлення?`}
        </span>

        <div className="button-bar">
          <button
            className="confirm-btn"
            onClick={() => {
              toogle();
            }}
          >
            Нi
          </button>

          <button className="cancel-btn" onClick={onConfirm}>
            Так
          </button>
        </div>
      </div>
    </Modal>
  );
};
