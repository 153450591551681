import React, { useState } from "react";
import { Radio, Tooltip } from "antd";
import userTree from "@/assets/img/usersThree.svg";
import desktopIcon from "@/assets/img/desktop.svg";
import deviceIcon from "@/assets/img/mobile.svg";
import { AppType } from "../../enums";
import "./style.scss";
import { RadioChangeEvent } from "antd/lib/radio";
import { ExportExcel } from "../ExportExcel/ExportExcel";

interface IProps {
  count: number;
  onChange?: (type: AppType | null) => void;
}

export const AppTypesFilterButtons = ({ count, onChange }: IProps) => {
  const [type, setType] = useState<AppType | null>(null);

  return (
    <div className="buttons-container">
      <Radio.Group
        className="buttons-group"
        value={type}
        onChange={(e: RadioChangeEvent) => {
          setType(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
      >
        <div className={`btn-container${type ? "" : "-active"}`}>
          <Radio.Button
            className={`action-btn_all-user ${type ? null : "btn-active"}`}
            style={{ border: "none", borderRadius: 10 }}
            value=""
          >
            Всі користувачі
          </Radio.Button>
        </div>
        <div className="btn-container">
          <Tooltip title={"Всі входи"} key={"all-entrances"}>
            <Radio.Button
              className={`action-btn_device ${
                type === AppType.All ? "btn-active" : null
              }`}
              style={{ border: "none", borderRadius: 10 }}
              value={AppType.All}
            >
              <img
                className={`${type === AppType.All ? "active-icon" : null}`}
                src={userTree}
                style={{ width: 32, height: 32 }}
                alt="Three users"
              />
            </Radio.Button>
          </Tooltip>
        </div>
        <div className="btn-container">
          <Tooltip title={"Веб-додаток"} key={"desktop"}>
            <Radio.Button
              className={`action-btn_device ${
                type === AppType.Desktop ? "btn-active" : null
              }`}
              style={{ border: "none", borderRadius: 10 }}
              value={AppType.Desktop}
            >
              <img
                className={`${type === AppType.Desktop ? "active-icon" : null}`}
                src={desktopIcon}
                style={{ width: 32, height: 32 }}
                alt="Three users"
              />
            </Radio.Button>
          </Tooltip>
        </div>
        <div style={{ marginRight: 40 }}>
          <Tooltip title={"Мобільний додаток"} key={"device"}>
            <Radio.Button
              className={`action-btn_device ${
                type === AppType.Mobile ? "btn-active" : null
              }`}
              style={{ border: "none", borderRadius: 10 }}
              value={AppType.Mobile}
            >
              <img
                className={`${type === AppType.Mobile ? "active-icon" : null}`}
                src={deviceIcon}
                style={{ width: 32, height: 32 }}
                alt="Three users"
              />
            </Radio.Button>
          </Tooltip>
        </div>
      </Radio.Group>
      <div>
        <p className="count-label">Кількість: {count}</p>
      </div>

      <ExportExcel type={type} />
    </div>
  );
};
