import { ChatType, createFullName, IChat, IChatInList } from "@/shared";
import _ from "lodash";

export const transformChatToSelectListItem = (
  chat: IChat,
  selectedChats: IChatInList[],
  disableIds?: number[]
): IChatInList => ({
  ...chat,
  previewUrl:
    chat?.type === ChatType.Group
      ? chat?.previewUrl
      : chat?.chatMembers[0]?.user?.avatarUrl,
  name:
    chat?.type === ChatType.Group
      ? chat.name
      : createFullName(
          chat?.chatMembers[0]?.user?.firstName,
          chat?.chatMembers[0]?.user?.lastName
        ),
  isSelected:
    !_.isNil(_.find(selectedChats, { id: chat.id })) ||
    _.includes(disableIds, chat.id),
  isDisabled: _.includes(disableIds, chat.id),
});
