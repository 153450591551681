import React, { useMemo } from "react";
import { Dropdown, Menu } from "antd";
import { Row as GridRow, RowRendererProps } from "react-data-grid";
import { ISelectedRowsMenuItemConfig } from "@/shared";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import _ from "lodash";
import "../style.scss";

interface DataType extends Record<string, string | number> {
  id: number;
}

interface IProps<T extends DataType> {
  rowData: RowRendererProps<T>;
  menuConfig: ISelectedRowsMenuItemConfig[];
  onOpenMenu: (isOpen: boolean, id: number) => void;
}

export const CustomTableRow = <T extends DataType>(props: IProps<T>) => {
  const menu = useMemo(() => {
    const items = props.menuConfig.map((it) => ({
      ..._.pick(it, ["key", "label", "onClick"]),
      icon: it.iconNode ? (
        <div className="context-menu-icon">{it.iconNode}</div>
      ) : (
        <button
          key={it.icon.name}
          onClick={it.onClick}
          className={`selected-rows-menu-btn context ${it.icon.name}`}
        >
          <img
            className={it.icon.className}
            src={it.icon.scr}
            alt={it.icon.name}
          />
        </button>
      ),
    }));

    return <Menu className="context-menu" items={items as ItemType[]} />;
  }, [props.menuConfig]);

  return (
    <Dropdown
      onVisibleChange={(visible: boolean) => {
        props.onOpenMenu(visible, props.rowData.row.id);
      }}
      visible={_.isEmpty(props.menuConfig) ? false : undefined}
      overlay={menu}
      trigger={[`contextMenu`]}
    >
      <GridRow id={`task-row-${props.rowData.row.id}`} {...props.rowData} />
    </Dropdown>
  );
};
