import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { TopBarProfile } from "./TopbarProfile";
import { TopBarSidebarButton } from "./TopbarSidebarButton";
import Dimensions from "react-dimensions";
import { RouteEnum } from "@/containers/App/router";
import { TopbarNotificationBlock } from "./TopbarNotificationBlock";
import { notificApi } from "@/api/notifications/request";
import {
  IPushNotification,
  usePaginationList,
  useSocketListener,
} from "@/shared";
import _ from "lodash";
import { SocketEvents } from "@/shared/events";
import { setItemTask } from "@/containers/Task/actions";

interface IProps {
  containerHeight: number;
  containerWidth: number;
  updateDimensions: any;
  changeMobileSidebarVisibility: () => void;
  changeSidebarVisibility: () => void;
  sidebar: {
    show: boolean;
    collapse: boolean;
  };
}

const Topbar = (props: IProps) => {
  const changeWidth = useRef<boolean>(false);

  useEffect(() => {
    changeWidth.current = true;

    if (
      changeWidth.current &&
      props.containerWidth < 768 &&
      !props.sidebar.collapse
    ) {
      props.changeSidebarVisibility();
    } else if (
      changeWidth.current &&
      props.containerWidth > 768 &&
      props.sidebar.collapse
    ) {
      props.changeSidebarVisibility();
    }
  }, [props?.containerWidth]);

  const paginationList = usePaginationList<IPushNotification>({
    fetchItems: notificApi.fetchNotificationsList,
    loadParams: {
      limit: 9999999,
      page: 1,
      sort: "DESC",
      sortField: "id",
    },
  });

  const onReadNotif = (ids?: number[]) => {
    if (ids) {
      const newList = paginationList.items.filter(
        (it) => !_.includes(ids, it.id)
      );
      paginationList._setItems(newList);
    } else {
      paginationList._setItems([]);
    }
  };

  const onNewNotification = ({}: SocketEvents["notification"]) => {
    paginationList.resetFlatList();
    setTimeout(() => {
      paginationList.resetFlatList();
    }, 100);
  };

  useSocketListener("notification", onNewNotification, [paginationList.items]);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopBarSidebarButton
            changeMobileSidebarVisibility={props.changeMobileSidebarVisibility}
            changeSidebarVisibility={props.changeSidebarVisibility}
          />
          <Link className="topbar__logo" to={RouteEnum.Tasks} />
        </div>
        <div className="topbar__right">
          <TopbarNotificationBlock
            notics={paginationList.items}
            onReadNotif={onReadNotif}
          />
          <TopBarProfile />
        </div>
      </div>
    </div>
  );
};

export default Dimensions()(Topbar);
