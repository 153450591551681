import { ChatAvatarWithOnlineIndicator } from "../atoms";
import { hasImageUrl } from "@/shared";
import React, { FC } from "react";

interface IProps {
  chatId: number;
  imageUrl: string;
  chatName: string;
  isChecked: boolean;
  onSelectItem: () => void;
  onDeselectItem?: () => void;
  isDisabled?: boolean;
}

export const ChatListItem: FC<IProps> = ({
  chatId,
  imageUrl,
  chatName,
  isChecked,
  onSelectItem,
  onDeselectItem,
}) => {
  return (
    <div className="chat-item-row-container">
      <div className="chat-info">
        <ChatAvatarWithOnlineIndicator
          isOnline={false}
          imageUrl={hasImageUrl(imageUrl, chatName)}
          size={35}
        />
        <span>{chatName}</span>
      </div>

      <label>
        <input
          id={chatId.toString()}
          name={chatName}
          type="checkbox"
          onChange={() => {
            !isChecked ? onSelectItem() : onDeselectItem();
          }}
        />
        <svg
          className={`checkbox ${isChecked ? "checkbox--active" : ""}`}
          aria-hidden="true"
          viewBox="0 0 15 11"
          fill="none"
        >
          <path
            d="M1 4.5L5 9L14 1"
            strokeWidth="2"
            stroke={isChecked ? "rgba(158, 39, 67, 1)" : "none"}
          />
        </svg>
      </label>
    </div>
  );
};
