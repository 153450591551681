import { secretModApi } from "@/api/secret-mod/requests";
import { IUser, notification } from "@/shared";
import { create } from "zustand";

interface State {
  users: IUser[];

  loadUsers: () => void;
  setUsers: (users: IUser[]) => void;
}

export const useSecretUsersList = create<State>()((set) => ({
  users: [],
  async loadUsers() {
    try {
      const { data } = await secretModApi.getUsers({
        limit: 500,
        sortField: "lastName",
        sort: "ASC",
      });
      set({ users: data.items });
    } catch (e) {
      notification.showError(
        "Помилка",
        "Виникла помилка при завантаженні списку, спробуйте, будь ласка, пізніше."
      );
    }
  },
  setUsers(users) {
    set({ users });
  },
}));

export const secretUsersListSelectIds = (users: IUser[]) =>
  users.map((it) => it.id);
