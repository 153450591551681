import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as req from "./requests.interfaces";
import * as res from "./responses.interfaces";

export const createComment = (
  data: req.ICreateComment
): ApiResponse<res.ICommentListItem> => {
  return http.post<res.ICommentListItem>("admin/tasks-comments", data);
};

export const fetchComments = (params: {
  taskId: number;
}): ApiResponse<res.ICommentsList> => {
  return http.get<res.ICommentsList>("admin/tasks-comments", { params });
};

export const editComment = (id: number, content: any) => {
  return http.put(`admin/tasks-comments/${id}`, { content });
};

export const deleteComment = (id: number) => {
  return http.delete(`admin/tasks-comments/${id}`);
};

export const taskCommentsApi = {
  createComment,
  fetchComments,
  deleteComment,
  editComment,
};
