import {
  ChatType,
  createFullName,
  getMessagePreviewText,
  IChat,
} from "@/shared";
import React, { FC, useCallback, useEffect, useState } from "react";
import { ChatRowCard } from "./chat-row-card.component";
import InfiniteScroll from "react-infinite-scroll-component";
import { chatsService } from "@/services/domain";
import { SelectChatId, selectSelectedChatId } from "@/store/chats";
import { useHistory } from "react-router-dom";
import { simpleDispatch } from "@/store/store-helpers";
import ChatLoader from "../atoms/chatloader.atom";
import _ from "lodash";
import { appEvents } from "@/shared/events";
import { useSelector } from "react-redux";

interface IProps {
  chats: IChat[];
  loadParams?: Record<string, string | number | any>;
  loadMore?: () => void;
  loadPage?: (page: number) => void;
  isLoading?: boolean;
  isLoadingNext?: boolean;
}

export const ChatsList: FC<IProps> = (props) => {
  const { chats } = props;
  const [chatList, setChatList] = useState<IChat[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const history = useHistory();

  const selectedChatId = useSelector(selectSelectedChatId);

  const { page, count } = props.loadParams;

  useEffect(() => {
    if (_.isEmpty(chats)) {
      setChatList([]);
      setHasMore(false);
      return;
    }

    if (count <= 20) {
      setChatList(chats);
      setHasMore(false);
      return;
    }

    setHasMore(true);
    setChatList(chats);
  }, [chats]);

  const fetchMoreData = async () => {
    if (chatList.length >= count) {
      setHasMore(false);
      return;
    }

    const nextpage = page + 1;
    const { data } = await chatsService.fetchChats({
      params: { page: nextpage },
    });

    setTimeout(() => {
      setChatList((prevItems) => [...prevItems, ...data.items]);
    }, 500);
  };

  const onPressItem = async (item: IChat) => {
    await simpleDispatch(new SelectChatId({ id: item.id }));

    appEvents.emit("onReadChat", {
      chatId: item.id,
      unreadCount: item.unreadMessagesCount,
    });
    history.push(`/chats?id=${item}`);
  };

  const renderEndMessage = useCallback(() => {
    if (_.isEmpty(chatList) && props.isLoading) {
      return <ChatLoader />;
    } else if (_.isEmpty(chatList) && !props.isLoading) {
      return (
        <div className="chatloader-container">
          <span>Активні чати відсутні</span>
        </div>
      );
    } else return null;
  }, [props.isLoading, chatList]);

  return (
    <>
      <InfiniteScroll
        dataLength={chatList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div className="chatloader-container">
            <ChatLoader />
          </div>
        }
        height={"65vh"}
        endMessage={renderEndMessage}
        className="infinite-scroll-container"
      >
        {chatList.map((value, id) => {
          const previewUrl =
            value?.type === ChatType.Group
              ? value?.previewUrl
              : value?.chatMembers[0]?.user?.avatarUrl;

          const name =
            value?.type === ChatType.Group
              ? value.name
              : createFullName(
                  value?.chatMembers[0]?.user?.firstName,
                  value?.chatMembers[0]?.user?.lastName
                );

          return (
            <ChatRowCard
              id={value.id}
              lastClicked={selectedChatId}
              key={id}
              label={name}
              lastMessage={getMessagePreviewText(value.lastMessage as any)}
              previewUrl={previewUrl}
              isOnline={Boolean(Math.round(Math.random()))}
              isPinned={value.isChatFixed}
              isUnread={value.isChatUnread}
              unreadMessagesCount={value.unreadMessagesCount}
              sendDateTime={value.lastMessageDate}
              onPress={() => onPressItem(value)}
            />
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export default ChatsList;
