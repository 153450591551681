import React, { useEffect, useMemo, useState } from "react";
import { SidebarLink } from "./SidebarLink";
import { SidebarCategory } from "./SidebarCategory";
import { useSelector } from "react-redux";
import FilterTask from "./filter_task/FilterTask";
import { getProfile } from "@/store/account";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteEnum } from "@/containers/App/router";
import { checkPermission, EUserRole, getParentsTree, IFactory } from "@/shared";

import {
  FILE_ChatIcon,
  FILE_TaskIcon,
  FILE_BuildingIcon,
  FILE_ContactsIcon,
  FILE_GroupPermIcon,
  FILE_LogsIcon,
  FILE_LogsIpIcon,
  FILE_UserIcon,
  FILE_BooksIcon,
  FILE_TasksGroupsIcon,
  FILE_TasksReasonsIcon,
} from "../../../assets/img/index";
import FilterUser from "./filter_user/FilterUser";
import FilterContact from "./filter_user_contacts/FilterContact";
import { simpleDispatch } from "@/store/store-helpers";
import {
  ClearContactsFilter,
  ClearUsersFilter,
  contactsFilterInitialState,
  getContactsFilter,
  getUsersFilter,
  usersFilterInitialState,
} from "@/store/users";
import { ClearFilter, filterInitialState, getTaskFilter } from "@/store/task";
import _ from "lodash";
import { getMyPermissions } from "@/store/permissions";
import { factoriesApi } from "@/api";

interface IProps extends RouteComponentProps {
  hideSidebar: () => void;
  changeToDark: () => void;
  changeToLight: () => void;
}

const SidebarContent = (props: IProps) => {
  const [factories, setFactories] = useState<IFactory[]>([]);
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);
  const tasksFilter = useSelector(getTaskFilter);
  const usersFilter = useSelector(getUsersFilter);
  const contactsFilter = useSelector(getContactsFilter);

  const fetchFactories = async () => {
    try {
      const { data: factories } = await factoriesApi.fetchFactoriesFullList();
      setFactories(factories);
    } catch (e) {
      console.log("FETCH FACTORIES LIST ERROR", e);
    }
  };

  useEffect(() => {
    fetchFactories();
  }, []);

  useEffect(() => {
    // очищення певного фільтру бокового меню при навігації до іншого пункту даного меню
    if (
      props.location.pathname !== RouteEnum.User &&
      !_.isEqual(usersFilter, usersFilterInitialState)
    )
      simpleDispatch(new ClearUsersFilter());

    if (
      props.location.pathname !== RouteEnum.Tasks &&
      !_.isEqual(tasksFilter, filterInitialState)
    )
      simpleDispatch(new ClearFilter());

    if (
      props.location.pathname !== RouteEnum.Contacts &&
      !_.isEqual(contactsFilter, contactsFilterInitialState)
    )
      simpleDispatch(new ClearContactsFilter());
  }, [props.location.pathname, tasksFilter, usersFilter, contactsFilter]);

  const factoriesTree = useMemo(() => getParentsTree(factories, null), [
    factories,
  ]);

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <div style={{ display: "flex", position: "relative" }}>
          <SidebarLink
            title="Чати"
            iconCustom={FILE_ChatIcon}
            route={RouteEnum.Chats}
            onClick={props.hideSidebar}
          />
        </div>

        <SidebarLink
          title="Задачі"
          iconCustom={FILE_TaskIcon}
          route={RouteEnum.Tasks}
          onClick={props.hideSidebar}
        />
        {checkPermission("tabs", "user", profile, permissions) && (
          <SidebarLink
            title="Користувачі"
            iconCustom={FILE_UserIcon}
            route={RouteEnum.User}
            onClick={props.hideSidebar}
          />
        )}
        <SidebarLink
          title="Контакти"
          iconCustom={FILE_ContactsIcon}
          route={RouteEnum.Contacts}
          onClick={props.hideSidebar}
        />
        {checkPermission("tabs", "factory", profile, permissions) && (
          <SidebarLink
            title="Підприємства"
            iconCustom={FILE_BuildingIcon}
            route={RouteEnum.Factory}
            onClick={props.hideSidebar}
          />
        )}
        {checkPermission("tabs", "group_permission", profile, permissions) && (
          <SidebarLink
            title="Групи прав"
            iconCustom={FILE_GroupPermIcon}
            route={RouteEnum.GroupPermission}
            onClick={props.hideSidebar}
          />
        )}
        {profile.role === EUserRole.Admin && (
          <SidebarLink
            title="Журнал подій"
            iconCustom={FILE_LogsIcon}
            route={RouteEnum.LogsUser}
            onClick={props.hideSidebar}
          />
        )}
        {profile.role === EUserRole.Admin && (
          <SidebarLink
            title="Журнал IP"
            iconCustom={FILE_LogsIpIcon}
            route={RouteEnum.Ips}
            onClick={props.hideSidebar}
          />
        )}

        {checkPermission("tabs", "taxonomy", profile, permissions) && (
          <SidebarCategory
            title="Довідники"
            iconCustom={FILE_BooksIcon}
            classNameCollapse="taxonomies"
          >
            {/* {checkPermission("taxonomy", "find", profile, permissions) && ( */}
            <SidebarLink
              title="Групи задач"
              iconCustom={FILE_TasksGroupsIcon}
              route={`${RouteEnum.Taxonomy}/task`}
              onClick={props.hideSidebar}
            />
            {/* )} */}
            {/* {checkPermission("taxonomy", "find", profile, permissions) && ( */}
            <SidebarLink
              title="Підстави задач"
              iconCustom={FILE_TasksReasonsIcon}
              route={`${RouteEnum.Taxonomy}/reasons_task`}
              onClick={props.hideSidebar}
            />
            {/* )} */}
          </SidebarCategory>
        )}
        {props.history.location.pathname === RouteEnum.Tasks && (
          <SidebarCategory
            // classNameCollapse={"FilterTask"}
            isOpen={true}
            title="Фільтр"
            icon="sliders"
          >
            <FilterTask />
          </SidebarCategory>
        )}
        {props.location.pathname === RouteEnum.User && (
          <SidebarCategory isOpen={true} title="Фільтр" icon="sliders">
            <FilterUser
              factoriesTree={factoriesTree}
              filterFactory={checkPermission(
                "filter_factory",
                null,
                profile,
                permissions
              )}
            />
          </SidebarCategory>
        )}
        {props.location.pathname === RouteEnum.Contacts && (
          <SidebarCategory isOpen={true} title="Фільтр" icon="sliders">
            {checkPermission("filter_factory", null, profile, permissions) && (
              <FilterContact factoriesTree={factoriesTree} />
            )}
          </SidebarCategory>
        )}
      </ul>
    </div>
  );
};

export default withRouter(SidebarContent);
