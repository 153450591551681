import { convertToFormData, IPagination } from "@/shared";
import http from "../http.service";
import { ApiResponse } from "../http.types";
import * as Req from "./requests.interfaces";
import * as Res from "./responses.interfaces";

const fetchUsersList = (
  params: IPagination
): ApiResponse<Res.IFetchUsersList> => {
  const reqParams = { ...params };
  if (!params.sortField) {
    reqParams.sort = "ASC";
    reqParams.sortField = "lastName";
  }
  return http.get<Res.IFetchUsersList>(`admin/users`, { params: reqParams });
};

const fetchShortInfoUsersList = (
  params: IPagination & { includeIds?: number[]; name?: string }
): ApiResponse<Res.IFetchShortInfoUsersList> => {
  return http.get<Res.IFetchShortInfoUsersList>(`admin/users/short-info`, {
    params
  });
};

const createUser = (
  payload: Req.ICreateUserPayloadReq
): ApiResponse<number> => {
  const data = convertToFormData(payload);
  return http.post<number>(`admin/users`, data);
};

export const fetchUserStats = (
  id: number
): ApiResponse<Res.IFetchUsersStats> => {
  return http.get<Res.IFetchUsersStats>(`admin/users/statistics/${id}`);
};

export const deleteUser = (userIds: number[]) => {
  return http.delete<Req.IDeleteUsersReq>(`admin/users/`, {
    params: { userIds }
  });
};

export const changeStatus = (data: Req.IChangeUserStatusPayload) => {
  return http.patch("admin/users/status", data);
};

export const updateFactoryRelations = (
  payload: Req.IUpdateUserFactoriesRelationsPayload[]
) => {
  return http.put("admin/users/factory-relations", payload);
};

export const getUsersForExport = (params: Req.IFetchUsersForExportParams) => {
  return http.get<Res.IUserForExportResp[]>("admin/users/for-export", {
    params
  });
};

export const usersApi = {
  fetchUsersList,
  createUser,
  fetchUserStats,
  deleteUser,
  changeStatus,
  updateFactoryRelations,
  fetchShortInfoUsersList,
  getUsersForExport
};
