import React, { FC, CSSProperties } from "react";
import { Input, message } from "antd";
import "./styles.scss";
import copySvg from "@/assets/img/copy-icon-1.svg";
import { copyText } from "@/shared/helpers/copy.helpers";

interface IProps {
  label: string;
  value: string | number;
  placeholder?: string;
  style?: CSSProperties;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange: (val: string) => void;
  maxLength?: number;
  inputStyle?: CSSProperties;
  enableCopy?: boolean;
}

export const InputField: FC<IProps> = ({
  label,
  value,
  placeholder,
  style,
  required,
  disabled,
  onChange,
  error,
  maxLength,
  inputStyle,
  enableCopy = false,
}) => {
  const onCopy = (value) => {
    copyText(value);
  };

  return (
    <div className="input-mask-field" style={style}>
      <p className="input-label">{label}</p>
      <div className="input-container">
        <Input
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          maxLength={maxLength}
          style={inputStyle}
        />
        {enableCopy && (
          <img
            className="contact-copy"
            src={copySvg}
            alt="copy"
            onClick={() => onCopy(value)}
          />
        )}
      </div>
      {error && <span className="error">{error}</span>}
    </div>
  );
};
