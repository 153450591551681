import { chatsService } from "@/services/domain";
import {
  ChatType,
  IChatDetails,
  useEventsListener,
  useSocketListener,
} from "@/shared";
import { getProfile } from "@/store/account";
import { UnselectChat } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getHeaderGroupChatInfo, getHeaderPersonalChatInfo } from "../helpers";
import { IHeaderChatInfo } from "../interfaces";
import { transformChatDetailToHeaderChatInfo } from "../transforms";
import { message } from "antd";

const headerChatInfoInitialState: IHeaderChatInfo = {
  name: " ",
  previewUrl: null,
  type: ChatType.Group,
  label: " ",
};

export const useChatDetails = (chatId: number) => {
  const accountId = useSelector(getProfile);

  const history = useHistory();

  const [chatDetails, setChatDetails] = useState<IChatDetails>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [headerChatInfo, setHeaderChatInfo] = useState<IHeaderChatInfo>(
    headerChatInfoInitialState
  );

  const fetchDetails = async () => {
    setLoading(true);

    try {
      const chatDetails = await chatsService.fetchChatDetails({ id: chatId });

      setHeaderInfo(chatDetails);
      setChatDetails(chatDetails);
    } catch (e) {
      message.error("Сталась помилка, такого чату не існує");
      console.log("e", e);
      setChatDetails(null);
    } finally {
      setLoading(false);
    }
  };

  const setHeaderInfo = (chatDetails: IChatDetails) => {
    const headerChatInfo = transformChatDetailToHeaderChatInfo(
      chatDetails,
      accountId.id
    );
    const onlineUsersCount = chatDetails.chatMembers?.reduce(
      (count, it) => count + (it.isOnline && !it.isDeleted ? 1 : 0),
      0
    );
    const headerLabel = {
      [ChatType.Group]: getHeaderGroupChatInfo(
        _.filter(chatDetails.chatMembers, (member) => !member.isDeleted).length,
        onlineUsersCount
      ),
      [ChatType.Personal]: getHeaderPersonalChatInfo(
        chatDetails.chatMembers,
        accountId.id
      ),
    };

    setHeaderChatInfo({
      ...headerChatInfo,
      label: headerLabel[chatDetails.type],
    });
  };

  useEffect(() => {
    if (chatId) fetchDetails();
  }, [chatId]);

  // const onDeleteChatHeaderData = () => {
  //   setHeaderChatInfo(headerChatInfoInitialState);
  // };

  const onClearAllChats = () => {
    setHeaderChatInfo(headerChatInfoInitialState);

    simpleDispatch(new UnselectChat());
    history.push(`/chats`);
  };

  const onDeleteAllChats = () => {
    setHeaderChatInfo(headerChatInfoInitialState);

    simpleDispatch(new UnselectChat());
    history.push(`/chats`);
  };

  const onDeleteChatForMe = () => {
    setHeaderChatInfo(headerChatInfoInitialState);

    simpleDispatch(new UnselectChat());
    history.push(`/chats`);
  };

  const onDeleteChatForAll = () => {
    setHeaderChatInfo(headerChatInfoInitialState);

    simpleDispatch(new UnselectChat());
    history.push(`/chats`);
  };

  const updateChatData = (id: number) => {
    if (id !== chatId) return;
    fetchDetails();
  };

  const onNewMembers = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  const onDeleteMember = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  const onEditChat = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  const onPinedMessage = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  const onDeleteChatMessage = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  const onChangeChatRole = (data: { chatId: number }) => {
    updateChatData(data?.chatId);
  };

  const onUpdateMessage = ({ message }) => {
    if (!chatDetails || _.isEmpty(chatDetails.pinedMessages)) return;
    const pinnedUpdated = _.find(
      chatDetails.pinedMessages,
      (it) => it.id === message.id
    );
    if (!pinnedUpdated) return;

    const newPinned = chatDetails.pinedMessages.map((it) => {
      if (it.id === message.id) {
        return message;
      }
      return it;
    });

    setChatDetails({
      ...chatDetails,
      pinedMessages: newPinned,
    });
  };

  useSocketListener("chat/update-message", onUpdateMessage, [
    chatDetails?.pinedMessages,
  ]);

  // APP EVENTS LISTENERS //

  useEventsListener("onDeleteChatForMe", onDeleteChatForMe);
  useEventsListener("onClearAllChats", onClearAllChats);
  useEventsListener("onDeleteAllChats", onDeleteAllChats);

  useSocketListener("chat/new-members", onNewMembers, [chatDetails]);
  useSocketListener("chat/delete-member", onDeleteMember, [chatDetails]);

  useSocketListener("chat/delete-chat", onDeleteChatForAll, [chatId]);
  useSocketListener("chat/edit-chat", onEditChat, [chatDetails]);
  useSocketListener("chat/pined-message", onPinedMessage, [chatDetails]);
  useSocketListener("chat/delete-message", onDeleteChatMessage, [chatDetails]);
  useSocketListener("chat/change-role", onChangeChatRole, [chatDetails]);

  return {
    chatDetails,
    headerChatInfo,
    isLoading,
  };
};
