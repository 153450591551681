import { tasksApi, tasksDocumentsApi } from "@/api";
import { IFile, ITask, notification } from "@/shared";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { defaultTaskValues } from "../consts";
import { ETaskModalMode } from "../enums";

interface IProps {
  onSubmitData: () => void;
  taskData?: ITask;
  mode?: ETaskModalMode;
}

export const useCreateUpdateTask = ({
  onSubmitData,
  taskData,
  mode,
}: IProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [attachments, setAttachments] = useState<(IFile & { id: number })[]>(
    []
  );
  const [copyDocumentsIds, setCopyDocumentsIds] = useState<number[]>([]);

  const form = useForm({
    defaultValues: defaultTaskValues,
  });

  const getStartDate = () => {
    const startDate = moment(new Date(taskData?.startDate)).format();

    if (mode === ETaskModalMode.Copy && startDate < moment().format())
      return moment().format();

    if (taskData?.startDate) return startDate;

    return null;
  };

  const getEndDate = () => {
    const endDate = moment(new Date(taskData.endDate)).format();

    if (
      mode === ETaskModalMode.Copy &&
      endDate <
        moment()
          .add(1, "day")
          .format()
    )
      return moment()
        .add(1, "day")
        .format();

    if (taskData?.endDate) return endDate;

    return null;
  };

  useEffect(() => {
    if (!taskData) {
      form.reset();
      return;
    }
    form.setValue("name", taskData.name);
    form.setValue("startDate", getStartDate());
    form.setValue("endDate", getEndDate());
    form.setValue("createDate", moment(new Date(taskData?.createdAt)).format());
    form.setValue("description", taskData.description);
    if (taskData.initiatorId)
      form.setValue("initiatorId", taskData.initiatorId.toString());
    if (taskData.executorId)
      form.setValue("executorsId", [taskData.executorId.toString()]);
    form.setValue("groupId", taskData.groupId.toString());
    if (taskData.reasonId)
      form.setValue("reasonId", taskData.reasonId.toString());
  }, [taskData, mode]);

  const { handleSubmit } = form;

  const openErrorNotification = (description: string) =>
    notification.showError("Помилка", description);

  const openSuccessNotification = (description: string) =>
    notification.showSuccess("Повідомлення", description);

  const addAttachment = (files: IFile[]) => {
    files.map((it) => (it.id = Math.random()));

    setAttachments((state) => [
      ...(files as (IFile & { id: number })[]),
      ...state,
    ]);
  };

  const deleteAttachment = (id: number) => {
    const attachmentsRest = attachments.filter((it) => it.id !== id);
    setAttachments(attachmentsRest);
  };

  const submitCreateTasks = async (values) => {
    const { data: newTaskIds } = await tasksApi.createTask(values);

    if (!_.isEmpty(attachments)) {
      try {
        await Promise.all(
          newTaskIds?.map(async (taskId: number) => {
            await tasksDocumentsApi.addTaskDocuments({
              taskId,
              files: attachments,
            });
          })
        );
        openSuccessNotification(
          `${attachments.length > 1 ? "Файли" : "Файл"} успішно завантажено`
        );

        onSubmitData();
      } catch (e) {
        openErrorNotification(
          `Не вдалось додати ${attachments.length > 1 ? "файли" : "файл"}`
        );
      } finally {
        setAttachments([]);
      }
    }
  };

  const submitUpdateTask = async (values) => {
    const data = {
      ..._.omit(values, "executorsId"),
      executorId: _.isArray(values.executorsId)
        ? values.executorsId[0]
        : values.executorsId,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    await tasksApi.updateTask(taskData.id, data);
  };

  const submitCopyTask = async (values) => {
    await submitCreateTasks({
      ...values,
      executorsId: _.isArray(values.executorsId)
        ? values.executorsId
        : [values.executorsId],
      copyOfTaskId: taskData?.id,
      copyDocumentsIds,
    });
  };

  const submitByMode = {
    [ETaskModalMode.Create]: submitCreateTasks,
    [ETaskModalMode.Update]: submitUpdateTask,
    [ETaskModalMode.Copy]: submitCopyTask,
  };

  const onSubmit = async (values) => {
    try {
      setIsSaving(true);
      await submitByMode[mode](values);
      onSubmitData();
      form.reset(defaultTaskValues);
    } catch (e) {
      openErrorNotification("Не вдалось зберегти дані задачі");
    } finally {
      setIsSaving(false);
    }
  };

  return {
    form,
    submit: handleSubmit(onSubmit),
    attachments,
    addAttachment,
    deleteAttachment,
    setCopyDocumentsIds,
    isSaving,
  };
};
