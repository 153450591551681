import React, { useEffect, useState } from "react";
import ReactDataGrid, { SortDirection } from "react-data-grid";
import "./style.scss";
// import "react-data-grid/dist/react-data-grid.css";
import "react-data-grid-addons/dist/react-data-grid-addons.css";
import { Spin, Pagination, PaginationProps, Tooltip } from "antd";
import { ETaskStatus, IColumn } from "@/shared";
import { ActionBottomBar, columnsRender } from "./components";
import { voidColumn, VOID_COLUMN_KEY } from "./configs";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";
import { notification } from "@/shared";

interface IProps {
  tableName: string;
  columns: IColumn[];
  activeColumns: string[];
  paginationList: any;
  selectedRows?: any;
  setSelectedRows?: any;
  tableProps?: any;
  showActionBottomBar?: boolean;
  onRowClick?: (idx, row: any) => void;
  focusedFilterField?: string;
  onFocusFilterField?: (fieldKey: string) => void;
  targetDate?: Date;
  openedRowId?: number;
  allSelected?: boolean;
  selectAll?: () => void;
}

interface ITableSettings {
  activeColumns?: string[];
  columnsWidth?: {
    [key: string]: number;
  };
  pageSize?: number;
  columnsSort?: string[];
}

export const Table = ({
  paginationList,
  selectedRows,
  setSelectedRows,
  showActionBottomBar = true,
  onRowClick,
  openedRowId,
  allSelected,
  selectAll,
  ...props
}: IProps) => {
  const [columns, setColumns] = useState<any>([voidColumn, ...props.columns]);
  const [rows, setRows] = useState<any>(paginationList.items);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [columnsSort, setColumnsSort] = useState<string[]>(
    columns.map((it) => it.key)
  );
  const [activeColumns, setActiveColumns] = useState<string[]>(
    props.activeColumns || [VOID_COLUMN_KEY]
  );
  const [columnsWidth, setColumnsWidth] = useState<{ [key: string]: number }>(
    {}
  );

  const [tableFilter, setTableFilter] = useState<
    Record<string, string | number>
  >({});

  const [sortBy, setSortBy] = useState(null);

  const initTableSettings = () => {
    const tableSettings: ITableSettings = JSON.parse(
      localStorage.getItem(`${props.tableName}TableSettings`)
    );

    if (tableSettings) {
      if (tableSettings?.activeColumns)
        setActiveColumns(tableSettings.activeColumns);

      if (tableSettings?.columnsWidth)
        setColumnsWidth(tableSettings.columnsWidth);

      if (tableSettings?.pageSize)
        paginationList?.setLoadParams({
          limit: tableSettings.pageSize,
          page: 1,
        });

      if (tableSettings.columnsSort)
        setColumnsSort([...tableSettings.columnsSort]);
    }
  };

  const onRowsEmpty = () => {
    setColumns([...props.columns]);
    setRows(paginationList.items);
  };

  useEffect(() => {
    initTableSettings();
  }, []);

  useEffect(() => {
    onRowsEmpty();
  }, [paginationList.items]);

  const onSaveTableSettings = () => {
    localStorage.setItem(
      `${props.tableName}TableSettings`,
      JSON.stringify({
        activeColumns,
        columnsWidth,
        pageSize: paginationList.loadParams.limit,
        columnsSort,
      })
    );
    notification.showSuccess(
      "Налаштування",
      "Дані налаштувань таблиці збережені"
    );
  };

  const getNextDirection = (direction: SortDirection | null) => {
    if (direction === "ASC") return "DESC";
    if (direction === "DESC") return null;
    return "ASC";
  };

  const onSort = (column: IColumn & { sortKey?: string }) => {
    const newDirection = _.has(sortBy, column.key)
      ? getNextDirection(sortBy[column.key])
      : "ASC";

    setSortBy({
      [column.key]: newDirection,
    });

    const orderKey = column.sortKey || column.key;
    paginationList.setOrderBy(newDirection ? orderKey : null);
  };

  const renderColumns = () =>
    columnsRender({
      columns,
      paginationList,
      selectedRows,
      columnsSort,
      columnsWidth,
      showFilter,
      sortBy,
      onSort,
      allSelected,
      // allSelected:
      //   !_.isEmpty(paginationList.items) &&
      //   selectedRows?.length === rows?.length,
      tableFilter,
      setTableFilter,
      onSelectAll: selectAll,
      columnsToShow: activeColumns,
      ...props,
    });

  const paginationItemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Tooltip
          title={"Попередня сторінка"}
          key={"previous-page"}
          mouseEnterDelay={0.5}
          placement="bottom"
        >
          {originalElement}
        </Tooltip>
      );
    }
    if (type === "next") {
      return (
        <Tooltip
          title={"Наступна сторінка"}
          key={"next-page"}
          mouseEnterDelay={0.5}
          placement="bottom"
        >
          {originalElement}
        </Tooltip>
      );
    }
    return originalElement;
  };

  const targetDate = props.targetDate ? moment(props.targetDate) : moment();

  return (
    <div className="table-grid_custom" id="table-el">
      {paginationList.isLoading && (
        <div className="table-spinner">
          <Spin />
        </div>
      )}

      <ReactDataGrid
        columns={renderColumns()}
        rows={rows}
        className={showFilter ? "rdg-light" : "rdg-light"}
        rowClass={(row: any) =>
          classNames({
            "rdg-row-custom-class_IP": props.tableName === "ips",
            "rdg-row-custom-class": props.tableName !== "ips",
            outdate: row.endDate
              ? moment(row.endDate).endOf("day") < targetDate
              : false,
            inactive: row.status === ETaskStatus.Deleted,
            doneOverdue:
              row.status === ETaskStatus.Finished &&
              moment(row.endDate).endOf("day") < moment(),
            "row-selected":
              props.tableName === "tasks" &&
              (_.includes(selectedRows, row.id) || openedRowId === row.id),
          })
        }
        rowKeyGetter={(row: any) => {
          if (props.tableName === "logs") return `${props.tableName}-${row.id}`;
          return row.userId
            ? `${props.tableName}-${row.userId}`
            : `${props.tableName}-${row.id}`;
        }}
        headerRowHeight={showFilter ? 120 : 55}
        rowHeight={55}
        enableFilterRow={showFilter}
        filters={<input />}
        onColumnResize={(idx, width) =>
          setColumnsWidth({ ...columnsWidth, [activeColumns[idx]]: width })
        }
        onRowClick={onRowClick} // click contact row
        {...props.tableProps}
      />

      <div className="table-navigate-action">
        {paginationList.loadParams.limit !== 99999 ? (
          <Pagination
            showLessItems
            showSizeChanger={false}
            onShowSizeChange={(current, size) => {
              paginationList.setLoadParams({ limit: size, page: current });
            }}
            total={paginationList.loadParams.count}
            pageSize={paginationList.loadParams.limit}
            current={paginationList.loadParams.page}
            itemRender={paginationItemRender}
            showTitle={false}
            onChange={(page) => {
              // if (setSelectedRows) setSelectedRows([]);
              paginationList.loadPage(page);
              // paginationList.setLoadParams({ page: Number(page) });
            }}
          />
        ) : (
          <div />
        )}

        {showActionBottomBar ? (
          <div className="table-navigate-action-r">
            <ActionBottomBar
              onSaveTableSettings={onSaveTableSettings}
              paginationParams={paginationList.loadParams}
              showFilter={showFilter}
              onSetLoadParams={paginationList.setLoadParams}
              setShowFilter={setShowFilter}
              onSetPaginationParams={paginationList.setLoadParams}
              columns={props.columns}
              columnsSort={columnsSort}
              activeColumns={activeColumns}
              onChangeColumns={setActiveColumns}
              onSaveColumnsSort={setColumnsSort}
              tableName={props.tableName}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
