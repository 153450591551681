import React, { useEffect } from "react";
import classNames from "classnames";
import Topbar from "./topbar/Topbar";
import { Sidebar } from "./sidebar/Sidebar";
import { useSelector } from "react-redux";
import {
  ChangeMobileSidebarVisibility,
  ChangeSidebarVisibility,
  ChangeThemeToDark,
  ChangeThemeToLight,
  getSidebar,
} from "@/store/shared";
import { simpleDispatch } from "@/store/store-helpers";
import { SocketIo } from "@/services/system";
import { EntityType, useSocketListener } from "@/shared";
import { accountService, permissionsService } from "@/services/domain";
import { ContentModalSmart } from "@/smart-components/content-modal";
import { getProfile } from "@/store/account";
import { SocketEvents } from "@/shared/events";

export const Layout = () => {
  const profile = useSelector(getProfile);

  useEffect(() => {
    const socketIo = SocketIo.get();

    socketIo.init();
  }, []);

  const onPermissionsChanged = async () => {
    await permissionsService.loadMyPermissions();
  };

  const onAccountChange = async (data: SocketEvents["version"]) => {
    if (data.type === EntityType.User && data.entityId === profile.id)
      await accountService.getAccount();
  };

  useSocketListener("user/change-permissions", onPermissionsChanged, []);
  useSocketListener("version", onAccountChange, [profile]);

  const sidebarState = useSelector(getSidebar);

  const changeSidebarVisibility = () => {
    simpleDispatch(new ChangeSidebarVisibility());
  };

  const changeMobileSidebarVisibility = () => {
    simpleDispatch(new ChangeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    simpleDispatch(new ChangeThemeToDark());
  };

  const changeToLight = () => {
    simpleDispatch(new ChangeThemeToLight());
  };

  const layoutClass = classNames({
    layout: true,
    "layout--collapse": sidebarState.collapse,
  });

  return (
    <div className={layoutClass}>
      <Topbar
        sidebar={sidebarState}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Sidebar
        sidebar={sidebarState}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />

      <ContentModalSmart />
    </div>
  );
};
