import {
  ISendFileMessage,
  ISendFilesMessages,
} from "@/api/chats-messages/request.interfaces";
import { chatMessagesService } from "@/services/domain";
import { checkFileSize, IconComponent, notification } from "@/shared";
import { appEvents } from "@/shared/events";
import { getFilesConfig } from "@/store/shared";
import React, { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CreateWebcamMediaModal } from "../components";
import { IChatMessage } from "../plugins";

import "./style.scss";

interface IProps {
  iconName: string;
  title: string;
  name: string;
  type: string;
  chatId: number;
  onPress: (name: string) => void;
  replyToMessage?: IChatMessage;
  onSend: () => void;
}

export const AttachmentsMenuItem: FC<IProps> = ({
  iconName,
  type,
  chatId,
  replyToMessage,
  onSend,
}) => {
  const [isCreateWebcamModal, setCreateWebcamModal] = useState<boolean>(false);
  const [mediaFile, setMediaFile] = useState<boolean>(false);
  const [sendFile, setSendFile] = useState<boolean>(false);
  const fileRef = useRef(null);
  const { chatFilesSize, chatVideosSize } = useSelector(getFilesConfig);

  const alertExceeded = (isVideo: boolean) => {
    notification.showError(
      "Помилка",
      `Не можна завантажувати ${isVideo ? "відео" : "файл"} розміром більше ${
        isVideo ? chatVideosSize : chatFilesSize
      }МB`
    );
  };

  const alertEmpty = () => {
    notification.showError("Помилка", `Файл не може бути пустиим`);
  };

  const sendMediaMessage = async (item: File) => {
    try {
      if (!item) return;

      if (item.size === 0) return alertEmpty();

      const { exceeded } = checkFileSize(
        item,
        chatFilesSize * 1000,
        chatVideosSize * 1000
      );

      if (exceeded) alertExceeded(item.type.includes("video"));
      else {
        onSend();
        await chatMessagesService.sendMediaMessage(
          [item].map((it) => {
            const dataToSend: ISendFileMessage = {
              file: it,
              chatId: chatId,
              fileName: it.name,
            };
            if (replyToMessage) dataToSend.replyToId = replyToMessage.id;
            return dataToSend;
          })
        );
      }
    } finally {
      setMediaFile(false);
    }
  };

  const sendFileMessage = async (file: File) => {
    try {
      if (!file) return;
      if (file.size === 0) return alertEmpty();

      if (file.size > chatFilesSize * 1000) alertExceeded(false);
      else {
        onSend();
        const dataToSend: ISendFilesMessages = {
          chatId,
          files: [file],
        };
        if (replyToMessage) dataToSend.replyToId = replyToMessage.id;

        await chatMessagesService.sendFileMessage(dataToSend);
      }
    } finally {
    }
  };

  const onFileChange = async (e) => {
    const files = e.target.files[0];
    mediaFile ? await sendMediaMessage(files) : null;
    sendFile ? await sendFileMessage(files) : null;
  };

  const sendSticker = async (sticker) => {
    await chatMessagesService.sendStickerMessage({
      chatId,
      sticker,
    });
  };

  const handlePressSendSticker = () => {
    appEvents.emit("selectSticker", {
      onSelect: sendSticker,
    });
  };

  const onBtnClick = () => {
    console.log("click", type);
    switch (type) {
      case "webcam":
        setCreateWebcamModal(true);
        break;
      case "image/*, video/*":
        setMediaFile(true);
        fileRef.current.click();
        break;
      case "file":
        setSendFile(true);
        fileRef.current.click();
        break;

      case "sticker":
        handlePressSendSticker();
        break;

      default:
        console.log("Unknown operation");
    }
  };

  return (
    <div className="attachments-item-container">
      <CreateWebcamMediaModal
        isShow={isCreateWebcamModal}
        setOpen={setCreateWebcamModal}
        chatId={chatId}
        replyToMessage={replyToMessage}
        onSend={onSend}
      />

      <button
        type="button"
        className="attachments-icon-button"
        onClick={onBtnClick}
      >
        <IconComponent name={iconName} />
        <input
          type="file"
          ref={fileRef}
          accept={type}
          onChange={(e) => onFileChange(e)}
          className="attachments-input"
          style={{
            visibility: "hidden",
            position: "absolute",
          }}
        />
      </button>
    </div>
  );
};
