import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { Dropdown, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import _ from "lodash";
import {
  CheckBoxForm,
  IconComponent,
  MessageType,
  useEventsListener,
} from "@/shared";
import ReactPlayer from "react-player/lazy";
import { IChatMessage } from "./interfaces";
import check_1 from "@/assets/img/check_1.svg";
import checks_1 from "@/assets/img/checks_1.svg";
import moment from "moment";
import { Avatar, ForwardMessageButton } from "../atoms";
import { ForwardedMessageHeader } from "./forwarded-message-header.component";
import { RepliedMessageInfo } from "./replied-message-info.component";
import { useChatSelectedMessagesState, useChatViewModeState } from "../hooks";
import { ChatViewModeEnum } from "../enums";

interface ChatItemVideoProps extends IChatMessage {
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onForwardedAuthorPress?: (id: number) => void;
  onRepliedPress?: (id: number) => void;

  onForwardPressMessage?: () => void;
}

export const ChatItemVideo: FC<ChatItemVideoProps> = (props) => {
  // const player = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [items, setItems] = useState([]);

  const viewMode = useChatViewModeState((s) => s.mode);
  const selectedMessages = useChatSelectedMessagesState((s) => s.messages);
  const { selectMessage } = useChatSelectedMessagesState();

  useEventsListener(
    "openMessageMenuOptions",
    (payload) => {
      setItems(payload.items);
    },
    [setItems]
  );

  useMemo(() => {
    if (viewMode === ChatViewModeEnum.SELECT) setIsPlaying(false);
  }, [viewMode]);

  const repliedMessage =
    props.content?.replyToMessage?.type === MessageType.Forwarded
      ? props.content?.replyToMessage?.content?.originalMessage
      : props.content?.replyToMessage;

  const menu = useMemo(() => {
    const menuItems = items.map((item) => ({
      ..._.pick(item, ["key", "label", "onClick"]),
      icon: item.iconNode ? item.iconNode : null,
    }));
    return (
      <Menu
        className="context-menu-chat-item"
        items={menuItems as ItemType[]}
      />
    );
  }, [items]);

  const select = () => selectMessage(props);

  const handleClickMessageItem = useCallback(() => {
    if (viewMode === ChatViewModeEnum.SELECT) select();
  }, [viewMode]);

  return (
    <div
      className={
        "chat-item-video-container " +
        (props.isMy && viewMode === ChatViewModeEnum.DEFAULT
          ? "my-video-container"
          : "") +
        (props.isMy && viewMode === ChatViewModeEnum.SELECT
          ? "mySelectableContainer"
          : "")
      }
      onClick={handleClickMessageItem}
    >
      {!props.isMy && viewMode === ChatViewModeEnum.DEFAULT ? (
        <div className="chat-avatar-container">
          <Avatar
            imageUrl={props.author.avatar}
            onClick={props.onProfilePress}
            maxHeight={35}
            maxWidth={35}
          />
        </div>
      ) : null}

      {viewMode === ChatViewModeEnum.SELECT ? (
        <CheckBoxForm
          className="message-checkbox"
          label=""
          onChange={select}
          checkBoxProps={{
            checked: Boolean(
              _.find(selectedMessages, (it) => it.id === props.id)
            ),
            onClick: select,
          }}
        />
      ) : null}

      <Dropdown
        onVisibleChange={props.onMenuPress}
        disabled={viewMode === ChatViewModeEnum.SELECT}
        overlay={menu}
        trigger={[`contextMenu`]}
      >
        <div className={props.forwardedFrom ? "with-border" : ""}>
          {props.forwardedFrom && (
            <ForwardedMessageHeader
              title={props.author?.name}
              onPress={() => props.onForwardedAuthorPress(props.author?.id)}
            />
          )}
          <div
            className={
              "player-wrapper " + (props.isMy ? "right-video" : "left-video")
            }
          >
            <RepliedMessageInfo
              repliedMessage={repliedMessage}
              onPress={() => {
                if (!repliedMessage?.isDeleted)
                  props.onRepliedPress(repliedMessage?.id);
              }}
            />
            {!props.isMy ? (
              <div className="video-authorContainer">
                <div className="name">
                  <span>
                    {props.forwardedFrom
                      ? `${props.forwardedFrom?.name}`
                      : `${props.author?.name}`}
                  </span>
                </div>
              </div>
            ) : null}

            <ReactPlayer
              url={props.content?.fileUrl}
              className="react-player"
              controls={viewMode === ChatViewModeEnum.DEFAULT}
              playing={isPlaying}
              onPlay={() => setIsPlaying(true)}
              width="100%"
              height="100%"
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              onContextMenu={(e) => e.preventDefault()}
            />

            <div className="video-footerRow">
              <span>{moment(props.createdAt).format("HH:mm")}</span>
              <IconComponent
                name={props.read ? checks_1 : check_1}
                className={`tick${
                  props.isMy && props.read ? " ticksMy" : " ticks"
                }`}
              />
            </div>
          </div>
        </div>
      </Dropdown>
      {!props.isMy && viewMode === ChatViewModeEnum.DEFAULT && (
        <>
          <ForwardMessageButton onPress={props.onForwardPressMessage} />
        </>
      )}
    </div>
  );
};
