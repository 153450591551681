import React from "react";
import { Checkbox } from "antd";
import { CheckboxProps } from "antd/lib/checkbox/Checkbox";

interface IProps {
  label: string;
  onChange?: (v: any) => any;
  field?: any;
  className?: string;
  checkBoxProps?: CheckboxProps;
}

export const CheckBoxForm = (props: IProps) => {
  return (
    <Checkbox
      className={props.className}
      onChange={(v) => props.onChange(v.target.checked)}
      {...props.checkBoxProps}
      {...props?.field}
    >
      {props.label}
    </Checkbox>
  );
};
