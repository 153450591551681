import React, { FC } from "react";
interface IProps {
  selectChat: (e: any) => void;
}
export const SelectChatButton: FC<IProps> = ({ selectChat }) => {
  return (
    <div className="select-chat-btn">
      <button onClick={selectChat}>
        <p>Оберіть чат, щоб почати спілкування</p>
      </button>
    </div>
  );
};
