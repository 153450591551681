import React, { useState } from "react";
import { Collapse } from "reactstrap";
import classNames from "classnames";
import SlidersHorizontalSVG from "@/assets/img/sliders-horizontal.svg";

interface IProps {
  title: string;
  icon?: string;
  iconCustom?: string;
  isNew?: boolean;
  children: any;
  isOpen?: boolean;
  classNameCollapse?: string;
}

const icons = {
  sliders: <img style={{ width: 25 }} src={SlidersHorizontalSVG} />,
  book: <span className={"sidebar__link-icon lnr lnr-book"} />,
};

export const SidebarCategory = (props: IProps) => {
  const [collapse, setCollapse] = useState<boolean>(props.isOpen || false);

  const categoryClass = classNames({
    "sidebar__category-wrap": true,
    "sidebar__category-wrap--open": collapse,
  });

  return (
    <div className={categoryClass}>
      <button
        type="button"
        className="sidebar__link sidebar__category"
        onClick={() => setCollapse((prevState) => !prevState)}
      >
        {props.icon ? icons[props.icon] : ""}
        {props.iconCustom ? (
          <div className="icon colored">
            <img style={{ width: 26 }} src={props.iconCustom} />
          </div>
        ) : (
          ""
        )}
        <p className="sidebar__link-title">
          {props.title}
          {props.isNew && <span className="sidebar__category-new" />}
        </p>
        <span className="sidebar__category-icon lnr lnr-chevron-right" />
      </button>
      <Collapse
        isOpen={collapse}
        className={classNames("sidebar__submenu-wrap", props.classNameCollapse)}
      >
        <ul className="sidebar__submenu">
          <div>{props.children}</div>
        </ul>
      </Collapse>
    </div>
  );
};
