import React from "react";

interface IProps {
  name: string;
  className?: any;
  onClick?: () => void;
}

export const IconComponent = ({ onClick, ...props }: IProps) => {
  if (onClick) {
    return (
      <div onClick={onClick}>
        <img src={props.name} className={props.className} />
      </div>
    );
  } else {
    return <img src={props.name} className={props.className} />;
  }
};
