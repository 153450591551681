import { ipsApi, logsApi, usersApi } from "@/api";
import { EUserStatus, notification, usePaginationList } from "@/shared";
import { ILog } from "@/shared/interfaces/log.interface";
import _ from "lodash";
import { useState } from "react";

interface IModalState {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
}

const defaultModalState = {
  isOpen: false,
  message: "",
  onConfirm: () => {},
};

export const useLogs = () => {
  const [selectedLogs, setSelectedLogs] = useState<Partial<ILog>[]>([]);
  const [confirmModal, setConfirmModal] = useState<IModalState>(
    defaultModalState
  );

  const paginationList = usePaginationList<ILog>({
    fetchItems: logsApi.fetchLogsList,
    loadParams: {
      limit: 10,
      page: 1,
      sort: "DESC",
      sortField: "createdAt",
    },
  });

  const openConfirmModal = (message: string, onConfirm: () => void) => {
    setConfirmModal({ isOpen: true, message, onConfirm });
  };

  const afterAction = () => {
    setConfirmModal(defaultModalState);
    setSelectedLogs([]);
  };

  const deleteLogs = async () => {
    try {
      await logsApi.deleteLogs(selectedLogs.map((it) => it.id));

      paginationList._setItems(
        _.filter(
          paginationList.items,
          (it) => !_.some(selectedLogs, (log) => log.id === it.id)
        )
      );
    } catch (e) {
      notification.showError("Помилка", "Не вдалось видалити події");
    } finally {
      afterAction();
    }
  };

  const changeUsersStatus = async (status: EUserStatus) => {
    try {
      await Promise.all(
        selectedLogs.map(async (it) => {
          if (it.userId) await usersApi.changeStatus({ id: it.userId, status });
        })
      );

      paginationList.resetFlatList();
    } catch (e) {
      notification.showError(
        "Помилка",
        `Не вдалось ${
          status === EUserStatus.Blocked ? "блокувати" : "розблокувати"
        } користувачів`
      );
    } finally {
      afterAction();
    }
  };

  const blockIPs = async () => {
    try {
      const ips = selectedLogs.filter((it) => !!it.ip).map((it) => it.ip);
      if (_.isEmpty(ips)) return;
      await ipsApi.blockIps(ips);

      paginationList.resetFlatList();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось блокувати IP");
    } finally {
      afterAction();
    }
  };

  const unblockIPs = async () => {
    try {
      const ips = selectedLogs.filter((it) => !!it.ip).map((it) => it.ip);
      await ipsApi.deleteIps(ips);

      paginationList.resetFlatList();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось розблокувати IP");
    } finally {
      afterAction();
    }
  };

  const onPressDelete = () => {
    openConfirmModal("Ви справді хочете видалити події?", () => deleteLogs());
  };

  const onPressBlockUsers = () => {
    openConfirmModal("Ви справді хочете заблокувати користувачів?", () =>
      changeUsersStatus(EUserStatus.Blocked)
    );
  };

  const onPressUnblockUsers = () => {
    openConfirmModal("Ви справді хочете розблокувати користувачів?", () =>
      changeUsersStatus(EUserStatus.Active)
    );
  };

  const onPressBlockIPs = () => {
    openConfirmModal("Ви справді хочете заблокувати IP?", () => blockIPs());
  };

  const onPressUnblockIPs = () => {
    openConfirmModal("Ви справді хочете розблокувати IP?", () => unblockIPs());
  };

  return {
    confirmModal: {
      state: confirmModal,
      set: setConfirmModal,
      open: openConfirmModal,
    },
    paginationList,
    selectedLogs,
    setSelectedLogs,
    actions: {
      delete: onPressDelete,
      blockUsers: onPressBlockUsers,
      unblockUsers: onPressUnblockUsers,
      blockIps: onPressBlockIPs,
      unblockIPs: onPressUnblockIPs,
    },
    allIds: paginationList.data?.map((it) => it.id),
  };
};
