import { InputMaskField } from "@/shared";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { LoginIcon } from "../atoms";

interface IProps {
  onChange?: any;
  onBlur?: any;
  value?: string;
  error?: string;
  register: UseFormRegisterReturn;
  disabled?: boolean;
}

export const PhoneField = (props: IProps) => {
  return (
    <div className="form__form-group-auth">
      <span className="form__form-group-label">Номер телефону</span>
      <div className="form__form-group-field-signIn">
        <div className="form__form-group-icon">
          <LoginIcon />
        </div>
        <InputMaskField
          required={props.value?.length > 0 ? true : false}
          value={props.value}
          placeholder={"+38 (0xx) xxx xx xx"}
          mask="+38 (999) 999 99 99"
          style={{ width: "auto" }}
          onChange={(text) => {
            props.onChange(text.replace(/[^+\d]/g, ""));
          }}
        />
      </div>
      {props?.error && (
        <span className="form__form-group-error">{props.error}</span>
      )}
    </div>
  );
};
