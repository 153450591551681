import { ChatBgKeys } from "@/shared";
import { Action } from "redux";

export class SetChatBg implements Action {
  readonly type = "SET_CHAT_BG";
  constructor(
    public readonly payload: {
      bgId: ChatBgKeys;
    }
  ) {}
}

export type TChatBgActions = SetChatBg;
