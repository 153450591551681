export enum StorageKey {
  Login = "Login",
  AccessToken = "AccessToken",
  RefreshToken = "RefreshToken",
  RememberMe = "RememberMe",
  ExpiryDate = "ExpiryDate",
  Path = "Path",
  PhoneNumber = "PhoneNumber",

  ChatBgId = "ChatBgId",
  CustomChatBg = "CustomChatBg",
}
