import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface IProps {
  onChange?: any;
  onBlur?: any;
  value?: string;
  error?: string;
  register: UseFormRegisterReturn;
}

export const CodeField = (props: IProps) => {
  return (
    <div className="form__form-group-auth">
      <span className="form__form-group-label">Код підтвердження</span>
      <div className="form__form-group-field-code">
        <input
          name="code"
          placeholder="Код підтвердження"
          type="text"
          onChange={props.onChange}
          onBlur={props.onBlur}
          {...props.register}
        />
      </div>
      {props?.error && (
        <span className="form__form-group-error">{props.error}</span>
      )}
    </div>
  );
};
