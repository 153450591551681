import React, { FC, CSSProperties } from "react";
import "./styles.scss";
import { AsYouType } from "libphonenumber-js";
import copySvg from "@/assets/img/copy-icon-1.svg";
import { copyText } from "@/shared/helpers/copy.helpers";

interface IProps {
  label: string;
  value: string | number;
  placeholder: string;
  style?: CSSProperties;
  required?: boolean;
  error?: string;
  svgIcon: string;
  phone?: boolean;
  onChange?: (val: string) => void;
  onClick?: () => void;
}

export const ContactField: FC<IProps> = ({
  label,
  value,
  placeholder,
  style,
  svgIcon,
  phone,
  error,
  onClick,
}) => {
  const formattedPhone = value ? new AsYouType().input(value?.toString()) : "";

  const onCopy = (event) => {
    if (!value) return;

    copyText(value, event);
  };

  return (
    <div className="contact-field" style={style} onClick={onClick}>
      <p className="contact-label">{label}</p>
      <div className="value-container">
        {!phone ? (
          <span title={String(value)}>{value || placeholder}</span>
        ) : (
          <span title={String(formattedPhone)}>
            {formattedPhone || placeholder}
          </span>
        )}
        <div className="images">
          <img src={svgIcon} />

          <img
            className="contact-copy"
            src={copySvg}
            alt="copy"
            onClick={onCopy}
            style={{ opacity: value ? 1 : 0.5 }}
          />
        </div>
      </div>

      {error && <span className="error">{error}</span>}
    </div>
  );
};
