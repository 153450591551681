import React, { FC } from "react";
import { Row, Col } from "reactstrap";
import {
  ISelectedRowsMenuItemConfig,
  SelectedDropdownMenu,
  SelectedRowsMenu,
} from "@/shared";
import _ from "lodash";

interface IProps {
  selectedRows: number[];
  selectedItemsMenuConfig: ISelectedRowsMenuItemConfig[];
  configDropdownSelected: any;
}

export const LogsSelectedTableHeader: FC<IProps> = ({
  selectedRows,
  selectedItemsMenuConfig,
  configDropdownSelected,
}) => {
  return (
    <Row>
      <Col md={12}>
        <div>
          {!_.isEmpty(selectedRows) ? (
            <div style={{ display: "flex" }}>
              {!_.isEmpty(selectedItemsMenuConfig) && (
                <SelectedDropdownMenu
                  configSelected={configDropdownSelected}
                  selected={selectedRows.length}
                />
              )}

              <SelectedRowsMenu
                blockClassName="selected-logs-menu"
                itemsConfig={selectedItemsMenuConfig}
              />
            </div>
          ) : (
            <div style={{ height: 30 }}></div>
          )}
        </div>
      </Col>
    </Row>
  );
};
