import React, { FC } from "react";
import { InputField, IShortUser, SelectAvatar } from "@/shared";
import { UserSearchWithBtn } from "@/containers/User/components";
import defaultIcon from "@/assets/img/camera-icon-custom.svg";

interface IProps {
  title: string;
  placeholder: string;
  imageUrl?: string;
  onChangeImg: (image: unknown) => void;
  onChangeTitle: (value: string) => void;
  disabled?: boolean;
  nameError?: string;

  items: IShortUser[];
  searchStr: string;
  onChangeContact: (text) => void;
  selectAll: (users: IShortUser[]) => void;
  previewImg?: any;
}

export const CreateChatHeaderComponent: FC<IProps> = ({
  title,
  placeholder,
  onChangeImg,
  onChangeTitle,
  nameError,

  items,
  searchStr,
  onChangeContact,
  selectAll,
}) => {
  return (
    <div className="chat-modal-header">
      <SelectAvatar
        image={null}
        title="Зміна аватару чату"
        defaultImg={defaultIcon}
        onChange={onChangeImg}
        className="chat__avatar"
      />

      <div className="set-header-info">
        <InputField
          placeholder={placeholder}
          label={""}
          onChange={onChangeTitle}
          value={title}
          error={nameError}
        />

        <UserSearchWithBtn
          items={items}
          searchStr={searchStr}
          onChangeContact={onChangeContact}
          selectAll={selectAll}
        />
      </div>
    </div>
  );
};
