import { ipsApi } from "@/api";
import { IAddIp, IUpdateIpType } from '@/api/ips/requests.interfaces';
import { IAddIpRes } from '@/api/ips/responses.interfaces';

const addIp = async (payload: IAddIp): Promise<IAddIpRes> => {
  try {
    const { data } = await ipsApi.addIp(payload);
    return data
  } catch (err) {
    console.log("CREATE ID ERROR: ", err);
  } finally {
  }
};

const updateIpType = async (payload: IUpdateIpType): Promise<void> => {
  try {
    await ipsApi.updateIpType(payload);

  } catch (err) {
    console.log("UPDATE IP TYPE ERROR: ", err);
  } finally {
  }
};

const deleteIp = async (ids: number[]): Promise<void> => {
  try {
    await ipsApi.deleteIp(ids);
  } catch (err) {
    console.log("DELETE IP ERROR: ", err);
  } finally {
  }
};

export const ipsService = {
  addIp,
  updateIpType,
  deleteIp
};
