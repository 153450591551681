import React, { useState } from "react";
import EyeIcon from "mdi-react/EyeIcon";
import { UseFormRegisterReturn } from "react-hook-form";

interface IProps {
  register: UseFormRegisterReturn | any;
  type?: "password" | "text";
  label: string;
  placeholder?: string;
  error?: string;
  warning?: string;
}

export const InputField = ({ type = "text", ...props }: IProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(true);

  if (type === "password") {
    return (
      <div className="form__form-group">
        {props.label && (
          <span className="form__form-group-label">{props.label}</span>
        )}
        <div className="form__form-group-field">
          <input
            {...props.register}
            placeholder={props.placeholder}
            type={showPassword ? "text" : "password"} // style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
            maxLength={253}
          />
          <button
            className={`form__form-group-button${
              showPassword ? " active" : ""
            }`}
            onClick={() => setShowPassword((prevState) => !prevState)}
            type="button"
            style={{
              border: "1px solid rgba(162,162,162,.65)",
              borderLeft: "none",
              borderBottomRightRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <EyeIcon />
          </button>
        </div>
        {props.error && (
          <span className="form__form-group-error">{props.error}</span>
        )}
        {props.warning && (
          <span className="form__form-group-warning">{props.warning}</span>
        )}
      </div>
    );
  }

  return (
    <div className="form__form-group">
      {props.label ? (
        <span className="form__form-group-label">{props.label}</span>
      ) : null}
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <input
            {...props.register}
            placeholder={props.placeholder}
            type={type}
            maxLength={253}
          />
          {props.error && (
            <span className="form__form-group-error">{props.error}</span>
          )}
          {props.warning && (
            <span className="form__form-group-warning">{props.warning}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputField;
