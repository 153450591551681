import {
  checkPermission,
  IFactory,
  Loader,
  Permissions,
  SearchInput,
} from "@/shared";
import { getProfile } from "@/store/account";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Container, Card, CardBody, Button } from "reactstrap";
import { ModalForm } from "./components/ModalForm";
import { TreeFactory } from "./components/TreeFactory";
import { useFactory } from "./hooks";
import Modal from "../../components/Modal";
import "./style.scss";
import { getMyPermissions } from "@/store/permissions";

export const Factory: FC = () => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [selectedFactory, setFactory] = useState<IFactory>();
  const [searchStr, setSearchStr] = useState<string>("");

  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const {
    isLoading,
    factories,
    createFactory,
    updateFactory,
    deleteFactory,
  } = useFactory();

  return (
    <Container className="factory">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Modal
                show={isOpenModal}
                toggle={setOpenModal}
                title="Підприємство"
              >
                <ModalForm
                  isOpen={isOpenModal}
                  selectedFactory={selectedFactory}
                  factories={factories}
                  closeModal={() => setOpenModal(false)}
                  createFactory={createFactory}
                  updateFactory={updateFactory}
                  editable={
                    selectedFactory
                      ? checkPermission(
                          "tabs",
                          "factory",
                          profile,
                          permissions,
                          Permissions.UPDATE
                        )
                      : true
                  }
                />
              </Modal>

              <Row>
                <div
                  className="col-md-12 justify-content-end flex"
                  style={{ marginBottom: 10 }}
                >
                  <SearchInput
                    value={searchStr}
                    placeholder="Пошук"
                    style={{ width: 250, marginRight: 20, minHeight: 46 }}
                    onChange={setSearchStr}
                  />

                  {checkPermission(
                    "tabs",
                    "factory",
                    profile,
                    permissions,
                    Permissions.CREATE
                  ) && (
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        setOpenModal(true);
                        setFactory(null);
                      }}
                    >
                      Створити
                    </Button>
                  )}
                </div>
              </Row>

              <Row>
                {isLoading ? (
                  <Loader />
                ) : (
                  <TreeFactory
                    searchVal={searchStr}
                    factories={factories}
                    profile={profile}
                    openModal={() => setOpenModal(true)}
                    setSelectedFactory={setFactory}
                    deleteFactory={deleteFactory}
                  />
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
