import React from "react";
interface IProps {
  generatePassword: (e: any) => void;
}
const styles: any = {
  btn: {
    border: "none",
    borderRadius: 10,
    backgroundColor: "rgba(158, 39, 67, 0.1)",

    padding: 8,
    position: "absolute",
    bottom: -15,
  },
  title: {
    fontSize: 12,
    color: "rgba(158, 39, 67, 1)",
  },
};
export const GeneratePassword = (props: IProps) => {
  return (
    <button onClick={props.generatePassword} style={styles.btn}>
      <p style={styles.title}>Згенерувати пароль</p>
    </button>
  );
};
