import React, { useEffect, useState } from "react";
import { Table } from "@/components/TableGrid/Table";
import { SearchInput, usePaginationList } from "@/shared";
import { contactsApi } from "@/api";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Switch } from "antd";
import { columnsConfig, defaultActiveColumnsConfig } from "./configs";
import { useSelector } from "react-redux";
import { ClearContactsFilter, getContactsFilter } from "@/store/users";
import { simpleDispatch } from "@/store/store-helpers";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { IGetContactsParams } from "@/api/contacts/requests.interfaces";

export const ContactsUsers = () => {
  const [searchString, setSearchString] = useState<string>("");
  const [isSoonBirthday, setOnlyWithSoonBirthday] = useState<boolean>(false);
  const [focusFilterKey, setFocusFilterKey] = useState<string>("");
  const filter = useSelector(getContactsFilter);
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      if ((history.location.state as any)?.soonBirthday) {
        setOnlyWithSoonBirthday(true);
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [history.location]);

  useEffect(() => {
    simpleDispatch(new ClearContactsFilter());
  }, []);

  const paginationList = usePaginationList<any>({
    fetchItems: contactsApi.fetchContactsList,
    loadParams: {
      limit: 10,
      page: 1,
      sortField: "lastName",
      sort: "ASC",
    },
  });

  useEffect(() => {
    const loadParams: Partial<IGetContactsParams> = {
      factoryId: filter.factoryId,
    };

    if (filter?.fromDateOfBirth || filter?.toDateOfBirth) {
      loadParams.fromDateOfBirth = filter?.fromDateOfBirth
        ? moment(filter?.fromDateOfBirth).format("MM-DD-YYYY")
        : moment()
            .startOf("year")
            .format("MM-DD-YYYY");

      loadParams.toDateOfBirth = filter?.toDateOfBirth
        ? moment(filter?.toDateOfBirth).format("MM-DD-YYYY")
        : moment()
            .endOf("year")
            .format("MM-DD-YYYY");
    } else {
      loadParams.fromDateOfBirth = null;
      loadParams.toDateOfBirth = null;
    }
    paginationList.setLoadParams(loadParams);
  }, [filter]);

  useEffect(() => {
    paginationList.setLoadParams({
      globalSearchString: searchString,
      soonBirthday: isSoonBirthday,
    });
  }, [searchString, isSoonBirthday]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: 50,
                }}
              >
                <Row style={{ alignItems: "center", marginLeft: 20 }}>
                  <Switch
                    style={{
                      marginRight: 10,
                      backgroundColor: !isSoonBirthday
                        ? " #7F7F7F "
                        : "rgba(158, 39, 67, 1)",
                    }}
                    checked={isSoonBirthday}
                    onChange={() => setOnlyWithSoonBirthday(!isSoonBirthday)}
                  />

                  <div>Показати сьогоднішні Д.Н.</div>
                </Row>

                <SearchInput
                  value={searchString}
                  onChange={setSearchString}
                  placeholder="Пошук"
                  style={{ width: 235, height: 46 }}
                  isFocused={focusFilterKey === "globalSearch"}
                  onFocus={() => setFocusFilterKey("globalSearch")}
                />
              </Row>

              <Table
                tableName={"contacts"}
                columns={columnsConfig()}
                paginationList={paginationList}
                activeColumns={defaultActiveColumnsConfig}
                // onRowClick={(idx, { userId, firstName, lastName }) => {
                //   console.log(
                //     idx,
                //     { userId: userId },
                //     { firstName: firstName },
                //     { lastName: lastName }
                //   );
                // }}
                focusedFilterField={focusFilterKey}
                onFocusFilterField={(fieldKey: string) =>
                  setFocusFilterKey(fieldKey)
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
