import { Events } from "jet-tools";
import { useEffect } from "react";

type SecretEvents = {
  addUsers: {};
  removeUsers: {};
};

export const secretEvents = new Events<SecretEvents>();

export const useSecretEventsListener = <T extends keyof SecretEvents>(
  name: T,
  action: (data: SecretEvents[T]) => void,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const fn = (data: SecretEvents[T]) => action(data);
    secretEvents.on(name, fn);

    return () => secretEvents.off(name, fn);
  }, dependencies);
};
