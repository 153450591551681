/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { IColumn } from "@/shared";
import { Button, Tooltip } from "antd";
import { IPaginationParams } from "../interfaces";
import { ColumnsView } from "./columns-view.component";
import FloppyDiskSVGIcon from "../../../assets/img/floppy-disk.svg";
import FilterSVGIcon from "../../../assets/img/filter.svg";
import { FilterNumberRow } from "../atoms/filterNumberRow";

interface IProps {
  onSaveTableSettings: () => void;
  paginationParams: IPaginationParams;
  showFilter: boolean;
  onSetLoadParams: (params: any) => void;
  setShowFilter: (val: boolean) => void;
  onSetPaginationParams: (params: any) => void;
  columns: IColumn[];
  columnsSort: string[];
  activeColumns: string[];
  onChangeColumns: (val: any) => void;
  onSaveColumnsSort: (val: any) => void;
  tableName: string;
}

export const ActionBottomBar = (props: IProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const toggle = () => {
    setIsShow((prevState) => !prevState);
  };
  return (
    <div style={{ flexDirection: "row", flexWrap: "nowrap", display: "flex" }}>
      <FilterNumberRow
        onSetPaginationParams={props.onSetPaginationParams}
        isShow={isShow}
        toggle={toggle}
        numberRow={props.paginationParams.limit}
      />

      <ColumnsView
        onChangeColumns={props.onChangeColumns}
        activeColumns={props.activeColumns}
        columns={props.columns}
        columnsSort={props.columnsSort}
        onSaveColumnsSort={props.onSaveColumnsSort}
        tableName={props.tableName}
      />
      <Tooltip
        title={"Зберегти налаштування вигляду"}
        key={"save-view-settings"}
        mouseEnterDelay={0.5}
        placement="bottom"
      >
        <Button type="text" onClick={props.onSaveTableSettings}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={FloppyDiskSVGIcon} style={{ width: 30 }} />
          </div>
        </Button>
      </Tooltip>

      <Tooltip
        title={"Сховати/показати фільтр"}
        key={"show/hide-filter"}
        mouseEnterDelay={0.5}
        placement="bottom"
      >
        <Button
          type="text"
          onClick={() => props.setShowFilter(!props.showFilter)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={FilterSVGIcon} style={{ width: 30 }} />
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};
