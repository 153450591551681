import {
  BYTES_IN_KILOBYTE,
  convertToKilobytes,
  convertToMegabytes,
  getIconNameByExtension,
  IconComponent,
} from "@/shared";

import React, { FC, useMemo, useState } from "react";
import { ChatItem } from "./chat-item.component";
import { IChatMessage } from "./interfaces";

import { ShowDocModal } from "../components";
import { useChatViewModeState } from "../hooks";
import { ChatViewModeEnum } from "../enums";

interface ChatItemFileProps extends IChatMessage {
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onForwardedAuthorPress?: (id: number) => void;
  onRepliedPress?: (id: number) => void;

  onForwardPressMessage?: () => void;
}

export const ChatItemFile: FC<ChatItemFileProps> = (props) => {
  const viewMode = useChatViewModeState((s) => s.mode);

  const [isOpenDocModal, setOpenDocModal] = useState<boolean>(false);
  const iconName = getIconNameByExtension(props?.content?.fileUrl);

  const sizePart = useMemo(() => {
    if (!props.content?.size) return "";

    if (props.content.size < 500 * BYTES_IN_KILOBYTE)
      return `${convertToKilobytes(props.content.size)} КБ - `;

    return `${convertToMegabytes(props.content.size)} MБ - `;
  }, [props.content.size]);

  const fileType = props?.content?.fileUrl?.split(".").pop();

  return (
    <>
      <ShowDocModal
        isShow={isOpenDocModal}
        setOpen={setOpenDocModal}
        docName={props?.content?.name}
        docType={fileType}
        docSrc={props?.content?.fileUrl}
      />
      <ChatItem {...props}>
        <div
          className="chat-item-file-container"
          onClick={() => {
            if (viewMode === ChatViewModeEnum.DEFAULT) setOpenDocModal(true);
          }}
        >
          <div className="file-icon-container">
            <IconComponent className="file-item-icon" name={iconName} />
          </div>
          <div className="file-item-text-block">
            <p>{props.content?.name || `Без назви`}</p>
            <p>{sizePart + `завантажений файл`}</p>
          </div>
          <div className="file-item_icons-block"></div>
        </div>
      </ChatItem>
    </>
  );
};
