import React, { FC } from "react";
import { ButtonToolbar, Button } from "reactstrap";

import { UsersSelectList } from "@/containers/User/components";
import { simpleDispatch } from "@/store/store-helpers";
import {
  SelectAll,
  selectSelectedUsers,
  SelectUser,
  UnselectUser,
} from "@/store/users";
import { useSelector } from "react-redux";
import { useFetchChatUsers } from "@/containers/User/hooks";
import { CreateChatHeaderComponent } from "./create-chat-header.component";

interface IProps {
  name: string;
  setName: (name: string) => void;
  setImg: React.Dispatch<unknown>;
  onSubmit: () => Promise<void>;
  nameError: string;
  membersError: string;
  excludeIds?: number[];
  previewImg?: any;
}

export const CreateChatForm: FC<IProps> = ({
  name,
  setName,
  setImg,
  onSubmit,
  nameError,
  membersError,
  excludeIds,
}) => {
  const selectedUsers = useSelector(selectSelectedUsers);

  const {
    items,
    searchString,
    setSearchVal,
    loadMore,
    loadParams,
  } = useFetchChatUsers();

  return (
    <div className="chat-modal-container">
      <CreateChatHeaderComponent
        title={name}
        placeholder={"Назва бесіди"}
        onChangeImg={setImg}
        onChangeTitle={setName}
        items={items}
        searchStr={searchString}
        onChangeContact={setSearchVal}
        selectAll={(items) => simpleDispatch(new SelectAll({ items }))}
        nameError={nameError}
      />

      {membersError && <span className="error">{membersError}</span>}

      <UsersSelectList
        items={items}
        selectedUsers={selectedUsers}
        selectOne={(user) => simpleDispatch(new SelectUser({ user }))}
        unSelectOne={(userId) => simpleDispatch(new UnselectUser({ userId }))}
        excludeIds={excludeIds}
        loadMore={loadMore}
        count={loadParams.count}
      />

      <ButtonToolbar className="form__add-chat">
        <Button
          style={{ width: "110px", height: "40px" }}
          name={"allSelect"}
          color="primary"
          onClick={onSubmit}
          title={"Створити"}
        >
          Створити
        </Button>
      </ButtonToolbar>
    </div>
  );
};
