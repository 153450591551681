import React from "react";

import caretleftIconSvg from "@/assets/img/caretleft-1.svg";
// import { transform } from "lodash";

interface IProps {
  rotated?: string;
}

export const NextIndicator = ({ rotated }: IProps) => {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        rotate: rotated,
      }}
    >
      <img src={caretleftIconSvg} />
    </div>
  );
};
