import { taxonomiesApi } from "@/api";
import { ICreateOrUpdateTaxonomy } from "@/shared";
import { simpleDispatch } from "@/store/store-helpers";
import * as TaxonomiesActions from "@/store/taxonomies/types";
import * as TaxonomyReqPayloads from "@/api/taxonomies/requests.interfaces";

const fetchTaxonomies = async () => {
  try {
    simpleDispatch(new TaxonomiesActions.isLoading({ isLoading: true }));
    const { data } = await taxonomiesApi.fetchTaxonomies();

    simpleDispatch(new TaxonomiesActions.SetTaxonomies({ taxonomies: data }));
  } catch (error) {
    console.log("FETCH TAXONOMIES LIST ERROR", error);
  } finally {
    simpleDispatch(new TaxonomiesActions.isLoading({ isLoading: false }));
  }
};

const deleteTaxonomy = async (taxonomyId: number) => {
  try {
    await taxonomiesApi.deleteTaxonomy(taxonomyId);
    await taxonomiesService.fetchTaxonomies();
  } catch (error) {
    console.log("DELETE TAXONOMY ERROR", error);
    throw new Error("DELETE TAXONOMY ERROR");
  } finally {
  }
};

const createTaxonomy = async (
  taxonomy: TaxonomyReqPayloads.ICreateTaxonomyReq
) => {
  try {
    await taxonomiesApi.createTaxonomy(taxonomy);
    await taxonomiesService.fetchTaxonomies();
  } catch (error) {
    console.log("CREATE TAXONOMY ERROR", error);
  } finally {
  }
};

const updateTaxonomy = async (
  taxonomyId: number,
  taxonomy: TaxonomyReqPayloads.IUpdateTaxonomyReq
) => {
  try {
    await taxonomiesApi.updateTaxonomy(taxonomyId, taxonomy);
    await taxonomiesService.fetchTaxonomies();
  } catch (error) {
    console.log("UPDATE TAXONOMY ERROR", error);
  } finally {
  }
};

const deleteTaxonomyIcon = async (taxonomyId: number) => {
  try {
    await taxonomiesApi.deleteTaxonomyIcon(taxonomyId);
    simpleDispatch(new TaxonomiesActions.DeleteIcon());
    await taxonomiesService.fetchTaxonomies();
  } catch (error) {
    console.log("DELETE TAXONOMY ICON ERROR", error);
  }
};

const setTargetTaxonomy = (taxonomy: ICreateOrUpdateTaxonomy) => {
  try {
    simpleDispatch(
      new TaxonomiesActions.SetTargetTaxonomy({ taxonomy: taxonomy })
    );
  } catch (error) {
    console.log("SET TARGET TAXONOMY ERROR", error);
  }
};

export const taxonomiesService = {
  fetchTaxonomies,
  deleteTaxonomy,
  setTargetTaxonomy,
  createTaxonomy,
  updateTaxonomy,
  deleteTaxonomyIcon
};
