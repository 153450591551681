import React, { FC, useCallback, useMemo, useState } from "react";
import { Dropdown, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import _ from "lodash";
import {
  CheckBoxForm,
  IconComponent,
  IMessage,
  MessageType,
  useEventsListener,
} from "@/shared";
import { Avatar, ForwardMessageButton, PinnedIndicator } from "../atoms";
import moment from "moment";
import "./style.scss";
import check_1 from "@/assets/img/check_1.svg";
import checks_1 from "@/assets/img/checks_1.svg";
import { ForwardedMessageHeader } from "./forwarded-message-header.component";
import { RepliedMessageInfo } from "./replied-message-info.component";
import { useChatViewModeState } from "../hooks";
import { ChatViewModeEnum } from "../enums";
import { useChatSelectedMessagesState } from "../hooks/use-selected-messages.hook";

interface ChatItemProps extends IMessage {
  containerStyle?: string;
  paddingHorizontal?: number;
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onForwardedAuthorPress?: (id: number) => void;
  onRepliedPress?: (id: number) => void;
  children?: any;
  isMenuVisible?: boolean;
  setMenuVisible?: (visible: boolean) => void;
  onForwardPressMessage?: () => void;
  hideBackground?: boolean;
}

export const ChatItem: FC<ChatItemProps> = (props) => {
  const [items, setItems] = useState([]);

  const [visible, setVisible] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const [linkValue, setLinkValue] = useState<string>("");

  const viewMode = useChatViewModeState((s) => s.mode);
  const selectedMessages = useChatSelectedMessagesState((s) => s.messages);
  const { selectMessage } = useChatSelectedMessagesState();

  const isSelectable = useMemo(() => {
    if (viewMode === ChatViewModeEnum.DEFAULT) return false;
    return (
      props.type === MessageType.Text ||
      props.type === MessageType.Image ||
      props.type === MessageType.Video ||
      props.type === MessageType.Audio ||
      props.type === MessageType.File ||
      props.type === MessageType.Sticker
    );
  }, [viewMode, props.type]);

  const repliedMessage =
    props.content?.replyToMessage?.type === MessageType.Forwarded
      ? props.content?.replyToMessage?.content?.originalMessage
      : props.content?.replyToMessage;

  useEventsListener(
    "openMessageMenuOptions",
    (payload) => {
      setItems(payload.items);
    },
    [setItems]
  );

  const menu = useMemo(() => {
    if (_.isEmpty(items)) return <></>;
    const menuItems = items.map((item) => ({
      ..._.pick(item, ["key", "label", "onClick"]),
      icon: item.iconNode ? item.iconNode : null,
    }));

    return (
      <Menu
        className="context-menu-chat-item"
        items={menuItems as ItemType[]}
      />
    );
  }, [items]);

  const linkMenu = (link: string) => {
    const items = {
      open: {
        key: "open",
        label: "Відкрити посилання",
        onClick: () => {
          window.open(link, "_blank");
          setInnerVisible(false);
        },
      },
      copy: {
        key: "copy",
        label: "Копіювати посилання",
        onClick: () => {
          navigator.clipboard.writeText(link);
          setInnerVisible(false);
        },
      },
      cancel: {
        key: "cancel",
        label: "Скасувати",
        onClick: () => {
          setInnerVisible(false);
        },
      },
    };

    const optionsKeys = ["open", "copy", "cancel"];
    const options = optionsKeys.map((key) => items[key]);

    const menuItems = options.map((item) => ({
      ..._.pick(item, ["key", "label", "onClick"]),
      icon: item.iconNode ? item.iconNode : null,
    }));
    return (
      <Menu
        className="context-menu-chat-item"
        items={menuItems as ItemType[]}
      />
    );
  };

  const closeOtherMenu = () => {
    const event = new Event("click");
    document.dispatchEvent(event);
  };

  const onContextMenu = (event) => {
    if (isSelectable) return;
    closeOtherMenu();
    if (event.target.id === "link") {
      const linkValue = (event.target as Element).getAttribute("href");
      setLinkValue(linkValue);
      setVisible(false);
      setInnerVisible(true);
    } else {
      setLinkValue("");
      setInnerVisible(false);
      setVisible(true);
    }
  };

  document.addEventListener("click", () => {
    setVisible(false);
    setInnerVisible(false);
  });

  const select = () => selectMessage(props);

  const handleClickMessageItem = useCallback(() => {
    setVisible(false);
    if (isSelectable) select();
  }, [isSelectable]);

  return (
    <div
      className={
        "chat-item-container " +
        (props.isMy && !isSelectable ? "myContainer" : "") +
        (props.isMy && isSelectable ? "mySelectableContainer" : "")
      }
      id="message"
      onClick={handleClickMessageItem}
      onContextMenu={onContextMenu}
    >
      {!props.isMy && !isSelectable ? (
        <div className="chat-avatar-container">
          <Avatar
            onClick={props.onProfilePress}
            imageUrl={props.author.avatar}
            maxHeight={35}
            maxWidth={35}
          />
        </div>
      ) : null}

      {isSelectable ? (
        <CheckBoxForm
          className="message-checkbox"
          label=""
          onChange={select}
          checkBoxProps={{
            checked: Boolean(
              _.find(selectedMessages, (it) => it.id === props.id)
            ),
            onClick: select,
          }}
        />
      ) : null}

      <Dropdown
        onVisibleChange={props.onMenuPress}
        visible={_.isEmpty(items) ? false : visible}
        overlay={menu}
        trigger={[`contextMenu`]}
      >
        <div
          className={
            "item-content " + (props.forwardedFrom ? "withBorder" : "")
          }
        >
          {props.forwardedFrom && (
            <ForwardedMessageHeader
              title={props.author?.name}
              onPress={() => props.onForwardedAuthorPress(props.author?.id)}
            />
          )}

          <div
            className={props.isMy ? "right" : "left"}
            style={
              props.hideBackground
                ? { backgroundColor: "transparent", paddingTop: 0 }
                : null
            }
          >
            <RepliedMessageInfo
              repliedMessage={repliedMessage}
              onPress={() => {
                if (!repliedMessage?.isDeleted)
                  props.onRepliedPress(repliedMessage?.id);
              }}
            />
            {!props.isMy ? (
              <div className="headerRow">
                <div className="name">
                  <span>
                    {props.forwardedFrom
                      ? `${props.forwardedFrom?.name}`
                      : `${props.author?.name}`}
                  </span>
                </div>
                <div className="ticksInfoRow">
                  {props.isPined && (
                    <div
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <PinnedIndicator width={15} height={15} />
                    </div>
                  )}
                  <span>{moment(props.createdAt).format("HH:mm")}</span>
                  <IconComponent
                    name={props.read ? checks_1 : check_1}
                    className={`tick${props.isMy ? " ticksMy" : " ticks"}`}
                  />
                </div>
              </div>
            ) : null}

            {!props.isMy || (props.isMy && props.content?.message === undefined)
              ? props.children
              : null}

            <Dropdown
              onVisibleChange={props.onMenuPress}
              overlay={linkMenu(linkValue)}
              trigger={[`contextMenu`]}
              visible={innerVisible}
            >
              <div className="mainContentRow">
                {props.isMy && (props.type === MessageType.Text || !props.type)
                  ? props.children
                  : null}

                {/* {props.children} */}

                {props.isMy ? (
                  <div className="ticksInfoRow">
                    <span>{moment(props.createdAt).format("HH:mm")}</span>
                    <IconComponent
                      name={props.read ? checks_1 : check_1}
                      className={`tick${
                        props.isMy && props.read ? " ticksMy" : " ticks"
                      }`}
                    />

                    {props.isPined && (
                      <div
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <PinnedIndicator width={15} height={15} />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </Dropdown>
          </div>
        </div>
      </Dropdown>
      {!props.isMy && !isSelectable && (
        <>
          <ForwardMessageButton onPress={props.onForwardPressMessage} />
        </>
      )}
    </div>
  );
};
