import { RootState } from "@/store";

export const isLoadingPermissions = (state: RootState) =>
  state.permissions.isLoading;

export const getAvailablePermissions = (state: RootState) =>
  state.permissions.availablePermissions;

export const getMyPermissions = (state: RootState) =>
  state.permissions.myPermissions;
