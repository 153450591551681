interface IProps {
  onSelectAll: () => void;
  onUnSelectAll: () => void;
  all: boolean;
}
export const menuConfig = (props: IProps) => {
  return props.all
    ? [
        {
          label: "Скинути всі відмічені",
          key: "1",
          onClick: props.onUnSelectAll,
        },
      ]
    : [
        {
          label: "Позначити всі",
          key: "0",
          onClick: props.onSelectAll,
        },

        {
          label: "Скинути всі відмічені",
          key: "1",
          onClick: props.onUnSelectAll,
        },
      ];
};
