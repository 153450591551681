import React, { FC } from "react";
import styles from "./styles.module.css";
import bellSvg from "@/assets/img/Bell.svg";
import { Tooltip } from "antd";

interface IProps {
  onClick: () => void;
}
export const ButtonAtom: FC<IProps> = ({ onClick }) => {
  return (
    <Tooltip title={"Надіслати пуш-сповіщення"} key={"notification"}>
      <button className={styles.button} onClick={onClick}>
        <img src={bellSvg} alt="bell" />
      </button>
    </Tooltip>
  );
};
