import http from "../http.service";
import * as Req from "./requests.interfaces";

const logoutUser = (userId: number) => {
  return http.post<Req.ILogoutUserPayload>(`admin/sessions/stop`, { userId });
};

export const sessionsApi = {
  logoutUser
};
