/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useEventsListener } from "@/shared";
import { Button } from "reactstrap";
import ModalComponent from "@/components/Modal";

export const PinnedLimitChatModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEventsListener(
    "openLimitPinnedChatsModal",
    (payload) => {
      setIsOpen(payload.isShow);
    },
    [setIsOpen]
  );

  return (
    <ModalComponent
      title="Максимум закріплених чатів"
      show={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <div className="pinned-limit-container">
        <p>Вибачте, ви можете закріпити лише 5 (п"ять) чатів !</p>
        <Button
          style={{ width: "110px", height: "40px" }}
          name={"allSelect"}
          color="primary"
          onClick={() => setIsOpen(false)}
        >
          Ок
        </Button>
      </div>
    </ModalComponent>
  );
};
