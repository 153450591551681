import { IconComponent } from "@/shared";
import React, { FC } from "react";

interface IProps {
  onPress: () => void;
  iconName: string;
}

export const PinnedMessageButton: FC<IProps> = ({ onPress, iconName }) => {
  return (
    <div className="pinned-msg-btn-container" onClick={onPress}>
      <IconComponent name={iconName} />
    </div>
  );
};
