import React, { FC } from "react";
import adminIcon from "@/assets/img/admin-icon.svg";
import unAdminIcon from "@/assets/img/un-admin-icon.svg";
import { ChatMemberRole, IconComponent } from "@/shared";

interface IProps {
  role: ChatMemberRole;
  setUserAsAdmin: () => void;
  setUserAsMember: () => void;
  possibleToRemoveAdmin: boolean;
}
export const SetUserAdminButton: FC<IProps> = ({
  role,
  setUserAsAdmin,
  setUserAsMember,
  possibleToRemoveAdmin,
}) => {
  const isAdmin = role === ChatMemberRole.Admin;

  return (
    <div className={isAdmin ? "set-as-member-admin-btn" : "set-as-admin-btn"}>
      <button
        onClick={isAdmin ? setUserAsMember : setUserAsAdmin}
        disabled={!possibleToRemoveAdmin && isAdmin}
      >
        <IconComponent name={isAdmin ? unAdminIcon : adminIcon} />
      </button>
    </div>
  );
};
