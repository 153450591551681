export enum ChatBgKeys {
  DEFAULT = "DEFAULT",

  CUSTOM_BG = "CUSTOM_BG",

  DEF_BG_1 = "DEF_BG_1",
  DEF_BG_2 = "DEF_BG_2",
  DEF_BG_3 = "DEF_BG_3",
  DEF_BG_4 = "DEF_BG_4",
  DEF_BG_5 = "DEF_BG_5",
  DEF_BG_6 = "DEF_BG_6",
}
