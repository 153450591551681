import React, { FC, useState } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { FormUser } from "./form-user";
import { UserInfo } from "./user-info";
import { checkPermission, IUser, notification, Permissions } from "@/shared";
import { ProfilePermissionsForm } from "./FormPermissions/profile-permissions-form";
import { PermissionsInfo } from "@/containers/Permissions";
import { useSelector } from "react-redux";
import { getMyPermissions } from "@/store/permissions";

interface IProps {
  isUpdate: boolean;
  profile: IUser;
  authProfile?: IUser;
  onUpdate?: () => void;
}

export const ProfileTabs: FC<IProps> = ({
  isUpdate,
  authProfile,
  profile,
  onUpdate,
}) => {
  const permissions = useSelector(getMyPermissions);
  const [activeTab, setTab] = useState<1 | 2>(1);

  const openSuccessNotification = () =>
    notification.showSuccess("Профайл", "Дані успішно збережені");

  const openErrorNotification = () =>
    notification.showError("Помилка", "Не вдалось зберегти дані профайлу");

  const onSuccessUpdate = () => {
    openSuccessNotification();
    if (onUpdate) onUpdate();
  };

  const canUpdatePermissions = checkPermission(
    "tabs",
    "user",
    authProfile,
    permissions,
    Permissions.PERMISSIONS
  );

  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setTab(1)}
                >
                  {authProfile.id === profile.id
                    ? "Налаштування профайлу"
                    : "Інформація"}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => setTab(2)}
                >
                  Права доступу
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} style={{ paddingBottom: 20 }}>
              <TabPane tabId={1}>
                {isUpdate ? (
                  <FormUser
                    profile={profile}
                    authProfileId={authProfile.id}
                    factory={[]}
                    onSuccessUpdate={onSuccessUpdate}
                    onError={openErrorNotification}
                  />
                ) : (
                  <UserInfo profile={profile} />
                )}
              </TabPane>
              <TabPane tabId={2}>
                {activeTab === 2 && canUpdatePermissions && (
                  <ProfilePermissionsForm
                    userId={profile.id}
                    onSuccessUpdate={openSuccessNotification}
                    onError={openErrorNotification}
                  />
                )}

                {activeTab === 2 && !canUpdatePermissions && (
                  <PermissionsInfo userId={profile.id} />
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};
