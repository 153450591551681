import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { TopBarMenuLink } from "./TopbarMenuLink";
import { getImageThumb } from "../../../lib/helper";
import { useSelector } from "react-redux";
import { getAvatar, getProfile } from "@/store/account";
import { authService } from "@/services/domain";
import { getRefreshToken } from "@/store/auth";
import default_avatar from "@/assets/img/default-avatar.jpg";
import { RouteEnum } from "@/containers/App/router";
import downIcon from "../../../assets/img/Vector.svg";
import { ConfirmModal, createFullName } from "@/shared";
import _ from "lodash";
import { useHistory } from "react-router-dom";

export const TopBarProfile = () => {
  const history = useHistory();
  const profile = useSelector(getProfile);
  const refreshToken = useSelector(getRefreshToken);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [isShowLogoutModal, setLogoutModal] = useState<boolean>(false);

  const avatarProfile = useSelector(getAvatar);
  const toggle = () => {
    setCollapse((prevState) => !prevState);
  };

  const logout = async () => {
    await authService.logout(refreshToken);
    history.push("/");
  };
  let userAvatar = default_avatar;
  if (avatarProfile || profile.info.avatarUrl) {
    userAvatar = avatarProfile;
  }

  return (
    <>
      <ConfirmModal
        isShow={isShowLogoutModal}
        message={"Ви справді бажаєте вийти?"}
        onConfirm={logout}
        onCancel={_.noop}
        setShow={setLogoutModal}
      />

      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={toggle}>
          <div className="topbar__avatar-img">
            <img src={userAvatar} alt="avatar" />
          </div>

          <p className="topbar__avatar-name">
            {createFullName(
              profile.info?.firstName,
              profile.info?.middleName,
              profile.info?.lastName
            )}
          </p>
          <img src={downIcon} alt="" className="topbar__icon" />
        </button>
        {collapse && (
          <button type="button" className="topbar__back" onClick={toggle} />
        )}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopBarMenuLink
              title="Профайл"
              icon="inbox"
              path={RouteEnum.ProfileDetail}
              onClick={() => setCollapse(false)}
            />
            <div className="topbar__menu-divider" />
            <a
              className="topbar__link"
              href="# "
              onClick={() => setLogoutModal(true)}
            >
              <span className="topbar__link-icon lnr lnr-exit"></span>
              <p className="topbar__link-title">Вихід</p>
            </a>
          </div>
        </Collapse>
      </div>
    </>
  );
};
