import { chatsService } from "@/services/domain";
import { simpleDispatch } from "@/store/store-helpers";
import { selectSelectedUsers, UnselectAllUsers } from "@/store/users";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface IProps {
  onChatCreated: () => void;
}

export const useCreateChat = ({ onChatCreated }: IProps) => {
  const [name, setName] = useState<string>("");
  const [previewImg, setImg] = useState(null);
  const [nameError, setError] = useState(null);
  const [membersError, setMembersError] = useState(null);

  const selectUsers = useSelector(selectSelectedUsers);

  const onChangeName = (name: string) => {
    if (nameError) setError(null);
    if (!name || name.trimStart() === "")
      setError("Поле назви не може бути пустим");
    setName(name.trimStart());
  };

  useEffect(() => {
    if (membersError && !_.isEmpty(selectUsers)) setMembersError(null);
  }, [selectUsers, membersError]);

  const isValidData = () =>
    name && name.trimStart() !== "" && !_.isEmpty(selectUsers);

  const onSubmit = async () => {
    if (!isValidData()) {
      if (!name || name.trimStart() === "")
        setError("Поле назви не може бути пустим");
      if (_.isEmpty(selectUsers))
        setMembersError("Не вибрано жодного користувача");
    } else {
      try {
        const usersIds = selectUsers.map(user => user.id);

        await chatsService.createGroupChat({
          name,
          previewImg,
          usersIds
        });

        simpleDispatch(new UnselectAllUsers());
      } catch (error) {
        onError(error);
      } finally {
        setName("");
        setImg(null);
        setError(null);
        simpleDispatch(new UnselectAllUsers());
        onChatCreated();
      }
    }
  };

  const onError = (error: any) => {
    const message =
      error.response?.data?.message === "Not enough members"
        ? "Недостатня кількість учасників для створення групи."
        : "Спробуйте будь-ласка пізніше";
    console.log(error.response?.data?.message);
    console.log("Error message:", message);
  };

  return {
    name,
    setName: onChangeName,
    setImg,
    onSubmit,
    nameError,
    setError,
    membersError,
    setMembersError,
    previewImg
  };
};
