import { createFullName, ITask } from "@/shared";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { FC } from "react";

interface IProps {
  task: ITask;
  hideUser?: boolean;
}

export const PrintTasksItem: FC<IProps> = ({ task, hideUser }) => {
  const styles = {
    td: {
      padding: "5px 0px",
      color: "black",
    },
  };

  const fullName = createFullName(
    task.executor?.firstName,
    task.executor?.middleName,
    task.executor?.lastName
  );

  console.log("task", task);
  return (
    <div style={{ pageBreakInside: "avoid", paddingTop: "20px" }}>
      <table
        style={{
          width: "100%",
          marginTop: "20px",
          borderBottom: !_.isEmpty(task?.comments)
            ? "0px solid black"
            : "1px solid black",
        }}
      >
        {!hideUser ? (
          <tr style={{ pageBreakInside: "avoid", pageBreakAfter: "auto" }}>
            <td colSpan={4} style={{ ...styles.td, fontSize: "19px" }}>
              <strong>
                <span style={{ textDecoration: "underline" }}>
                  {fullName ? fullName : "Невідомо"}
                </span>
              </strong>{" "}
              <i>{`(${
                task?.executor?.position ? task?.executor?.position : ""
              }${
                task?.executor?.position && task?.executor?.factoryName
                  ? `, `
                  : ""
              }${
                task?.executor?.factoryName
                  ? `${task.executor.factoryName}`
                  : ""
              })`}</i>
            </td>
            {/* <td></td> */}
          </tr>
        ) : null}
        <tr
          style={{
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontSize: "14px",
          }}
        >
          <td style={styles.td}>
            <strong> №{task.id}</strong>
          </td>
          <td style={styles.td}>
            <strong> Початок: </strong>{" "}
            {moment(task.startDate).format("DD-MM-YYYY")}
          </td>
          <td style={styles.td}>
            <strong> Закінчення: </strong>{" "}
            {moment(task.endDate).format("DD-MM-YYYY")}{" "}
          </td>
          <td rowSpan={2} style={{ width: "40px" }}>
            <div
              style={{
                border: "1px solid black",
                width: "40px",
                height: "40px",
              }}
            ></div>
          </td>
        </tr>
        <tr
          style={{
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontSize: "14px",
            overflowWrap: "anywhere",
          }}
        >
          <td colSpan={3} style={styles.td}>
            <strong>Найменування: </strong>
            {task?.name}
          </td>
          {/* <td></td> */}
        </tr>
        <tr
          style={{
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontSize: "14px",
          }}
        >
          <td colSpan={3} style={styles.td}>
            <strong>Група: </strong>
            {task?.group?.name}
          </td>
          <td></td>
        </tr>
        <tr
          style={{
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontSize: "14px",
          }}
        >
          <td colSpan={3} style={styles.td}>
            <strong>Хто поставив: </strong>
            {createFullName(task?.author?.firstName, task?.author?.lastName)}
          </td>
          <td></td>
        </tr>
        <tr
          style={{
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontSize: "14px",
          }}
        >
          <td colSpan={3} style={styles.td}>
            <strong>Ініціатор: </strong>
            {createFullName(
              task.initiator?.firstName,
              task.initiator?.middleName,
              task.initiator?.lastName
            )}
          </td>
          <td></td>
        </tr>
        {task?.reason && (
          <tr
            style={{
              pageBreakInside: "avoid",
              pageBreakAfter: "auto",
              fontSize: "14px",
            }}
          >
            <td colSpan={3} style={styles.td}>
              <strong>На підставі: </strong>
              {task?.reason?.name}
            </td>
            <td></td>
          </tr>
        )}
        <tr
          style={{
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontSize: "14px",
            overflowWrap: "anywhere",
          }}
        >
          <td
            colSpan={4}
            style={{
              ...styles.td,
            }}
          >
            {task.description || " "}
          </td>
        </tr>
        <tr>
          <td colSpan={3}></td>
          <td></td>
        </tr>
      </table>
      {!_.isEmpty(task?.comments?.filter((it) => Boolean(it))) && (
        <p
          style={{
            fontSize: "14px",
            pageBreakInside: "avoid",
            pageBreakAfter: "auto",
            fontWeight: "bold",
            color: "black",
          }}
        >
          Коментарі:
        </p>
      )}
      {!_.isEmpty(task?.comments?.filter((it) => Boolean(it))) && (
        <table
          style={{
            width: "100%",
            marginTop: "10px",
            color: "black",
            borderBottom: "1px solid black",
          }}
        >
          {task?.comments
            ?.filter((it) => Boolean(it))
            .map((item) => (
              <>
                <tr
                  style={{
                    pageBreakInside: "avoid",
                    pageBreakAfter: "auto",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  <td>
                    {createFullName(
                      task.author?.firstName,
                      task.author?.middleName,
                      task.author?.lastName
                    )}
                    :
                  </td>
                  <td>{item?.content}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <br />
                  </td>
                </tr>
              </>
            ))}
        </table>
      )}
    </div>
  );
};
