import React, { FC } from "react";
import "./style.scss";

interface IProps {
  label: string;
  userCount: string; // transformed number
}

export const ChatHeaderInfo: FC<IProps> = ({ label, userCount }) => {
  return (
    <div>
      <p className="chat-header-label">{label}</p>

      <p className="chat-header-stats">{`${userCount}`}</p>
    </div>
  );
};
