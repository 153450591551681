import React from "react";
import { SignInForm } from "../components";
import logo from "@/assets/img/logo_rws.png";

export const SignIn = () => {
  if (location.pathname === "/forgot" || location.pathname === "/recover")
    return null;

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div
            className="account__head"
            style={{ border: "none", paddingLeft: 0 }}
          >
            <h3 className="account__title">
              <span className="account__logo">
                <img style={{ width: "145px" }} src={logo} />
              </span>
            </h3>
            <h4 className="account__subhead subhead" />
          </div>
          <SignInForm />
        </div>
      </div>
    </div>
  );
};
