import React, { FC } from "react";
import { ButtonToolbar, Button } from "reactstrap";
import { IChatInList } from "@/shared";
import { ChatsSearchWithBtn } from "./chat-search-with-btn.component";
import { ChatsSelectList } from "./chats-select-list.component";

interface IProps {
  chats: IChatInList[];
  selectOne: (id: number) => void;
  unSelectOne: (id: number) => void;
  searchString: string;
  onChange: (val: string) => void;
  selectAll: () => void;
  forwardMessage: () => void;
}

export const ChatsSelectListWithSearch: FC<IProps> = ({
  searchString,
  onChange,
  selectOne,
  unSelectOne,
  selectAll,
  chats,
  forwardMessage,
}) => {
  return (
    <div className="forward-msg-modal">
      <ChatsSearchWithBtn
        searchStr={searchString}
        onChange={onChange}
        selectAll={selectAll}
      />
      <ChatsSelectList
        items={chats}
        selectOne={selectOne}
        unSelectOne={unSelectOne}
      />
      <ButtonToolbar className="form__add-chat">
        <Button
          style={{ width: "110px", height: "40px" }}
          name={"allSelect"}
          color="primary"
          onClick={forwardMessage}
          title={"Виклик користувача"}
        >
          Переслати
        </Button>
      </ButtonToolbar>
    </div>
  );
};
