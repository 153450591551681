import React, { FC, useEffect } from "react";
import Modal from "../../components/Modal";
import { Controller } from "react-hook-form";
import { InputField, TextField } from "@/shared";
import { useSendNotifications } from "./hooks/use-send-notifications.hook";
import "./style.scss";
import { useFetchChatUsers } from "@/containers/User/hooks";
import {
  UserSearchWithBtn,
  UsersSelectList,
} from "@/containers/User/components";
import { ButtonToolbar, Button } from "reactstrap";
import { FormCheckbox } from "@/components/Forms/FormCheckbox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SendNotificationsWidget: FC<Props> = ({ isOpen, onClose }) => {
  const {
    form,
    selectUser,
    selectedUsers,
    unselectUser,
    selectUsers,
    submit,
    allSelected,
    setAllSelected,
  } = useSendNotifications(onClose);

  const {
    items,
    searchString,
    setSearchVal,
    loadMore,
    loadParams,
    totalUsersCount,
  } = useFetchChatUsers();

  const toggleSelectAll = () => {
    if (allSelected) {
      selectUsers([]);
      setAllSelected(false);
    } else setAllSelected(true);
  };

  const reset = () => {
    setSearchVal("");
    setAllSelected(false);
    form.reset({ title: "", haveToSendEmail: false, content: "", users: [] });
  };

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  useEffect(() => {
    if (allSelected) selectUsers(items);
  }, [allSelected, items]);

  useEffect(() => {
    if (totalUsersCount > 0 && selectedUsers.length === totalUsersCount)
      setAllSelected(true);
  }, [selectedUsers, totalUsersCount]);

  const handleUnselectUser = (userId: number) => {
    unselectUser(userId);

    if (allSelected) setAllSelected(false);
  };

  return (
    <Modal show={isOpen} toggle={() => onClose()} title="Нове пуш-сповіщення">
      <div className="form send-notifi-form">
        <Controller
          name="title"
          control={form.control}
          rules={{ required: "Заповнити обов'язково" }}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputField
              placeholder={""}
              label={"Заголовок сповіщення"}
              onChange={onChange}
              value={value}
              error={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name="content"
          control={form.control}
          rules={{ required: "Заповнити обов'язково" }}
          render={({ field: { value, onChange }, fieldState }) => (
            <TextField
              placeholder={""}
              label={"Текст сповіщення"}
              onChange={onChange}
              value={value}
              error={fieldState?.error?.message}
              textAreaProps={{}}
            />
          )}
        />

        <div className="set-header-info">
          <UserSearchWithBtn
            items={items}
            searchStr={searchString}
            onChangeContact={setSearchVal}
            selectAll={toggleSelectAll}
            allBtnLabel={allSelected ? "Очистити" : "Додати всіх"}
          />
        </div>
        <Controller
          name="users"
          control={form.control}
          rules={{
            required: allSelected
              ? null
              : "Потрібно вибрати принаймні 1 користувача",
            minLength: allSelected
              ? null
              : {
                  message: "Потрібно вибрати принаймні 1 користувача",
                  value: 1,
                },
          }}
          render={({ field: { value }, fieldState }) => (
            <>
              <UsersSelectList
                items={items}
                selectedUsers={value}
                selectOne={selectUser}
                unSelectOne={handleUnselectUser}
                excludeIds={[]}
                loadMore={loadMore}
                count={loadParams.count}
              />
              <p className="error">{fieldState?.error?.message}</p>
            </>
          )}
        />
        <ButtonToolbar className="form__add-chat">
          <Button
            style={{ width: "220px", height: "46px" }}
            name={"allSelect"}
            color="primary"
            onClick={submit}
            title={"Надіслати пуш-сповіщення"}
          >
            Надіслати пуш-сповіщення
          </Button>

          <Controller
            name="haveToSendEmail"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <FormCheckbox
                checked={Boolean(value)}
                onChange={onChange}
                label="Дублювати на email"
              />
            )}
          />
        </ButtonToolbar>
      </div>
    </Modal>
  );
};
