import { useSelector } from "react-redux";
import { simpleDispatch } from "@/store/store-helpers";
import { defaultChatBgConfig } from "@/config/default-chat-bg.config";
import { useEffect, useState } from "react";

import { IChatBackgroundData } from "../interfaces";
import { selectCurrentChatBgId, SetChatBg } from "@/store/chat-bg";
import { ChatBgKeys, StorageKey } from "@/shared";

export const useChatBgFromLibrary = () => {
  const [chatBackgrounds, setChatBackgrounds] = useState<IChatBackgroundData[]>(
    defaultChatBgConfig
  );

  const [base64Img, setBase64] = useState<string>();

  const currentSelectedBgId = useSelector(selectCurrentChatBgId);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const chooseNewBgImage = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    return base64;
  };

  const selectImgFromLibrary = async (e) => {
    const base64Img = await chooseNewBgImage(e);
    console.log(base64Img);

    setBase64(base64Img as string);

    setChatBackgrounds([
      { id: ChatBgKeys.CUSTOM_BG, img: base64Img },
      ...defaultChatBgConfig,
    ]);

    simpleDispatch(new SetChatBg({ bgId: ChatBgKeys.CUSTOM_BG }));
  };

  const onSave = () => {
    if (base64Img) {
      localStorage.setItem(StorageKey.CustomChatBg, base64Img);
    }

    localStorage.setItem(StorageKey.ChatBgId, currentSelectedBgId);
    simpleDispatch(new SetChatBg({ bgId: currentSelectedBgId }));
  };

  const hasCustomChatBg = async () => {
    const customChatBg = await localStorage.getItem(StorageKey.CustomChatBg);

    if (customChatBg)
      setChatBackgrounds((prev) => [
        { id: ChatBgKeys.CUSTOM_BG, img: customChatBg },
        ...prev,
      ]);
  };

  const setDefaultBg = () => {
    simpleDispatch(new SetChatBg({ bgId: ChatBgKeys.DEFAULT }));
    localStorage.removeItem(StorageKey.CustomChatBg);
  };

  useEffect(() => {
    hasCustomChatBg();
  }, []);

  return {
    chatBackgrounds,
    selectImgFromLibrary,
    setDefaultBg,
    onSave,
  };
};
