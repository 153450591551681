import React from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { PhoneField } from "./fields";
import { authService } from "@/services/domain";
import { RouteEnum } from "@/containers/App/router";
import { StorageKey } from "@/shared";

interface ISendCodeForm {
  phoneNumber: string;
}

export const SendCodeForm = () => {
  const { register, handleSubmit, control } = useForm<ISendCodeForm>({
    defaultValues: {
      phoneNumber: "",
    },
  });

  const history = useHistory();

  const onSubmit = (data: ISendCodeForm) => {
    authService.sendConfirmationCode(data);
    localStorage.setItem(StorageKey.PhoneNumber, data.phoneNumber);
    history.push(RouteEnum.Recover);
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: "Заповнити обов'язково",
        }}
        render={({ field: { value, onChange }, fieldState }) => (
          <PhoneField
            register={register("phoneNumber", {
              required: "Заповнити обов'язково",
            })}
            value={value}
            onChange={onChange}
            error={fieldState?.error?.message}
          />
        )}
      />

      <button className="btn btn-primary btn-auth saccount__btn account__btn--small">
        Відправити
      </button>
    </form>
  );
};
