import { IUser } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TAccountActions } from "./types";

export interface IAccountState {
  account?: IUser;
  isLoading: boolean;
  isVisibleModal: boolean;
  avatar: string;
}

const initialState: IAccountState = {
  account: null,
  isLoading: true,
  isVisibleModal: false,
  avatar: null,
};

export const accountReducer = createReducer<IAccountState, TAccountActions>(
  initialState,
  {
    SAVE_ACCOUNT: (state, action) => {
      return {
        ...state,
        account: action.payload.account,
      };
    },

    IS_LOADING: (state, action) => {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    },

    RESET_ACCOUNT: () => {
      return initialState;
    },
    SHOW_MODAL: (state, action) => {
      return {
        ...state,
        isVisibleModal: action.payload.isVisibleModal,
      };
    },
    SAVE_AVATAR_ACCOUNT: (state, action) => {
      return {
        ...state,
        avatar: action.payload.avatarUrl,
      };
    },
  }
);
