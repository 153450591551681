import React, { FC, CSSProperties } from "react";
import copySvg from "@/assets/img/copy-icon-1.svg";
import { message } from "antd";
import "./styles.scss";
import { copyText } from "@/shared/helpers/copy.helpers";

interface IProps {
  label: string;
  value: string | number;
  style?: CSSProperties;
  onClick?: () => void;
  enableCopy?: boolean;
}

export const FunctionalEmailField: FC<IProps> = ({
  label,
  value,
  style,
  onClick,
  enableCopy,
}) => {
  const handleIconClick = (event) => {
    copyText(value, event);
  };

  return (
    <div className="functional-email-field" style={style}>
      <p className="functional-email-label">{label}</p>
      <div className="value-container" onClick={onClick}>
        <span>{value}</span>
        {enableCopy && (
          <img
            className="contact-copy"
            src={copySvg}
            alt="copy"
            onClick={handleIconClick}
          />
        )}
      </div>
    </div>
  );
};
