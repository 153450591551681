import React, { FC } from "react";

interface IProps {
  title: string;
  onPress?: () => void;
}

export const ForwardedMessageHeader: FC<IProps> = ({ title, onPress }) => {
  return (
    <div className="forwarded-row-container">
      <div>
        <p className="forwarded">Переслане повідомлення</p>
      </div>
      {title && (
        <div className="forwarded-row">
          <div>
            <p className="forwarded">{`Від: `}</p>
          </div>
          <div
            style={{ cursor: "pointer", marginLeft: "5px" }}
            onClick={onPress}
          >
            <p className="forwarded link">{`${title}`}</p>
          </div>
        </div>
      )}
    </div>
  );
};
