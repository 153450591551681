import React from "react";

export const LoginIcon = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="18" fill="#9E2743" fillOpacity="0.1" />
        <path
          d="M26.1212 23.8969C25.679 22.8496 25.0374 21.8984 24.2321 21.0961C23.4292 20.2915 22.4781 19.65 21.4313 19.207C21.4219 19.2023 21.4126 19.2 21.4032 19.1953C22.8633 18.1406 23.8126 16.4227 23.8126 14.4844C23.8126 11.2734 21.211 8.67188 18.0001 8.67188C14.7891 8.67188 12.1876 11.2734 12.1876 14.4844C12.1876 16.4227 13.1368 18.1406 14.5969 19.1977C14.5876 19.2023 14.5782 19.2047 14.5688 19.2094C13.5188 19.6523 12.5766 20.2875 11.768 21.0984C10.9634 21.9013 10.3219 22.8524 9.87896 23.8992C9.44378 24.924 9.20908 26.0228 9.18756 27.1359C9.18693 27.161 9.19132 27.1858 9.20046 27.2091C9.20961 27.2324 9.22332 27.2537 9.24079 27.2716C9.25826 27.2895 9.27915 27.3037 9.30221 27.3134C9.32527 27.3231 9.35004 27.3281 9.37506 27.3281H10.7813C10.8844 27.3281 10.9665 27.2461 10.9688 27.1453C11.0157 25.3359 11.7422 23.6414 13.0266 22.357C14.3555 21.0281 16.1204 20.2969 18.0001 20.2969C19.8797 20.2969 21.6446 21.0281 22.9735 22.357C24.2579 23.6414 24.9844 25.3359 25.0313 27.1453C25.0337 27.2484 25.1157 27.3281 25.2188 27.3281H26.6251C26.6501 27.3281 26.6749 27.3231 26.6979 27.3134C26.721 27.3037 26.7419 27.2895 26.7593 27.2716C26.7768 27.2537 26.7905 27.2324 26.7997 27.2091C26.8088 27.1858 26.8132 27.161 26.8126 27.1359C26.7891 26.0156 26.5571 24.9258 26.1212 23.8969ZM18.0001 18.5156C16.9243 18.5156 15.9118 18.0961 15.1501 17.3344C14.3883 16.5727 13.9688 15.5602 13.9688 14.4844C13.9688 13.4086 14.3883 12.3961 15.1501 11.6344C15.9118 10.8727 16.9243 10.4531 18.0001 10.4531C19.0758 10.4531 20.0883 10.8727 20.8501 11.6344C21.6118 12.3961 22.0313 13.4086 22.0313 14.4844C22.0313 15.5602 21.6118 16.5727 20.8501 17.3344C20.0883 18.0961 19.0758 18.5156 18.0001 18.5156Z"
          fill="#9E2743"
        />
      </svg>
    </>
  );
};
