import http, { IRequestConfig } from "../http.service";
import { ApiResponse } from "../http.types";
import * as Res from "./responses.interfaces";
import * as Req from "./requests.interfaces";
import { convertToFormData } from "@/shared";

export const getChatsListReq = (
  params: IRequestConfig = {
    params: { limit: 20 },
  }
): ApiResponse<Res.IFetchChatList> => {
  return http.get<Res.IFetchChatList>("app/chats", params);
};

export const fetchChats = (
  params: Req.IFetchChatsParams
): ApiResponse<Res.IFetchChatList> => {
  return http.get<Res.IFetchChatList>("app/chats", { params });
};

export const getChatDetailReq = (
  params: Req.IFetchChatDetail
): ApiResponse<Res.IFetchChatDetail> => {
  return http.get<Res.IFetchChatDetail>(`app/chats/${params.id}`);
};

export const deleteChatByIdReq = (chatId: number): ApiResponse<void> => {
  return http.delete<void>(`app/chats/${chatId}`);
};

export const setChatMutedReq = (data: Req.ISetChatMuted): ApiResponse<void> => {
  return http.post<void>(`app/chats/set-muted`, data);
};

export const editGroupChatReq = (
  chatId: number,
  data: Req.IEditGroupChat
): ApiResponse<void> => {
  return http.patch<void>(`app/chats/${chatId}`, convertToFormData(data));
};

export const createPersonalChatReq = (
  data: Req.ICreatePersonalChat
): ApiResponse<Res.NewChatGroupId> => {
  return http.post<Res.NewChatGroupId>(`app/chats/personal-chat`, data);
};

export const createGroupChatReq = (
  params: Req.ICreateGroupChat
): ApiResponse<Res.NewChatGroupId> => {
  return http.post<Res.NewChatGroupId>(
    `app/chats/group-chat`,
    convertToFormData(params)
  );
};

export const pinChatReq = ({ chatId, ...data }: Req.IPinChatPayload) => {
  console.log(chatId, data);
  return http.post<void>(`common/chats/${chatId}/fix`, data, {});
};

export const unPinChatReq = (chatId: number) => {
  return http.delete(`common/chats/${chatId}/unfix`, {});
};

export const setChatUnreadReq = (chatId: number) => {
  return http.post(`common/chats/${chatId}/unread`, {});
};

export const setChatReadReq = (chatId: number) => {
  return http.post(`common/chats/${chatId}/read`, {});
};

export const getUsersForChatReq = async (
  params: Req.IFetchUsersParams
): ApiResponse<Res.IFetchPotentialChatMembersResponse> => {
  return http.get<Res.IFetchPotentialChatMembersResponse>("app/chats/users", {
    params,
  });
};

export const chatsApi = {
  getChatsListReq,
  fetchChats,
  getChatDetailReq,
  deleteChatByIdReq,
  setChatMutedReq,
  editGroupChatReq,
  createPersonalChatReq,
  createGroupChatReq,
  pinChatReq,
  unPinChatReq,
  setChatUnreadReq,
  setChatReadReq,
  getUsersForChatReq,
};
