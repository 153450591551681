import { ChatMemberRole, IChatMember, IShortUser } from "@/shared";

export const transformShrotUserToChatMember = (
  chatId: number,
  shortUser: IShortUser
): IChatMember =>
  ({
    id: null,
    role: ChatMemberRole.Member,
    chatId,
    userId: shortUser.id,
    authorId: null,
    isDeleted: false,
    user: { ...shortUser, userId: shortUser.id },
    isOnline: false,
  } as IChatMember);
