import React, { FC, useEffect, useMemo } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { UsersTableList } from "./components/DataUser";
import Modal from "../../components/Modal";
import { useState } from "react";
import {
  checkPermission,
  ConfirmModal,
  dropdownMenuConfig,
  EUserRole,
  IUser,
  Permissions,
  SearchInput,
} from "@/shared";
import "./style.scss";
import { getProfile } from "@/store/account";
import { FormUser } from "./components/FormUser";
import { AppTypesFilterButtons } from "./components/AppTypesButtons";
import { AppType, UserModalMode } from "./enums";
import _ from "lodash";
import { useUsersList } from "./hooks";
import { getSelectedUsersMenuConfig } from "./configs";
import { UserAddOutlined } from "@ant-design/icons";
import { getMyPermissions } from "@/store/permissions";
import { UsersSelectedTableHeader } from "./components";
import {
  SendNotificationsButtonWidget,
  SendNotificationsWidget,
} from "@/widgets";

interface IModalState {
  isOpen: boolean;
  mode: UserModalMode;
}

const defaultModalState: IModalState = {
  isOpen: false,
  mode: null,
};

export const Users: FC = () => {
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const [userModal, setUserModal] = useState<IModalState>(defaultModalState);
  const [focusFilterKey, setFocusFilterKey] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");

  const {
    paginationList,
    confirmModal,
    selectedUsers,
    setSelectedUsers,
    userActions,
    allIds,
  } = useUsersList();

  const onOpenContextMenu = (isOpen: boolean, userId: number) => {
    if (isOpen && !_.some(selectedUsers, (it) => it.id === userId))
      setSelectedUsers([paginationList.items.find((it) => it.id === userId)]);
  };

  const onSubmitUserData = () => {
    paginationList.resetFlatList();
    setUserModal(defaultModalState);
    setSelectedUsers([]);
  };

  useEffect(() => {
    paginationList.setLoadParams({
      globalSearchString: searchString,
    });
  }, [searchString]);

  const onUserAction = (
    key: "edit" | "block" | "logout" | "delete" | "create"
  ) => {
    const actionByKey = {
      edit: () => {
        console.log('selectedUsers', selectedUsers)
        setUserModal({
          isOpen: true,
          mode: UserModalMode.Edit,
        });
      },
      block: async () => await userActions.onPressBanUser(),
      logout: () =>
        confirmModal.open(
          "Ви справді хочете розлогінити?",
          userActions.onLogoutUser
        ),
      delete: () =>
        confirmModal.open(
          "Ви справді хочете видалити?",
          userActions.onDeleteUser
        ),
      create: () => setUserModal({ isOpen: true, mode: UserModalMode.Create }),
    };

    actionByKey[key]();
  };

  const selectedItemsMenuConfig = useMemo(
    () =>
      getSelectedUsersMenuConfig({
        onClick: onUserAction,
        selectedItems: selectedUsers,
        profile,
        permissions,
      }),
    [selectedUsers, profile, permissions]
  );

  const contextMenuConfig = useMemo(() => {
    const config = [];
    if (
      checkPermission("tabs", "user", profile, permissions, Permissions.CREATE)
    )
      config.push({
        onClick: () => onUserAction("create"),
        key: "create",
        label: "Створити",
        iconNode: (
          <UserAddOutlined className="selected-rows-menu-btn context icon-add-user" />
        ),
      });

    config.push(...selectedItemsMenuConfig);

    return config;
  }, [selectedItemsMenuConfig, profile, permissions]);

  const selectAllContacts = () => {
    setSelectedUsers(paginationList?.data);
  };
  const unSelectAllContacts = () => {
    setSelectedUsers([]);
  };
  const configDropdownSelected = useMemo(() => {
    return dropdownMenuConfig({
      onSelectAll: selectAllContacts,
      onUnSelectAll: unSelectAllContacts,
      all: allIds?.length === selectedUsers.length,
    });
  }, [paginationList, selectedUsers]);

// console.log(paginationList.data)
  return (
    <Container className="factory">
      <ConfirmModal
        isShow={confirmModal.state.isOpen}
        message={confirmModal.state.message}
        onConfirm={confirmModal.state.onConfirm}
        setShow={(state: boolean) =>
          confirmModal.set({ ...confirmModal.state, isOpen: state })
        }
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Modal
                show={userModal.isOpen}
                toggle={(state: boolean) =>
                  setUserModal({ isOpen: state, mode: userModal.mode })
                }
                title="Користувач"
              >
                <FormUser
                  profile={profile}
                  onSubmit={onSubmitUserData}
                  selectedUser={selectedUsers[0] as IUser}
                  mode={userModal.mode}
                  permissions={permissions}
                />
              </Modal>

              <Row>
                <div
                  className="col-md-12 flex"
                  style={{
                    justifyContent:
                      profile.role === EUserRole.Admin
                        ? "space-between"
                        : "flex-end",
                  }}
                >
                  {profile.role === EUserRole.Admin && (
                    <AppTypesFilterButtons
                      count={paginationList.loadParams.count}
                      onChange={(type: AppType | null) =>
                        paginationList.setLoadParams({ appType: type })
                      }
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <SearchInput
                      value={searchString}
                      onChange={setSearchString}
                      placeholder="Пошук"
                      style={{ height: 46, marginRight: "20px", width: 235 }}
                      isFocused={focusFilterKey === "globalSearch"}
                      onFocus={() => setFocusFilterKey("globalSearch")}
                    />
                    <Button
                      color="primary"
                      size="sm"
                      disabled={
                        !checkPermission(
                          "tabs",
                          "user",
                          profile,
                          permissions,
                          Permissions.CREATE
                        )
                      }
                      onClick={() => {
                        setUserModal({
                          isOpen: true,
                          mode: UserModalMode.Create,
                        });
                      }}
                    >
                      Створити
                    </Button>

                    <SendNotificationsButtonWidget />
                  </div>
                </div>
              </Row>

              <UsersSelectedTableHeader
                selectedRows={selectedUsers.map((it) => it.id)}
                selectedItemsMenuConfig={selectedItemsMenuConfig}
                configDropdownSelected={configDropdownSelected}
              />

              <UsersTableList
                filter={undefined}
                factory={undefined}
                profile={profile}
                showModal={userModal.isOpen}
                selectedUsers={selectedUsers}
                paginationList={paginationList}
                setSelectedUsers={setSelectedUsers}
                focusFilterKey={focusFilterKey}
                setFocusFilterKey={setFocusFilterKey}
                onOpenContextMenu={onOpenContextMenu}
                contextMenuConfig={contextMenuConfig}
                allUsers={paginationList.data}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
