import { IPermissionsGroup, IUserPermissions } from "@/shared";
import { Action } from "redux";

export class CreatePermissionsGroup implements Action {
  readonly type = "CREATE_PERMISSIONS_GROUP";

  constructor(
    public readonly payload: {
      permissionsGroup: IPermissionsGroup;
    }
  ) {}
}
export class UpdatePermissionsGroup implements Action {
  readonly type = "UPDATE_PERMISSIONS_GROUP";

  constructor(
    public readonly payload: {
      permissionsGroup: {
        id: number;
        name: string;
        permissions: IUserPermissions;
      };
    }
  ) {}
}
export class DeletePermissionsGroup implements Action {
  readonly type = "DELETE_PERMISSIONS_GROUP";

  constructor(
    public readonly payload: {
      id: number;
    }
  ) {}
}

export class SetTargetPermissionsGroup implements Action {
  readonly type = "SET_TARGET_PERMISSIONS_GROUP";

  constructor(
    public readonly payload: {
      permissionGroup: IPermissionsGroup;
    }
  ) {}
}

export class SetPermissionsGroups implements Action {
  readonly type = "SET_PERMISSIONS_GROUPS";

  constructor(
    public readonly payload: {
      data: IPermissionsGroup[];
      count: number;
    }
  ) {}
}

export class isLoading {
  readonly type = "IS_LOADING_PERMISSIONS_GROUP";
  constructor(
    public readonly payload: {
      isLoading: boolean;
    }
  ) {}
}

export type TPermissionsGroupActions =
  | isLoading
  | SetTargetPermissionsGroup
  | SetPermissionsGroups
  | DeletePermissionsGroup
  | CreatePermissionsGroup
  | UpdatePermissionsGroup;
