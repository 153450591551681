import { createReducer } from '@bitalikrty/redux-create-reducer'
import { TNotificationActions } from './types'
import _ from 'lodash'
import { INotification } from "@/shared"

export interface INotificationState {
	isNotification: boolean
	messages: INotification[]
}

const initialState: INotificationState = {
	isNotification: false,
	messages: [],
}

export const NotificationReducer = createReducer<INotificationState, TNotificationActions>(
	initialState,
	{
		NOTIFICATION: (state, action) => {
			return {
				...state,
				isNotification: true,
				messages: [...state.messages, action.payload.notification]
			}
		},
		CLEAR_NOTIFICATION: (state, action) => {
			return initialState
		}
	},
)
