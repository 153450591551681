import React, { useState } from "react";
import { Dropdown, Button, Tooltip } from "antd";
import { Menu } from "antd";
import _ from "lodash";
import { IColumn, InputCheckbox } from "@/shared";
import HorizontalSLidersSVGIcon from "../../../assets/img/sliders-horizontal.svg";
import { VOID_COLUMN_KEY } from "../configs/void-row.config";
import "../style.scss";

interface IProps {
  columns: IColumn[];
  columnsSort: string[];
  activeColumns: string[];
  onChangeColumns: (val: any) => void;
  onSaveColumnsSort: (val: any) => void;
  tableName: string;
}

export const ColumnsView = (props: IProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const prepareKeys = (keys: string[]): string[] => {
    if (!keys.length || (keys.length === 1 && keys.includes("select-row")))
      return [...keys];

    return keys;
  };

  const onChange = (isChecked: boolean, value: string) => {
    if (isChecked) {
      props.onChangeColumns(
        [...props.activeColumns, value].sort(function(a, b) {
          return props.columnsSort.indexOf(a) - props.columnsSort.indexOf(b);
        })
      );
    } else {
      props.onChangeColumns(
        prepareKeys(props.activeColumns.filter((key) => key !== value))
      );
    }
  };

  const onChangeSortColumns = (index, type = "next") => {
    const tmp_array = _.cloneDeep(props.columnsSort);

    if (type === "next") {
      const tmp = _.cloneDeep(tmp_array[index]);
      tmp_array[index] = tmp_array[index + 1];
      tmp_array[index + 1] = tmp;
    } else {
      const tmp = _.cloneDeep(tmp_array[index]);
      tmp_array[index] = tmp_array[index - 1];
      tmp_array[index - 1] = tmp;
    }

    props.onSaveColumnsSort(tmp_array);
  };

  const columns = props.columnsSort.map((key) => {
    const index = props.columns.findIndex((it) => it?.key === key);
    return props.columns[index];
  });

  const filteredColumns = columns.filter(
    (it) => !["select-row", VOID_COLUMN_KEY].includes(it?.key)
  );

  return (
    <Dropdown
      placement="topLeft"
      visible={visible}
      onVisibleChange={() => setVisible((state) => !state)}
      overlay={() => (
        <Menu style={{ borderRadius: 20, padding: 15, left: -7, top: -8 }}>
          {columns.map((item, index) => {
            if (["select-row", VOID_COLUMN_KEY].includes(item?.key))
              return null;
            return (
              <Menu.Item key={index}>
                <div className="collums-row-sort">
                  <InputCheckbox
                    value={props.activeColumns.indexOf(item?.key) > -1}
                    onChange={(isChecked) => onChange(isChecked, item?.key)}
                  >
                    {item?.name === "№" && props.tableName === "tasks"
                      ? `${item?.name} задачі`
                      : item?.name}
                  </InputCheckbox>

                  <div className="sort-icons">
                    {filteredColumns.findIndex((it) => it?.key === item?.key) >
                    0 ? (
                      <div
                        className="btn-icon"
                        style={{ border: "none", borderRadius: 2 }}
                        onClick={() => onChangeSortColumns(index, "prev")}
                      >
                        <i
                          className="fal fa-chevron-up"
                          style={{ fontWeight: 500 }}
                          color="rgba(127, 127, 127, 1)"
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                    {props.columns.length !== index ? (
                      <div
                        className="btn-icon"
                        style={{ border: "none", borderRadius: 2 }}
                        onClick={() => onChangeSortColumns(index, "next")}
                      >
                        <i
                          className="fal fa-chevron-down"
                          style={{ fontWeight: 500 }}
                          color="rgba(127, 127, 127, 1)"
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
      )}
      trigger={["click"]}
    >
      <Tooltip
        title={"Налаштування таблиці"}
        placement="bottom"
        key={"table-settings"}
        mouseEnterDelay={0.5}
      >
        <Button type="text">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={HorizontalSLidersSVGIcon} style={{ width: 30 }} />
          </div>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};
