export enum GroupPermissionsModalType {
  CREATE,
  UPDATE
}

export enum PermissionsSection {
  FACTORY = "factory",
  USER = "user",
  TABS = "tabs",
  FILTER_FACTORY = "filter_factory"
}
