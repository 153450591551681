import { ChatType } from "@/shared";
import default_avatar from "@/assets/img/default-avatar.jpg";
import default_avatar2 from "@/assets/img/default_avatar_2.png";
import default_avatar3 from "@/assets/img/default_avatar_3.png";
import moment from "moment";

export const chatsInfoMockConfig: any[] = [
  {
    id: 1,
    name: "Артем Осядлий",
    usersCount: 1,
    type: ChatType.Personal,
    authorId: 40,
    previewUrl: default_avatar,
    lastMessage: "Доброго дня, дякую!",
    lastMessageDate: moment(new Date()).format(),
    unreadMessagesCount: 2,
    createdAt: "17-05-2022",
  },
  {
    id: 2,
    name: "Менеджмент",
    usersCount: 25,
    type: ChatType.Group,
    authorId: 47,
    previewUrl: "",
    lastMessage:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do...",
    lastMessageDate: moment(new Date()).format(),
    // unreadMessagesCount: 12,
    createdAt: "17-05-2022",
  },
  {
    id: 3,
    name: "Анастасія Попова",
    usersCount: 1,
    type: ChatType.Personal,
    authorId: 40,
    previewUrl: default_avatar2,
    lastMessage: "Ок, зробиш, скинеш.",
    lastMessageDate: "06-17-2015 14:24:36",
    createdAt: "17-05-2022",
  },
  {
    id: 4,
    name: "Войтенко Олег",
    usersCount: 1,
    type: ChatType.Personal,
    authorId: 40,
    previewUrl: default_avatar3,
    lastMessage: "Доброго дня, дякую!",
    lastMessageDate: "06-17-2015 14:24:36",
    createdAt: "17-05-2022",
  },
  {
    id: 5,
    name: "Bessie Cooper",
    usersCount: 1,
    type: ChatType.Personal,
    authorId: 40,
    previewUrl: default_avatar,
    lastMessage:
      "Amet minim mollit non deseruntullamco est sit aliqua dolor do...",
    lastMessageDate: "06-17-2015 14:24:36",
    createdAt: "17-05-2022",
  },
  {
    id: 6,
    name: "Анастасія Попова",
    usersCount: 1,
    type: ChatType.Personal,
    authorId: 40,
    previewUrl: default_avatar2,
    lastMessage: "Ок, зробиш, скинеш.",
    lastMessageDate: "06-17-2015 14:24:36",
    createdAt: "17-05-2022",
  },
];
