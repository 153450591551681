import { chatMembersService } from "@/services/domain";
import { ChatMemberRole, IChatMember } from "@/shared";
import { appEvents } from "@/shared/events";

interface IChangeRole {
  member: IChatMember;
  role: ChatMemberRole;
}

export const useChatMembers = () => {
  const changeMemberRole = async ({ member, role }: IChangeRole) => {
    if (member?.id) {
      await chatMembersService.changeRole({ memberId: member.id, role });
      appEvents.emit("onChangeMemberRole", { memberId: member.id, role });
    } else {
      console.log("Member didn't added! to list");
      // appEvents.emit('openInfoModal', {
      // 	title: 'Учасника ще не додано',
      // 	message: 'Спочатку збережіть нових учасників',
      // 	onPressOk: () => {},
      // })
    }
  };

  const deleteMember = async (member: IChatMember) => {
    if (member.id) {
      // видалення існуючого користувача чату
      await chatMembersService.deleteMember({ memberId: member.id });
      appEvents.emit("onDeleteMember", { memberId: member.id });
    } else {
      // видалення нового користувача, якого адміністратор вибрав для
      // додавання в учасники але ще не був збережений в базі
      console.log("Unselect added User by Admin");
      // simpleDispatch(new UnselectUser({ id: member.userId }))
    }
  };

  return {
    changeMemberRole,
    deleteMember,
  };
};
