import React from "react";
import _ from "lodash";

import {
  ISelectedRowsMenuItemConfig,
  IUser,
  usePaginationList,
} from "@/shared";
import { Table } from "@/components/TableGrid/Table";
import { usersTableColumns } from "../../configs";
import { getSelectColumn } from "@/components/TableGrid/configs";
import { CustomTableRow } from "@/components/TableGrid/components";

interface IProps {
  filter: any;
  factory: any;
  profile: IUser;
  showModal: boolean;
  selectedUsers: any[];
  paginationList: typeof usePaginationList.prototype;
  setSelectedUsers: (user: any) => void;
  focusFilterKey: string;
  setFocusFilterKey: (key: string) => void;
  contextMenuConfig: ISelectedRowsMenuItemConfig[];
  onOpenContextMenu: (isOpen: boolean, id: number) => void;
  allUsers: Partial<IUser>[];
}

export const UsersTableList = (props: IProps) => {
  const defaultColumnsActive = [
    "select-row",
    "avatarUrl",
    "name",
    "login",
    "email",
    "status",
    "factory",
    "loginOnDesktopDate",
    "loginOnMobileDate",
  ];

  const selectColumn = getSelectColumn({
    selected: props?.selectedUsers,
    onSelect: props?.setSelectedUsers,
  });

  const handleSelectAllColumnPress = () => {
    if (props.selectedUsers.length === props.allUsers?.length)
      props.setSelectedUsers([]);
    else if (props.allUsers?.length === 1) {
      console.log(props.paginationList);
      props.setSelectedUsers([props.paginationList.items[0]]);
    } else props.setSelectedUsers(props.allUsers);
  };

  return (
    <Table
      onRowClick={(data) => {}}
      tableName={"users"}
      columns={[selectColumn, ...usersTableColumns()]}
      paginationList={props.paginationList}
      activeColumns={defaultColumnsActive}
      selectedRows={props.selectedUsers?.map((it) => it.id)}
      // setSelectedRows={props.setSelectedUsersId}
      focusedFilterField={props.focusFilterKey}
      onFocusFilterField={(fieldKey: string) =>
        props.setFocusFilterKey(fieldKey)
      }
      tableProps={{
        rowRenderer: (data: any) => (
          <CustomTableRow
            rowData={data}
            menuConfig={props.contextMenuConfig}
            onOpenMenu={props.onOpenContextMenu}
          />
        ),
      }}
      allSelected={
        !_.isEmpty(props.selectedUsers) &&
        props.selectedUsers.length === props.allUsers?.length
      }
      selectAll={handleSelectAllColumnPress}
    />
  );
};
