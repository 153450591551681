import { errorsKey } from "./../../containers/Profile/config/errorsKey";
import {
  IChangeAvatarReq,
  IExistDataReq,
  IUpdatePasswordReq,
} from "./../../api/account/requests.interfaces";
import { IUser } from "@/shared";
import { simpleDispatch } from "@/store/store-helpers";
import { AvatarAccount, isLoading, SaveAccount } from "@/store/account";
import { accountApi } from "@/api";
import {
  IUpdateAccountReq,
  IVerificationUserName,
} from "@/api/account/requests.interfaces";

const getAccount = async () => {
  simpleDispatch(new isLoading({ isLoading: true }));
  try {
    const { data } = await accountApi.fetchAccount();
    // console.log(data);
    simpleDispatch(new SaveAccount({ account: data }));
    simpleDispatch(
      new AvatarAccount({
        avatarUrl: data.info.avatarUrl,
      })
    );
  } catch (e) {
    console.log(e);
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

const catchErrorAccount = (type) => {
  switch (type) {
    case errorsKey.email.key:
      return (errorsKey.email.exist = true);
    case errorsKey.phone_number.key:
      return (errorsKey.phone_number.exist = true);
    case errorsKey.personalPhone_number.key:
      return (errorsKey.personalPhone_number.exist = true);
    case errorsKey.login_exist.key:
      return (errorsKey.login_exist.exist = true);
    default:
      return (errorsKey.default_err = true);
  }
};

const updateAccount = async (
  params: IUpdateAccountReq,
  id: number,
  isMyAccount = false
) => {
  try {
    if (isMyAccount) {
      const resp = await accountApi.updateAccount(params);
      if (resp.status === 200) {
        getAccount();
      }
    } else await accountApi.updateUser(params, id);
  } catch (e) {
    console.log("errorProfileUser=>", e.response.data);
    return catchErrorAccount(e.response.data.key);
  }
};

const fetchExistUserData = async (params: IExistDataReq) => {
  try {
    const { data } = await accountApi.existUserData(params);

    // console.log("response existData", data);
    return data;
  } catch (e) {
    console.log("error existData", e.response.data);
  }
};
const existenceUserName = async (params: IVerificationUserName) => {
  try {
    const { data } = await accountApi.verificationUserName(params);
    // console.log("exist user name?", data);
    return data;
  } catch (e) {
    console.log("error exist name", e.response);
  }
};

const changeAvatarAccount = async (
  params: IChangeAvatarReq,
  avatar: any,
  authProfile: IUser
) => {
  try {
    if (params.userId === authProfile.id) {
      const resp = await accountApi.changeMyAvatar(params, avatar);
      simpleDispatch(
        new AvatarAccount({
          avatarUrl: resp.data.avatarUrl,
        })
      );
    } else await accountApi.changeAvatar(params, avatar);
  } catch (e) {
    return console.log("errorMessage", e.response);
  }
};

const deleteAvatarAccount = async (id?: number, isMyProfile?: boolean) => {
  try {
    if (isMyProfile) await accountApi.deleteMyAvatar();
    else await accountApi.deleteAvatar(id);
  } catch (e) {
    return console.log("errorMessage", e.response);
  }
};

const updatePassword = async (params: IUpdatePasswordReq) => {
  try {
    await accountApi.updatePassword(params);
    // console.log("data updatePassword", resp);
  } catch (e) {
    console.log("error updatePassword", e.response);
  }
};
export const accountService = {
  getAccount,
  updateAccount,
  existenceUserName,
  changeAvatarAccount,
  fetchExistUserData,
  updatePassword,
  deleteAvatarAccount,
};
