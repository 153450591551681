import { useEffect } from "react";
import { socketEvents, SocketEvents } from "../events";

export const useSocketListener = <T extends keyof SocketEvents>(
  name: T,
  action: (data: SocketEvents[T]) => void,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const fn = (data: SocketEvents[T]) => action(data);
    socketEvents.on(name, fn);

    return () => socketEvents.off(name, fn);
  }, dependencies);
};
