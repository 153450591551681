import React, { FC, useEffect } from "react";
import {
  createFullName,
  getParentsTree,
  InputField,
  notification,
  Option,
  transformFormValuesToPermissions,
  transformPermissionsToFormValues,
  usePaginationList,
} from "@/shared";
import { useFactory } from "@/containers/Factory/hooks";
import { Button } from "@/shared/components/buttons";
import { Controller, UseFormReturn } from "react-hook-form";
import "./style.scss";
import { usersApi } from "@/api";
import { permissionsGroupsService } from "@/services/domain";
import { PermissionsForm } from "@/containers/Permissions";
import { IGroupPermissionsForm } from "../../interfaces";
import {
  IFetchPermissionsGroupList,
  IUpdatePermissionsGroup,
} from "@/api/permissions-group/responses.interfaces";

interface CreateGroupPermissionsFormProps {
  form: UseFormReturn<IGroupPermissionsForm, any>;
  groupPermissionsToEdit: IFetchPermissionsGroupList["items"][0];
  toggleModal: (val: boolean) => void;
  onUpdateGroup: (item: IUpdatePermissionsGroup) => void;
  onCreateGroup: () => void;
}

export const CreateEditGroupPermissionsForm: FC<CreateGroupPermissionsFormProps> = ({
  form,
  groupPermissionsToEdit,
  toggleModal,
  onUpdateGroup,
  onCreateGroup,
}) => {
  const { factories } = useFactory();

  const userPaginationList = usePaginationList<Option>({
    fetchItems: usersApi.fetchUsersList,
    serrializatorItems: (items) =>
      items.map((it) => ({
        title: createFullName(
          it.info.firstName,
          it.info.middleName,
          it.info.lastName
        ),
        value: it.id,
      })),
  });

  useEffect(() => {
    if (groupPermissionsToEdit) {
      form.reset({
        groupName: groupPermissionsToEdit.name,
        permissions: transformPermissionsToFormValues(
          groupPermissionsToEdit.permissions
        ),
      });
    }
  }, [groupPermissionsToEdit]);

  const onSubmit = async (values: IGroupPermissionsForm) => {
    try {
      const permissionsToSave = transformFormValuesToPermissions(
        values.permissions
      );

      const preparedValues = {
        name: values.groupName,
        permissions: permissionsToSave,
      };

      if (groupPermissionsToEdit) {
        const editedItem = await permissionsGroupsService.updatePermissionsGroup(
          groupPermissionsToEdit.id,
          {
            name: preparedValues.name,
            permissions: preparedValues.permissions,
          }
        );
        onUpdateGroup(editedItem);
      } else
        await permissionsGroupsService.createPermissionsGroup(preparedValues);
      onCreateGroup();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось зберегти групу прав");
    } finally {
      form.reset();
      toggleModal(false);
    }
  };

  const parentCategoryTree = getParentsTree(factories, null);

  return (
    <form
      className="form"
      style={{ flexDirection: "column", padding: "20px 0 20px 0" }}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div style={{ marginBottom: 10 }}>
        <Controller
          name="groupName"
          control={form.control}
          rules={{ required: "Заповнити обов'язково" }}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputField
              placeholder={"Назва групи"}
              label={"Назва групи"}
              onChange={onChange}
              value={value}
              error={fieldState?.error?.message}
            />
          )}
        />
      </div>

      <div className="tab-permissions-container">
        <PermissionsForm
          form={form}
          userPaginationList={userPaginationList}
          factoriesTree={parentCategoryTree}
        />
      </div>

      <div className="btn-save">
        <Button onClick={form.handleSubmit(onSubmit)} style={{ width: 152 }}>
          Зберегти
        </Button>
      </div>
    </form>
  );
};
