import React, { FC, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { Dropdown, Menu, Tooltip } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { SearchInput } from "../atoms";
import { ConfirmModal } from "@/shared";
import { useChatsSettings } from "../hooks";
import NotePencilSvgIcon from "../../../assets/img/notepencil-icon.svg";
import gearIcon from "@/assets/img/gear-icon.svg";
import _ from "lodash";
import "./style.scss";

interface IProps {
  searchStr: string;
  setSearchStr: (str: string) => void;
  onClickCreate: () => void;
}

export const SearchChatsWithSettings: FC<IProps> = ({
  searchStr,
  setSearchStr,
  onClickCreate,
}) => {
  const [visible, setVisible] = useState(false);

  const showSettingMenu = () => setVisible((visible) => !visible);

  const { confirmModal, actions } = useChatsSettings();

  const linkMenu = () => {
    const items = {
      clearchats: {
        key: "clearchats",
        label: "Очистити повідомлення у всіх бесідах",
        onClick: () => actions.clearchats(),
      },
      deletechats: {
        key: "deletechats",
        label: "Видалити всі бесіди",
        onClick: () => actions.deletechats(),
      },
    };

    const optionsKeys = ["clearchats", "deletechats"];
    const options = optionsKeys.map((key) => items[key]);

    const menuItems = options.map((item) => ({
      ..._.pick(item, ["key", "label", "onClick"]),
      icon: item.iconNode ? item.iconNode : null,
    }));
    return (
      <Menu
        className="context-menu-chat-item"
        items={menuItems as ItemType[]}
      />
    );
  };

  return (
    <Row>
      <Col md={12}>
        <div className="chats-search-row">
          <ConfirmModal
            isShow={confirmModal.state.isOpen}
            message={confirmModal.state.message}
            onConfirm={confirmModal.state.onConfirm}
            setShow={(state: boolean) =>
              confirmModal.set({ ...confirmModal.state, isOpen: state })
            }
          />

          <SearchInput
            value={searchStr}
            placeholder="Знайдіть бесіду"
            onChange={setSearchStr}
          />
          <div className="settings-chats-container">
            <Tooltip
              title="Створити бесіду"
              key={"create-chat"}
              mouseEnterDelay={0.5}
            >
              <Button
                // type={props.showFilter ? "primary" : "default"}
                onClick={onClickCreate}
                className="create-chat-btn"
              >
                <div className="search-btn-icon">
                  <img src={NotePencilSvgIcon} style={{ width: 24 }} />
                </div>
              </Button>
            </Tooltip>

            <div className="settings-chats-item">
              <Dropdown
                visible={visible}
                overlay={linkMenu}
                onVisibleChange={showSettingMenu}
                trigger={[`contextMenu`]}
              >
                <Tooltip
                  title="Налаштування чатів"
                  key={"chats-settings"}
                  mouseEnterDelay={0.5}
                >
                  <Button
                    onClick={showSettingMenu}
                    className="chats-settings-btn"
                  >
                    <div className="setting-btn-icon">
                      <img src={gearIcon} style={{ width: 24 }} />
                    </div>
                  </Button>
                </Tooltip>
              </Dropdown>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
