import { CheckField } from "@/components/Fields";
import ModalComponent from "@/components/Modal";
import { Select, Spin } from "antd";
import React, { useCallback, useRef } from "react";
import { FC } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "reactstrap";
import { printModalOptions } from "../consts";
import { EPrintTasksMode } from "../enums";
import { usePrintTasks } from "../hooks";
import { PrintTasksItem } from "./print-tasks-item.component";

interface IProps {
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  onSubmit: () => void;
  selectedTasksIds: number[];
  searchString?: string;
}

export const PrintTasksModal: FC<IProps> = ({
  isOpen,
  setOpen,
  onSubmit,
  selectedTasksIds,
  searchString,
}) => {
  const {
    mode,
    setMode,
    withComments,
    setWithComments,
    loading,
    loadTasks,
    tasks,
  } = usePrintTasks();

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  const handleOnBeforeGetContent = useCallback(async () => {
    await loadTasks(selectedTasksIds, searchString);
  }, [selectedTasksIds, loadTasks, searchString]);

  const handleAfterPrint = useCallback(() => {
    onSubmit();
    setWithComments(false);
    setMode(EPrintTasksMode.Selected);
  }, [onSubmit, setWithComments, setMode]);

  React.useEffect(() => {
    if (tasks && typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, tasks]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button color="primary" className="submit-btn">
        Друкувати
      </Button>
    );
  }, []);

  const docContent = () => {
    if (mode === EPrintTasksMode.All || mode === EPrintTasksMode.Selected)
      return tasks.map((it) => <PrintTasksItem key={it.id} task={it} />);

    const groups = {};
    tasks.map((item) => {
      const executor = item.executorId ? item.executorId : "no_executor";
      if (groups[executor]) groups[executor].push(item);
      else groups[executor] = [item];
    });

    return Object.keys(groups).map((key, ind, arr) => {
      return (
        <div
          key={key + ind}
          style={ind < arr.length - 1 ? { pageBreakAfter: "always" } : null}
        >
          {groups[key].map((item, index) => (
            <PrintTasksItem key={item.id} task={item} hideUser={index != 0} />
          ))}
        </div>
      );
    });
  };

  return (
    <ModalComponent title="Друк" show={isOpen} toggle={() => setOpen(false)}>
      <Spin tip="Завантаження даних..." spinning={loading}>
        <div className="print-task-modal_content">
          <Select
            value={mode}
            showSearch
            className="select"
            optionFilterProp="children"
            allowClear
            onChange={setMode}
          >
            {Object.keys(printModalOptions).map((item) => (
              <Select.Option key={item} value={item} disabled={false}>
                {printModalOptions[item]}
              </Select.Option>
            ))}
          </Select>

          <CheckField
            label="Разом з коментарями"
            isChecked={withComments}
            onChange={(state: boolean) => {
              console.log("new checked state", state);
              setWithComments(state);
            }}
            input={{}}
            color={withComments ? "rgba(158, 39, 67, 1)" : null}
            disabled={undefined}
            className="input-checkbox"
            meta={{
              touched: undefined,
              error: undefined,
            }}
          />

          <div>
            <ReactToPrint
              content={reactToPrintContent}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />

            <div style={{ display: "none" }}>
              <div className="print-tasks-list" ref={componentRef}>
                {docContent()}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </ModalComponent>
  );
};
