import React, { FC, useEffect, useState } from "react";

import { IMessage } from "@/shared";
import { ChatItem } from "./chat-item.component";
import { ShowImageModal } from "../components";
import { useChatViewModeState } from "../hooks";
import { ChatViewModeEnum } from "../enums";

interface ChatItemImageProps extends IMessage {
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onForwardedAuthorPress?: (id: number) => void;
  onRepliedPress?: (id: number) => void;

  onForwardPressMessage?: () => void;
}

export const ChatItemImage: FC<ChatItemImageProps> = (props) => {
  const viewMode = useChatViewModeState((s) => s.mode);

  const [isOpenImageModal, setOpenImageModal] = useState<boolean>(false);

  const [imgHeight, setHeight] = useState(null);
  const [imgWidth, setWidth] = useState(null);

  const chatAreaSize = document.querySelector(".chat-field-area");
  const width = chatAreaSize?.clientWidth;
  const height = chatAreaSize?.clientHeight;

  const maxwidth = width * 0.5;
  const maxheigth = height * 0.7;

  const calcSize = (height: number, width: number) => {
    if (height > width) {
      const persent = maxheigth / height;
      return persent;
    } else {
      const persent = maxwidth / width;
      return persent;
    }
  };

  useEffect(() => {
    const imgUrl = props.content?.fileUrl;

    const img = new Image();
    img.src = imgUrl;

    img.onload = () => {
      const imageHeight = img.height;
      const imageWidth = img.width;

      const scale = calcSize(imageHeight, imageWidth);

      setHeight(imageHeight * scale);
      setWidth(imageWidth * scale);
    };
  }, [props.content?.fileUrl]);

  return (
    <>
      <ShowImageModal
        isShow={isOpenImageModal}
        setOpen={setOpenImageModal}
        imageSrc={props?.content?.fileUrl}
      />
      <ChatItem {...props}>
        {imgHeight && (
          <div
            className="chat-item-image-container"
            onClick={() => {
              if (viewMode === ChatViewModeEnum.DEFAULT)
                setOpenImageModal(true);
            }}
          >
            <img
              src={props.content?.fileUrl || null}
              className={props.isMy ? "myImage" : null}
              style={{ width: imgWidth, height: imgHeight }}
            />
          </div>
        )}
      </ChatItem>
    </>
  );
};
