import { CheckField, DateField } from "@/components/Fields";
import {
  TaxonomySelectWithSearch,
  UserSelectWithSearch,
} from "@/components/SmartComponents";
import {
  ETaskStatus,
  ITaskFilter,
  SelectField,
  Option,
  EUsersListType,
  ETaxonomyType,
} from "@/shared";
import { simpleDispatch } from "@/store/store-helpers";
import { getTaskFilter, SetTaskFilter } from "@/store/task";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import "./style.scss";

const FilterTask: FC = () => {
  const filter = useSelector(getTaskFilter);
  // console.log("i render filter task", filter);
  const onChange = (key: keyof ITaskFilter, value: any) => {
    simpleDispatch(new SetTaskFilter({ filter: { ...filter, [key]: value } }));
  };

  return (
    <div className="filter-task">
      <div className="filter">
        <Field
          name="isStar"
          className="filter"
          label="Відмічені зірочкою"
          component={CheckField}
          color={filter.isStar && "#9e2743"}
          input={{
            value: filter.isStar,
            onChange: (val: boolean) => onChange("isStar", val),
          }}
        />

        <Field
          name="isMy"
          className="filter"
          label="Власні"
          component={CheckField}
          color={filter.isMy && "#9e2743"}
          input={{
            value: filter.isMy,
            onChange: (val: boolean) => onChange("isMy", val),
          }}
        />

        <Field
          name="isOverdue"
          label="Прострочені"
          component={CheckField}
          color={filter.isOverdue && "#9e2743"}
          input={{
            value: filter.isOverdue,
            onChange: (val: boolean) => onChange("isOverdue", val),
          }}
        />

        <Field
          name="status"
          label="Статус"
          options={[
            { title: "Активні", value: ETaskStatus.Active },
            { title: "Виконані", value: ETaskStatus.Finished },
            { title: "Видалені", value: ETaskStatus.Deleted },
          ]}
          component={SelectField}
          input={{
            value: filter.status,
            onChange: (val: string) => onChange("status", val),
          }}
        />

        <Field
          name="executor"
          label="Виконавець"
          component={UserSelectWithSearch}
          type={EUsersListType.ExecutorsAll}
          input={{
            value: filter.executor,
            onChange: (val: Option) => onChange("executor", val),
          }}
        />

        <Field
          name="creator"
          label="Хто створив"
          component={UserSelectWithSearch}
          input={{
            value: filter.creator,
            onChange: (val: string) => onChange("creator", val),
          }}
        />

        <Field
          name="group"
          label="Група"
          component={TaxonomySelectWithSearch}
          type={ETaxonomyType.taskCategory}
          mode="multiple"
          input={{
            value: filter.group,
            onChange: (val: string) => onChange("group", val),
          }}
        />

        <Field
          name="date"
          label="Задачі на дату"
          component={DateField}
          input={{
            value: filter.date,
            onChange: (val: string) => {
              if (!val) onChange("date", new Date());
              else onChange("date", val);
            },
            allowClear: true,
          }}
        />

        <Field
          name="startDate"
          label="Задачі з"
          component={DateField}
          input={{
            value: filter.startDate,
            onChange: (val: string) => onChange("startDate", val),
            allowClear: true,
          }}
        />

        <Field
          name="endDate"
          label="Задачі до"
          component={DateField}
          input={{
            value: filter.endDate,
            onChange: (val: string) => onChange("endDate", val),
            allowClear: true,
          }}
        />
      </div>
    </div>
  );
};

export default reduxForm({
  form: "filter_task",
})(FilterTask);
