import React, { FC } from "react";
interface IProps {
  btnTitle: string;
  deleteChat: () => void;
}
export const DeleteChatButton: FC<IProps> = ({ btnTitle, deleteChat }) => {
  return (
    <div className="delete-chat-btn">
      <button onClick={deleteChat}>
        <p>{btnTitle}</p>
      </button>
    </div>
  );
};
