import React, { FC } from "react";
import TrashSvgIcon from "@/assets/img/trash-icon.svg";
import DownloadIcon from "@/assets/img/icon-download-file.svg";
import { getIconNameByExtension, IconComponent } from "@/shared";
import "./style.scss";
import { Tooltip } from "antd";

interface IProps {
  title: string;
  date?: string;
  onDelete?: () => void;
  onDownload?: () => void;
}

export const FileItem: FC<IProps> = ({ title, date, onDelete, onDownload }) => {
  const iconName = getIconNameByExtension(title);

  return (
    <div className="file-item">
      <div className="file-item_icon-container">
        <IconComponent className="file-item_icon" name={iconName} />
      </div>
      <div className="file-item_text-block">
        <p className="file-item_title">{title.replace(/_/g, " ")}</p>
        <p>{date}</p>
      </div>
      <div className="file-item_icons-block">
        {onDownload && (
          <Tooltip title="Зберегти файл">
            <div>
              <IconComponent
                className="file-item_small-icon"
                name={DownloadIcon}
                onClick={onDownload}
              />
            </div>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title="Видалити файл">
            <div>
              <IconComponent
                className="file-item_small-icon"
                name={TrashSvgIcon}
                onClick={onDelete}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
