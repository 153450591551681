import { simpleDispatch } from "@/store/store-helpers";
import {
  isLoading,
  SetAvailablePermissions,
  SetMyPermissions
} from "@/store/permissions";
import { permissionsApi } from "@/api";

export const loadAvailablePermissions = async () => {
  try {
    simpleDispatch(new isLoading({ isLoading: true }));

    const { data } = await permissionsApi.fetchAvailablePermissions();
    simpleDispatch(new SetAvailablePermissions({ data }));
  } catch (error) {
    console.log("GET AVAILABLE PERMISSIONS ERROR", error);
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

export const loadMyPermissions = async () => {
  try {
    simpleDispatch(new isLoading({ isLoading: true }));

    const { data } = await permissionsApi.fetchMyPermissionsTransformed();
    simpleDispatch(new SetMyPermissions({ data }));
  } catch (error) {
    console.log("GET MY PERMISSIONS ERROR", error);
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

export const permissionsService = {
  loadAvailablePermissions,
  loadMyPermissions
};
