import React, { FC } from "react";
import { Row, Col, Button } from "reactstrap";
import {
  ISelectedRowsMenuItemConfig,
  SearchInput,
  SelectedRowsMenu,
} from "@/shared";
import "./style.scss";
import _ from "lodash";
import { SelectedTasksDropdown } from "../atoms";

interface IProps {
  searchStr: string;
  setSearchStr: (str: string) => void;
  onClickCreate: () => void;
  selectedRows: any;
  isInputFocused: boolean;
  onInputFocus: () => void;
  tasksMenuConfig: ISelectedRowsMenuItemConfig[];
  configSelected: any;
}

export const TasksHeader: FC<IProps> = ({
  searchStr,
  setSearchStr,
  onClickCreate,
  selectedRows,
  isInputFocused,
  onInputFocus,
  tasksMenuConfig,
  configSelected,
}) => {
  return (
    <Row>
      <Col md={12}>
        <div className="task-header">
          {!_.isEmpty(selectedRows) ? (
            <div style={{ display: "flex" }}>
              <SelectedTasksDropdown
                configSelected={configSelected}
                selected={selectedRows.length}
              />
              <SelectedRowsMenu
                blockClassName="selected-tasks-menu"
                itemsConfig={tasksMenuConfig}
              />
            </div>
          ) : (
            <div style={{ height: 30 }}></div>
          )}

          <div style={{ display: "flex" }}>
            <SearchInput
              value={searchStr}
              style={{ width: 235, height: 46 }}
              placeholder="Знайдіть задачу"
              onChange={setSearchStr}
              onFocus={onInputFocus}
              isFocused={isInputFocused}
            />

            <Button
              style={{ fontSize: 16 }}
              color="primary"
              className="btn btn-primary create-btn"
              onClick={onClickCreate}
            >
               Створити
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
