import {
  IAddNewMember,
  IChangeMemberRole,
  IDeleteMember,
} from "@/api/chats-members/request.interfaces";
import { chatMembersApi } from "@/api/chats-members/requests";
import { appEvents } from "@/shared/events";
import { SetUnreadMessagesCount } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";

const deleteMember = async (
  params: IDeleteMember,
  unreadMessagesCount?: number
) => {
  try {
    await chatMembersApi.deleteMemeberReq(params);

    if (params.chatId)
      appEvents.emit("onDeleteChatForMe", { chatId: params.chatId });

    if (params.chatId && unreadMessagesCount > 0)
      appEvents.emit("decreaseCounter", {
        count: unreadMessagesCount,
      });
  } catch (err) {
    console.log(err);
  }
};

const changeRole = async (params: IChangeMemberRole): Promise<void> => {
  try {
    await chatMembersApi.changeMemberRoleReq(params);
  } catch (err) {
    console.log(err);
  }
};

const deleteFromAllChats = async () => {
  try {
    await chatMembersApi.deleteMemeberReq({ deleteAll: true });

    simpleDispatch(new SetUnreadMessagesCount(0));
    appEvents.emit("onDeleteAllChats", {});
  } catch (error) {
    console.log("ERROR ON DELETE CHATS", error);
  }
};

const addMember = async (params: IAddNewMember): Promise<void> => {
  try {
    await chatMembersApi.addNewMemberReq(params);
  } catch (err) {
    console.log(err);
  }
};

export const chatMembersService = {
  addMember,
  deleteMember,
  deleteFromAllChats,
  changeRole,
};
