import _ from "lodash";
import { FileType } from "../enums";
import { IFile } from "../interfaces";
import DOCIcon from "@/assets/img/DOC.svg";
import JPGIcon from "@/assets/img/JPG.svg";
import GIFIcon from "@/assets/img/GIF.svg";
import JPEGIcon from "@/assets/img/JPEG.svg";
import MP3Icon from "@/assets/img/MP3.svg";
import MP4Icon from "@/assets/img/MP4.svg";
import PDFIcon from "@/assets/img/PDF.svg";
import PNGIcon from "@/assets/img/PNG.svg";
import SVGIcon from "@/assets/img/SVG.svg";
import TXTIcon from "@/assets/img/TXT.svg";
import XLSIcon from "@/assets/img/XLS.svg";
import ZIPIcon from "@/assets/img/ZIP.svg";
import OtherIcon from "@/assets/img/Others.svg";

export const MAX_FILE_SIZE = 50000000;
export const MAX_VIDEO_SIZE = 100000000;

const iconByFileType = {
  [FileType.DOC]: DOCIcon,
  [FileType.JPG]: JPGIcon,
  [FileType.GIF]: GIFIcon,
  [FileType.JPEG]: JPEGIcon,
  [FileType.MP3]: MP3Icon,
  [FileType.MP4]: MP4Icon,
  [FileType.PDF]: PDFIcon,
  [FileType.PNG]: PNGIcon,
  [FileType.SVG]: SVGIcon,
  [FileType.TXT]: TXTIcon,
  [FileType.XLS]: XLSIcon,
  [FileType.ZIP]: ZIPIcon
};

export const runActionByType = (
  file: IFile,
  actions: Record<string, () => Promise<void>>
) => {
  const keys = Object.keys(actions);
  const key = keys.find(key => {
    return file.type.includes(key);
  });
  return actions[key]();
};

export const getNameFromFileUrl = (url: string) => {
  if (!url) return "";
  const splitUrl = url.split("/");

  if (splitUrl.length === 1) return url;
  const dirtyName = splitUrl[splitUrl.length - 1];
  const clearName = dirtyName.split(".");
  clearName.shift();

  if (clearName.length > 0) return clearName.join(".");

  return dirtyName;
};

export const prepareFormData = (file: IFile, formData: FormData) => {
  const filesData = {
    name: file.name,
    width: file?.width,
    height: file?.height
  };

  formData.append("files", file as any);
  formData.append("filesData", JSON.stringify(filesData));
};

export const checkFileSize = (
  file: IFile,
  fileLimit: number,
  videoLimit: number
) => {
  let allowed: IFile;
  let exceeded: IFile;

  if (
    (file.type.includes("video") && file.size > videoLimit) ||
    (!file.type.includes("video") && file.size > fileLimit)
  )
    exceeded = file;
  else allowed = file;

  return { allowed, exceeded };
};

export const alertFileSizeExceeded = (file: IFile) => {
  const name = file.name;

  const alertMessage = `Розмір файла ${name} перевищує допустимий`;

  console.log(`Не вдалось відправити ${name}`, alertMessage);
};

export const getFileExtension = (name: string) => name.split(".").pop();

export const getFileType = (name: string) => {
  if (!name) return null;

  const extension = getFileExtension(name);
  if (_.isEmpty(extension)) return null;

  if (Object.values(FileType).includes(extension.toLowerCase() as FileType))
    return extension.toLowerCase() as FileType;

  return null;
};

export const getIconNameByExtension = (name: string) => {
  const fileType = getFileType(name);
  const iconByType = iconByFileType[fileType];
  if (iconByType) return iconByType;
  return OtherIcon;
};

export const checkTaskFilesSize = (files: IFile[], limit: number) => {
  const allowed = [];
  const exceeded = [];

  files.map(file => {
    if (file.size > limit) exceeded.push(file);
    else allowed.push(file);
  });

  return {
    allowed,
    exceeded
  };
};
