import React, { FC } from "react";
interface IProps {
  btnTitle: string;
  onPressSetting: () => void;
  isSave: boolean;
}
export const SettingsChatButton: FC<IProps> = ({
  isSave,
  btnTitle,
  onPressSetting,
}) => {
  return (
    <div className={isSave ? "save-chat-btn" : "cancel-chat-btn"}>
      <button onClick={onPressSetting}>
        <p>{btnTitle}</p>
      </button>
    </div>
  );
};
