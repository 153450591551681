import cameraIcon from "@/assets/img/attchmenu-camera-icon.svg";
import galleryIcon from "@/assets/img/attchmenu-gallery-icon.svg";
import documentIcon from "@/assets/img/attchmenu-document-icon.svg";
import smileIcon from "@/assets/img/Smiley.svg";

export const attachmentsMenuConfig = [
  {
    title: "Зробити фото",
    name: "takePhoto",
    icon: cameraIcon,
    type: "webcam",
  },
  {
    title: "Галерея",
    name: "gallery",
    icon: galleryIcon,
    type: "image/*, video/*",
  },
  {
    title: "Додати файл",
    name: "file",
    icon: documentIcon,
    type: "file",
  },
  {
    title: "Стікер",
    name: "sticker",
    icon: smileIcon,
    type: "sticker",
  },
];
