import { IFactory, Option, SelectField, SelectMultiField } from "@/shared";
import React, { FC } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import _ from "lodash";
import { TreeSelectField } from "@/components/Fields";
import { PermissionsSection } from "../../enums";
import { UserSelectWithSearch } from "@/components/SmartComponents";
import classNames from "classnames";

interface FormPermissionsItemProps {
  values: any;
  index: number;
  isLoadMoreTargetOptions: boolean;
  onChange: (key: string, val: any) => void;
  onLoadMore: () => void;
  options: {
    section: Option[];
    target: Option[] | IFactory[];
    actions: Option[];
  };
  targetTitle: string;
  onDelete: () => void;
  targetTotalLength?: number;
}

export const FormPermissionsItem: FC<FormPermissionsItemProps> = ({
  values,
  isLoadMoreTargetOptions,
  onChange,
  onLoadMore,
  options,
  targetTitle,
  onDelete,
  targetTotalLength,
}) => {
  const selectAllActions = () => {
    if (_.isEmpty(options.actions)) return;
    const actions = options.actions.map((it) => it.value);
    onChange(
      "permissions",
      _.filter(actions, (it) => it !== "not_permission")
    );
  };

  const renderTargetField = () => {
    if (!values.section || !options.target) return null;

    if (values.section === PermissionsSection.FACTORY)
      return (
        <div className="select_container">
          <TreeSelectField
            register={{
              onChange: (val) => {
                onChange("target", val);
                if (
                  !_.isEmpty(values.permissions) &&
                  values.section === PermissionsSection.TABS
                )
                  onChange("permissions", []);
              },
              value: values.target,
              // , onBlur, ref
            }}
            label={targetTitle}
            tree={options.target ? options.target : []}
            touched={true}
          />
        </div>
      );

    if (values.section === PermissionsSection.USER)
      return (
        <div className="select_container">
          <UserSelectWithSearch
            onChange={(val) => {
              onChange("target", val);
              if (
                !_.isEmpty(
                  values.permissions &&
                    values.section === PermissionsSection.TABS
                )
              )
                onChange("permissions", []);
            }}
            value={values.target}
            defaultOptions={options.target ? (options.target as Option[]) : []}
            defaultCount={targetTotalLength}
            label={"Виберіть користувача"}
          />
        </div>
      );

    return (
      <div className="select_container">
        <SelectField
          value={values.target}
          label={targetTitle}
          type={isLoadMoreTargetOptions ? "loadMore" : "default"}
          isDisabled={!values?.section}
          loadMore={onLoadMore}
          options={options.target ? options.target : []}
          onChange={(val) => {
            onChange("target", val);
            if (
              !_.isEmpty(
                values.permissions && values.section === PermissionsSection.TABS
              )
            )
              onChange("permissions", []);
          }}
        />
      </div>
    );
  };

  return (
    <div className="permissions-form-item_container">
      <div style={{ width: "100%" }}>
        <div className="select-target_container">
          <div
            className={classNames({
              select_container: true,
              large: values.section === PermissionsSection.FILTER_FACTORY,
            })}
          >
            <SelectField
              value={values.section}
              label={"Розділ"}
              options={options.section}
              onChange={(val) => {
                onChange("section", val);
                if (!_.isEmpty(values.target)) onChange("target", null);
                if (!_.isEmpty(values.permissions)) onChange("permissions", []);
              }}
            />
          </div>

          {renderTargetField()}
        </div>
        {values["target"] && (
          <div className="multi-select">
            <SelectMultiField
              value={values.permissions}
              allowClear={false}
              label="Дії"
              options={options.actions}
              onChange={(val) => {
                onChange("permissions", val);
              }}
              onPressRightBtn={selectAllActions}
            />
          </div>
        )}
      </div>
      <Button type="link" onClick={onDelete}>
        <CloseOutlined style={{ fontSize: "14px", color: "red" }} />
      </Button>
    </div>
  );
};
