import React, { FC } from "react";

import { IMessage } from "@/shared";
import { ChatItem } from "./chat-item.component";
import { stickersList } from "@/config/stickers.config";

interface ChatItemImageProps extends IMessage {
  onMenuPress?: () => void;
  onProfilePress?: () => void;
  onForwardedAuthorPress?: (id: number) => void;
  onRepliedPress?: (id: number) => void;

  onForwardPressMessage?: () => void;
}

export const ChatItemSticker: FC<ChatItemImageProps> = (props) => {
  return (
    <>
      <ChatItem {...props} hideBackground={true}>
        <img
          src={stickersList[props.content?.sticker]}
          className={props.isMy ? "myImage" : null}
          style={{ width: 200, height: 200, objectFit: "contain" }}
        />
      </ChatItem>
    </>
  );
};
