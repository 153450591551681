import { Option } from "@/shared/interfaces";
import { Button, Select, SelectProps } from "antd";
import React, { FC, useRef, useState } from "react";
import "./styles.scss";

interface SelectMultiplyProps {
  value: any;
  label?: string;
  placeholder?: string;
  options: Option[] | any;
  defaultValue?: Option[];
  isShowRightBtn?: boolean;
  touched?: boolean;
  style?: React.CSSProperties;
  error?: string;
  input?: any;
  allowClear?: boolean;
  onChange: (val: any) => void;
  onPressRightBtn?: () => void;
  selectProps?: SelectProps;
}

export const SelectMultiField: FC<SelectMultiplyProps> = ({
  value,
  label,
  placeholder,
  options,
  touched,
  error,
  defaultValue,
  isShowRightBtn = true,
  input,
  style,
  onChange,
  allowClear = true,
  onPressRightBtn,
  selectProps,
}) => {
  // const selectRef = useRef<Select<Option[]>>(null);

  return (
    <div
      className="form__form-group select-field select-multiply-field"
      style={style}
    >
      {label && <span className="label">{label}</span>}

      <div className="field-wrap">
        <Select
          value={value}
          showSearch
          // ref={(ref) => (selectRef.current = ref)}
          showArrow={!input?.value || !input?.value.length}
          className="select"
          defaultValue={defaultValue}
          placeholder={placeholder}
          optionFilterProp="children"
          mode="multiple"
          onChange={onChange}
          allowClear={allowClear}
          filterOption={(input, option) =>
            ((option!.children as unknown) as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          {...selectProps}
        >
          {options.map((item, index) => (
            <Select.Option key={item.value + "-" + index} value={item.value}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
        {isShowRightBtn && (
          <Button onClick={onPressRightBtn} className="open-select-btn">
            <i className="fa fa-bars icon arrow-down" />
          </Button>
        )}
      </div>

      {touched && error && <span className="error">{error}</span>}
    </div>
  );
};
