import React from "react";
import { Controller, useForm } from "react-hook-form";
import { PasswordField, LoginField } from "./fields";
import { authService } from "@/services/domain";
import { CheckBoxForm } from "@/shared/components";

interface ISignInForm {
  login: string;
  password: string;
  rememberMe: boolean;
}

export const SignInForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISignInForm>();

  const onSubmit = (data: ISignInForm) => {
    authService.signIn(data);
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <LoginField
        register={register("login", { required: "Заповнити обов'язково" })}
        error={errors?.login?.message}
      />
      <PasswordField
        register={register("password", { required: "Заповнити обов'язково" })}
        error={errors?.password?.message}
      />

      <div className="form__form-group">
        <div className="form__form-group-field-remember">
          <Controller
            control={control}
            name={"rememberMe"}
            defaultValue={false}
            render={({ field }) => (
              <CheckBoxForm label={"Запам’ятати"} field={field} />
            )}
          />
        </div>
      </div>
      <button className="btn btn-primary btn-auth saccount__btn account__btn--small">
        Вхід
      </button>
    </form>
  );
};
