import React, { FC, useMemo } from "react";
import { Nav } from "reactstrap";
import { ETaskModalMode, ETasksModalTabs } from "../enums";
import { ModalTab } from "../atoms";
import { tabsTitles } from "../configs";
import "./style.scss";

interface IProps {
  type: ETaskModalMode;
  activeTab: ETasksModalTabs;
  onToggleTab: (tab: ETasksModalTabs) => void;
}

export const ModalTabs: FC<IProps> = ({ type, activeTab, onToggleTab }) => {
  const tabsToRender = useMemo(() => {
    const preparedTabs = Object.entries(ETasksModalTabs)
      .filter(([_, tabNum]) => {
        const mainCondition = typeof tabNum === "number";

        if (
          mainCondition &&
          (type === ETaskModalMode.Create || type === ETaskModalMode.Copy)
        )
          return (
            tabNum !== ETasksModalTabs.Comments &&
            tabNum !== ETasksModalTabs.TaskDetails
          );

        if (mainCondition && type === ETaskModalMode.Update)
          return tabNum !== ETasksModalTabs.TaskDetails;

        if (mainCondition && type === ETaskModalMode.Watch)
          return tabNum !== ETasksModalTabs.Tasks;

        return mainCondition;
      })
      .map(([tabKey, tabNum]) => (
        <ModalTab
          key={`${tabKey}-${tabNum}`}
          title={tabsTitles[tabNum]}
          isActive={activeTab === tabNum}
          onToggleTab={() => onToggleTab(ETasksModalTabs[tabKey])}
        />
      ));

    return preparedTabs;
  }, [activeTab, type, onToggleTab]);

  return (
    <div className="tabs tabs--justify tabs--bordered-top modal-tabs">
      <div className="tabs__wrap">
        <Nav tabs>{tabsToRender}</Nav>
      </div>
    </div>
  );
};
