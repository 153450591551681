import { usersApi } from "@/api";
import { ICreateUserPayloadReq } from "@/api/users/requests.interfaces";

export interface ICreateUserPayload extends ICreateUserPayloadReq {}

const createUser = async (createUserPayload: ICreateUserPayload) => {
  try {
    const { data } = await usersApi.createUser(createUserPayload);
    return data;
  } catch (error) {
    console.log("CREATE USER ERROR", error);
    throw error;
  } finally {
  }
};

const fetchUserStats = async (userId: number) => {
  try {
    const { data } = await usersApi.fetchUserStats(userId);
    return data;
  } catch (error) {
    console.log("FETCH USER STATS ERROR", error);
  } finally {
  }
};

export const usersService = {
  createUser,
  fetchUserStats,
};
