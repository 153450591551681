import { ChatBgKeys } from "@/shared";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TChatBgActions } from "./types";

export interface IChatBgState {
  currentBgId: ChatBgKeys;
}

const initialState: IChatBgState = {
  currentBgId: ChatBgKeys.DEFAULT,
};

export const chatBgReducer = createReducer<IChatBgState, TChatBgActions>(
  initialState,
  {
    SET_CHAT_BG: (_, action) => {
      return { currentBgId: action.payload.bgId };
    },
  }
);
