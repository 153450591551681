export enum ChatMessageActionEnum {
  COPY,
  FORWARD,
  REPLY,
  PIN,
  UNPIN,
  DELETE,
  DELETE_FOR_ALL,
  DOWNLOAD,
  EDIT,
  SELECT,
}
