import React from "react";
import { useHistory } from "react-router-dom";
import { PasswordRecoveryForm } from "../components";

import logo from "@/assets/img/logo_rws.png";

export const RecoverPassword = () => {
  let history = useHistory();

  function handleClick() {
    history.push("/");
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div
            className="account__head"
            style={{ border: "none", paddingLeft: 0 }}
          >
            <h3 className="account__title">
              <span className="account__logo account__logo_clique">
                <img
                  style={{ width: "145px" }}
                  src={logo}
                  onClick={handleClick}
                />
              </span>
            </h3>
            <h4 className="account__subhead subhead" />
          </div>

          <PasswordRecoveryForm />
        </div>
      </div>
    </div>
  );
};
