import { tasksApi } from "@/api";
import { ITask, notification, usePaginationList } from "@/shared";
import { getProfile } from "@/store/account/selectors";
import { getTaskFilter } from "@/store/task";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTasksListEvents } from "./use-tasks-list-events.hook";

export const useTasksList = () => {
  const filter = useSelector(getTaskFilter);
  const account = useSelector(getProfile);
  const [selectedTasks, setSelectedTasks] = useState<Partial<ITask>[]>([]);

  const paginationList = usePaginationList<ITask>({
    fetchItems: tasksApi.fetchTasks,
    loadParams: {
      limit: 10,
      page: 1,
      sortField: "id",
      status: "active",
      targetDate: new Date(),
    },
  });

  useEffect(() => {
    const loadParams = {
      isFavorite: filter?.isStar,
      isOutdate: filter?.isOverdue,
      status: filter?.status,
      executorId: filter?.isMy ? account.id : filter?.executor,
      authorId: filter?.isMy ? account.id : filter?.creator,
      groupsIds: !_.isEmpty(filter?.group) ? filter?.group : null,
      targetDate: filter?.date
        ? moment(filter?.date).format("MM-DD-YYYY")
        : null,
      startDateFrom: filter?.startDate
        ? moment(filter?.startDate).format("MM-DD-YYYY")
        : null,
      startDateTo: filter?.endDate
        ? moment(filter?.endDate).format("MM-DD-YYYY")
        : null,
    };

    paginationList.setLoadParams(loadParams);
    setSelectedTasks([]);
  }, [filter, account.id]);

  useTasksListEvents({
    items: paginationList.items,
    onReload: () => paginationList.resetFlatList(),
    setItems: (items: ITask[]) => paginationList._setItems(items),
    // onAction,
    taskFilterStatus: filter?.status,
  });

  const resetData = () => {
    paginationList.resetFlatList();
    setSelectedTasks([]);
  };

  const finishTasks = async (doneDate: string) => {
    try {
      await tasksApi.finishTasks({
        taskIds: selectedTasks.map((it) => it.id),
        doneDate,
      });

      resetData();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось зберегти зміни");
    }
  };

  const reassignTasks = async (executorsIds: number[]) => {
    try {
      await tasksApi.reassignTask({
        tasksIds: selectedTasks.map((it) => it.id),
        executorsIds,
      });

      resetData();
    } catch (e) {
      notification.showError(
        "Помилка",
        `Не вдалось перепоставити ${
          selectedTasks.length > 1 ? "задачі" : "задачу"
        }`
      );
    }
  };

  const removeTasks = async () => {
    try {
      await tasksApi.removeTasks(selectedTasks.map((it) => it.id));
      resetData();
    } catch (e) {
      notification.showError(
        "Помилка",
        `Не вдалось видалити ${selectedTasks.length > 1 ? "задачі" : "задачу"}`
      );
    }
  };

  const hardDeleteTasks = async () => {
    try {
      await tasksApi.hardDeleteTasks(selectedTasks.map((it) => it.id));
      resetData();
    } catch (e) {
      notification.showError(
        "Помилка",
        `Не вдалось видалити ${selectedTasks.length > 1 ? "задачі" : "задачу"}`
      );
    }
  };

  return {
    paginationList,
    selectedTasks,
    setSelectedTasks,
    tasksActions: {
      finishTasks,
      reassignTasks,
      removeTasks,
      hardDeleteTasks,
    },
    filter,
    allIds: paginationList.data?.map((it) => it.id),
  };
};
