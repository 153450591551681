import React, { FC, useEffect } from "react";
import { ButtonToolbar, Button } from "reactstrap";
import _ from "lodash";
import { IFactory, InputField, SelectMultiField } from "@/shared";
import { Controller, useForm } from "react-hook-form";
import { TreeSelectField } from "@/components/Fields";
import { genTree } from "@/lib/helper";
import {
  ICreateFactory,
  IUpdateFactory,
} from "@/api/factories/requests.interface";
import { UserSelectWithSearch } from "@/components/SmartComponents";
import { IFactoryForm } from "../../interfaces";
import { defaultFormValues } from "../../consts";

interface IProps {
  selectedFactory: IFactory;
  factories: IFactory[];
  updateFactory: (factory: IUpdateFactory) => void;
  createFactory: (factory: ICreateFactory) => void;
  closeModal: () => void;
  editable: boolean;
}

export const FormFactory: FC<IProps> = ({
  selectedFactory,
  factories,
  updateFactory,
  createFactory,
  closeModal,
  editable,
}) => {
  const { control, handleSubmit, reset } = useForm<IFactoryForm>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (selectedFactory) {
      reset({
        name: selectedFactory.name,
        shortName: selectedFactory.shortName,
        directorId: selectedFactory.directorId?.toString(),
        parentId: selectedFactory.parentId,
        permissions: JSON.parse(selectedFactory?.permissions),
      });
    }
  }, [selectedFactory, reset]);

  const filterParentsTree = (
    factoriesArr: IFactory[],
    selectedFactory: IFactory
  ) => {
    const factories = _.cloneDeep(factoriesArr);
    const filteredFactories = _.filter(
      factories,
      (it) => it.id !== selectedFactory.id
    );

    for (let i = 0; i < filteredFactories.length; i++) {
      if (filteredFactories[i].children.length) {
        filteredFactories[i].children = filterParentsTree(
          filteredFactories[i].children,
          selectedFactory
        );
      }
    }

    return filteredFactories;
  };

  const getParentsTree = () => {
    let parentsTree: any;

    if (selectedFactory) {
      const filteredFactories = filterParentsTree(factories, selectedFactory);

      parentsTree = genTree(
        filteredFactories,
        undefined,
        undefined,
        "parentId"
      );
    } else {
      parentsTree = genTree(factories, undefined, undefined, "parentId");
    }

    return parentsTree;
  };

  const submit = (values) => {
    if (selectedFactory) {
      updateFactory({ ...values, id: selectedFactory.id });
    } else {
      createFactory({ ...values });
    }
    closeModal();
  };

  const parentCategoryTree = getParentsTree();

  return (
    <form
      className="form"
      style={{ marginBottom: 30, paddingTop: 20 }}
      onSubmit={handleSubmit(submit)}
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: "Заповнити обов'язково" }}
        render={({ field: { value, onChange }, fieldState }) => (
          <InputField
            placeholder={"Назва"}
            label={"Назва"}
            onChange={onChange}
            value={value}
            style={{ marginBottom: 10 }}
            inputStyle={{ color: "#212529" }}
            error={fieldState?.error?.message}
            disabled={!editable}
          />
        )}
      />

      <Controller
        name="shortName"
        control={control}
        render={({ field: { value, onChange } }) => (
          <InputField
            placeholder={"Коротка назва"}
            label={"Коротка назва"}
            onChange={onChange}
            value={value}
            style={{ marginBottom: 10 }}
            inputStyle={{ color: "#212529" }}
            disabled={!editable}
          />
        )}
      />

      <Controller
        name="parentId"
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <div style={{ width: "100%", marginBottom: 20 }}>
              <TreeSelectField
                register={{
                  onChange: (val) => {
                    if (!val) onChange(null);
                    else onChange(val);
                  },
                  onBlur,
                  ref,
                  value,
                  disabled: !editable,
                }}
                label="Батьківська категорія"
                placeholder="Батьківська категорія"
                tree={parentCategoryTree}
              />
            </div>
          );
        }}
      />

      <Controller
        name="directorId"
        control={control}
        render={({ field: { value, onChange }, fieldState }) => (
          <div style={{ marginBottom: 20, width: "100%" }}>
            <UserSelectWithSearch
              onChange={onChange}
              value={value}
              label={"Директор"}
              selectProps={{
                placeholder: "Директор",
                disabled: !editable,
              }}
              error={fieldState?.error?.message}
            />
          </div>
        )}
      />

      <Controller
        name="permissions"
        control={control}
        render={({ field: { value, onChange } }) => (
          <div style={{ marginBottom: 30, width: "100%" }}>
            <SelectMultiField
              value={value}
              onChange={onChange}
              isShowRightBtn={false}
              allowClear={false}
              label="Права доступу на підприємство"
              placeholder="Права доступу на підприємство"
              options={[
                { title: "Перегляд задач", value: "find" },
                { title: "Створення задач", value: "create" },
                { title: "Редагування задач", value: "update" },
                { title: "Видалення задач", value: "destroy" },
              ]}
              selectProps={{
                disabled: !editable,
              }}
            />
          </div>
        )}
      />

      {editable && (
        <ButtonToolbar
          style={{ marginTop: 0 }}
          className="form__button-toolbar"
        >
          <Button
            style={{ width: 152, height: 46 }}
            color="primary"
            type="submit"
          >
            Зберегти
          </Button>
        </ButtonToolbar>
      )}
    </form>
  );
};
