import { TranspositionCipher } from "./tranposition-cipher.helper";

export class UserFingerprintGenerator {
  private static exist: string;

  static generateUserFingerprint(): string {
    if (this.exist) return this.exist;

    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const colorDepth = window.screen.colorDepth;

    const fingerprint = `u:::${userAgent}|p:::${platform}|cd:::${colorDepth}`;

    const hashedFingerprint = this.hashFunction(fingerprint.trim());

    this.exist = hashedFingerprint;
    return hashedFingerprint;
  }

  private static hashFunction(input: string): string {
    return TranspositionCipher.encrypt(input);
  }
}
