import {
  ETaskStatus,
  EUserRole,
  ITask,
  useEventsListener,
  useSocketListener,
} from "@/shared";
import { SocketEvents } from "@/shared/events";
import { getProfile } from "@/store/account";
import { getMyPermissions } from "@/store/permissions";
import _ from "lodash";
import { useSelector } from "react-redux";

interface IProps {
  items: ITask[];
  onReload: () => void;
  setItems: (items: ITask[]) => void;
  // onAction?: (key: string) => void
  taskFilterStatus?: ETaskStatus;
}

export const useTasksListEvents = (props: IProps) => {
  const account = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const checkListContainAnyItem = (ids: number[]) =>
    _.some(props.items, (item) => _.includes(ids, item.id));

  const setTasksIsFavorite = (ids: number[], isFavorite: boolean) => {
    const changedItems = props.items.map((it) => {
      if (_.includes(ids, it.id)) it.isFavorite = isFavorite;
      return it;
    });

    props.setItems(changedItems);
  };

  const hasPermissionsForUserTasks = (userId: number) => {
    if (account.role === EUserRole.Admin) return true;
    if (userId === account.id) return true;
    if (permissions && permissions.user && !_.isEmpty(permissions.user[userId]))
      return true;

    return false;
  };

  // *********** APP EVENTS AND SOCKET SIGNALS HANDLERS *************
  const onTaskAddToFavorite = (data: { ids: number[] }) => {
    if (!checkListContainAnyItem(data.ids)) return;
    setTasksIsFavorite(data.ids, true);
  };

  const onTaskRemoveFromFavorite = (data: { ids: number[] }) => {
    if (!checkListContainAnyItem(data.ids)) return;
    setTasksIsFavorite(data.ids, false);
  };

  const onFirstTaskDocument = (data: { taskId: number }) => {
    const changedItems = props.items.map((it) => {
      if (it.id === data.taskId) it.hasDocuments = true;
      return it;
    });

    props.setItems(changedItems);
  };

  const onFirstTaskComment = (data: { taskId: number }) => {
    const changedItems = props.items.map((it) => {
      if (it.id === data.taskId) it.hasComments = true;
      return it;
    });

    props.setItems(changedItems);
  };

  const onDeleteAllTaskDocs = (data: { taskId: number }) => {
    const changedItems = props.items.map((it) => {
      if (it.id === data.taskId) it.hasDocuments = false;
      return it;
    });

    props.setItems(changedItems);
  };

  const onReadTaskDocs = (data: { taskId: number | string }) => {
    const changedItems = props.items.map((it) => {
      if (it.id.toString() === data.taskId.toString())
        it.hasUnreadDocuments = false;
      return it;
    });

    props.setItems(changedItems);
  };

  const onReadTaskComments = (data: {
    taskId: number | string;
    userId?: number;
  }) => {
    const index = _.findIndex(
      props.items,
      (item) => item.id.toString() === data.taskId.toString()
    );
    if (index < 0) return;

    const changedItems = props.items.map((it) => {
      if (it.id.toString() === data.taskId.toString())
        it.hasUnreadComments = false;
      return it;
    });

    props.setItems(changedItems);
  };

  const onCommentsAreRead = (data: SocketEvents["task/read-comments"]) => {
    if (!data.userId || (data.userId && data.userId !== account.id)) return;
    onReadTaskComments(data);
  };

  const onNewTaskComment = (data: SocketEvents["task/new-comment"]) => {
    if (data.userId === account.id) return;
    const changedItems = props.items.map((it) => {
      if (it.id === data.taskId) {
        it.hasComments = true;
        it.hasUnreadComments = true;
      }
      return it;
    });

    props.setItems(changedItems);
  };

  const onNewTaskDocs = (data: SocketEvents["task/new-docs"]) => {
    const changedItems = props.items.map((it) => {
      if (it.id === data.taskId) {
        it.hasDocuments = true;
        it.hasUnreadDocuments = true;
      }
      return it;
    });

    props.setItems(changedItems);
  };

  const onNewTask = (data: { taskId: number; executorId: number }) => {
    if (checkListContainAnyItem([data.taskId])) return;
    if (hasPermissionsForUserTasks(Number(data.executorId))) {
      props.onReload();

      // if (_.isEmpty(props.items)) appEvents.emit('onFirstTask', {})
    }
  };

  const onTaskDeleted = (data: { ids: number[] }) => {
    if (
      props.taskFilterStatus === ETaskStatus.Active ||
      props.taskFilterStatus === ETaskStatus.Finished
    ) {
      deleteTasksFromList(data);
    }
  };

  function deleteTasksFromList(data: { ids: number[] }) {
    if (!checkListContainAnyItem(data.ids)) return;
    {
      const filteredItems = _.filter(
        props.items,
        (item) => !_.includes(data.ids, item.id)
      );
      props.setItems(filteredItems);
    }
  }

  const onDeleteTask = (data: { taskId: number; executorId: number }) => {
    onTaskDeleted({ ids: [data.taskId] });
  };

  const onHardDeleteTask = (data: { taskId: number; executorId: number }) => {
    deleteTasksFromList({ ids: [data.taskId] });
  };

  // ************ APP EVENTS LISTENERS **********************

  useEventsListener("onTaskAddToFavorite", onTaskAddToFavorite, [props.items]);

  useEventsListener("onTaskRemoveFromFavorite", onTaskRemoveFromFavorite, [
    props.items,
  ]);

  useEventsListener("onFirstTaskDocument", onFirstTaskDocument, [props.items]);

  useEventsListener("onFirstTaskComment", onFirstTaskComment, [props.items]);

  useEventsListener("onDeleteAllTaskDocs", onDeleteAllTaskDocs, [props.items]);

  useEventsListener("onReadDocuments", onReadTaskDocs, [props.items]);

  useEventsListener("onReadComments", onReadTaskComments, [props.items]);

  // ******** SOCKET SIGNALS LISTENERS **************************

  useSocketListener("task/new-comment", onNewTaskComment, [
    props.items,
    account,
  ]);

  useSocketListener("task/new-docs", onNewTaskDocs, [props.items]);

  useSocketListener("task/read-docs", onReadTaskDocs, [props.items]);

  useSocketListener("task/read-comments", onCommentsAreRead, [props.items]);

  useSocketListener("task/new-task", onNewTask, [props.items]);

  useSocketListener("task/finish-task", onDeleteTask, [props.items]);

  useSocketListener("task/delete-task", onDeleteTask, [props.items]);

  useSocketListener("task/hard-delete-task", onHardDeleteTask, [props.items]);
};
