import { chatsService } from "@/services/domain";
import { IUser } from "@/shared";
import { appEvents } from "@/shared/events";
import { SelectChatId } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { CardBody, Col, Button } from "reactstrap";

interface IProps {
  contact: IUser;
}

export const Actions: FC<IProps> = ({ contact }) => {
  const history = useHistory();

  const onPressMessage = async () => {
    try {
      const chatId =
        contact?.chatId ||
        (await chatsService.createPersonalChat({
          userId: contact?.id,
        }));

      simpleDispatch(new SelectChatId({ id: chatId }));

      appEvents.emit("onReadChat", {
        chatId: chatId,
        unreadCount: 0,
      });

      history.push(`/chats?id=${chatId}`);
    } catch (e) {
      console.log("Error", e.message);
    }
  };

  return (
    <Col md={2} lg={12} xl={12}>
      <CardBody className="btn_container">
        <div className="contact__action-card">
          <div className="contact__action-header">Спілкування в «Task Me» </div>
          <div className="contact__action-btns">
            <Button
              color={"danger"}
              onClick={() => {}}
              className="icon contact__btn contact-left"
              title={"Виклик користувача"}
              disabled
            >
              {"Виклик"}
            </Button>

            <Button
              color={"danger"}
              onClick={onPressMessage}
              className="icon contact__btn contact-rigth"
              title="Надіслати повідомлення"
            >
              {"Повідомлення"}
            </Button>
          </div>
        </div>
      </CardBody>
    </Col>
  );
};
