import { ICreateFactory, IUpdateFactory } from "./requests.interface";
import { ApiResponse } from "../http.types";
import http from "../http.service";
import { IFactory } from "@/shared";

const fetchFactories = (): ApiResponse<IFactory[]> => {
  return http.get<IFactory[]>("admin/factories", null);
};

const fetchFactoriesFullList = (): ApiResponse<IFactory[]> => {
  return http.get<IFactory[]>("admin/factories/all", null);
};

const createFactory = (payload: ICreateFactory): ApiResponse<IFactory> => {
  return http.post<IFactory>("admin/factories", payload);
};
const updateFactory = (payload: IUpdateFactory): ApiResponse<IFactory> => {
  return http.put<IFactory>("admin/factories", payload);
};

const deleteFactory = (id: number): ApiResponse<void> => {
  return http.delete<void>(`admin/factories/${id}`);
};

export const factoriesApi = {
  fetchFactories,
  createFactory,
  updateFactory,
  deleteFactory,
  fetchFactoriesFullList
};
