import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Tooltip } from "antd";
import React, { FC } from "react";
import "./style.scss";
interface IProps {
  configSelected: any;
  selected: number;
}
export const SelectedTasksDropdown: FC<IProps> = ({
  configSelected,
  selected,
}) => {
  const menu = () => {
    return (
      <Menu className="selected-tasks">
        {configSelected.map((el) => {
          return (
            <Menu.Item key={el.key} onClick={el.onClick}>
              {el.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  return (
    <Tooltip title={"Позначені"} key={"Selected"}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <button onClick={() => {}} className={"selected-rows-menu-btn"}>
          <Space size={3}>
            <p style={{ color: "#6c757d" }}>{selected}</p>
            <DownOutlined style={{ color: "#6c757d" }} />
          </Space>
        </button>
      </Dropdown>
    </Tooltip>
  );
};
