import { Collapse } from "reactstrap";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import bell_icon from "../../../assets/img/Bell.svg";
import { IconComponent, IPushNotification, notification } from "@/shared";
import { notificationActions } from "./config/config";
import { Button } from "antd";
import { notificApi } from "@/api/notifications/request";
import check_1 from "@/assets/img/check_1.svg";
import { NotificationItem } from "./NotificationItem";

interface IProps {
  notics: IPushNotification[];
  onReadNotif: (ids?: number[]) => void;
}

export const TopbarNotificationBlock = (props: IProps) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const history = useHistory();

  const unreadMessages = props.notics.length ? props.notics.length : null;
  const toggle = () => {
    setCollapse((prevState) => !prevState);
  };

  const handlePressDetails = async (notif: IPushNotification) => {
    setCollapse(false);
    const action = notificationActions(notif, history)[notif?.data?.type];
    action?.redirect();
    props.onReadNotif([notif.id]);
    try {
      await notificApi.readByIds({ ids: [notif.id] });
    } catch (e) {
      console.log("ERROR ON READ NOTIFICATIONS BY IDS", e);
    }
  };

  const handleReadAll = async () => {
    try {
      setCollapse(false);
      await notificApi.readAll();
      props.onReadNotif();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось зберегти зміни");
    }
  };

  return (
    <div
      onClick={toggle}
      className="topbar__collapse"
      style={{ marginLeft: "10px", position: "relative" }}
    >
      <div className="topbar__btn topbar__btn--mail">
        <img src={bell_icon} alt="" className="topbar__notification-icon" />
        {unreadMessages && unreadMessages > 0 ? (
          <div className="topbar_notification-count">
            <p
              style={{
                fontSize: unreadMessages > 99 ? 9 : 10,
                color: "#FFFF",
                textAlign: "center",
              }}
            >
              {unreadMessages > 99 ? "99+" : unreadMessages}
            </p>
          </div>
        ) : null}
      </div>

      {collapse && (
        <button type="button" className="topbar__back" onClick={() => toggle} />
      )}
      <Collapse isOpen={collapse} className="topbar__menu-notification">
        <div
          className="notification_content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="notification_title_wrapper">
            <p className="title">Сповіщення</p>
            <Button
              className="read-all-button"
              type="text"
              onClick={() => handleReadAll()}
            >
              <IconComponent name={check_1} className="icon" />
              Відмітити всі як прочитані
            </Button>
          </div>

          <div className="notification_content_items_list">
            {props.notics.map((el) => (
              <NotificationItem
                key={el.id}
                item={el}
                onPressItem={handlePressDetails}
              />
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};
