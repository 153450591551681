export class FactoriesIsLoading {
  readonly type = "IS_LOADING_FACTORIES";
  constructor(
    public readonly payload: {
      isLoading: boolean;
    }
  ) {}
}

export type TFactoriesActions = FactoriesIsLoading;
