import { RouteEnum } from "@/containers/App/router";
import { IPushNotification, NotificationKeys } from "@/shared";
import { SelectChatId } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";

export const notificationActions = (
  notification: IPushNotification,
  history: any
) => ({
  [NotificationKeys.NEW_MESSAGE]: {
    redirect: () => {
      simpleDispatch(
        new SelectChatId({ id: Number(notification.data.chatId) })
      );
      history.push(`/chats?id=${notification.data.chatId}`);
    }
  },
  [NotificationKeys.NEW_CHAT_MEMBER]: {
    redirect: () => {
      simpleDispatch(
        new SelectChatId({ id: Number(notification.data.chatId) })
      );
      history.push(`/chats?id=${notification.data.chatId}`);
    }
  },
  [NotificationKeys.NEW_TASK]: {
    redirect: () => {
      history.push(RouteEnum.Tasks, {
        type: NotificationKeys.NEW_TASK,
        taskId: notification.data.taskId
      });
    }
  },
  [NotificationKeys.NEW_TASK_COMMENT]: {
    redirect: () => {
      history.push(RouteEnum.Tasks, {
        type: NotificationKeys.NEW_TASK_COMMENT,
        taskId: notification.data.taskId
      });
    }
  },
  [NotificationKeys.NEW_TASK_FILE]: {
    redirect: () => {
      history.push(RouteEnum.Tasks, {
        type: NotificationKeys.NEW_TASK_FILE,
        taskId: notification.data.taskId
      });
    }
  },
  [NotificationKeys.TODAY_BIRTHDAY]: {
    redirect: () => {
      if (notification.data.id)
        history.push("/contact", {
          contactinfo: { userId: notification.data.id }
        });
      else history.push(RouteEnum.Contacts, { soonBirthday: true });
    }
  }
});
