import React, { useEffect, useRef, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "reactstrap";
import { CSVLink } from "react-csv";
import { createUserName } from "@/shared";
import { AppType } from "../../enums";
import { usersApi } from "@/api";
import { IFetchUsersForExportParams } from "@/api/users/requests.interfaces";
import { IUserForExportResp } from "@/api/users/responses.interfaces";
import _ from "lodash";
import "./style.scss";

interface IProps {
  type: AppType;
}

export const ExportExcel = ({ type }: IProps) => {
  const [users, setUsers] = useState<Record<string, string>[]>([]);
  const [isLoading, setLoading] = useState<"csv" | "excel" | "none">("none");
  const [isDataReady, setDataReady] = useState<boolean>(false);
  const [openCSV, setOpenCSV] = useState<boolean>(false);
  const csvInstance = useRef<any | null>(null);

  useEffect(() => {
    if (!_.isEmpty(users)) {
      setUsers([]);
      setDataReady(false);
      setOpenCSV(false);
    }
  }, [type]);

  useEffect(() => {
    if (
      isDataReady &&
      openCSV &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    )
      setTimeout(() => {
        csvInstance.current.link.click();
        setOpenCSV(false);
      });
  }, [users, isDataReady, openCSV]);

  const getUsersData = async (fileType: "csv" | "excel") => {
    if (!_.isEmpty(users)) return users;

    try {
      setLoading(fileType);
      const params: IFetchUsersForExportParams = {};
      if (type) params.appType = type;

      const { data } = await usersApi.getUsersForExport(params);
      if (!data) return users;

      const transformed = transformUsers(data);

      setUsers(transformed);
      setDataReady(true);
      return transformed;
    } catch (e) {
      console.log("Load users data for export error", e);
      setOpenCSV(false);
    } finally {
      setLoading("none");
    }
  };

  const loadCSV = async (event, done) => {
    if (isDataReady) done(true);
    else {
      event.preventDefault();
      await getUsersData("csv");
      setOpenCSV(true);
    }
  };

  const exportToExcel = async () => {
    const dataUsers = await getUsersData("excel");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(dataUsers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "users" + fileExtension);
  };

  const pr_title = {
    find: "Перегляд",
    create: "Створення",
    update: "Редагування",
    destroy: "Видалення",
    permissions: "Надання прав доступу",
    logout: "Розлогінювати",
    ban: "Блокувати/Розблоковувати",
    not_permission: "Виключити право доступу",
  };

  const transformUsers = (data: IUserForExportResp[]) => {
    return data.map((user) => {
      let perm = "Не має";

      if (!_.isEmpty(user.permissions)) {
        perm = "| ";

        Object.keys(user.permissions).map((permissionKey) => {
          if (permissionKey == "factory") {
            const permForFactories = user.permissions.factory;
            const names = Object.keys(permForFactories);
            names.map(
              (it) =>
                (perm +=
                  it +
                  ": " +
                  permForFactories[it].map((i) => pr_title[i]).join(", ") +
                  " | ")
            );
          }
          if (permissionKey == "user") {
            const permForUsers = user.permissions.user;
            const names = Object.keys(permForUsers);
            names.map(
              (it) =>
                (perm +=
                  it +
                  ": " +
                  permForUsers[it].map((i) => pr_title[i]).join(", ") +
                  " | ")
            );
          }
        });
      }

      if (perm == "| ") {
        perm = "Не має";
      }

      return {
        ПІБ: createUserName(user.firstName, user.middleName, user.lastName),
        Логін: user.login,
        Пошта: user.email,
        Телефон: user.phoneNumber,
        Підрозділ: user.factoryName,
        "Права доступу": perm,
      };
    });
  };

  return (
    <div className="export-excel-buttons">
      <CSVLink
        data={users}
        asyncOnClick={true}
        onClick={loadCSV}
        filename={"users.csv"}
        className="btn btn-primary btn-sm margin-left btn-link"
        ref={csvInstance}
      >
        {isLoading === "csv" ? "Підготовка..." : "Експорт в CSV"}
      </CSVLink>
      <Button
        className="btn margin-left"
        color="primary"
        size="sm"
        onClick={exportToExcel}
      >
        {isLoading === "excel" ? "Підготовка..." : "Експорт в Excel"}
      </Button>
    </div>
  );
};
