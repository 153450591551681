import { IFactoryShortInfo, IUserPermissions, IUserShortInfo } from "@/shared";
import _ from "lodash";
import {
  permissionsInfoSections,
  permissionsTabsActions,
  permissionsTabsTargets,
  permissionsTasksActions
} from "../consts";
import { PermissionsSection } from "../enums";

interface IProps {
  permissions: IUserPermissions;
  users: IUserShortInfo[];
  factories: IFactoryShortInfo[];
}

export const transformPermissionsToInfo = ({
  permissions,
  users,
  factories
}: IProps) => {
  if (_.isEmpty(permissions)) return [];

  const transformed = [];

  const sections = Object.keys(permissions);

  sections.map(it => {
    if (it === PermissionsSection.FILTER_FACTORY)
      transformed.push(`${permissionsInfoSections[it]} -> Так`);

    if (it === PermissionsSection.USER && !_.isEmpty(permissions[it]))
      Object.entries(permissions[it]).map(it =>
        transformed.push(getPermissionForUserString(it, users))
      );

    if (it === PermissionsSection.FACTORY && !_.isEmpty(permissions[it]))
      Object.entries(permissions[it]).map(it =>
        transformed.push(getPermissionForFactoryString(it, factories))
      );

    if (it === PermissionsSection.TABS && !_.isEmpty(permissions[it]))
      Object.entries(permissions[it]).map(it =>
        transformed.push(getPermissionForTabString(it))
      );
  });

  return transformed;
};

const getPermissionForUserString = (
  permissionEntry: [string, string[]],
  users: IUserShortInfo[]
): string => {
  const user = _.find(users, it => it.userId === Number(permissionEntry[0]));

  if (!user) return "";

  const userName = [user.firstName, user.lastName].filter(it => it).join(" ");

  const actionsArr = permissionEntry[1]?.map(it => permissionsTasksActions[it]);

  return `Користувач -> ${userName} -> ${actionsArr.join(", ")}`;
};

const getPermissionForFactoryString = (
  permissionEntry: [string, string[]],
  factories: IFactoryShortInfo[]
): string => {
  const factory = _.find(factories, it => it.id === Number(permissionEntry[0]));

  if (!factory) return "";

  const actionsArr = permissionEntry[1]?.map(it => permissionsTasksActions[it]);

  return `Підприємство -> ${factory.name} -> ${actionsArr.join(", ")}`;
};

const getPermissionForTabString = (permissionEntry: [string, string[]]) => {
  const actionsArr = permissionEntry[1]?.map(it => permissionsTabsActions[it]);

  return `Сторінка -> ${
    permissionsTabsTargets[permissionEntry[0]]
  } -> ${actionsArr.join(", ")}`;
};
