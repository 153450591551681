import { ipsApi } from "@/api";
import {
  IIps,
  IPListType,
  TranspositionCipher,
  notification,
  usePaginationList,
} from "@/shared";
// import { decode } from "js-base64";
import { useEffect, useState } from "react";

interface IModalState {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
}

const defaultModalState = {
  isOpen: false,
  message: "",
  onConfirm: () => {},
};

export const useIPs = () => {
  const [selectedIPs, setSelectedIPs] = useState<Partial<IIps>[]>([]);
  const [confirmModal, setConfirmModal] = useState<IModalState>(
    defaultModalState
  );
  const [listType, setListType] = useState<IPListType>(IPListType.Black);

  const openConfirmModal = (message: string, onConfirm: () => void) => {
    setConfirmModal({ isOpen: true, message, onConfirm });
  };

  const afterAction = () => {
    setConfirmModal(defaultModalState);
    setSelectedIPs([]);
  };

  const paginationList = usePaginationList<IIps>({
    fetchItems: ipsApi.fetchIpsList,
    loadParams: {
      limit: 10,
      page: 1,
      type: listType,
      sort: "ASC",
      sortField: "ip",
    },
    serrializatorItems: serrializatorItems,
  });

  function serrializatorItems(items: IIps[]) {
    return items.map((it) => {
      const paths = it.ip.split("///");
      it.ip = paths[0];
      try {
        if (paths[1]) it.fingreprint = TranspositionCipher.decrypt(paths[1]);
      } catch (e) {
        console.log(e);
      }
      return it;
    });
  }

  const changeType = async (listType: IPListType) => {
    try {
      await ipsApi.updateIpType({
        ids: selectedIPs.map((it) => it.id),
        listType,
      });
      paginationList.resetFlatList();
    } catch (e) {
      notification.showError(
        "Помилка",
        `Не вдалось ${
          listType === IPListType.Black ? "блокувати" : "розблокувати"
        } IP`
      );
    } finally {
      afterAction();
    }
  };

  const unblock = async () => {
    try {
      await ipsApi.deleteIp(selectedIPs.map((it) => it.id));
      paginationList.resetFlatList();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось розблокувати IP");
    } finally {
      afterAction();
    }
  };

  const deleteIPs = async () => {
    try {
      await ipsApi.deleteIp(selectedIPs.map((it) => it.id));
      paginationList.resetFlatList();
    } catch (e) {
      notification.showError("Помилка", "Не вдалось видалити IP");
    } finally {
      afterAction();
    }
  };

  const onPressBlock = () => {
    openConfirmModal("Ви справді хочете заблокувати IP", () =>
      changeType(IPListType.Black)
    );
  };

  const onPressUnblock = () => {
    openConfirmModal("Ви справді хочете розблокувати IP", () => unblock());
  };

  const onPressDelete = () => {
    openConfirmModal("Ви справді хочете видалити IP", () => deleteIPs());
  };

  const onPressAddToWhiteList = () => {
    openConfirmModal("Ви справді хочете додати в білий список IP", () =>
      changeType(IPListType.White)
    );
  };

  useEffect(() => {
    paginationList.setLoadParams({ type: listType });
  }, [listType]);

  return {
    confirmModal: {
      state: confirmModal,
      set: setConfirmModal,
      open: openConfirmModal,
    },
    paginationList,
    selectedIPs,
    setSelectedIPs,
    listType,
    setListType,
    actions: {
      block: onPressBlock,
      unblock: onPressUnblock,
      addToWhiteList: onPressAddToWhiteList,
      delete: onPressDelete,
    },
    allIds: paginationList.items?.map((it) => it.ip),
  };
};
