import { useEventsListener } from "@/shared";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

export const MessageSettings = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [items, setItems] = useState([]);

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  useEventsListener(
    "openMessageMenuOptions",
    (payload) => {
      setItems(payload.items);
      setDropdownOpen(true);
    },
    [setItems, setDropdownOpen]
  );

  const ref = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setDropdownOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const renderItems = () => {
    return items.map((item, i) => {
      return (
        <div
          key={i}
          className="menu-setting-item"
          onClick={() => {
            setDropdownOpen(false);
            if (item.onClick) setTimeout(() => item.onClick(), 100);
          }}
        >
          <span>{item.label}</span>
        </div>
      );
    });
  };

  return (
    <div ref={ref} className="message-settings-container">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="message-settings-btn"></DropdownToggle>
        <DropdownMenu>{renderItems()}</DropdownMenu>
      </Dropdown>
    </div>
  );
};
