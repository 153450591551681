import * as Res from "./responses.interfaces";
import * as Req from "./requests.interfaces";
import http from "../http.service";
import { IMyPermissions } from "@/shared";

const fetchAvailablePermissions = () => {
  return http.get<Res.IAvailablePermissions>("admin/permissions");
};

const saveUserPermissions = (
  data: Req.ISaveUserPermissions,
  userId: number
) => {
  return http.post<void>(`admin/users-permissions/${userId}`, data);
};

const deleteUserPermissions = (userId: number) => {
  return http.delete<void>(`admin/users-permissions/${userId}`);
};

const fetchUserPermissions = (userId: number) => {
  return http.get<Res.IUserPermissionsResp>(
    `admin/users-permissions/${userId}`
  );
};

const fetchUserPermissionsWithInfo = (
  userId: number,
  params?: { withExtendedInfo?: boolean }
) => {
  return http.get<Res.IUserPermissionsWithInfoResp>(
    `admin/users-permissions/${userId}`,
    { params }
  );
};

const fetchMyPermissionsTransformed = () => {
  return http.get<IMyPermissions>("admin/permissions/my-transformed");
};

const fetchMyPermissionsOriginal = () => {
  return http.get<IMyPermissions>("admin/permissions/my-original");
};

export const permissionsApi = {
  fetchAvailablePermissions,
  saveUserPermissions,
  deleteUserPermissions,
  fetchUserPermissions,
  fetchUserPermissionsWithInfo,
  fetchMyPermissionsTransformed,
  fetchMyPermissionsOriginal
};
