import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  icon: string;
  path: string;
  onClick?: () => void;
}

export const TopBarMenuLink = (props: IProps) => {
  return (
    <Link className="topbar__link" to={props.path} onClick={props.onClick}>
      <span className={`topbar__link-icon lnr lnr-${props.icon}`} />
      <p className="topbar__link-title">{props.title}</p>
    </Link>
  );
};
