import { PermissionForm } from "@/containers/Permissions/interfaces";
import {
  IUser,
  IUserPermissions,
  transformPermissionsToFormValues
} from "@/shared";
import _ from "lodash";

export interface IUserForm {
  id?: number;
  avatar?: File;
  avatarUrl?: string;
  email: string;
  login: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  role: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  position: string;
  personalPhoneNumber: string;
  innerPhoneNumber: string;
  factoryId: string;
  permissions?: PermissionForm[];
  sendPasswordBy?: "email" | "sms";
}

export const convertUserDataToFormData = (
  user: IUser,
  permissions?: IUserPermissions
): IUserForm => {
  if (!user) return null;
  return {
    id: user.id,
    avatarUrl: user.info.avatarUrl,
    email: user.email,
    login: user.login,
    firstName: user.info.firstName,
    lastName: user.info.lastName,
    middleName: user.info.middleName,
    dateOfBirth: user.info.dateOfBirth,
    role: user.role,
    phoneNumber: user.phoneNumber,
    password: null,
    confirmPassword: null,
    position: user.info.position,
    personalPhoneNumber: user.info.personalPhoneNumber,
    innerPhoneNumber: user.info.innerPhoneNumber,
    factoryId: !_.isEmpty(user.factoryIds) ? user.factoryIds[0].toString() : "",
    permissions: transformPermissionsToFormValues(permissions),
    sendPasswordBy: null
  };
};
