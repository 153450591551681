import React, { FC } from "react";
import { Tooltip } from "antd";

interface IProps {
  isVisible: boolean;
  message: string;
}

export const CreateTaskTooltip: FC<IProps> = ({ isVisible, message }) => {
  return (
    <Tooltip placement="top" visible={isVisible} title={message}>
      <div style={{ width: "100%" }} />
    </Tooltip>
  );
};
