import React, { FC } from "react";
import "./style.scss";

interface IProps {
  count: number;
}

export const NewMessagesCountIndicator: FC<IProps> = ({ count }) => {
  return (
    <div className="indicator-wrapper">
      <p
        className="indicator-count"
        style={count === 0 ? { color: "transparent" } : {}}
      >
        {count}
      </p>
    </div>
  );
};
