import { chatsApi } from "@/api";
import { chatsService } from "@/services/domain";
import {
  IChat,
  IChatMessage,
  useEventsListener,
  usePaginationList,
  useSocket,
  useSocketListener
} from "@/shared";
import { appEvents, SocketEvents } from "@/shared/events";
import { getProfile } from "@/store/account";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useChatList = () => {
  const [searchString, setSearchVal] = useState<string>(null);

  const socket = useSocket();
  const accountId = useSelector(getProfile);

  const {
    items: chats,
    loadMore,
    resetFlatList,
    setLoadParams,
    loadParams,
    isLoading,
    loadPage,
    _setItems,
    setOrderBy
  } = usePaginationList<IChat>({
    fetchItems: params => chatsService.fetchChats(params)
  });

  useEffect(() => {
    if (searchString || searchString === "")
      setLoadParams({ params: { searchString } });
  }, [searchString]);

  const presentInList = (chatId: number) => {
    const chatIds = chats.map(it => it.id);
    return _.includes(chatIds, chatId);
  };

  const reloadIfIncludes = (chatId: number) => {
    if (presentInList(Number(chatId))) resetFlatList();
  };

  const onDeleteChat = (data: { chatId: number }) => {
    if (presentInList(Number(data?.chatId))) {
      // *** при видаленні чату для себе або для всіх потрібно відразу забрати його зі списку
      // бо перезавантаження чатів з серверу дає затримку і в цей час користувач може встигнути
      // натиснути на цей видалений чат і відкрити його деталку *** //
      _setItems(chats.filter(it => it.id !== data.chatId));
      // *** перезавантаження списку з сервера все одно робимо, щоб отримати актуальний список
      // чатів та правильний порядок закріплених чатів
      resetFlatList();
    }
  };

  const onEditChat = (data: { chatId: number }) => {
    reloadIfIncludes(data?.chatId);
  };

  const onSetUnread = async (chatId: number, data?: Partial<IChat>) => {
    const chat = _.find(chats, chat => chat.id === chatId);

    if (!chat.isChatUnread) return setUnread(chatId);

    if (data?.unreadMessagesCount > 0)
      socket.emit("chat/read-chat", {
        userId: accountId.id,
        chatsIds: [chatId]
      });

    if (chat.isChatUnread) await chatsApi.setChatReadReq(chatId);

    appEvents.emit("onReadChat", {
      chatId,
      unreadCount: data.unreadMessagesCount
    });
  };

  const setUnread = async (chatId: number) => {
    try {
      await chatsApi.setChatUnreadReq(chatId);

      _setItems(
        _.clone(chats).map(it => {
          if (it.id === chatId) it.isChatUnread = true;
          return it;
        })
      );
    } catch (e) {
      console.log("Виникла помилка, спробуйте будь-ласка пізніше");
    }
  };

  const onPin = async (chatId: number) => {
    try {
      const chatIndex = chats.findIndex(item => item.id === chatId);
      if (chats[chatIndex].isChatFixed) return unPin(chatId);

      const pinned = chats.filter(item => item.isChatFixed);

      if (pinned.length > 4) {
        // alert("max pinned chats 5!");
        appEvents.emit("openLimitPinnedChatsModal", { isShow: true });
        return;
      }

      const lastPinnedIndex = chats.findIndex(
        item => item.id === pinned[pinned.length - 1]?.id
      );

      await chatsApi.pinChatReq({ chatId, order: lastPinnedIndex + 2 });
      reloadIfIncludes(chatId);
    } catch (error) {
      console.log(error);
    }
  };

  const unPin = async (chatId: number) => {
    try {
      await chatsApi.unPinChatReq(chatId);
      reloadIfIncludes(chatId);
    } catch (error) {
      console.log(error);
    }
  };

  const isPinned = (chatId: number): boolean => {
    const pinned = chats.find(item => item.id === chatId)?.isChatFixed || false;
    return pinned;
  };

  const isUnread = (chatId: number): boolean => {
    const readed =
      chats.find(item => item.id === chatId)?.isChatUnread || false;
    return readed;
  };

  const onReadChat = useCallback(
    (data: { chatId: number }) => {
      const chat = _.find(
        chats,
        chat => Number(chat.id) === Number(data.chatId)
      );
      if (!chat || (chat?.unreadMessagesCount <= 0 && !chat?.isChatUnread))
        return;

      _setItems(
        chats.map(it => {
          if (Number(it.id) !== Number(data.chatId)) return it;
          return {
            ...it,
            unreadMessagesCount: 0,
            isChatUnread: false
          };
        })
      );
    },
    [chats]
  );

  const getChatIdFromData = (data: {
    message: IChatMessage | IChatMessage[];
  }) => {
    if (_.isArray(data.message) && !_.isEmpty(data.message))
      return data.message[0].chatId;

    return (data.message as IChatMessage).chatId;
  };

  const onNewMessage = (data: { message: IChatMessage | IChatMessage[] }) => {
    const chatId = getChatIdFromData(data);
    reloadIfIncludes(chatId);
  };

  const onChatIsRead = (data: SocketEvents["chat/is-read"]) => {
    if (data.userId === accountId.id) onReadChat(data);
  };

  const onChatPinned = (data: SocketEvents["chat/pin"]) => {
    const chat = _.find(
      chats,
      chat => chat.id.toString() === data.chatId.toString()
    );

    if (!chat || chat.isChatFixed) return;
    resetFlatList();
  };

  const onChatUnpinned = (data: SocketEvents["chat/unpin"]) => {
    const chat = _.find(
      chats,
      chat => chat.id.toString() === data.chatId.toString()
    );

    if (!chat || !chat.isChatFixed) return;

    resetFlatList();
  };

  const onSetChatUnread = (data: SocketEvents["chat/unread"]) => {
    const chat = _.find(
      chats,
      chat => chat.id.toString() === data.chatId.toString()
    );

    if (!chat || chat.isChatUnread) return;

    _setItems(
      _.clone(chats).map(it => {
        if (it.id.toString() === data.chatId.toString()) it.isChatUnread = true;
        return it;
      })
    );
  };
  const onUpdateMessage = (data: { message: IChatMessage }) => {
    const chatId = getChatIdFromData(data);

    reloadIfIncludes(chatId);
  };

  // EVENTS LISTENERS //

  useEventsListener(
    "onDeleteMessage",
    ({ chatId }) => reloadIfIncludes(chatId),
    [reloadIfIncludes]
  );

  useEventsListener(
    "onDeleteChatForMe",
    ({ chatId }) => reloadIfIncludes(chatId),
    [reloadIfIncludes]
  );

  useEventsListener("onDeleteChatForMe", onDeleteChat, [chats]);

  useEventsListener("onDeleteAllChats", () => _setItems([]), [_setItems]);

  useEventsListener("onReadChat", onReadChat, [chats]);

  useEventsListener(
    "onClearAllChats",
    () => {
      _setItems(
        chats.map(it => ({
          ...it,
          lastMessage: null,
          lastMessageDate: it.createdAt,
          unreadMessagesCount: 0,
          isChatUnread: false
        }))
      );
    },
    [chats, _setItems]
  );

  // SOCKET LISTENERS //

  useSocketListener(
    "chat/delete-chat",
    ({ chatId }) => reloadIfIncludes(chatId),
    [reloadIfIncludes]
  );

  useSocketListener("chat/edit-chat", onEditChat, [chats]);

  useSocketListener("chat/update-message", onUpdateMessage, [chats]);

  useSocketListener("chat/new-chat", resetFlatList, []);

  useSocketListener("chat/new-message", onNewMessage, [chats]);

  useSocketListener(
    "chat/delete-message",
    ({ chatId }) => reloadIfIncludes(chatId),
    [reloadIfIncludes]
  );

  useSocketListener(
    "chat/change-role",
    ({ chatId }) => reloadIfIncludes(chatId),
    [reloadIfIncludes]
  );

  useSocketListener("chat/is-read", onChatIsRead, [chats, accountId]);

  useSocketListener("chat/pin", onChatPinned, [chats]);

  useSocketListener("chat/unpin", onChatUnpinned, [chats]);

  useSocketListener("chat/unread", onSetChatUnread, [chats]);

  return {
    chats: chats as IChat[],
    searchString,
    loadParams,
    loadMore,
    resetFlatList: resetFlatList,
    setSearchVal,
    isLoading,
    loadPage,
    setOrderBy,
    isPinned,
    isUnread,
    onPin,
    onSetUnread
  };
};
