import React, { CSSProperties, FC } from "react";

interface AvatarProps {
  imageUrl: string;
  maxWidth: number;
  maxHeight: number;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Avatar: FC<AvatarProps> = (props) => {
  return (
    <div
      className="avatar-imageWrap"
      onClick={props?.onClick}
      style={{
        width: props.maxWidth,
        height: props.maxHeight,
        borderRadius: "50%",
        ...props.style,
      }}
    >
      {props.imageUrl?.length > 1 ? (
        <img
          style={{
            width: props.maxWidth,
            height: props.maxHeight,
            borderRadius: props.maxWidth / 2,
          }}
          src={props.imageUrl}
        />
      ) : (
        <div className="avatar-noImage">
          <p>{props.imageUrl}</p>
        </div>
      )}
    </div>
  );
};
