import { getSelectColumn } from "@/components/TableGrid/configs";
import { Table } from "@/components/TableGrid/Table";
import {
  ConfirmModal,
  getIdFromHash,
  hasHash,
  ITask,
  NotificationKeys,
} from "@/shared";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import {
  CreateUpdateTaskModal,
  FinishTasksModal,
  PrintTasksModal,
  ReassignTasksModal,
  TasksHeader,
} from "./components";
import {
  getSelectedTasksMenuConfig,
  initialActiveColumns,
  getTasksColumnsConfig,
  menuConfig,
} from "./configs";
import { defaultConfirmModalState, defaultModalState } from "./consts";
import { ETaskModalMode, ETasksModalTabs } from "./enums";
import { useTasksList } from "./hooks";
import { IConfirmModalState, ITaskModalState } from "./interfaces";
import iconNotePencil from "@/assets/img/notepencil-light-icon.svg";
import { CustomTableRow } from "@/components/TableGrid/components";
import { getProfile } from "@/store/account";
import { useSelector } from "react-redux";
import { getMyPermissions } from "@/store/permissions";
import { useHistory, useLocation } from "react-router-dom";
import { tasksApi } from "@/api";
import { ITaskDetailsResponse } from "@/api/tasks/responses.interfaces";

const startTextByDeleteAction = {
  delete: "Ви справді бажаєте",
  hardDelete: "Ви впевнені, що хочете",
};

const endTextByDeleteAction = {
  delete: "?",
  hardDelete: " без можливості відновлення?",
};

export const TasksScreen: FC = () => {
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);
  const location = useLocation();

  const [taskModalState, setTaskModal] = useState<ITaskModalState>(
    defaultModalState
  );
  const [confirmModal, setConfirmModal] = useState<IConfirmModalState>(
    defaultConfirmModalState
  );
  const [isOpenFinishTasks, setOpenFinishTasks] = useState<boolean>(false);
  const [isOpenReassignTask, setOpenReassignTask] = useState<boolean>(false);
  const [isPrintModalOpen, setPrintModal] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>();
  const [focusFilterKey, setFocusFilterKey] = useState<string>("");
  const [openTask, setOpenTask] = useState<ITask>(null);

  const history = useHistory();

  const openTaskFunc = (task: ITaskDetailsResponse, tab: ETasksModalTabs) => {
    setOpenTask(task);
    setTaskModal({
      isOpen: true,
      mode: ETaskModalMode.Watch,
      tab: tab,
    });
  };

  const loadAndOpenTask = async (
    id,
    type:
      | NotificationKeys.NEW_TASK
      | NotificationKeys.NEW_TASK_COMMENT
      | NotificationKeys.NEW_TASK_FILE
  ) => {
    const { data: task } = await tasksApi.getTaskDetails(id);

    if (task) {
      const actionByKey = {
        newTask: () => openTaskFunc(task, ETasksModalTabs.Tasks),
        newTaskComment: () => openTaskFunc(task, ETasksModalTabs.Comments),
        newTaskFile: () => openTaskFunc(task, ETasksModalTabs.Docs),
      };

      actionByKey[type]();
    }
  };

  useEffect(() => {
    if ((history.location.state as any)?.taskId) {
      loadAndOpenTask(
        (history.location.state as any)?.taskId,
        (history.location.state as any)?.type
      );
    }
  }, [history.location]);

  useEffect(() => {
    if (hasHash(location, "taskDetail")) {
      const taskId = getIdFromHash(location.hash);
      if (!taskId) return;

      loadAndOpenTask(taskId, "newTask" as any);
    }
  }, [location]);

  const {
    paginationList,
    selectedTasks,
    setSelectedTasks,
    tasksActions,
    filter,
    allIds,
  } = useTasksList();

  useEffect(() => {
    paginationList.setLoadParams({
      globalSearchString: searchStr,
    });
  }, [searchStr]);

  const selectColumn = getSelectColumn({
    selected: selectedTasks,
    onSelect: setSelectedTasks,
  });

  const onSubmitTaskData = () => {
    paginationList.resetFlatList();
    setTaskModal(defaultModalState);

    if (!_.isEmpty(selectedTasks)) setSelectedTasks([]);
  };

  const confirmModalMessage = useCallback(
    (action: "delete" | "hardDelete") => {
      const ids = selectedTasks.map((it) => it.id);

      const startText = startTextByDeleteAction[action];
      const endText = endTextByDeleteAction[action];

      const head = `${startText} видалити ${
        selectedTasks.length > 1 ? "задачі" : "задачу"
      }`;

      const tail = ` № ${ids.join(", ")}${endText}`;

      return head + tail;
    },
    [selectedTasks]
  );

  const onTaskAction = (
    key:
      | "create"
      | "edit"
      | "finish"
      | "reassign"
      | "copy"
      | "delete"
      | "print"
      | "hardDelete"
  ) => {
    const actionByKey = {
      create: () => setTaskModal({ isOpen: true, mode: ETaskModalMode.Create }),
      edit: () =>
        setTaskModal({
          isOpen: true,
          mode: ETaskModalMode.Update,
        }),
      finish: () => setOpenFinishTasks(true),
      reassign: () => setOpenReassignTask(true),
      copy: () =>
        setTaskModal({
          isOpen: true,
          mode: ETaskModalMode.Copy,
        }),
      delete: () =>
        setConfirmModal({
          isOpen: true,
          message: confirmModalMessage("delete"),
          onConfirm: () => {
            tasksActions.removeTasks();
            setConfirmModal(defaultConfirmModalState);
          },
        }),
      print: () => setPrintModal(true),
      hardDelete: () =>
        setConfirmModal({
          isOpen: true,
          message: confirmModalMessage("hardDelete"),
          onConfirm: () => {
            tasksActions.hardDeleteTasks();
            setConfirmModal(defaultConfirmModalState);
          },
        }),
    };

    actionByKey[key]();
  };

  const selectedItemsMenuConfig = useMemo(
    () =>
      getSelectedTasksMenuConfig({
        onClick: onTaskAction,
        selectedItems: selectedTasks,
        profile,
        permissions,
      }),
    [selectedTasks, permissions, profile]
  );

  const contextMenuConfig = useMemo(
    () => [
      {
        onClick: () => onTaskAction("create"),
        key: "create",
        label: "Створити нову",
        icon: {
          scr: iconNotePencil,
          name: "note pencil",
          className: "note-pencil-icon",
        },
      },
      ...selectedItemsMenuConfig,
    ],
    [selectedItemsMenuConfig]
  );

  const onPressName = (task: ITask, event?: any) => {
    if (event.detail === 2) {
      setOpenTask(task);
      setTaskModal({
        isOpen: true,
        mode: ETaskModalMode.Watch,
      });
    }
  };
  const tasksColumnsConfig = useMemo(
    () => getTasksColumnsConfig({ onPressName }),
    []
  );

  const onCloseModal = () => {
    if (taskModalState.mode === ETaskModalMode.Watch) {
      setOpenTask(null);
    }
  };

  const onOpenContextMenu = (isOpen: boolean, taskId: number) => {
    if (isOpen && !_.some(selectedTasks, (it) => it.id === taskId))
      setSelectedTasks([paginationList.items.find((it) => it.id === taskId)]);
  };

  const selectedTask = useMemo(() => {
    if (taskModalState.mode === ETaskModalMode.Create) return null;
    if (openTask) return openTask;
    if (!_.isEmpty(selectedTasks))
      // return _.find(
      //   paginationList.items,
      //   (it) => it.id === selectedTasks[0]?.id
      // );
      return selectedTasks[0];

    return null;
  }, [taskModalState.mode, openTask, selectedTasks]);

  const finishTasksModalTitle = useMemo(() => {
    const ids = selectedTasks.map((it) => it.id);
    const head = selectedTasks.length > 1 ? "Задачі" : "Задача";
    const tail = ` № ${ids.join(", ")}`;
    return head + tail;
  }, [selectedTasks]);

  const selectedTasksMaxStartDate = useMemo(() => {
    let maxStartDate = null;
    if (selectedTasks)
      selectedTasks.map((task) => {
        // const task = _.find(paginationList.items, (it) => it.id === id);
        if (task && new Date(task.startDate) > new Date(maxStartDate))
          maxStartDate = task.startDate;
      });

    return maxStartDate;
  }, [selectedTasks, paginationList.items]);

  const reassignTasksModalTitle = useMemo(() => {
    const ids = selectedTasks.map((it) => it.id);
    const head =
      selectedTasks.length > 1
        ? "Перепоставити задачі"
        : "Перепоставити задачу";
    const tail = ` № ${ids.join(", ")}`;
    return head + tail;
  }, [selectedTasks]);

  const selectAllTasks = () => {
    setSelectedTasks(paginationList?.data);
  };

  const unSelectAllTasks = () => {
    setSelectedTasks([]);
  };

  const handleSelectAllColumnPress = () => {
    if (selectedTasks.length === allIds?.length) unSelectAllTasks();
    else selectAllTasks();
  };

  const configSelected = useMemo(() => {
    return menuConfig({
      onSelectAll: selectAllTasks,
      onUnSelectAll: unSelectAllTasks,
      all: allIds?.length === selectedTasks.length,
    });
  }, [paginationList, selectedTasks]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <CreateUpdateTaskModal
              state={taskModalState}
              setOpen={(state: boolean) =>
                setTaskModal((prev) => ({ ...prev, isOpen: state }))
              }
              onSubmitData={onSubmitTaskData}
              selectedTask={selectedTask as ITask}
              onClose={onCloseModal}
            />

            <FinishTasksModal
              selectedTasksStartDate={selectedTasksMaxStartDate}
              title={finishTasksModalTitle}
              isOpen={isOpenFinishTasks}
              setOpen={setOpenFinishTasks}
              onSubmit={(date: string) => tasksActions.finishTasks(date)}
            />

            <ReassignTasksModal
              title={reassignTasksModalTitle}
              isOpen={isOpenReassignTask}
              setOpen={setOpenReassignTask}
              onSubmit={(ids: string[]) =>
                tasksActions.reassignTasks(ids.map((it) => Number(it)))
              }
            />

            <ConfirmModal
              isShow={confirmModal.isOpen}
              message={confirmModal.message}
              onConfirm={confirmModal.onConfirm}
              setShow={(state: boolean) =>
                setConfirmModal({ ...confirmModal, isOpen: state })
              }
            />

            <PrintTasksModal
              isOpen={isPrintModalOpen}
              setOpen={setPrintModal}
              onSubmit={() => {
                setPrintModal(false);
                setSelectedTasks([]);
              }}
              selectedTasksIds={selectedTasks.map((it) => it.id)}
              searchString={searchStr}
            />

            <CardBody>
              <TasksHeader
                configSelected={configSelected}
                selectedRows={selectedTasks.map((it) => it.id)}
                searchStr={searchStr}
                setSearchStr={setSearchStr}
                onClickCreate={() =>
                  setTaskModal({ isOpen: true, mode: ETaskModalMode.Create })
                }
                isInputFocused={focusFilterKey === "globalSearch"}
                onInputFocus={() => setFocusFilterKey("globalSearch")}
                tasksMenuConfig={selectedItemsMenuConfig}
              />

              <Table
                tableName={"tasks"}
                targetDate={filter.date}
                columns={[selectColumn, ...tasksColumnsConfig]}
                activeColumns={initialActiveColumns}
                paginationList={paginationList}
                selectedRows={selectedTasks.map((it) => it.id)}
                // setSelectedRows={setSelectedTasksIds}
                focusedFilterField={focusFilterKey}
                onFocusFilterField={(fieldKey: string) =>
                  setFocusFilterKey(fieldKey)
                }
                openedRowId={openTask?.id}
                tableProps={{
                  rowRenderer: (data: any) => (
                    <CustomTableRow
                      rowData={data}
                      menuConfig={contextMenuConfig}
                      onOpenMenu={onOpenContextMenu}
                    />
                  ),
                }}
                allSelected={
                  !_.isEmpty(selectedTasks) &&
                  selectedTasks.length === allIds?.length
                }
                selectAll={handleSelectAllColumnPress}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
