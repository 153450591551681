import * as PermissionsGroupsActions from "@/store/group-permission/types";
import * as PermissionsGroupsReqPayloads from "@/api/permissions-group/requests.interfaces";
import { permissionsGroupApi } from "@/api/permissions-group/requests";
import { simpleDispatch } from "@/store/store-helpers";

const createPermissionsGroup = async (
  permissionsGroup: PermissionsGroupsReqPayloads.ICreatePermissionsGroupPayload
) => {
  try {
    simpleDispatch(new PermissionsGroupsActions.isLoading({ isLoading: true }));
    const { data } = await permissionsGroupApi.createPermissionsGroup(
      permissionsGroup
    );
    simpleDispatch(
      new PermissionsGroupsActions.CreatePermissionsGroup({
        permissionsGroup: data
      })
    );
  } catch (error) {
    console.log("PERMISSIONS GROUPS ERROR", error);
  } finally {
    simpleDispatch(
      new PermissionsGroupsActions.isLoading({ isLoading: false })
    );
  }
};

const updatePermissionsGroup = async (
  permissionGroupId: number,
  permissionsGroup: PermissionsGroupsReqPayloads.IUpdatePermissionsGroupPayload
) => {
  try {
    simpleDispatch(new PermissionsGroupsActions.isLoading({ isLoading: true }));
    const { data } = await permissionsGroupApi.updatePermissionsGroup(
      permissionGroupId,
      permissionsGroup
    );

    return data;

    // const permissions = JSON.parse(data.permissions);

    // simpleDispatch(
    //   new PermissionsGroupsActions.UpdatePermissionsGroup({
    //     permissionsGroup: { ...data, permissions: permissions }
    //   })
    // );
  } catch (error) {
    console.log("PERMISSIONS GROUPS ERROR", error);
  } finally {
    simpleDispatch(
      new PermissionsGroupsActions.isLoading({ isLoading: false })
    );
  }
};

const deletePermissionsGroup = async (permissionGroupId: number) => {
  try {
    simpleDispatch(new PermissionsGroupsActions.isLoading({ isLoading: true }));
    await permissionsGroupApi.deletePermissionsGroup(permissionGroupId);
  } catch (error) {
    console.log("PERMISSIONS GROUPS ERROR", error);
  } finally {
    simpleDispatch(
      new PermissionsGroupsActions.isLoading({ isLoading: false })
    );
  }
};

// const fetchPermissionsGroupsList = async (params?: any) => {
//   try {
//     simpleDispatch(new PermissionsGroupsActions.isLoading({ isLoading: true }));
//     const { data } = await permissionsGroupApi.fetchPermissionsGroupsList(
//       params
//     );
//     simpleDispatch(
//       new PermissionsGroupsActions.SetPermissionsGroups({
//         data: data.items,
//         count: data.count
//       })
//     );
//   } catch (error) {
//     console.log("PERMISSIONS GROUPS ERROR", error);
//   } finally {
//     simpleDispatch(
//       new PermissionsGroupsActions.isLoading({ isLoading: false })
//     );
//   }
// };

const fetchPermissionsGroupById = async (permissionGroupId: number) => {
  try {
    simpleDispatch(new PermissionsGroupsActions.isLoading({ isLoading: true }));
    const { data } = await permissionsGroupApi.fetchPermissionsGroupById(
      permissionGroupId
    );
    return data.permissions;
  } catch (error) {
    console.log("PERMISSIONS GROUPS ERROR", error);
  } finally {
    simpleDispatch(
      new PermissionsGroupsActions.isLoading({ isLoading: false })
    );
  }
};

export const permissionsGroupsService = {
  createPermissionsGroup,
  updatePermissionsGroup,
  deletePermissionsGroup,
  // fetchPermissionsGroupsList,
  fetchPermissionsGroupById
};
