import { objectToOption, Permissions, PermissionsTabs } from "@/shared";
import { PermissionsSection } from "../enums";
import { PermissionForm } from "../interfaces";

export const permissionsSections: Record<PermissionsSection, string> = {
  [PermissionsSection.FACTORY]: "Підприємство",
  [PermissionsSection.USER]: "Користувачі",
  [PermissionsSection.TABS]: "Сторінки",
  [PermissionsSection.FILTER_FACTORY]: "Показувати фільтр підприємств",
};

export const permissionsInfoSections: Record<PermissionsSection, string> = {
  [PermissionsSection.FACTORY]: "Підприємство",
  [PermissionsSection.USER]: "Користувач",
  [PermissionsSection.TABS]: "Сторінка",
  [PermissionsSection.FILTER_FACTORY]: "Показувати фільтр підприємств",
};

export const permissionsTasksActions: Partial<Record<Permissions, string>> = {
  [Permissions.FIND]: "Перегляд задач",
  [Permissions.CREATE]: "Створення задач",
  [Permissions.UPDATE]: "Редагування задач",
  [Permissions.DESTROY]: "Видалення задач",
  [Permissions.HARD_DELETE]: "Повне видалення задач",
  [Permissions.NOT_PERMISSION]: "Виключити право доступу",
};

export const permissionsTabsTargets: Partial<Record<
  PermissionsTabs,
  string
>> = {
  [PermissionsTabs.FACTORY]: "Підприємства",
  [PermissionsTabs.USER]: "Користувачі",
  [PermissionsTabs.GROUP_PERMISSION]: "Групи прав",
  [PermissionsTabs.TAXONOMY]: "Довідники",
};

export const permissionsTabsActions: Partial<Record<Permissions, string>> = {
  [Permissions.FIND]: "Перегляд",
  [Permissions.CREATE]: "Створити",
  [Permissions.UPDATE]: "Редагувати",
  [Permissions.DESTROY]: "Видалити",
  [Permissions.BAN]: "Блокувати",
  [Permissions.LOGOUT]: "Розлогінити",
  [Permissions.PERMISSIONS]: "Надання прав доступу",
};

export const sectionOptions = objectToOption(permissionsSections);
export const actionsOptions = objectToOption(permissionsTasksActions);

export const initialNewPermissionsState: PermissionForm = {
  section: null,
  target: null,
  permissions: [],
};

export const selectedOptionTargetTitle: Record<PermissionsSection, string> = {
  [PermissionsSection.FACTORY]: "Виберіть підприємство",
  [PermissionsSection.USER]: "Виберіть користувача",
  [PermissionsSection.TABS]: "Виберіть сторінку",
  [PermissionsSection.FILTER_FACTORY]: "",
};
