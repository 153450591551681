import React, { Component } from "react";
class TextField extends Component<any> {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {
    const {
      input,
      placeholder,
      label,
      meta: { touched, error },
    } = this.props;

    return (
      <div className="form__form-group">
        {label ? <span className="form__form-group-label">{label}</span> : null}
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <textarea {...input} placeholder={placeholder} />
            {touched && error && (
              <span className="form__form-group-error">{error}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TextField;
