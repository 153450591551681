import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";
import { Table } from "@/components/TableGrid/Table";
import { permissionsGroupApi } from "@/api/permissions-group/requests";
import { ConfirmModal } from "@/shared";
import { defaultColumnsActive, getColumnsConfig } from "../../configs";
import _ from "lodash";
import { getMyPermissions } from "@/store/permissions";

interface IProps {
  paginationList: any;
  onPressEdit: (id: number) => void;
  focusFilterKey: string;
  setFocusFilterKey: (key: string) => void;
}

export const Data = ({ paginationList, ...props }: IProps) => {
  const [groupIdToDelete, setIdToDelete] = useState<number>();
  const [isShowDeleteModal, setConfirmModal] = useState<boolean>(false);

  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);

  const onDeleteGroup = async (id: number) => {
    await permissionsGroupApi.deletePermissionsGroup(id);
    setConfirmModal(false);

    const newItems = _.filter(paginationList.items, (it) => it.id !== id);
    paginationList._setItems(newItems);
  };

  const openDeleteModal = (id: number) => {
    setConfirmModal(true);
    setIdToDelete(id);
  };

  const columnsConfig = getColumnsConfig({
    profile,
    permissions,
    onEdit: props.onPressEdit,
    onDelete: openDeleteModal,
  });

  return (
    <>
      <ConfirmModal
        isShow={isShowDeleteModal}
        message={"Ви справді хочете видалити цю групу прав доступу?"}
        onConfirm={() => onDeleteGroup(groupIdToDelete)}
        setShow={setConfirmModal}
      />

      <Table
        tableName={"group-permissions"}
        columns={columnsConfig}
        paginationList={paginationList}
        showActionBottomBar={true}
        activeColumns={defaultColumnsActive}
        focusedFilterField={props.focusFilterKey}
        onFocusFilterField={props.setFocusFilterKey}
      />
    </>
  );
};
