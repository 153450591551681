import { authService } from "@/services/domain";
import { useSocketListener } from "@/shared";
import { useHistory } from "react-router-dom";

export const useAppSocketListener = () => {
  const history = useHistory();
  const onStopSession = async () => {
    await authService.stopSession();
    history.push("/");
  };

  useSocketListener("stopSessions", onStopSession, []);
};
