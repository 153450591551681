export enum RouteEnum {
  Login = "",
  Forgot = "/forgot",
  Recover = "/recover",
  Reset = "/reset/:key",

  Pages = "/pages",
  Factory = "/factory",
  User = "/user",
  ProfileDetail = "/profile_detail",
  UserDetail = "/user_detail/:id",
  GroupPermission = "/group_permission",
  GroupPermissionDetail = "/group_permission/:id",
  Tasks = "/tasks",
  Taxonomy = "/taxonomy",
  TaxonomyType = "/taxonomy/:type",
  LogsUser = "/logs_users",
  Ips = "/ips",
  Chats = "/chats",
  Contacts = "/contacts",
  Contact = "/contact",
}
