import { getIconNameByExtension, IconComponent, MessageType } from "@/shared";
import React, { useMemo, useState } from "react";
import ReactPlayer from "react-player/lazy";

interface IProps {
  type: MessageType;
  uri?: string;
  defaultPreview?: JSX.Element;
  // containerStyle?: StyleProp<ViewStyle>
}

export const MessageMediaPreview = ({
  type,
  uri,
  defaultPreview,
}: // containerStyle,
IProps) => {
  const [paused] = useState(false);

  const getContent = () => {
    if (type === MessageType.File) {
      const iconName = getIconNameByExtension(uri);
      return (
        <IconComponent className="reply-message_file-icon" name={iconName} />
      );
    }

    if (type === MessageType.Image)
      return (
        <div>
          <img src={uri} height={40} />
        </div>
      );

    if (type === MessageType.Video)
      return (
        <div style={{ height: "40px" }}>
          <ReactPlayer
            url={uri}
            className="react-player"
            controls={true}
            playing={false}
            width="100%"
            height="100%"
          />
        </div>
      );

    return defaultPreview;
  };

  const content = useMemo(() => getContent(), [type, uri, paused]);

  if (!content) return null;

  return <div className="message-media-container">{content}</div>;
};
