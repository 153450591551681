import React, { FC } from "react";
import "./style.scss";
import { DateField } from "@/components/Fields";
import { Controller } from "react-hook-form";
import {
  InputField,
  InputPasswordField,
  IUser,
  InputMaskField,
  emailPattern,
  loginPattern,
} from "@/shared";
import { Button } from "reactstrap";
import Modal from "../../../../components/Modal";
import { useProfileForm } from "./hooks";
import { GeneratePassword } from "../../atoms/generatePassword";
import { ModalValidationUserName } from "../../atoms/modalValidationUserName";
import moment from "moment";

interface IProps {
  profile: IUser;
  authProfileId: number;
  factory: any;
  onSuccessUpdate?: () => void;
  onError?: () => void;
}

export const FormUser: FC<IProps> = ({
  profile,
  authProfileId,
  onSuccessUpdate,
  onError,
}) => {
  const {
    errors,
    control,
    onSubmit,
    generatePassword,
    submitChageAccount,
    validateFullName,
    validateLogin,
    validatePhoneNumber,
    validatePersonalPhoneNumber,
    validateEmail,
    isErrorModal,
    isDisableBtns,
    getValues,
    setError,
    setValue,
  } = useProfileForm({
    profile,
    authId: authProfileId,
    onSuccessUpdate,
    onError,
  });

  const disabledBirthDates = (current) => {
    return moment().add(0, "days") <= current;
  };

  const submitAndSendPassword = (sendTo: "email" | "sms") => {
    setValue("sendPasswordBy", sendTo);
    onSubmit();
  };

  const submitChanges = () => {
    setValue("sendPasswordBy", null);
    onSubmit();
  };

  return (
    <>
      <Modal show={isErrorModal.isShow} title={"Профіль не збережено"}>
        <h4 style={{ textAlign: "center", marginBottom: 30 }}>
          {isErrorModal.message}
        </h4>
        <div style={{ padding: "0 10px" }}></div>
      </Modal>
      <ModalValidationUserName submitChageAccount={submitChageAccount} />
      <form
        className="form"
        onSubmit={submitChanges}
        style={{ flexDirection: "column" }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div className="col-md-6" style={{ paddingLeft: 0 }}>
            <Controller
              name="fullName"
              control={control}
              rules={{
                required: "Заповнити обов'язково",
                validate: (val: string) =>
                  val.split(" ").length >= 3 || "Введіть ПІБ повністю",
              }}
              render={({ field: { value, onChange }, fieldState }) => {
                return (
                  <InputField
                    error={fieldState.error?.message || errors["userExist"]}
                    placeholder={"П.І.Б."}
                    label={"П.І.Б."}
                    onChange={(text) => {
                      onChange(text);
                      validateFullName(text);
                    }}
                    value={value}
                  />
                );
              }}
            />
          </div>
          <div className="col-md-6" style={{ paddingLeft: 0 }}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Заповнити обов'язково",
                pattern: {
                  value: emailPattern,
                  message: "Email введено не вірно",
                },
              }}
              render={({ field: { value, onChange }, fieldState }) => {
                return (
                  <InputField
                    required
                    error={fieldState?.error?.message || errors["email"]}
                    placeholder={"Email"}
                    label={"Email"}
                    onChange={(text) => {
                      setError({ ...errors, email: "" });
                      onChange(text);
                      validateEmail(text);
                    }}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="row row-profile-user">
          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: "Заповнити обов'язково",
                validate: (val) =>
                  moment.utc().subtract(18, "years") > moment(val) ||
                  "Вік не може бути менше 18 років",
              }}
              render={({ field: { value, onChange }, fieldState }) => (
                <DateField
                  label={"Дата народження"}
                  placeholder={"Дата народження"}
                  disabledDate={disabledBirthDates}
                  onChange={onChange}
                  value={value}
                  meta={{
                    error: fieldState?.error?.message,
                  }}
                />
              )}
            />
          </div>

          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "Заповнити обов'язково",
                validate: (val: string) =>
                  val !== getValues("personalPhoneNumber") ||
                  "Не може співпадати з особистим номером",
              }}
              render={({ field: { value, onChange }, fieldState }) => {
                return (
                  <InputMaskField
                    error={fieldState?.error?.message || errors["phoneNumber"]}
                    required={value.length > 0 ? true : false}
                    label={"Телефон робочий"}
                    value={value}
                    placeholder={"+38 (0xx) xxx xx xx"}
                    mask="+380 (999) 999 99 99"
                    onChange={(text) => {
                      setError({
                        ...errors,
                        phoneNumber: "",
                      });
                      onChange(text.replace(/[^+\d]/g, ""));
                      validatePhoneNumber(text);
                    }}
                  />
                );
              }}
            />
          </div>

          <div
            className="col-md-3 inner-phone-number"
            style={{ paddingLeft: 0 }}
          >
            <Controller
              name="innerPhoneNumber"
              control={control}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputField
                  error={fieldState?.error?.message}
                  label={"Телефон внутрішній"}
                  value={value}
                  placeholder={"00 000"}
                  onChange={(val: string) =>
                    onChange(val.replace(/[^\d]/g, ""))
                  }
                />
              )}
            />
          </div>

          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <Controller
              name="personalPhoneNumber"
              control={control}
              rules={{
                validate: (val: string) => {
                  if (!val) return true;
                  return (
                    val !== getValues("phoneNumber") ||
                    "Не може співпадати з робочим номером"
                  );
                },
              }}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputMaskField
                  error={
                    fieldState?.error?.message || errors["personalPhoneNumber"]
                  }
                  label={"Телефон особитий"}
                  value={value}
                  placeholder={"+38 (0xx) xxx xx xx"}
                  mask="+380 (999) 999 99 99"
                  onChange={(text) => {
                    setError({ ...errors, personalPhoneNumber: "" });
                    onChange(text.replace(/[^+\d]/g, ""));
                    validatePersonalPhoneNumber(text);
                  }}
                />
              )}
            />
          </div>
        </div>

        <div
          className="row row-profile-user"
          // style={{ width: "100%", margin: 0, marginTop: 20 }}
        >
          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <Controller
              name="position"
              control={control}
              rules={{ required: "Заповнити обов'язково" }}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputField
                  placeholder={"Посада"}
                  label={"Посада"}
                  inputStyle={{ width: "85%" }}
                  onChange={onChange}
                  value={value}
                  error={fieldState?.error?.message}
                />
              )}
            />
          </div>
          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <Controller
              name="login"
              control={control}
              rules={{
                required: "Заповнити обов'язково",
                pattern: {
                  value: loginPattern,
                  message:
                    "Логін може містити тільки цифри та латинські літери",
                },
              }}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputField
                  placeholder={"Логін"}
                  label={"Логін"}
                  error={fieldState?.error?.message || errors["loginExist"]}
                  onChange={(text) => {
                    setError({ ...errors, loginExist: "" });
                    onChange(text);
                    validateLogin(text);
                  }}
                  value={value}
                />
              )}
            />
          </div>
          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <div className="password-container">
              <Controller
                name="newPassword"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <>
                      <div style={{ width: "100%" }}>
                        <InputPasswordField
                          placeholder={"Новий пароль"}
                          label={"Новий пароль"}
                          onChange={onChange}
                          value={value}
                        />
                      </div>
                      <GeneratePassword generatePassword={generatePassword} />
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                validate: (val: string) =>
                  val === getValues("newPassword") || "Паролі не співпадають",
              }}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputPasswordField
                  placeholder={"Підтвердити пароль"}
                  label={"Підтвердити пароль"}
                  onChange={onChange}
                  error={fieldState?.error?.message}
                  value={value}
                />
              )}
            />
          </div>
        </div>

        <div style={{ paddingLeft: 0, marginTop: 30, display: "flex" }}>
          <Button
            onClick={submitChanges}
            color="primary"
            className="btn save-profile__btn"
          >
            Зберегти
          </Button>

          <Button
            className="btn save-profile__btn"
            color="primary"
            type="button"
            disabled={isDisableBtns}
            onClick={() => submitAndSendPassword("email")}
          >
            Зберегти{" "}
            <i style={{ marginLeft: 10 }} className="fal fa-envelope" />
          </Button>

          <Button
            className="btn save-profile__btn"
            color="primary"
            type="button"
            disabled={isDisableBtns}
            onClick={() => submitAndSendPassword("sms")}
          >
            Зберегти <i style={{ marginLeft: 10 }} className="fal fa-sms" />
          </Button>
        </div>
      </form>
    </>
  );
};
