import React, { FC, Fragment, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Loader, MainWrapper } from "@/shared";
import { useSelector } from "react-redux";
import { getThemeColor } from "@/store/shared";
import { RouteEnum } from "./routes.enum";
import { routesConfig } from "./routes.config";
import { Layout } from "@/containers/Layout";
import { getProfile, isLoadingAccount } from "@/store/account";
import { useAppSocketListener } from "../hooks";
import { needRedirect } from "@/services/system";

export const AppRouter: FC = () => {
  const themeColor = useSelector(getThemeColor);
  const account = useSelector(getProfile);
  const isLoading = useSelector(isLoadingAccount);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (account && (location.pathname === "" || location.pathname === "/"))
      history.push(RouteEnum.Tasks);
  }, [location.pathname, account]);

  useAppSocketListener();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (needRedirect.to) {
        history.push(needRedirect.to, needRedirect.payload);
        needRedirect.to = null;
        needRedirect.payload = null;
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  if (isLoading) return <Loader />;

  const modules = () => {
    if (!account) {
      return (
        <Fragment>
          {routesConfig.authRoutes.map((item, i) => (
            <Route key={i} exact path={item.path} component={item.component} />
          ))}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Layout />
        <div className="container__wrap">
          {routesConfig.privateRoutes.map((item, i) => (
            <Route
              key={i}
              exact
              path={item.path}
              component={item.component as any}
            />
          ))}
        </div>
      </Fragment>
    );
  };

  return (
    <MainWrapper theme={themeColor}>
      <Switch>{modules()}</Switch>
    </MainWrapper>
  );
};
