import { RecoverPassword, SendCode, SignIn } from "@/containers/auth";
import { GroupPermission } from "@/containers/GroupPermissions";
import { Factory } from "@/containers/Factory";
import { Taxonomy } from "@/containers/Taxonomy";
import { RouteEnum } from "./routes.enum";
import { ProfileDetail } from "@/containers/Profile";
import { UserDetail } from "@/containers/UserDetail";
import { Users } from "@/containers/User";
import { Ips } from "@/containers/Ips";
import { Logs } from "@/containers/Logs";
import { ContactsUsers } from "@/containers/ContactsUsers";
import { TasksScreen } from "@/containers/Tasks";
import { ContactPage } from "@/containers/Contact";
import ChatsPage from "@/containers/Chats/chats.screen";
import { SecretModPage } from "@/containers/SecretMod";

const authRoutes = [
  {
    path: RouteEnum.Forgot,
    component: SendCode,
  },
  {
    path: RouteEnum.Recover,
    component: RecoverPassword,
  },
  {
    path: RouteEnum.Login,
    component: SignIn,
  },
];

const privateRoutes = [
  {
    path: RouteEnum.Tasks,
    component: TasksScreen,
  },
  {
    path: RouteEnum.Factory,
    component: Factory,
  },
  {
    path: RouteEnum.User,
    component: Users,
  },
  {
    path: RouteEnum.ProfileDetail,
    component: ProfileDetail,
  },
  {
    path: RouteEnum.UserDetail,
    component: UserDetail,
  },
  {
    path: RouteEnum.GroupPermission,
    component: GroupPermission,
  },
  {
    path: RouteEnum.Taxonomy,
    component: Taxonomy,
  },
  {
    path: RouteEnum.TaxonomyType,
    component: Taxonomy,
  },
  {
    path: RouteEnum.LogsUser,
    component: Logs,
  },
  {
    path: RouteEnum.Ips,
    component: Ips,
  },
  {
    path: RouteEnum.Chats,
    component: ChatsPage,
  },
  {
    path: RouteEnum.Contacts,
    component: ContactsUsers,
  },
  {
    path: RouteEnum.Contact,
    component: ContactPage,
  },
  {
    path: "/mod",
    component: SecretModPage,
  },
];

export const routesConfig = {
  authRoutes,
  privateRoutes,
};
