import { tasksApi } from "@/api";
import { createFullName, ITask } from "@/shared";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { TabPane } from "reactstrap";
import { taskStatusTitleRelation } from "../consts";
import { ETasksModalTabs } from "../enums";

interface IProps {
  taskId: number;
}

export const ModalTabDetails: FC<IProps> = ({ taskId }) => {
  const [task, setTask] = useState<ITask>(null);

  const fetchTaskDetails = async (taskId) => {
    const { data } = await tasksApi.getTaskDetails(taskId);
    if (data) setTask(data);
  };

  useEffect(() => {
    if (taskId) fetchTaskDetails(taskId);
  }, [taskId]);

  if (!task) return null;

  return (
    <TabPane tabId={ETasksModalTabs.TaskDetails}>
      <section className="details-tab_dates">
        <div className="details-tab_dates-column">
          <div>
            <span className="bold-text">{"Створення:  "}</span>
            <span>{`${moment(task.createdAt).format("DD-MM-YYYY")}`}</span>
          </div>
          <div>
            <span className="bold-text">{"Кінець:  "}</span>
            <span>{`${moment(task.endDate).format("DD-MM-YYYY")}`}</span>
          </div>
        </div>
        <div className="details-tab_dates-column">
          <div>
            <span className="bold-text">{"Початок:  "}</span>
            <span>{`${moment(task.startDate).format("DD-MM-YYYY")}`}</span>
          </div>
          <div>
            <span className="bold-text">{"Виконано:  "}</span>
            <span>{`${
              task.doneDate ? moment(task.doneDate).format("DD-MM-YYYY") : ""
            }`}</span>
          </div>
        </div>
      </section>

      <section className="details-tab_name">
        <div>
          <span className="bold-text">{"Назва:  "}</span>
          <span>{task.name}</span>
        </div>
      </section>

      <section className="details-tab_description">
        <div>
          <span className="bold-text">{"Опис:  "}</span>
          <span>{task.description}</span>
        </div>
      </section>

      <section className="details-tab_group">
        <div>
          <span className="bold-text">{"Група:  "}</span>
          <span>{task.group?.name}</span>
        </div>
      </section>

      <section className="details-tab_reason">
        <div>
          <span className="bold-text">{"Підстава:  "}</span>
          <span>{task.reason?.name}</span>
        </div>
      </section>

      <section className="details-tab_users">
        <div className="details-tab_users-column">
          <div>
            <span className="bold-text">{"Хто створив:  "}</span>
            <span>
              {createFullName(
                task.author?.firstName,
                task.author?.middleName,
                task.author?.lastName
              )}
            </span>
          </div>
          <div>
            <span className="bold-text">{"Ініціатор:  "}</span>
            <span>
              {createFullName(
                task.initiator?.firstName,
                task.initiator?.middleName,
                task.initiator?.lastName
              )}
            </span>
          </div>
        </div>
        <div className="details-tab_dates-column">
          <div>
            <span className="bold-text">{"Виконавець:  "}</span>
            <span>
              {createFullName(
                task.executor?.firstName,
                task.executor?.middleName,
                task.executor?.lastName
              )}
            </span>
          </div>
          <div>
            <span className="bold-text">{"Статус:  "}</span>
            <span>{taskStatusTitleRelation[task.status]}</span>
          </div>
        </div>
      </section>
    </TabPane>
  );
};
