import React, { FC } from "react";
import { Avatar } from "./avatar.atom";
import "./style.scss";

interface IProps {
  isOnline?: boolean;
  imageUrl?: string;
  size: number;
}

export const ChatAvatarWithOnlineIndicator: FC<IProps> = ({
  imageUrl,
  size,
}) => {
  return (
    <div className="chat-avatar-style">
      {/* {isOnline ? <div className="online-indicator" /> : null} */}

      <Avatar imageUrl={imageUrl} maxHeight={size} maxWidth={size} />
    </div>
  );
};
