import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import UploadDocumentSVG from "@/assets/img/upload-document.svg";
import "./style.scss";
import { ETaskModalMode, ETasksModalTabs } from "../enums";
import { TabPane } from "reactstrap";
import {
  checkTaskFilesSize,
  ConfirmModal,
  IFile,
  notification,
} from "@/shared";
import { FileItem } from "./file-item.component";
import { useTaskDocs } from "../hooks";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { getFilesConfig } from "@/store/shared";

interface IProps {
  newFiles: (IFile & { id: number })[];
  onSelect: (files: IFile[]) => void;
  onDelete: (id: number) => void;
  onTaskFilesChange: (fileIds: number[]) => void;
  mode: ETaskModalMode;
  isActive: boolean;
  taskId?: number;
  disabled?: boolean;
}

export const ModalTabDocument: FC<IProps> = ({
  newFiles,
  onSelect,
  onDelete,
  onTaskFilesChange,
  taskId,
  disabled,
  mode,
  isActive,
}) => {
  const [showConfirmModal, setConfirmModal] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { taskFilesTypes, taskFilesSize } = useSelector(getFilesConfig);

  const {
    taskDocs,
    addDocs,
    deleteDoc,
    setDeleteDocId,
    softDelete,
  } = useTaskDocs({
    taskId,
    needInit: isActive,
  });

  const onChange = useCallback(
    async (files: IFile[]) => {
      const { exceeded } = checkTaskFilesSize(files, taskFilesSize * 1000);
      if (!_.isEmpty(exceeded)) {
        notification.showError(
          "Помилка",
          `Не можна завантажувати файли розміром більше ${taskFilesSize}КB`
        );
      } else {
        if (taskId && mode !== ETaskModalMode.Copy) await addDocs(files);
        else onSelect(files);
      }
    },
    [taskId, addDocs, onSelect]
  );

  useEffect(() => onTaskFilesChange(taskDocs?.map((it) => it.id)), [
    taskDocs,
    onTaskFilesChange,
  ]);

  const acceptTypes = useMemo(
    () =>
      taskFilesTypes
        .split(",")
        .map((it) => `.${it}`)
        .join(","),
    [taskFilesTypes]
  );

  const filesTypesMessage = useMemo(() => acceptTypes.split(",").join("/"), [
    acceptTypes,
  ]);

  return (
    <TabPane tabId={ETasksModalTabs.Docs}>
      <ConfirmModal
        isShow={showConfirmModal}
        message={"Ви справді бажаєте видалити цей документ?"}
        onConfirm={() => {
          setConfirmModal(false);
          deleteDoc();
        }}
        setShow={setConfirmModal}
      />

      <div
        className={classNames({
          "upload-document": true,
          empty: _.isEmpty(taskDocs),
        })}
      >
        {!disabled && (
          <>
            <div
              className="upload-container"
              onClick={() => inputFileRef.current?.click()}
            >
              <input
                hidden
                type="file"
                multiple={true}
                accept={acceptTypes}
                ref={inputFileRef}
                size={taskFilesSize}
                onChange={(e) => {
                  onChange(Array.from(e.target.files));
                }}
              />

              <img src={UploadDocumentSVG} />

              <span>Завантажити файл</span>
            </div>

            <span className="help-txt">
              {`
              Увага! Дозволяється звантажувати документи та зображення, розміром
              не більше ${taskFilesSize / 1000}МВ та з наступними розширенями:
              ${filesTypesMessage}`}
            </span>
          </>
        )}

        <div className="files-list">
          {newFiles.map((it) => (
            <FileItem
              key={it.id}
              title={it.name}
              onDelete={() => onDelete(it.id)}
              date={moment().format("DD-MM-YYYY")}
            />
          ))}
          {taskDocs?.map((it) => (
            <FileItem
              key={it.id}
              title={it.name}
              date={it.date}
              onDownload={
                mode === ETaskModalMode.Copy
                  ? null
                  : () => window.open(it.url, "_blank", "noopener,noreferrer")
              }
              onDelete={
                disabled
                  ? undefined
                  : () => {
                      if (mode === ETaskModalMode.Copy) {
                        softDelete(it.id);
                      } else {
                        setDeleteDocId(it.id);
                        setConfirmModal(true);
                      }
                    }
              }
            />
          ))}
        </div>
      </div>
    </TabPane>
  );
};
